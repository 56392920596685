import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import ButtonContainer from "../components/ButtonContainer";
import FilterAccordion from "../components/FilterAccordion";
import ListingTableComponent from "../components/ListingTableComponent";
import { vehicleTrashCreateData, vehicleStatus } from "../misc/createData";
import {
  ConditionReportStatus,
  TransportRequestStatus,
  VehicleStatus,
} from "../misc/enum";
import {
  useLazyGetVehiclesQuery,
  useUpdateVehicleMutation,
} from "../store/reducer/vehicle";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { appendQueryParams } from "../misc/api.utils";
import {
  calculateDays,
  checkBoolean,
  formatDate,
  numericValueOrNull,
  prioritizeAndMergeObject,
} from "../misc/utils";
import {
  NUMBER,
  PDF_TITLE,
  PDF_PAGE_STYLE,
  DATE_FORMAT,
  DATE_TYPE,
  ACTION_BUTTON_TYPES,
} from "../misc/constants";
import { useReactToPrint } from "react-to-print";
import { useAppDispatch, useAppSelector } from "../hooks/redux-toolkit";
import { Tabs } from "../misc/access.enum";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { MESSAGES } from "../misc/messages";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";
import { useSuccessDispatcher } from "../hooks/useSuccessDispatcher";
import { toggleProgressBar } from "../store/reducer/progressBar";

const VehiclesTrash = () => {
  const dispatch = useAppDispatch();
  const errorDispatcher = useErrorDispatcher();
  const successDispatcher = useSuccessDispatcher();
  const reference = useRef<HTMLTableSectionElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.TRASH,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const { permissions } = useAppSelector((state) => state.authorization);

  const [vehicleRows, setVehicleRows] = useState<[] | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    make: "",
    model: "",
    location: NUMBER.MINUS_ONE,
    announcement: NUMBER.MINUS_ONE,
    dealerName: NUMBER.MINUS_ONE,
    startAge: "",
    endAge: "",
    startYear: "",
    endYear: "",
    vin: "",
  });

  const inputRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);

  const [getVehicles, { data: vehicleStateData }] = useLazyGetVehiclesQuery();
  const [updateVehicle] = useUpdateVehicleMutation();

  const handleExport = () => {
    const vehiclesData = vehicleStateData?.data?.vehicles;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.RemoveFromLotTab,
      vehiclesData
    );
    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Remove From Lot (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const mapDataToTableRows = (vehiclesData: any) => {
    const pendingStatus = [
      TransportRequestStatus.Picked,
      TransportRequestStatus.Dropped,
    ];
    const droppedStatus = [TransportRequestStatus.Dropped];
    return vehiclesData.map((vehicleData: any) => {
      const age = calculateDays(vehicleData?.created_at);
      const remarks = vehicleData?.remarks?.length
        ? vehicleData?.remarks?.map((remark: any) => remark?.label)?.join(", ")
        : "N/A";

      return vehicleTrashCreateData(
        vehicleData?.id,
        vehicleData?.condition_report?.id,
        vehicleData?.vin,
        vehicleData?.make,
        vehicleData?.model,
        vehicleData?.year,
        vehicleData?.dealer?.name,
        vehicleData?.location?.name,
        `${age?.toString()} ${age === 1 ? "Day" : "Days"}`,
        vehicleData?.lane || "N/A",
        vehicleData?.run || "N/A",
        vehicleData?.announcement?.label,
        remarks,
        numericValueOrNull(vehicleData?.acv),
        numericValueOrNull(vehicleData?.dealer_stock_number),
        numericValueOrNull(vehicleData?.floor_price),
        vehicleStatus[`${vehicleData?.status}`],
        vehicleData?.comment || "N/A",
        vehicleData?.condition_report?.rating,
        vehicleData?.condition_report?.status !==
          ConditionReportStatus.Requested &&
          vehicleData?.condition_report?.report_url &&
          vehicleData?.condition_report?.rating,
        pendingStatus?.includes(
          vehicleData?.transport_request?.[NUMBER.ZERO]?.status
        ),
        droppedStatus?.includes(
          vehicleData?.transport_request?.[NUMBER.ZERO]?.status
        ),
        checkBoolean(permissions?.[Tabs.VINModal]),
        checkBoolean(permissions?.remove_from_lot)
      );
    });
  };

  async function fetchData(queryParams: DynamicKeyValuesWithNumArray) {
    let apiParams = ``;

    const queryParamObj: DynamicKeyValuesWithNumArray = {
      status: VehicleStatus.Removed,
      page: (queryParams.page as number) + 1,
      limit: queryParams.rowsPerPage as number,
      make: queryParams?.make as string,
      model: queryParams?.model as string,
      startYear: queryParams?.startYear as string,
      endYear: queryParams?.endYear as string,
      startAge: queryParams?.startAge as string,
      endAge: queryParams?.endAge as string,
      vin: queryParams?.vin as string,
    };

    const location = queryParams.location;
    const announcement = queryParams.announcement;
    const dealerName = queryParams.dealerName;

    if (location && Number(location) >= NUMBER.ZERO)
      queryParamObj["location"] = location;
    if (announcement && Number(announcement) >= NUMBER.ZERO)
      queryParamObj["announcementId"] = announcement;
    if (dealerName && Number(dealerName) >= NUMBER.ZERO)
      queryParamObj["dealerId"] = dealerName;

    apiParams += appendQueryParams(queryParamObj, true);

    try {
      const response: any = await getVehicles(apiParams);

      const data = response?.data?.data;
      const vehicles = data?.vehicles;
      const count = data?.count;

      if (!vehicles) {
        setVehicleRows([]);
        return;
      }

      const vehiclesData = mapDataToTableRows(vehicles);

      setVehicleRows(vehiclesData);

      setTotalRowsCount(count);
    } catch (error) {}
  }

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); //eslint-disable-line

  useEffect(() => {
    if (vehicleStateData?.data?.vehicles) {
      let vehiclesData = vehicleStateData?.data?.vehicles;
      vehiclesData = mapDataToTableRows(vehiclesData);
      setVehicleRows(vehiclesData);
      setTotalRowsCount(vehicleStateData?.data?.count);
    }
  }, [vehicleStateData]); //eslint-disable-line

  const applyFilter = (values: DynamicKeyValuesWithNumArray) => {
    setVehicleRows(null);
    const prioritizedQueryParams = prioritizeAndMergeObject(
      values,
      queryParams,
      [
        "page",
        "rowsPerPage",
        "make",
        "model",
        "vin",
        "location",
        "announcement",
        "dealerName",
        "startAge",
        "endAge",
        "startYear",
        "endYear",
      ]
    );

    setQueryParams({
      ...queryParams,
      ...prioritizedQueryParams,
    });
  };

  const triggerFilter = (values: DynamicKeyValuesWithNumArray) => {
    applyFilter({
      ...values,
      vin: (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement)?.value,
      page: NUMBER.ZERO,
    });
  };

  const emptyVin = () => {
    if (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement) {
      (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement).value = "";
    }
  };

  const handleActionButton = async (type: string, id: string) => {
    let response: any;
    let message: string = "";
    dispatch(toggleProgressBar(true));
    switch (type) {
      case ACTION_BUTTON_TYPES.MOVE_TO_INVENTORY:
        response = await updateVehicle({
          id,
          payload: {
            status: VehicleStatus.Inventory,
          },
        });
        message = MESSAGES.VEHICLE.MOVED_TO_INVENTORY_SUCCESS;
        break;
      default:
        break;
    }
    dispatch(toggleProgressBar(false));
    if (!response?.error) successDispatcher(true, message);
  };

  return (
    <Box>
      <ButtonContainer
        hideRightSearchField={false}
        inputRefs={inputRefs}
        handlePrint={handlePrint}
        handleExport={handleExport}
        handleVinSearch={() => {
          triggerFilter({
            ...queryParams,
            vin: (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement)?.value,
          });
        }}
        disableButtons={vehicleRows ? vehicleRows?.length === 0 : true}
      />
      <FilterAccordion
        status={"In Removed"}
        triggerFilter={triggerFilter}
        emptyVin={emptyVin}
      />
      <ListingTableComponent
        reference={reference}
        rows={vehicleRows}
        showCheckbox={false}
        rowCount={totalRowsCount}
        applyFilter={applyFilter}
        queryParams={queryParams}
        handleActionButton={handleActionButton}
      />
    </Box>
  );
};

export default VehiclesTrash;
