import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AuthDataPayload,
  ErrorPayload,
  NewMessagePayload,
  SuccessPayload,
} from "../../ts/api.types";
import { AuthState } from "../../ts/api.interface";
import { SNACKBAR_VARIANT } from "../../misc/constants";

const initialState: AuthState = {
  role: 0,
  userId: null,
  signature: "",
  token: "",
  isAuthenticated: false,
  error: false,
  errorMessage: "",
  success: false,
  isInvalidMessage: false,
  successMessage: "",
  variant: "",
  permissions: {},
  newMessages: false,
  caseId: null,
};

export const authSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<AuthDataPayload>) => {
      const { payload } = action;
      state.role = payload.role;
      state.signature = payload.signature;
      state.token = payload.token;
      state.isAuthenticated = payload.isAuthenticated;
      state.permissions = payload.permissions;
      state.userId = payload.userId;
    },
    setError: (state, action: PayloadAction<ErrorPayload>) => {
      const { payload } = action;
      state.error = payload.error || false;
      state.errorMessage = payload.errorMessage;
      state.isInvalidMessage = payload.isInvalidMessage || false;
    },
    setSuccess: (state, action: PayloadAction<SuccessPayload>) => {
      const { payload } = action;
      state.success = payload.success || false;
      state.successMessage = payload.successMessage;
      state.variant = payload?.variant || SNACKBAR_VARIANT.SUCCESS;
      state.isInvalidMessage = false;
    },
    setNewMessages: (state, action: PayloadAction<NewMessagePayload>) => {
      const { payload } = action;
      state.newMessages = payload?.status;
      state.caseId = payload?.caseId || null;
    },
    resetAuthData: (state) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAuthData,
  setError,
  setSuccess,
  setNewMessages,
  resetAuthData,
} = authSlice.actions;

export default authSlice.reducer;
