import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import TableComponent from "../components/TableComponent";
import {
  transportRequestColumns,
  transportRequestCreateData,
} from "../misc/createData";
import {
  ADMIN_ROLES,
  DATE_FORMAT,
  DATE_TYPE,
  MODAL_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
  TABLE_COLUMNS,
} from "../misc/constants";

import { toggleModal } from "../store/reducer/modal";
import {
  useAcceptTransportRequestMutation,
  useAlternateTransportRequestMutation,
  useLazyGetUnassignedTransportRequestsQuery,
} from "../store/reducer/transportRequest";
import { useGetUserDataQuery } from "../store/reducer/user";
import { useLazyGetLocationsQuery } from "../store/reducer/location";
import {
  TransportRequestStatus,
  TransportationRequest,
  UserTypes,
} from "../misc/enum";
import {
  Column,
  Data,
  ModalSelectOption,
  MultipleValues,
  SelectOption,
} from "../ts/component.types";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { appendQueryParams } from "../misc/api.utils";
import { toggleProgressBar } from "../store/reducer/progressBar";
import { useLazyGetTransportersQuery } from "../store/reducer/transporter";
import { checkBoolean, formatDate, hasAccess } from "../misc/utils";
import { useAppSelector } from "../hooks/redux-toolkit";
import ButtonContainer from "../components/ButtonContainer";
import { SelectChangeEvent } from "@mui/material/Select/Select";
import { MESSAGES } from "../misc/messages";
import { useReactToPrint } from "react-to-print";
import { Actions, Tabs } from "../misc/access.enum";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { useSuccessDispatcher } from "../hooks/useSuccessDispatcher";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";

type DateRangeType = {
  startDate: string;
  endDate: string;
};

type TransporterRows = {
  id: string;
  createdAt: string;
  pickupLocation: string;
  dropoffLocation: string;
  status: string;
  contactInfo: MultipleValues;
  alternateDate: string;
  vin: string;
  year: string;
  make: string;
  model: string;
  color: string;
  actionType: number;
};

type QueryParamType = {
  id: string;
  page: number;
  rowsPerPage: number;
  activeStatus: string;
  contactInfo: string;
  pickupLocation: string;
  dropoffLocation: string;
  alternateDate: DateRangeType;
  pickupDate: DateRangeType;
};

const TransportRequest = () => {
  const reference = useRef<HTMLTableElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.TRANSPORT_REQUEST,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const dispatch = useDispatch();
  const successDispatcher = useSuccessDispatcher();
  const errorDispatcher = useErrorDispatcher();
  const { role, permissions } = useAppSelector((state) => state.authorization);

  const { data: userData, isSuccess } = useGetUserDataQuery("");
  const [getTransporters, { data: transporterStateData }] =
    useLazyGetTransportersQuery();
  const [getLocations, { data: locationsData }] = useLazyGetLocationsQuery();
  const [getTransportRequests, { data: transportRequestStateData }] =
    useLazyGetUnassignedTransportRequestsQuery();

  const [
    acceptTransportRequest,
    {
      isSuccess: acceptTransportRequestSuccess,
      isError: acceptTransportRequestError,
    },
  ] = useAcceptTransportRequestMutation();

  const [
    requestAlternateDate,
    {
      isSuccess: requestAlternateDateSuccess,
      isError: requestAlternateDateError,
    },
  ] = useAlternateTransportRequestMutation();

  const [selectValue, setSelectValue] = useState<string>("");

  const [selectOptions, setSelectOptions] =
    useState<Array<SelectOption> | null>(null);

  const [transportRequestRows, setTransportRequestRows] =
    useState<Array<TransporterRows> | null>(null);

  const [fieldsData, setFieldsData] = useState<Column[]>([]);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    activeStatus: "active",
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    id: "",
    pickupLocation: "",
    dropoffLocation: "",
    contactInfo: "",
    alternateDate: {
      startDate: "",
      endDate: "",
    },
    pickupDate: {
      startDate: "",
      endDate: "",
    },
    status: TransportRequestStatus.Requested?.toString(),
  });

  const handleExport = () => {
    const transportationData =
      transportRequestStateData?.data?.transportRequests;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.UnAssignedTTransporterTab,
      transportationData
    );
    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Transport Requests (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const handleOpen = (id: string) => {
    const row = transportRequestRows?.find((row) => row?.id === id);
    dispatch(
      toggleModal({
        open: true,
        modalType: MODAL_TYPE.VEHICLE_LISTING,
        data: {
          vehicleData: {
            id: row?.vin as string,
            make: row?.make as string,
            model: row?.model as string,
            year: row?.year as string,
            color: row?.color as string,
          },
        },
      })
    );
  };

  const parseData = (
    data: readonly Column[],
    locationsData: Array<ModalSelectOption>
  ) =>
    data?.map((field) => {
      if (
        (field.id === TABLE_COLUMNS.PICKUP_LOCATION ||
          field.id === TABLE_COLUMNS.DROPOFF_LOCATION) &&
        locationsData
      ) {
        const locations = locationsData?.map((location) => {
          return {
            label: location.name,
            value: location.id,
          };
        });
        locations.push({
          label: "All",
          value: NUMBER.MINUS_ONE,
        });
        return {
          ...field,
          options: locations,
        };
      }

      return field;
    });

  const mapDataToTableRows = (transportRequests: any) => {
    const transporterId =
      userData?.data?.transporter_user?.transporter_id?.toString() ||
      selectValue;

    let rows = [];
    rows = transportRequests.map((transportRequest: any) => {
      let alternateDate = "";
      let actionType = TransportationRequest.Pending;

      if (transportRequest?.alternate_date_request?.length) {
        transportRequest?.alternate_date_request?.forEach((request: any) => {
          if (request?.transporter_id === transporterId) {
            actionType = TransportationRequest.Requested;
            alternateDate = formatDate(
              request?.alternate_date_time,
              DATE_TYPE.UTC_DATE_STRING,
              DATE_FORMAT.YYYY_MM_DD
            );
          }
        });
      }
      const createdAt = formatDate(
        transportRequest?.created_at,
        DATE_TYPE.TIMESTAMP,
        DATE_FORMAT.DD_MM_YYYY
      );

      const pickupDate = formatDate(
        transportRequest?.pickup_date_time,
        DATE_TYPE.UTC_DATE_STRING,
        DATE_FORMAT.DD_MM_YYYY
      );

      return transportRequestCreateData(
        transportRequest?.id,
        transportRequest?.vehicle?.vin || "N/A",
        transportRequest?.vehicle?.year || "N/A",
        transportRequest?.vehicle?.make || "N/A",
        transportRequest?.vehicle?.model || "N/A",
        transportRequest?.vehicle?.exterior_color || "N/A",
        createdAt,
        pickupDate,
        transportRequest?.pickup_location?.name,
        transportRequest?.dropoff_location?.name,
        TransportRequestStatus[transportRequest?.status],
        {
          value1: transportRequest?.name,
          value2:
            transportRequest?.country_code +
            " " +
            transportRequest?.phone_number,
        },
        alternateDate,
        actionType,
        !checkBoolean(permissions?.[Actions.AlternateDateRequest]),
        !checkBoolean(permissions?.[Actions.AcceptTransportRequest]),
        !checkBoolean(permissions?.[Actions.AssignedTransportActions])
      );
    });

    return {
      rows,
    };
  };

  async function fetchData(queryParams: QueryParamType) {
    const page = queryParams.page + 1;
    const limit = queryParams.rowsPerPage;
    const pickupLocation = queryParams.pickupLocation;
    const dropoffLocation = queryParams.dropoffLocation;

    let apiParams = `limit=${limit}&page=${page}`;

    const queryParamObj: DynamicKeyValuesWithNumArray = {
      id: queryParams.id,
      isActive: queryParams.activeStatus,
      contactInfo: queryParams.contactInfo,
      alternateStartDate:
        queryParams.alternateDate?.startDate &&
        formatDate(
          queryParams.alternateDate?.startDate,
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.YYYY_MM_DD
        ),
      alternateEndDate:
        queryParams.alternateDate?.endDate &&
        formatDate(
          queryParams.alternateDate?.endDate,
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.YYYY_MM_DD
        ),
      pickupStartDate:
        queryParams.pickupDate?.startDate &&
        formatDate(
          queryParams.pickupDate?.startDate,
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.YYYY_MM_DD
        ),
      pickupEndDate:
        queryParams.pickupDate?.endDate &&
        formatDate(
          queryParams.pickupDate?.endDate,
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.YYYY_MM_DD
        ),
    };

    if (pickupLocation && Number(pickupLocation) >= NUMBER.ZERO)
      queryParamObj["pickupLocation"] = pickupLocation;
    if (dropoffLocation && Number(dropoffLocation) >= NUMBER.ZERO)
      queryParamObj["dropoffLocation"] = dropoffLocation;

    apiParams += appendQueryParams(queryParamObj, true);

    try {
      const response = await getTransportRequests(apiParams);

      const data = response?.data?.data;
      const transportRequests = data?.transportRequests;
      const count = data?.count;

      if (!transportRequests) {
        setTransportRequestRows([]);
        return;
      }

      const { rows } = mapDataToTableRows(transportRequests);

      setTransportRequestRows(rows);

      setTotalRowsCount(count);
    } catch (error) {}
  }

  useEffect(() => {
    (async () => {
      if (isSuccess) await fetchData(queryParams);
    })();
  }, [queryParams, isSuccess]); //eslint-disable-line

  useEffect(() => {
    if (transportRequestStateData?.data?.transportRequests && isSuccess) {
      const transportRequests =
        transportRequestStateData?.data?.transportRequests;
      const count = transportRequestStateData?.data?.count;
      const { rows } = mapDataToTableRows(transportRequests);
      setTransportRequestRows(rows);
      setTotalRowsCount(count);
    }
  }, [transportRequestStateData, selectValue, isSuccess]);

  useEffect(() => {
    (acceptTransportRequestSuccess ||
      requestAlternateDateSuccess ||
      acceptTransportRequestError ||
      requestAlternateDateError) &&
      dispatch(toggleProgressBar(false));
  }, [
    acceptTransportRequestSuccess,
    requestAlternateDateSuccess,
    acceptTransportRequestError,
    requestAlternateDateError,
  ]);

  useEffect(() => {
    (async () => {
      if (hasAccess(ADMIN_ROLES, role as UserTypes)) {
        await getTransporters("");
      }
    })();
  }, []); //eslint-disable-line

  useEffect(() => {
    const queryParamObj = {
      isActive: "active",
    };
    const apiParams = appendQueryParams(queryParamObj, false);
    if (!locationsData) {
      getLocations(apiParams);
      return;
    }
    const modifiedFields = parseData(
      transportRequestColumns,
      locationsData?.data?.locations
    );
    setFieldsData(modifiedFields);
  }, [locationsData]); //eslint-disable-line

  useEffect(() => {
    const data = transporterStateData?.data?.transporters;
    if (data) {
      const transporters: Array<SelectOption> = [];
      transporterStateData?.data?.transporters?.forEach((transporter: any) => {
        transporters.push({
          label: transporter?.name,
          value: transporter?.id,
        });
      });

      setSelectOptions(transporters);
    }
  }, [transporterStateData]);

  const applyFilter = (values: {
    [key: string]:
      | number
      | boolean
      | string
      | {
          startDate: string;
          endDate: string;
        };
  }) => {
    setTransportRequestRows(null);
    setQueryParams({
      ...queryParams,
      activeStatus:
        (values?.activeStatus as string) || queryParams.activeStatus,
      page: values?.page as number,
      rowsPerPage: (values?.rowsPerPage as number) || queryParams.rowsPerPage,
      id: "id" in values ? (values?.id as string) : queryParams?.id,
      pickupLocation:
        "pickupLocation" in values
          ? (values?.pickupLocation as string)
          : queryParams?.pickupLocation,
      dropoffLocation:
        "dropoffLocation" in values
          ? (values?.dropoffLocation as string)
          : queryParams?.dropoffLocation,
      contactInfo:
        "contactInfo" in values
          ? (values?.contactInfo as string)
          : queryParams?.contactInfo,
      alternateDate: {
        startDate:
          "alternateDate" in values
            ? (values?.alternateDate as DateRangeType)?.startDate
            : queryParams?.alternateDate?.startDate,
        endDate:
          "alternateDate" in values
            ? (values?.alternateDate as DateRangeType)?.endDate
            : queryParams?.alternateDate?.endDate,
      },
      pickupDate: {
        startDate:
          "pickupDate" in values
            ? (values?.pickupDate as DateRangeType)?.startDate
            : queryParams?.pickupDate?.startDate,
        endDate:
          "pickupDate" in values
            ? (values?.pickupDate as DateRangeType)?.endDate
            : queryParams?.pickupDate?.endDate,
      },
    });
  };

  const handleAlternateDate = (id: number, date: Date) => {
    const modifiedTransporterRows =
      transportRequestRows?.map((transportRequest: any) => {
        if (transportRequest?.id === id) {
          return {
            ...transportRequest,
            alternateDate: date,
          };
        }
        return transportRequest;
      }) || [];
    setTransportRequestRows(modifiedTransporterRows);
  };

  const handleTransportRequest = async (values?: Data) => {
    let transporterId = userData?.data?.transporter_user?.transporter_id;
    if (!transporterId) {
      if (!selectValue) {
        errorDispatcher(true, MESSAGES.TRANSPORTER.SELECT_TRANSPORTER);
        return;
      }
      transporterId = Number(selectValue);
    }
    dispatch(toggleProgressBar(true));
    let successMessage = "";
    let response: any;
    if (values?.alternateDate) {
      const alternateDate = formatDate(
        values?.alternateDate?.toString(),
        DATE_TYPE.DATE_STRING,
        DATE_FORMAT.YYYY_MM_DD
      );

      response = await requestAlternateDate({
        id: values?.id as string,
        payload: {
          alternate_date_time: alternateDate as string,
          transporter_id: transporterId,
        },
      });
      successMessage = MESSAGES.TRANSPORTER.ALTERNATE_DATE_REQUEST_SENT;
    } else {
      response = await acceptTransportRequest({
        id: values?.id as string,
        payload: {
          transporter_id: transporterId,
        },
      });
      successMessage = MESSAGES.TRANSPORTER.ACCEPT_TRANSPORT_REQUEST_SUCCESS;
    }

    if (!response.error) successDispatcher(true, successMessage);
  };

  const handleTransporterChange = (
    event: SelectChangeEvent<HTMLSelectElement>
  ) => {
    setSelectValue(event?.target?.value?.toString());
  };

  return (
    <Box>
      <ButtonContainer
        selectLabel="Act as"
        selectPlaceholder="Transporter"
        selectValue={selectValue}
        selectOptions={selectOptions}
        handleSelectChange={handleTransporterChange}
        handlePrint={handlePrint}
        hideRightSearchField={true}
        handleExport={handleExport}
        disableButtons={
          transportRequestRows ? transportRequestRows?.length === 0 : true
        }
      />
      <TableComponent
        reference={reference}
        rows={transportRequestRows}
        columns={fieldsData}
        handleOpen={handleOpen}
        handleAlternateDate={handleAlternateDate}
        handleTransportRequest={handleTransportRequest}
        rowCount={totalRowsCount}
        applyFilter={applyFilter}
        queryParams={queryParams}
      />
    </Box>
  );
};

export default TransportRequest;
