import Box from "@mui/material/Box";
import Chart from "react-apexcharts";
import Loader from "../Loader";
import { ApexOptions } from "apexcharts";

type Props = {
  areaChart: {
    chart: { series: ApexOptions["series"]; options: ApexOptions };
    sum: number;
  } | null;
};

const WeeklySoldVehiclesAreaGraph = ({ areaChart }: Props) => {
  return (
    <Box
      className={`flex flex-col ${
        areaChart ? "items-start" : "items-center"
      } justify-evenly p-10 pl-8 bg-primary-100 rounded-md box-shadow max-h-[640px]`}
    >
      {areaChart ? (
        <>
          <Box className="pl-4 text-tertiary-500">
            Weekly Graph of Vehicle Sold
          </Box>
          <Box className="pl-4 text-6xl font-bold my-5">{areaChart?.sum}</Box>
          {/* TODO: Filter will be applied in future */}
          {/* <Box className="flex items-center justify-end w-full mb-4">
            <Box className="bg-secondary-500 text-center w-fit rounded-xl px-6 py-1 cursor-pointer text-sm mx-2">
              All
            </Box>
            <Box className="bg-primary-400  text-center w-fit rounded-xl px-6 py-1 cursor-pointer text-sm">
              Top 5 Dealers
            </Box>
          </Box> */}
          <Chart
            className="self-center mt-4 w-full"
            options={areaChart?.chart?.options}
            series={areaChart?.chart?.series}
            type="area"
            height="400"
          />
        </>
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default WeeklySoldVehiclesAreaGraph;
