import { SNACKBAR_VARIANT } from "../misc/constants";

export const globalSnackbarConfig: any = {
  anchorOrigin: { vertical: "top", horizontal: "right" },
  autoHideDuration: 3000,
};

export const reloadButtonSnackbarConfig: any = {
  anchorOrigin: { vertical: "bottom", horizontal: "right" },
  autoHideDuration: 10000,
  variant: SNACKBAR_VARIANT.RELOAD_BUTTON_SNACKBAR,
};

export const successSnackbarConfig = {
  ...globalSnackbarConfig,
  variant: SNACKBAR_VARIANT.SUCCESS,
};

export const errorSnackbarConfig = {
  ...globalSnackbarConfig,
  variant: SNACKBAR_VARIANT.ERROR,
};
