import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProgressState } from "../../ts/api.interface";

const initialState: ProgressState = {
    loading: false
};

export const progressBarSlice = createSlice({
    name: "progress",
    initialState,
    reducers: {
      toggleProgressBar(state, action: PayloadAction<boolean>){
        state.loading = action.payload;
      }
    },
  });
  
  export const { toggleProgressBar } =
  progressBarSlice.actions;
  
  export default progressBarSlice.reducer;