import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TableComponent from "../components/TableComponent";
import {
  transportRequestScheduledColumns,
  transportRequestCreateData,
} from "../misc/createData";
import {
  DATE_FORMAT,
  DATE_TYPE,
  MODAL_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
  TABLE_COLUMNS,
} from "../misc/constants";
import { toggleModal } from "../store/reducer/modal";
import {
  useLazyGetAssignedTransportRequestsQuery,
  usePickedTransportMutation,
  useDroppedTransportMutation,
} from "../store/reducer/transportRequest";
import { useLazyGetLocationsQuery } from "../store/reducer/location";
import { useGetUserDataQuery } from "../store/reducer/user";
import { TransportRequestStatus, TransportationRequest } from "../misc/enum";
import {
  Column,
  MultipleValues,
  ModalSelectOption,
} from "../ts/component.types";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { appendQueryParams } from "../misc/api.utils";
import { toggleProgressBar } from "../store/reducer/progressBar";
import { MESSAGES } from "../misc/messages";
import { useAppDispatch, useAppSelector } from "../hooks/redux-toolkit";
import { checkBoolean, formatDate } from "../misc/utils";
import ButtonContainer from "../components/ButtonContainer";
import { useReactToPrint } from "react-to-print";
import { Actions, Tabs } from "../misc/access.enum";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { useSuccessDispatcher } from "../hooks/useSuccessDispatcher";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";

type DateRangeType = {
  startDate: string;
  endDate: string;
};

type TransporterRows = {
  id: string;
  createdAt: string;
  dropoffDate: string;
  pickupLocation: string;
  dropoffLocation: string;
  status: string;
  contactInfo: MultipleValues;
  alternateDate: string;
  vin: string;
  year: string;
  make: string;
  model: string;
  color: string;
  actionType: number;
};

type QueryParamType = {
  id: string;
  page: number;
  rowsPerPage: number;
  status: string;
  contactInfo: string;
  activeStatus: string;
  pickupLocation: string;
  dropoffLocation: string;
  dropoffDate: DateRangeType;
  alternateDate: DateRangeType;
  pickupDate: DateRangeType;
};

const TransportScheduledTask = () => {
  const reference = useRef<HTMLTableElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.SCHEDULED_TASK,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const { permissions } = useAppSelector((state) => state?.authorization);
  const dispatch = useAppDispatch();
  const successDispatcher = useSuccessDispatcher();
  const errorDispatcher = useErrorDispatcher();
  const { data: userData } = useGetUserDataQuery("");
  const [getLocations, { data: locationsData }] = useLazyGetLocationsQuery();
  const [getTransportRequests, { data: transportRequestStateData }] =
    useLazyGetAssignedTransportRequestsQuery();
  const [
    pickedTransport,
    { isSuccess: pickedTransportSuccess, isError: pickedTransportError },
  ] = usePickedTransportMutation();
  const [
    droppedTransport,
    { isSuccess: droppedTransportSuccess, isError: droppedTransportError },
  ] = useDroppedTransportMutation();
  const [transportRequestRows, setTransportRequestRows] =
    useState<Array<TransporterRows> | null>(null);
  const [fieldsData, setFieldsData] = useState<Column[]>([]);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    activeStatus: "active",
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    id: "",
    dropoffDate: {
      startDate: "",
      endDate: "",
    },
    pickupLocation: "",
    dropoffLocation: "",
    contactInfo: "",
    alternateDate: {
      startDate: "",
      endDate: "",
    },
    pickupDate: {
      startDate: "",
      endDate: "",
    },
    status: "all",
  });

  const handleExport = () => {
    const transportationData =
      transportRequestStateData?.data?.transportRequests;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.UnAssignedTTransporterTab,
      transportationData
    );
    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Transport Scheduled Tasks (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const handleOpen = (id: string) => {
    const row = transportRequestRows?.find((row) => row?.id === id);
    dispatch(
      toggleModal({
        open: true,
        modalType: MODAL_TYPE.VEHICLE_LISTING,
        data: {
          vehicleData: {
            id: row?.vin as string,
            make: row?.make as string,
            model: row?.model as string,
            year: row?.year as string,
            color: row?.color as string,
          },
        },
      })
    );
  };

  const parseData = (
    data: readonly Column[],
    locationsData: Array<ModalSelectOption>
  ) =>
    data?.map((field) => {
      if (
        (field.id === TABLE_COLUMNS.PICKUP_LOCATION ||
          field.id === TABLE_COLUMNS.DROPOFF_LOCATION) &&
        locationsData
      ) {
        const locations = locationsData?.map((location) => {
          return {
            label: location.name,
            value: location.id,
          };
        });
        locations.push({
          label: "All",
          value: NUMBER.MINUS_ONE,
        });
        return {
          ...field,
          options: locations,
        };
      }

      return field;
    });

  const mapDataToTableRows = (transportRequests: any) => {
    const transporterId =
      userData?.data?.transporter_user?.transporter_id?.toString();

    let rows = [];

    rows = transportRequests?.map((transportRequest: any) => {
      let actionType = TransportationRequest.Accepted;
      let alternateDate = "N/A";

      if (transportRequest?.alternate_date_request?.length) {
        transportRequest?.alternate_date_request?.forEach((request: any) => {
          if (request?.transporter_id === transporterId) {
            alternateDate = formatDate(
              request?.alternate_date_time,
              DATE_TYPE.UTC_DATE_STRING,
              DATE_FORMAT.DD_MM_YYYY
            );
          }
        });
      }
      const createdAt = formatDate(
        transportRequest?.created_at,
        DATE_TYPE.TIMESTAMP,
        DATE_FORMAT.DD_MM_YYYY
      );

      const pickupDate = formatDate(
        transportRequest?.pickup_date_time,
        DATE_TYPE.UTC_DATE_STRING,
        DATE_FORMAT.DD_MM_YYYY
      );

      return transportRequestCreateData(
        transportRequest?.id,
        transportRequest?.vehicle?.vin || "N/A",
        transportRequest?.vehicle?.year || "N/A",
        transportRequest?.vehicle?.make || "N/A",
        transportRequest?.vehicle?.model || "N/A",
        transportRequest?.vehicle?.exterior_color || "N/A",
        createdAt,
        pickupDate,
        transportRequest?.pickup_location?.name,
        transportRequest?.dropoff_location?.name,
        TransportRequestStatus[transportRequest?.status],
        {
          value1: transportRequest?.name,
          value2:
            transportRequest?.country_code +
            " " +
            transportRequest?.phone_number,
        },
        alternateDate,
        actionType,
        !checkBoolean(permissions?.[Actions.AlternateDateRequest]),
        !checkBoolean(permissions?.[Actions.AcceptTransportRequest]),
        !checkBoolean(permissions?.[Actions.AssignedTransportActions])
      );
    });

    return {
      rows,
    };
  };

  async function fetchData(queryParams: QueryParamType) {
    const page = queryParams.page + 1;
    const limit = queryParams.rowsPerPage;
    const pickupLocation = queryParams.pickupLocation;
    const dropoffLocation = queryParams.dropoffLocation;
    const status = [];

    if (
      queryParams.status?.toString() ===
      TransportRequestStatus.Accepted?.toString()
    ) {
      status.push(TransportRequestStatus.Accepted);
    } else if (
      queryParams.status?.toString() ===
      TransportRequestStatus.Picked?.toString()
    ) {
      status.push(TransportRequestStatus.Picked);
    } else {
      status.push(TransportRequestStatus.Accepted);
      status.push(TransportRequestStatus.Picked);
    }

    let apiParams = `limit=${limit}&page=${page}`;

    const queryParamObj: DynamicKeyValuesWithNumArray = {
      id: queryParams.id,
      isActive: queryParams.activeStatus,
      dropoffStartDate: queryParams?.dropoffDate?.startDate,
      dropoffEndDate: queryParams?.dropoffDate?.endDate,
      contactInfo: queryParams.contactInfo,
      alternateStartDate:
        queryParams.alternateDate?.startDate &&
        formatDate(
          queryParams.alternateDate?.startDate,
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.YYYY_MM_DD
        ),
      alternateEndDate:
        queryParams.alternateDate?.endDate &&
        formatDate(
          queryParams.alternateDate?.endDate,
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.YYYY_MM_DD
        ),
      pickupStartDate:
        queryParams.pickupDate?.startDate &&
        formatDate(
          queryParams.pickupDate?.startDate,
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.YYYY_MM_DD
        ),
      pickupEndDate:
        queryParams.pickupDate?.endDate &&
        formatDate(
          queryParams.pickupDate?.endDate,
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.YYYY_MM_DD
        ),
      status: status,
    };

    if (pickupLocation && Number(pickupLocation) >= NUMBER.ZERO)
      queryParamObj["pickupLocation"] = pickupLocation;
    if (dropoffLocation && Number(dropoffLocation) >= NUMBER.ZERO)
      queryParamObj["dropoffLocation"] = dropoffLocation;

    apiParams += appendQueryParams(queryParamObj, true);

    try {
      const response = await getTransportRequests(apiParams);

      const data = response?.data?.data;
      const transportRequests = data?.transportRequests;
      const count = data?.count;

      if (!transportRequests) {
        setTransportRequestRows([]);
        return;
      }

      const { rows } = mapDataToTableRows(transportRequests);

      setTransportRequestRows(rows);

      setTotalRowsCount(count);
    } catch (error) {}
  }

  const applyFilter = (values: {
    [key: string]:
      | number
      | boolean
      | string
      | {
          startDate: string;
          endDate: string;
        };
  }) => {
    setTransportRequestRows(null);
    setQueryParams({
      ...queryParams,
      activeStatus:
        (values?.activeStatus as string) || queryParams.activeStatus,
      page: values?.page as number,
      rowsPerPage: (values?.rowsPerPage as number) || queryParams.rowsPerPage,
      status:
        "status" in values ? (values?.status as string) : queryParams?.status,
      id: "id" in values ? (values?.id as string) : queryParams?.id,
      pickupLocation:
        "pickupLocation" in values
          ? (values?.pickupLocation as string)
          : queryParams?.pickupLocation,
      dropoffLocation:
        "dropoffLocation" in values
          ? (values?.dropoffLocation as string)
          : queryParams?.dropoffLocation,
      contactInfo:
        "contactInfo" in values
          ? (values?.contactInfo as string)
          : queryParams?.contactInfo,
      dropoffDate: {
        startDate:
          "dropoffDate" in values
            ? (values?.dropoffDate as DateRangeType)?.startDate
            : queryParams?.dropoffDate?.startDate,
        endDate:
          "dropoffDate" in values
            ? (values?.dropoffDate as DateRangeType)?.endDate
            : queryParams?.dropoffDate?.endDate,
      },
      alternateDate: {
        startDate:
          "alternateDate" in values
            ? (values?.alternateDate as DateRangeType)?.startDate
            : queryParams?.alternateDate?.startDate,
        endDate:
          "alternateDate" in values
            ? (values?.alternateDate as DateRangeType)?.endDate
            : queryParams?.alternateDate?.endDate,
      },
      pickupDate: {
        startDate:
          "pickupDate" in values
            ? (values?.pickupDate as DateRangeType)?.startDate
            : queryParams?.pickupDate?.startDate,
        endDate:
          "pickupDate" in values
            ? (values?.pickupDate as DateRangeType)?.endDate
            : queryParams?.pickupDate?.endDate,
      },
    });
  };

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); //eslint-disable-line

  useEffect(() => {
    if (transportRequestStateData?.data?.transportRequests) {
      const transportRequests =
        transportRequestStateData?.data?.transportRequests;
      const count = transportRequestStateData?.data?.count;
      const { rows } = mapDataToTableRows(transportRequests);
      setTransportRequestRows(rows);
      setTotalRowsCount(count);
    }
  }, [transportRequestStateData]);

  useEffect(() => {
    const queryParamObj = {
      isActive: "active",
    };
    const apiParams = appendQueryParams(queryParamObj, false);
    if (!locationsData) {
      getLocations(apiParams);
      return;
    }
    const modifiedFields = parseData(
      transportRequestScheduledColumns,
      locationsData?.data?.locations
    );
    setFieldsData(modifiedFields);
  }, [locationsData]); //eslint-disable-line

  useEffect(() => {
    if (
      pickedTransportSuccess ||
      droppedTransportSuccess ||
      pickedTransportError ||
      droppedTransportError
    ) {
      dispatch(toggleProgressBar(false));
    }
  }, [
    pickedTransportSuccess,
    droppedTransportSuccess,
    pickedTransportError,
    droppedTransportError,
  ]);

  const handleScheduledTransportRequest = async (type: number, id: string) => {
    let successMessage = "";
    let response: any;
    dispatch(toggleProgressBar(true));

    if (type === TransportRequestStatus.Picked) {
      response = await pickedTransport(id);
      successMessage = MESSAGES.TRANSPORTER.VEHICLE_PICKED_SUCCESS;
    } else if (type === TransportRequestStatus.Dropped) {
      response = await droppedTransport(id);
      successMessage = MESSAGES.TRANSPORTER.VEHICLE_DROPPED_SUCCESS;
    }

    dispatch(toggleProgressBar(false));
    if (!response.error) successDispatcher(true, successMessage);
  };

  return (
    <Box>
      <ButtonContainer
        hideRightSearchField={true}
        handlePrint={handlePrint}
        handleExport={handleExport}
        disableButtons={
          transportRequestRows ? transportRequestRows?.length === 0 : true
        }
      />
      <TableComponent
        reference={reference}
        handleOpen={handleOpen}
        columns={fieldsData}
        rows={transportRequestRows}
        rowCount={totalRowsCount}
        applyFilter={applyFilter}
        queryParams={queryParams}
        handleScheduledTransportRequest={handleScheduledTransportRequest}
      />
    </Box>
  );
};

export default TransportScheduledTask;
