import { useEffect, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { NavLinks } from "../../ts/component.types";
import { COLORS } from "../../misc/colors";

import { useAppDispatch, useAppSelector } from "../../hooks/redux-toolkit";
import { useGetUserDataQuery } from "../../store/reducer/user";

import "./../../styles/Sidebar.css";
import { logout } from "../../store/services/auth";
import { Pages } from "../../misc/access.enum";
import { getRouteOfTabs } from "../../misc/utils";
import { FRONTEND_ROUTES, UserTypes } from "../../misc/enum";
import { useLazySubscribeToEventsQuery } from "./../../store/socket/baseSocket";
import {
  PAGE_TITLE,
  RouteWithMultipleTabs,
  USER_TYPE_NAME,
} from "../../misc/constants";
import { MESSAGES } from "../../misc/messages";
import Menu from "@mui/material/Menu";
import { useSuccessDispatcher } from "../../hooks/useSuccessDispatcher";
import { useErrorDispatcher } from "../../hooks/useErrorDispatcher";
import { toggleProgressBar } from "../../store/reducer/progressBar";
import { toggleSidebar } from "../../store/reducer/sidebar";

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "10px",
  padding: theme.spacing(0, 1),
  paddingTop: "20px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerBody = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  sidebarOpen?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "sidebarOpen",
})<AppBarProps>(({ theme, sidebarOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(sidebarOpen && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "sidebarOpen",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavList = ({
  navLinks,
  sidebarOpen,
}: {
  navLinks: NavLinks[];
  sidebarOpen: Boolean;
}) => {
  const { permissions } = useAppSelector((state) => state.authorization);

  const hasPageAccess = (key: string) => {
    if (key === Pages.Logout) return true;
    else if (permissions[key]) return true;
    return false;
  };

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isActive = (path: string): Boolean => {
    const firstSegment = location.pathname?.split("/")[1];
    const firstSegmentWithSlash = "/" + firstSegment;

    const secondSegment = path?.split("/")[1];
    const secondSegmentWithSlash = "/" + secondSegment;

    if (secondSegmentWithSlash === "/" && firstSegmentWithSlash === "/")
      return true;
    else if (
      secondSegmentWithSlash !== "/" &&
      firstSegmentWithSlash === secondSegmentWithSlash
    )
      return true;
    return false;
  };

  return (
    <List>
      {navLinks.map((link, index) =>
        hasPageAccess(link?.access) ? (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: sidebarOpen ? "initial" : "center",
                px: 2.5,
                borderRadius: "7px",
                backgroundColor: sidebarOpen
                  ? isActive(link.path)
                    ? COLORS.LAVENDER_BLUE
                    : COLORS.INHERIT
                  : COLORS.INHERIT,
              }}
              onClick={() => {
                let path = link.path;
                let title = link.title;

                if (link.path === FRONTEND_ROUTES.LOGIN) {
                  dispatch(logout());
                  return;
                } else if (RouteWithMultipleTabs.includes(link.path)) {
                  path = getRouteOfTabs(link.path, permissions);
                }
                navigate(path, { state: { title } });
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: sidebarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  className={`sidebar-icon ${
                    sidebarOpen
                      ? isActive(link.path)
                        ? "icon-open-active"
                        : "icon-open-inactive"
                      : isActive(link.path)
                      ? "icon-closed-active"
                      : "icon-closed-inactive"
                  } ${link.icon}
                  `}
                ></Box>
              </ListItemIcon>
              <ListItemText
                primary={link.title}
                sx={{
                  color: sidebarOpen
                    ? isActive(link.path)
                      ? COLORS.BLACK
                      : COLORS.PERIWINKLE_BLUE
                    : COLORS.GRAY,
                  opacity: sidebarOpen ? 1 : 0,
                }}
              />
            </ListItemButton>
          </ListItem>
        ) : null
      )}
    </List>
  );
};

const Sidebar = ({
  navLinks,
  bottomNavLinks,
}: {
  navLinks: NavLinks[];
  bottomNavLinks: NavLinks[];
}) => {
  const successDisptacher = useSuccessDispatcher();
  const errorDispatcher = useErrorDispatcher();
  const [subscribeSocketEvents] = useLazySubscribeToEventsQuery();
  const dispatch = useAppDispatch();

  const {
    authorization: { isAuthenticated },
    sidebar: { sidebarOpen },
  } = useAppSelector((state) => state);

  const location = useLocation();
  const theme = useTheme();

  const { refetch, data: userData } = useGetUserDataQuery("");

  const firstNameChar = userData?.data?.first_name?.[0];

  const handleDrawerOpen = () => {
    dispatch(toggleSidebar(true));
  };

  const handleDrawerClose = () => {
    dispatch(toggleSidebar(false));
  };

  const handleOnlineStatusChange = () => {
    if (navigator?.onLine) {
      successDisptacher(true, MESSAGES.API.INTERNET_CONNECTED);
      return;
    }
    errorDispatcher(true, MESSAGES.API.INTERNET_DISCONNECTED);
  };

  useEffect(() => {
    dispatch(toggleProgressBar(false));
    refetch();
    subscribeSocketEvents(new Date().getTime());
    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="flex">
      <CssBaseline />
      {isAuthenticated ? (
        <>
          <AppBar
            className="appbar"
            position="fixed"
            sidebarOpen={sidebarOpen}
            sx={{
              width: !sidebarOpen ? "calc(100% - 118px)" : "calc(100% - 318px)",
              zIndex: "100 !important",
            }}
          >
            <Toolbar className="toolbar">
              <Box className="flex items-center">
                {!sidebarOpen && (
                  <IconButton
                    aria-label="sidebarOpen drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className="icon-button"
                  >
                    <MenuRoundedIcon className="menu-button" />
                  </IconButton>
                )}
                <Box className="font-bold text-3xl text-tertiary-200">
                  {location?.state?.title || PAGE_TITLE.DASHBOARD}
                </Box>
              </Box>

              <Box
                onClick={handleClick}
                className={`w-[40px] h-[40px] bg-white capitalize text-tertiary-200 border border-tertiary-200 hover:border-tertiary-400 cursor-pointer rounded-full flex items-center justify-center font-bold text-xl`}
              >
                {firstNameChar}
              </Box>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Box className="px-4">
                  <Box className="flex items-center">
                    <Box className="font-bold">ID :&nbsp;</Box>
                    {userData?.data?.id}
                  </Box>
                  <Box className="flex items-center">
                    <Box className="font-bold">Name :&nbsp;</Box>
                    {userData?.data?.first_name +
                      " " +
                      userData?.data?.last_name}
                  </Box>
                  <Box className="flex items-center">
                    <Box className="font-bold">Username :&nbsp;</Box>
                    {userData?.data?.user_name}
                  </Box>
                  <Box className="flex items-center">
                    <Box className="font-bold">Email :&nbsp;</Box>
                    {userData?.data?.email}
                  </Box>
                  <Box className="flex items-center">
                    <Box className="font-bold">Role :&nbsp;</Box>
                    {USER_TYPE_NAME[userData?.data?.role_id as UserTypes]}
                  </Box>
                  <Box className="flex items-center">
                    <Box className="font-bold">Phone no :&nbsp;</Box>
                    {userData?.data?.country_code +
                      " " +
                      userData?.data?.phone_number}
                  </Box>
                  {(userData?.data?.dealer_user ||
                    userData?.data?.transporter_user) && (
                    <>
                      <Box className="flex items-center">
                        <Box className="font-bold">Location :&nbsp;</Box>
                        {userData?.data?.dealer_user
                          ? userData?.data?.dealer_user?.location?.name
                          : userData?.data?.transporter_user?.location?.name}
                      </Box>
                      <Box className="flex items-center">
                        <Box className="font-bold">
                          {userData?.data?.dealer_user
                            ? "Dealer "
                            : "Transporter "}
                          Name :&nbsp;
                        </Box>
                        {userData?.data?.dealer_user
                          ? userData?.data?.dealer_user?.dealer?.name
                          : userData?.data?.transporter_user?.transporter?.name}
                      </Box>
                    </>
                  )}
                </Box>
              </Menu>
            </Toolbar>
          </AppBar>
          <Drawer
            PaperProps={{
              sx: {
                backgroundColor: sidebarOpen ? COLORS.WHITE : COLORS.BLACK,
                borderRadius: sidebarOpen ? "0" : "0px 15px 15px 0px",
              },
              className: "hide-scroll",
            }}
            variant="permanent"
            open={sidebarOpen}
          >
            <DrawerHeader>
              <Box className="flex items-center">
                <Box
                  className={`bg-spectrumLogo bg-no-repeat bg-contain bg-center ${
                    sidebarOpen ? "ml-3" : "ml-0"
                  } ml-2 w-[38px] h-[26px] mt-[3px]`}
                ></Box>

                <Box
                  className={`font-light text-3xl ml-3 ${
                    sidebarOpen ? "opacity-100" : "opacity-0"
                  }`}
                >
                  Spectrum
                </Box>
              </Box>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <Box className="bg-tertiary-100">
                    <ChevronRightIcon className="chevron-color" />
                  </Box>
                ) : (
                  <Box className="flex items-center justify-center rounded-full  bg-tertiary-100 h-[25px] w-[25px]">
                    <ChevronLeftIcon className="chevron-color" />
                  </Box>
                )}
              </IconButton>
            </DrawerHeader>
            <DrawerBody>
              <NavList navLinks={navLinks} sidebarOpen={sidebarOpen} />
              <NavList navLinks={bottomNavLinks} sidebarOpen={sidebarOpen} />
            </DrawerBody>
          </Drawer>
        </>
      ) : null}
      <Box component="main" className="body">
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Sidebar;
