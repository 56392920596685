import { RouterProvider } from "react-router-dom";
import { routes } from "./router/router";
import Loader from "./components/Loader";
import "./App.css";

const App = () => {
  return (
    <RouterProvider
      router={routes}
      fallbackElement={<Loader className="w-full h-screen" />}
    ></RouterProvider>
  );
};

export default App;
