import { prependZero } from "../../misc/utils";
import CardComponent from "../CardComponent";
import Box from "@mui/material/Box";

type StatsGridProps = {
  fetching: boolean;
  generalStats: {
    inventoryVehicles: number;
    soldVehicles: number;
    titlesPending: number;
    arbitrationCases: number;
    upcomingAuctions: number;
  };
};

const StatsGrid = ({
  fetching,
  generalStats: {
    inventoryVehicles,
    soldVehicles,
    titlesPending,
    arbitrationCases,
    upcomingAuctions,
  },
}: StatsGridProps) => {
  return (
    <Box className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 my-4">
      <CardComponent
        loading={fetching}
        title="Inventory"
        subtitle={prependZero(inventoryVehicles?.toString())}
        subtitleClassName="text-6xl"
      />
      <CardComponent
        loading={fetching}
        title="Vehicle Sold"
        subtitle={prependZero(soldVehicles?.toString())}
        subtitleClassName="text-6xl"
      />
      <CardComponent
        loading={fetching}
        title="Title Pending"
        subtitle={prependZero(titlesPending?.toString())}
        subtitleClassName="text-6xl"
      />
      <CardComponent
        loading={fetching}
        title="Arbitration Cases Open"
        subtitle={prependZero(arbitrationCases?.toString())}
        subtitleClassName="text-6xl"
      />
      <CardComponent
        loading={fetching}
        title="Upcoming Auction"
        subtitle={prependZero(upcomingAuctions?.toString())}
        subtitleClassName="text-6xl"
      />
      {/* //Hided CR Report and Car Faxx Report Work for now, this will be included in future */}
      {/* <CardComponent
    loading={fetching}
    title="CR Report Request In Process"
    subtitle={prependZero(
      generalStats?.data?.requested_condition_reports?.toString()
    )}
    subtitleClassName="text-6xl"
  /> */}
    </Box>
  );
};

export default StatsGrid;
