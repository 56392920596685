import { Formik } from "formik";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import FieldComponent from "../components/FieldComponent";
import { useAppDispatch } from "../hooks/redux-toolkit";

import { login } from "../store/services/auth";
import { loginSchema } from "../components/ModalComponent/schema";
import "./../styles/Login.css";
import { useRef } from "react";
import { FRONTEND_ROUTES } from "../misc/enum";
import Loader from "../components/Loader";

import ReCAPTCHA from "react-google-recaptcha";
import { MESSAGES } from "../misc/messages";
import { logger } from "../misc/utils";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";

const Login = () => {
  const dispatch = useAppDispatch();
  const errorDispatcher = useErrorDispatcher();
  const inputRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);

  const captchaRef = useRef<any>();
  const asyncScriptOnLoad = () => {
    logger("*****Captcha Script Loaded****");
  };

  return (
    <div
      className={`h-screen flex flex-col justify-center items-center bg-[url(./assets/images/LoginBg.jpg)] bg-no-repeat bg-center bg-cover`}
    >
      <Box
        className={`w-[200px] h-[60px] xl:w-[320px] xl:h-[80px] bg-[url(./assets/images/SpectrumLogo.png)] bg-contain bg-no-repeat bg-center mb-5 xl:mb-10`}
      ></Box>
      <Paper className="xl:w-[30%] lg:w-[40%] md:w-[60%] w-[90%] glassmorphism">
        <Stack spacing={1} alignItems={"center"} px={3} py={3}>
          <Box className="text-2xl font-bold text-primary-100 uppercase">
            Login
          </Box>
          <Formik
            initialValues={{ signature: "", password: "", rememberMe: false }}
            validationSchema={loginSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const { signature, password } = values;

              const recaptchaValue = captchaRef.current.getValue();
              if (recaptchaValue) {
                const loginResponse = await dispatch(
                  login({
                    signature: signature?.trim(),
                    password: password?.trim(),
                    key: recaptchaValue,
                  })
                );
                if (!loginResponse) {
                  captchaRef.current?.reset();
                }
              } else {
                errorDispatcher(true, MESSAGES.MISC.RECAPTCHA);
              }

              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Box
                className="w-full flex flex-col justify-center items-center"
                onKeyDown={(event) => {
                  if (event?.key === "Enter") {
                    const element = event?.target as HTMLElement;
                    if (
                      element?.closest(
                        "[aria-label='toggle password visibility']"
                      ) ||
                      element?.closest("[name='rememberMe']")
                    )
                      return;

                    handleSubmit();
                  }
                }}
              >
                <FieldComponent
                  type="text"
                  name="signature"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="Username or Email Address"
                  className="w-[100%] "
                  inputClassName="bg-primary-100"
                  errorTextColor="text-primary-500"
                  errors={errors}
                  touched={touched}
                />
                <FieldComponent
                  type="password"
                  name="password"
                  inputRefs={inputRefs}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="Password"
                  className="w-[100%]"
                  inputClassName="bg-primary-100"
                  errorTextColor="text-primary-500"
                  errors={errors}
                  touched={touched}
                />

                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}`}
                  asyncScriptOnLoad={asyncScriptOnLoad}
                />

                <FormGroup className="self-start">
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          "&.MuiCheckbox-root::before": {
                            content: '" "',
                            position: "absolute",
                            width: "15px",
                            height: "15px",
                            backgroundColor: "white",
                            borderRadius: "2px",
                          },
                          "&.Mui-checked::before": {
                            display: "none !important",
                          },
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.rememberMe}
                        name="rememberMe"
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle2"
                        className="text-primary-100"
                      >
                        Remember me
                      </Typography>
                    }
                  />
                </FormGroup>

                <Button
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                  variant="contained"
                  className="w-[100%] button"
                >
                  {isSubmitting ? (
                    <Loader className="items-center" size={25} />
                  ) : (
                    "Log In"
                  )}
                </Button>
              </Box>
            )}
          </Formik>

          <Box className="text-sm text-primary-200">
            <Link to={FRONTEND_ROUTES.RESET_PASSWORD}>
              Forget your password?
            </Link>
          </Box>
        </Stack>
      </Paper>
    </div>
  );
};

export default Login;
