import { API_ENDPOINT } from "../../misc/enum";
import {
  CreateTransporterPayload,
  UpdateTransporterPayload,
  DeleteTransporterPayload,
} from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const transporterSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getTransporters: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.TRANSPORTER}?${params}`,
      }),
      providesTags: ["Transporter"],
    }),
    createTransporter: builder.mutation({
      query: (payload: CreateTransporterPayload) => ({
        method: "POST",
        url: `${API_ENDPOINT.TRANSPORTER}/`,
        body: payload,
      }),
      invalidatesTags: ["Transporter"],
    }),
    updateTransporter: builder.mutation({
      query: ({id, payload}: UpdateTransporterPayload) => ({
        url: `${API_ENDPOINT.TRANSPORTER}/${id}`,
        method: "PUT",
        body: {...payload},
      }),
      invalidatesTags: ["Transporter"],
    }),
    deleteTransporter: builder.mutation({
      query: ({ id }: DeleteTransporterPayload) => ({
        url: `${API_ENDPOINT.TRANSPORTER}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetTransportersQuery,
  useCreateTransporterMutation,
  useUpdateTransporterMutation,
  useDeleteTransporterMutation,
} = transporterSlice;
