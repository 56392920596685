import { API_ENDPOINT } from "../../misc/enum";
import { UpdateUserPayload } from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const userSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getUserData: builder.query({
      query: () => ({
        url: `${API_ENDPOINT.USER}/me`,
      }),
    }),
    updateUser: builder.mutation({
      query: (payload: UpdateUserPayload) => ({
        url: `${API_ENDPOINT.USER}/me`,
        method: "PUT",
        body: payload,
      }),
    }),
    checkUsername: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.USER}/check-username/?${params}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetUserDataQuery,
  useUpdateUserMutation,
  useLazyCheckUsernameQuery,
} = userSlice;
