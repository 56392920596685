import { useState, useEffect, useRef } from "react";
import ButtonContainer from "../components/ButtonContainer";
import AuditTableComponent from "../components/AuditTableComponent";
import Box from "@mui/material/Box";
import { auditTrailColumns, auditTrailCreateData } from "../misc/createData";
import { useLazyGetAuditTrailsQuery } from "../store/reducer/auditTrail";
import { appendQueryParams } from "../misc/api.utils";
import { Data } from "../ts/component.types";
import { AuditType, UserTypes } from "../misc/enum";
import { formatDate } from "../misc/utils";
import {
  DATE_FORMAT,
  DATE_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
} from "../misc/constants";
import { useReactToPrint } from "react-to-print";

type QueryParamType = {
  page: number;
  rowsPerPage: number;
};

const AuditTrails = () => {
  const reference = useRef<HTMLTableElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.AUDIT_TRAILS,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const [getAuditTrails, { data: auditTrailsStateData }] =
    useLazyGetAuditTrailsQuery();
  const [rowsData, setRowsData] = useState<readonly Data[] | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    page: NUMBER.ZERO,
    rowsPerPage: 10,
  });

  const mapDataToTableRows = (auditTrails: any) => {
    return auditTrails.map((auditTrail: any) => {
      const date = formatDate(
        auditTrail?.created_at,
        DATE_TYPE.TIMESTAMP,
        DATE_FORMAT.DD_MM_YYYY
      );
      return auditTrailCreateData(
        auditTrail?.id,
        date,
        auditTrail?.user?.first_name + " " + auditTrail?.user?.last_name ||
          "N/A",
        auditTrail?.ip_address?.replace("::ffff:", "") || "N/A",
        UserTypes[auditTrail?.user?.role_id],
        AuditType[auditTrail?.type],
        auditTrail?.message,
        JSON.stringify(auditTrail?.body)
      );
    });
  };

  async function fetchData(queryParams: QueryParamType) {
    const page = queryParams.page + 1;
    const limit = queryParams.rowsPerPage;

    let apiParams = `limit=${limit}&page=${page}`;
    const queryParamObj = {};
    apiParams += appendQueryParams(queryParamObj, true);

    try {
      const response = await getAuditTrails(apiParams);
      const data = response?.data?.data;
      const auditTrails = data?.audit_trail;
      const count = data?.count;

      if (!auditTrails) {
        setRowsData([]);
        return;
      }
      const mappedAuditTrails = mapDataToTableRows(auditTrails);
      setRowsData(mappedAuditTrails);
      setTotalRowsCount(count);
    } catch (error) {}
  }

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); // eslint-disable-line

  useEffect(() => {
    let auditTrails, count;

    auditTrails = auditTrailsStateData?.data?.audit_trail;
    if (auditTrails) {
      count = auditTrailsStateData.data.count;
      const auditTrailsData = mapDataToTableRows(auditTrails);
      setRowsData(auditTrailsData);
      setTotalRowsCount(count);
    }
  }, [auditTrailsStateData]); //eslint-disable-line

  const applyFilter = (values: {
    [key: string]: number | boolean | string;
  }) => {
    setRowsData(null);
    setQueryParams({
      ...queryParams,
      page: values?.page as number,
      rowsPerPage: (values?.rowsPerPage as number) || queryParams.rowsPerPage,
    });
  };

  return (
    <Box>
      <ButtonContainer
        hideRightSearchField={true}
        hideExportButton={true}
        handlePrint={handlePrint}
        disableButtons={rowsData ? rowsData?.length === 0 : true}
      />
      <AuditTableComponent
        reference={reference}
        rows={rowsData}
        applyFilter={applyFilter}
        rowCount={totalRowsCount}
        queryParams={queryParams}
        columns={auditTrailColumns}
      />
    </Box>
  );
};

export default AuditTrails;
