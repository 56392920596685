import { API_ENDPOINT } from "../../misc/enum";
import {
  OfferRequestPayload,
  BumpOfferRequestPayload,
  CounterOfferRequestPayload,
  AcceptOfferRequestPayload,
  ResetOfferRequestPayload,
  DeleteAuctionVehiclePayload,
  UpdateAuctionVehiclePayload,
} from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const auctionVehicleSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAuctionVehicles: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.AUCTION_VEHICLE}?${params}`,
      }),
      providesTags: ["AuctionVehicle"],
    }),
    offerRequest: builder.mutation({
      query: ({ id, payload }: OfferRequestPayload) => ({
        url: `${API_ENDPOINT.AUCTION_VEHICLE}/offer/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["AuctionVehicle"],
    }),
    bumpOfferRequest: builder.mutation({
      query: ({ id, payload }: BumpOfferRequestPayload) => ({
        url: `${API_ENDPOINT.AUCTION_VEHICLE}/bump-offer/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["AuctionVehicle"],
    }),
    counterOfferRequest: builder.mutation({
      query: ({ id, payload }: CounterOfferRequestPayload) => ({
        url: `${API_ENDPOINT.AUCTION_VEHICLE}/counter-offer/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["AuctionVehicle"],
    }),
    acceptOfferRequest: builder.mutation({
      query: ({ id, payload }: AcceptOfferRequestPayload) => ({
        url: `${API_ENDPOINT.AUCTION_VEHICLE}/accept-offer/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["AuctionVehicle"],
    }),
    resetOfferRequest: builder.mutation({
      query: ({ id }: ResetOfferRequestPayload) => ({
        url: `${API_ENDPOINT.AUCTION_VEHICLE}/reset-offer/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["AuctionVehicle"],
    }),

    deleteAuctionVehicle: builder.mutation({
      query: ({ id }: DeleteAuctionVehiclePayload) => ({
        url: `${API_ENDPOINT.AUCTION_VEHICLE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AuctionVehicle"],
    }),

    updateAuctionVehicle: builder.mutation({
      query: (payload: UpdateAuctionVehiclePayload) => ({
        url: `${API_ENDPOINT.AUCTION_VEHICLE}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["AuctionVehicle"],
    }),
  }),
});

export const {
  useLazyGetAuctionVehiclesQuery,
  useDeleteAuctionVehicleMutation,
  useUpdateAuctionVehicleMutation,
  useOfferRequestMutation,
  useBumpOfferRequestMutation,
  useCounterOfferRequestMutation,
  useAcceptOfferRequestMutation,
  useResetOfferRequestMutation,
} = auctionVehicleSlice;
