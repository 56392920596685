import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { Listing } from "../../ts/component.types";

type Props = {
  quickLinks: Listing[];
};

const QuickLinks = ({ quickLinks }: Props) => {
  return (
    <Box>
      <Box className="text-xl font-semibold mb-2">QuickLinks</Box>
      <Box className="flex items-center flex-wrap bg-primary-100 text-secondary-300 rounded-md border p-6 text-xl">
        {quickLinks?.map((link, index) => {
          return (
            <div key={index} className="flex items-center">
              <Box
                className="flex items-center justify-center cursor-pointer my-1"
                onClick={link?.action}
              >
                {link?.title}
              </Box>
              {index !== quickLinks.length - 1 && (
                <Divider
                  orientation="vertical"
                  component={"div"}
                  sx={{ height: "20px", width: "1px", margin: "0 20px" }}
                  className="bg-tertiary-100"
                />
              )}
            </div>
          );
        })}
      </Box>
    </Box>
  );
};

export default QuickLinks;
