import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import ButtonContainer from "../components/ButtonContainer";
import ListingTableComponent from "../components/ListingTableComponent";
import {
  arbitrationCreateData,
  arbitrationStatusOptions,
} from "../misc/createData";

import { useLazyGetArbitrationsQuery } from "../store/reducer/arbitration";
import { ArbitrationStatus, FRONTEND_ROUTES } from "../misc/enum";
import { formatDate } from "../misc/utils";
import {
  ACTION_BUTTON_TYPES,
  CONSTANTS,
  DATE_FORMAT,
  DATE_TYPE,
  NUMBER,
  PAGE_TITLE,
  SELECT_TYPES,
  SIZES,
} from "../misc/constants";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { DynamicSelectDataType } from "../ts/component.types";
import { appendQueryParams } from "../misc/api.utils";

type QueryParamType = {
  page: number;
  rowsPerPage: number;
  arbitrationStatus: string;
  vin: string;
};

const Arbitration = () => {
  const navigate = useNavigate();

  const [arbitrationRows, setArbitrationRows] = useState<[] | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    arbitrationStatus: NUMBER.MINUS_ONE?.toString(),
    vin: "",
  });
  const [selectsData, setSelectsData] = useState<Array<DynamicSelectDataType>>([
    {
      label: "Arbitration Status",
      key: SELECT_TYPES.ARBITRATION_STATUS,
      options: arbitrationStatusOptions,
      selectedValue: NUMBER.MINUS_ONE?.toString(),
    },
  ]);

  const inputRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);

  const [getArbitrations, { data: arbitrationStateData }] =
    useLazyGetArbitrationsQuery();

  const handleSearch = () => {
    let payloadObject = {};
    selectsData?.forEach((selectData) => {
      if (selectData?.key === SELECT_TYPES.ARBITRATION_STATUS) {
        payloadObject = {
          ...payloadObject,
          arbitrationStatus: selectData?.selectedValue,
        };
      }
    });
    (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement).value = "";
    applyFilter(payloadObject);
  };

  const buttons = [
    {
      label: "Search",
      color: CONSTANTS.PRIMARY,
      size: SIZES.SMALL,
      onClick: handleSearch,
      access: true,
    },
  ];

  const mapDataToTableRows = (arbitrationsData: any) => {
    return arbitrationsData?.map((arbitrationData: any) => {
      const vehicle = arbitrationData?.auction_vehicle?.vehicle;
      const auction = arbitrationData?.auction_vehicle?.auction;
      const caseOpenDate = arbitrationData?.created_at
        ? formatDate(
            arbitrationData?.created_at,
            DATE_TYPE.TIMESTAMP,
            DATE_FORMAT.DD_MM_YYYY
          )
        : "-";

      const caseCloseDate =
        arbitrationData?.updated_at &&
        arbitrationData?.status !== ArbitrationStatus.Open
          ? formatDate(
              arbitrationData?.updated_at,
              DATE_TYPE.TIMESTAMP,
              DATE_FORMAT.DD_MM_YYYY
            )
          : "-";
      const auctionDate = auction?.start_time
        ? formatDate(
            auction?.start_time,
            DATE_TYPE.TIMESTAMP,
            DATE_FORMAT.DD_MM_YYYY
          )
        : auction?.auction_date
        ? formatDate(
            auction?.auction_date,
            DATE_TYPE.DATE_STRING,
            DATE_FORMAT.DD_MM_YYYY
          )
        : "-";

      return arbitrationCreateData(
        arbitrationData?.id,
        caseOpenDate,
        caseCloseDate,
        vehicle?.vin,
        vehicle?.make || "N/A",
        vehicle?.model || "N/A",
        vehicle?.year || "N/A",
        vehicle?.dealer?.name || "N/A",
        vehicle?.location?.name || "N/A",
        auctionDate,
        auction?.location?.name || "N/A",
        vehicle?.sale_price || "N/A",
        ArbitrationStatus[arbitrationData?.status],
        arbitrationData?.unread_count
          ? Number(arbitrationData?.unread_count)
          : 0,
        false
      );
    });
  };

  const applyFilter = (values: DynamicKeyValuesWithNumArray) => {
    setArbitrationRows(null);
    setQueryParams({
      ...queryParams,
      page: (values?.page as number) || NUMBER.ZERO,
      rowsPerPage: (values?.rowsPerPage as number) || queryParams.rowsPerPage,
      vin: "vin" in values ? (values?.vin as string) : "",
      arbitrationStatus:
        "arbitrationStatus" in values
          ? (values?.arbitrationStatus as string)
          : queryParams.arbitrationStatus,
    });
  };

  const handleSubmit = async (type: string, caseId: number) => {
    if (type === ACTION_BUTTON_TYPES.OPEN_ARBITRATION) {
      navigate(`${FRONTEND_ROUTES.ARBITRATION}/${caseId}`, {
        state: { title: PAGE_TITLE.ARBITRATION },
      });
    }
  };

  const handleDynamicSelectChange = (key: string, value: string) => {
    const updatedSelectsData = selectsData?.map((selectData) => {
      if (selectData?.key === key) {
        return {
          ...selectData,
          selectedValue: value,
        };
      }
      return selectData;
    });
    setSelectsData(updatedSelectsData);
  };

  const fetchData = async (queryParams: QueryParamType) => {
    const page = queryParams.page + 1;
    const limit = queryParams.rowsPerPage;
    const arbitrationStatus = queryParams.arbitrationStatus;
    const vin = queryParams.vin;

    let apiParams = `limit=${limit}&page=${page}`;

    const queryParamObj: any = {};

    if (arbitrationStatus && Number(arbitrationStatus) >= NUMBER.ZERO)
      queryParamObj["arbitrationStatus"] = arbitrationStatus;
    if (vin) queryParamObj["vin"] = vin;

    apiParams += appendQueryParams(queryParamObj, true);
    try {
      const arbitrations: any = await getArbitrations(apiParams);

      let arbitrationsData = arbitrations?.data?.data?.arbitrations;
      if (arbitrationsData) {
        arbitrationsData = mapDataToTableRows(arbitrationsData);
        setArbitrationRows(arbitrationsData);
        setTotalRowsCount(arbitrations?.data?.data?.count);
      } else {
        setArbitrationRows([]);
      }
    } catch (err) {
      setArbitrationRows([]);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); //eslint-disable-line

  useEffect(() => {
    if (arbitrationStateData?.data?.arbitrations) {
      let arbitrationsData = arbitrationStateData?.data?.arbitrations;
      arbitrationsData = mapDataToTableRows(arbitrationsData);
      setArbitrationRows(arbitrationsData);
      setTotalRowsCount(arbitrationStateData?.data?.count);
    }
  }, [arbitrationStateData]);

  return (
    <Box>
      <ButtonContainer
        hideReportButtons={true}
        inputRefs={inputRefs}
        hideRightSearchField={false}
        buttons={buttons}
        selectsData={selectsData}
        handleDynamicSelectChange={handleDynamicSelectChange}
        handleVinSearch={() => {
          applyFilter({
            ...queryParams,
            vin: (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement)?.value,
            page: NUMBER.ZERO,
          });
        }}
      />
      <ListingTableComponent
        rows={arbitrationRows}
        rowCount={totalRowsCount}
        queryParams={queryParams}
        applyFilter={applyFilter}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};

export default Arbitration;
