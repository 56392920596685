import Box from "@mui/material/Box";
import Chart from "react-apexcharts";
import { DEALER_ROLES } from "../../misc/constants";
import { UserTypes } from "../../misc/enum";
import Loader from "../Loader";
import { ApexOptions } from "apexcharts";

type Props = {
  role: UserTypes;
  timeSeriesChart: {
    chart: { series: ApexOptions["series"]; options: ApexOptions };
    changePercentage: string;
    lastAuctionSold: number;
  } | null;
};

const SoldVehiclesDateTimeChart = ({ role, timeSeriesChart }: Props) => {
  if (DEALER_ROLES?.includes(role as UserTypes))
    return (
      <Box
        className={`flex flex-col ${
          timeSeriesChart ? "items-start" : "items-center"
        } justify-evenly p-7 pl-8 pb-0 my-4 bg-primary-100 rounded-md box-shadow min-h-[430px]`}
      >
        {timeSeriesChart ? (
          <>
            <Box className="pl-4 flex items-center justify-between w-full text-tertiary-500">
              <Box>Last Auction Sold</Box>
              <Box className="font-bold">
                {timeSeriesChart?.changePercentage + "%"}
              </Box>
            </Box>
            <Box className="pl-4 text-5xl font-bold my-5">
              {timeSeriesChart?.lastAuctionSold}
            </Box>
            <Chart
              className="self-center w-full"
              options={timeSeriesChart?.chart?.options}
              series={timeSeriesChart?.chart?.series}
              type="area"
              height={250}
            />
          </>
        ) : (
          <Loader />
        )}
      </Box>
    );

  return null;
};

export default SoldVehiclesDateTimeChart;
