import { ChangeEvent, RefObject } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import usePagination from "@mui/material/usePagination";
import PaginationComponent from "../PaginationComponent";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import SelectComponent from "../SelectComponent";
import ButtonComponent from "../ButtonComponent";
import TagComponent from "../TagComponent";
import { CONSTANTS, SIZES } from "../../misc/constants";
import {
  SelectOptions,
  SelectedPaginationProps,
  Column,
  Data,
  TagOption,
} from "../../ts/component.types";

import "./../../styles/TableComponent.css";
import "./../../styles/AuditTableComponent.css";
import Loader from "../Loader";

type TableComponentProps = {
  reference?: RefObject<HTMLTableElement>;
  columns: readonly Column[];
  rows: readonly Data[] | null;
  rowCount?: number;
  applyFilter?: (values: { [key: string]: number | boolean | string }) => void;
  queryParams?: { [key: string]: string | number | boolean };
};

export default function AuditTableComponent({
  rows,
  columns,
  applyFilter,
  queryParams,
  rowCount = 0,
  reference,
}: TableComponentProps) {
  const rowsPerPage = (queryParams?.rowsPerPage as number) || 10;
  const page = (queryParams?.page as number) || 0;

  const totalPages = Math.ceil(rowCount / rowsPerPage);
  const { items } = usePagination({
    count: totalPages,
  });

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    applyFilter &&
      applyFilter({
        rowsPerPage: +event.target.value,
        page: 0,
      });
  };

  const handlePageChange = (selected: SelectedPaginationProps) => {
    if (!selected?.selected) {
      const pageNumber = selected?.page ? selected?.page - 1 : 0;
      applyFilter &&
        applyFilter({
          page: pageNumber,
        });
    }
  };

  return (
    <>
      <Paper className="table-container audit-table-container">
        <TableContainer className="table-scrollbar" sx={{ maxHeight: "60vh" }}>
          <Table
            ref={reference}
            stickyHeader
            aria-label="sticky table"
            className="audit-table"
          >
            <TableHead>
              <TableRow className="audit-table-head">
                {columns.map((column) =>
                  column?.filter ? (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <SelectComponent
                        hideErrorMessage={true}
                        className="bg-primary-300"
                        options={column.options}
                        value={column?.currentValue || ""}
                        placeholder={column?.placeholder}
                        handleChange={() => {}}
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                    >
                      {column.label}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
            {!rows ? (
              <TableRow className="relative" sx={{ height: "100px" }}>
                <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <Loader />
                </Box>
              </TableRow>
            ) : !rows?.length ? (
              <Box className="w-full py-3 text-center font-bold text-lg text-tertiary-400 tracking-wide">
                No Data Available
              </Box>
            ) : (
              <TableBody>
                {rows?.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row?.id}
                      className="audit-table-row"
                    >
                      {columns.map((column) => {
                        if (column.dataType === CONSTANTS.BUTTON) {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <ButtonComponent
                                text="Edit"
                                color={CONSTANTS.PRIMARY}
                                size={SIZES.SMALL}
                              />
                            </TableCell>
                          );
                        } else if (column?.dataType === CONSTANTS.SELECT) {
                          const value = row[column.id] as SelectOptions;
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <SelectComponent
                                hideErrorMessage={true}
                                className="bg-primary-300"
                                size={SIZES.SMALL}
                                options={value.options}
                                value={value.currentValue}
                                handleChange={() => {}}
                              />
                            </TableCell>
                          );
                        } else if (column?.dataType === CONSTANTS.TAG) {
                          const value = row[column?.id] as TagOption;
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <TagComponent
                                text={value?.label}
                                color={value?.color}
                              />
                            </TableCell>
                          );
                        } else if (column?.dataType === CONSTANTS.ACTION) {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className="vertical-text-top"
                            >
                              <MoreHorizIcon className="cursor-pointer" />
                            </TableCell>
                          );
                        } else {
                          const value = row[column?.id] as string;
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                maxWidth: 500,
                                wordBreak: "break-word",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {column?.format && typeof value === "number"
                                ? column?.format(value)
                                : value}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
      <Box className="flex items-center justify-between py-3">
        <Box className="text-sm font-light">
          Showing {(page + 0) * rowsPerPage || 1} to{" "}
          {(page + 1) * rowsPerPage > rowCount
            ? rowCount
            : (page + 1) * rowsPerPage}{" "}
          of {rowCount} entries
        </Box>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={() => {}}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={() => ""}
          className="bg-primary-300 border-none shadow-none outline-none"
          ActionsComponent={() => {
            return (
              <PaginationComponent
                items={items}
                handlePageChange={handlePageChange}
              />
            );
          }}
        />
      </Box>
    </>
  );
}
