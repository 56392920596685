import { RootState } from "./../index";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseAPI = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL_API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authorization.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("ngrok-skip-browser-warning", "69420");
      }

      return headers;
    },
  }),
  tagTypes: [
    "User",
    "Vehicle",
    "Auction",
    "SpectrumUser",
    "Dealer",
    "DealerUser",
    "Transporter",
    "TransporterUser",
    "Location",
    "Announcement",
    "Remark",
    "TransportRequest",
    "AuctionVehicle",
    "ArbitrationChat",
    "ArbitrationById"
  ],
  endpoints: () => ({}),
});
