import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SidebarState } from "../../ts/api.interface";

const initialState: SidebarState = {
  sidebarOpen: false,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar(state, action: PayloadAction<boolean>) {
      state.sidebarOpen = action.payload;
    },
  },
});

export const { toggleSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
