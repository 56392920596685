import { API_ENDPOINT } from "../../misc/enum";
import { baseAPI } from "./baseSlice";

export const auditTrailSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAuditTrails: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.AUDIT_TRAIL}?${params}`,
      }),
    }),
  }),
});

export const { useLazyGetAuditTrailsQuery } = auditTrailSlice;
