import { useState, useEffect } from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import Tooltip from "@mui/material/Tooltip";

import { COLORS } from "../../misc/colors";
import { SubNavigationParentProps } from "../../ts/component.types";
import { useAppSelector } from "../../hooks/redux-toolkit";
import { checkBoolean } from "../../misc/utils";
import { FRONTEND_ROUTES } from "../../misc/enum";
import { PAGE_TITLE } from "../../misc/constants";
import { Tabs as TabsAccess } from "../../misc/access.enum";

const SubNavigationParent = ({
  navigationTabs,
  hideReportButtons,
}: SubNavigationParentProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState("users");
  const { permissions } = useAppSelector((state) => state.authorization);

  interface StyledTabsProps {
    children?: React.ReactNode;
    value: string;
    onChange: (event: React.SyntheticEvent, newValue: string) => void;
  }

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      variant="scrollable"
      scrollButtons={false}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: COLORS.TRANSPARENT,
      height: "4px",
    },
    "& .MuiTabs-indicatorSpan": {
      width: "100%",
      backgroundColor: COLORS.DODGER_BLUE_LIGHT,
    },
    "& *": {
      padding: 0,
      minWidth: 0,
    },
    "& .MuiButtonBase-root": {
      margin: "0 10px",
    },
  });

  interface StyledTabProps {
    label: string;
    value: string;
  }

  const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
  ))(({ theme }) => ({
    textTransform: "none",
    fontSize: "16px",
    color: COLORS.GRAY,
    width: "fit-content",
    padding: 0,
    "&.Mui-selected": {
      color: COLORS.DODGER_BLUE_LIGHT,
    },
    fontWeight: "bold",
    "&.Mui-focusVisible": {
      backgroundColor: COLORS.CORN_FLOWER_BLUE,
    },
    "@media screen and (max-width: 768px)": {
      fontSize: "12px",
    },
  }));

  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: string
  ) => {
    setValue(newValue);
    switch (newValue) {
      case "users":
        navigate(FRONTEND_ROUTES.USER, { state: { title: PAGE_TITLE.USERS } });
        break;
      case "dealers":
        navigate(FRONTEND_ROUTES.DEALER_USER, {
          state: { title: PAGE_TITLE.USERS },
        });
        break;
      case "transporters":
        navigate(FRONTEND_ROUTES.TRANSPORTER_USER, {
          state: { title: PAGE_TITLE.USERS },
        });
        break;
      case "transporter":
        navigate(FRONTEND_ROUTES.TRANSPORT, {
          state: { title: PAGE_TITLE.TRANSPORTATION },
        });
        break;
      case "unassigned-transporter":
        navigate(FRONTEND_ROUTES.TRANSPORT_UNASSIGNED, {
          state: { title: PAGE_TITLE.TRANSPORTATION },
        });
        break;
      case "transporter-request":
        navigate(FRONTEND_ROUTES.TRANSPORT_REQUEST, {
          state: { title: PAGE_TITLE.TRANSPORTATION },
        });
        break;
      case "transporter-schedule":
        navigate(FRONTEND_ROUTES.TRANSPORT_SCHEDULED, {
          state: { title: PAGE_TITLE.TRANSPORTATION },
        });
        break;
      case "transporter-complete":
        navigate(FRONTEND_ROUTES.TRANSPORT_COMPLETED, {
          state: { title: PAGE_TITLE.TRANSPORTATION },
        });
        break;
      case "vehicle-inventory":
        navigate(FRONTEND_ROUTES.VEHICLE_INVENTORY, {
          state: { title: PAGE_TITLE.INVENTORY },
        });
        break;
      case "vehicle-sold":
        navigate(FRONTEND_ROUTES.VEHICLE_SOLD, {
          state: { title: PAGE_TITLE.INVENTORY },
        });
        break;
      case "vehicle-trash":
        navigate(FRONTEND_ROUTES.VEHICLE_TRASH, {
          state: { title: PAGE_TITLE.INVENTORY },
        });
        break;
      case "auction":
        navigate(FRONTEND_ROUTES.AUCTION, {
          state: { title: PAGE_TITLE.AUCTION },
        });
        break;
      case "request-ove":
        navigate(FRONTEND_ROUTES.REQUEST_OVE, {
          state: { title: PAGE_TITLE.AUCTION },
        });
        break;
      case "rerun-auction":
        navigate(FRONTEND_ROUTES.RERUN, {
          state: { title: PAGE_TITLE.AUCTION },
        });
        break;
      case "second-chance-auction":
        navigate(FRONTEND_ROUTES.SECOND_CHANCE, {
          state: { title: PAGE_TITLE.AUCTION },
        });
        break;
      case "auction-list":
        navigate(FRONTEND_ROUTES.AUCTION_LIST, {
          state: { title: PAGE_TITLE.AUCTION },
        });
        break;
      case "stakeholders-dealers":
        navigate(FRONTEND_ROUTES.DEALER_STAKEHOLDER, {
          state: { title: PAGE_TITLE.STAKEHOLDERS },
        });
        break;
      case "stakeholders-transporters":
        navigate(FRONTEND_ROUTES.TRANSPORTER_STAKEHOLDER, {
          state: { title: PAGE_TITLE.STAKEHOLDERS },
        });
        break;
      case "setting-locations":
        navigate(FRONTEND_ROUTES.SETTING, {
          state: { title: PAGE_TITLE.SETTINGS },
        });
        break;
      case "setting-announcements":
        navigate(FRONTEND_ROUTES.SETTING_ANNOUNCEMENT, {
          state: { title: PAGE_TITLE.SETTINGS },
        });
        break;
      case "setting-remarks":
        navigate(FRONTEND_ROUTES.SETTING_REMARK, {
          state: { title: PAGE_TITLE.SETTINGS },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let value = "dashboard";
    let hasAccess = true;
    const isSlash =
      location?.pathname?.substring(location?.pathname?.length - 1) === "/";

    const finalPath = isSlash
      ? location?.pathname?.substring(0, location?.pathname?.length - 1)
      : location?.pathname;

    switch (finalPath) {
      case FRONTEND_ROUTES.USER:
        hasAccess = checkBoolean(permissions?.[TabsAccess.UserTab]);
        value = "users";
        break;
      case FRONTEND_ROUTES.DEALER_USER:
        hasAccess = checkBoolean(permissions?.[TabsAccess.DealerUsersTab]);
        value = "dealers";
        break;
      case FRONTEND_ROUTES.TRANSPORTER_USER:
        hasAccess = checkBoolean(permissions?.[TabsAccess.TransporterUsersTab]);
        value = "transporters";
        break;
      case FRONTEND_ROUTES.TRANSPORT:
        hasAccess = checkBoolean(permissions?.[TabsAccess.TransportationTab]);
        value = "transporter";
        break;
      case FRONTEND_ROUTES.TRANSPORT_UNASSIGNED:
        hasAccess = checkBoolean(
          permissions?.[TabsAccess.UnAssignedTTransporterTab]
        );
        value = "unassigned-transporter";
        break;
      case FRONTEND_ROUTES.TRANSPORT_REQUEST:
        hasAccess = checkBoolean(permissions?.[TabsAccess.TransportRequestTab]);
        value = "transporter-request";
        break;
      case FRONTEND_ROUTES.TRANSPORT_SCHEDULED:
        hasAccess = checkBoolean(
          permissions?.[TabsAccess.ScheduledTransportTab]
        );
        value = "transporter-schedule";
        break;
      case FRONTEND_ROUTES.TRANSPORT_COMPLETED:
        hasAccess = checkBoolean(
          permissions?.[TabsAccess.CompletedTransportTab]
        );
        value = "transporter-complete";
        break;
      case FRONTEND_ROUTES.VEHICLE_INVENTORY:
        hasAccess = checkBoolean(permissions?.[TabsAccess.InventoryTab]);

        value = "vehicle-inventory";
        break;
      case FRONTEND_ROUTES.VEHICLE_SOLD:
        hasAccess = checkBoolean(permissions?.[TabsAccess.SoldTab]);
        value = "vehicle-sold";
        break;
      case FRONTEND_ROUTES.VEHICLE_TRASH:
        hasAccess = checkBoolean(permissions?.[TabsAccess.RemoveFromLotTab]);
        value = "vehicle-trash";
        break;
      case FRONTEND_ROUTES.AUCTION:
        hasAccess = checkBoolean(permissions?.[TabsAccess.AuctionTab]);
        value = "auction";
        break;
      case FRONTEND_ROUTES.REQUEST_OVE:
        hasAccess = checkBoolean(permissions?.[TabsAccess.OVERequestTab]);
        value = "request-ove";
        break;
      case FRONTEND_ROUTES.RERUN:
        hasAccess = checkBoolean(permissions?.[TabsAccess.ReRunTab]);
        value = "rerun-auction";
        break;
      case FRONTEND_ROUTES.SECOND_CHANCE:
        hasAccess = checkBoolean(permissions?.[TabsAccess.SecondChanceTab]);
        value = "second-chance-auction";
        break;
      case FRONTEND_ROUTES.AUCTION_LIST:
        hasAccess = checkBoolean(permissions?.[TabsAccess.AuctionListTab]);
        value = "auction-list";
        break;
      case FRONTEND_ROUTES.STAKEHOLDER:
        hasAccess = checkBoolean(permissions?.[TabsAccess.DealersTab]);
        value = "stakeholders-dealers";
        break;
      case FRONTEND_ROUTES.TRANSPORTER_STAKEHOLDER:
        hasAccess = checkBoolean(permissions?.[TabsAccess.TransportersTab]);
        value = "stakeholders-transporters";
        break;
      case FRONTEND_ROUTES.SETTING:
        hasAccess = checkBoolean(permissions?.[TabsAccess.LocationTab]);
        value = "setting-locations";
        break;
      case FRONTEND_ROUTES.SETTING_ANNOUNCEMENT:
        hasAccess = checkBoolean(permissions?.[TabsAccess.AnnouncementTab]);
        value = "setting-announcements";
        break;
      case FRONTEND_ROUTES.SETTING_REMARK:
        hasAccess = checkBoolean(permissions?.[TabsAccess.RemarksTab]);
        value = "setting-remarks";
        break;
      default:
        break;
    }

    if (!hasAccess) {
      navigate(FRONTEND_ROUTES.DASHBOARD, {
        state: { title: PAGE_TITLE.DASHBOARD },
      });
      return;
    }

    handleChange(null, value);
  }, [location?.pathname]); //eslint-disable-line

  return (
    <>
      <Box className="w-full flex items-center justify-between">
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="secondary tabs"
          
        >
          {navigationTabs?.map((item, index) => {
            if (checkBoolean(permissions[item?.access])) {
              return (
                <StyledTab
                  key={index}
                  value={item?.value}
                  label={item?.label}
                />
              );
            }
            return null;
          })}
        </StyledTabs>
        {!hideReportButtons && (
          <Box className="flex justify-evenly items-center">
            <Tooltip title="Print">
              <PrintRoundedIcon className="cerulean-blue mx-3 cursor-pointer text-sm md:text-2xl" />
            </Tooltip>
            <Tooltip title="Export">
              <DescriptionRoundedIcon className="cerulean-blue cursor-pointer text-sm md:text-2xl" />
            </Tooltip>
          </Box>
        )}
      </Box>
      <Outlet />
    </>
  );
};

export default SubNavigationParent;
