import { useRef, useEffect, useState, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Divider from "@mui/material/Divider";
import FieldComponent from "../FieldComponent";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Popper from "@mui/material/Popper";
import { SelectChangeEvent } from "@mui/material/Select";
import { MuiTelInput } from "mui-tel-input";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import InfoIcon from "@mui/icons-material/Info";
import {
  UserModalProps,
  ModalComponentProps,
  GroupModalProps,
  ModalFieldProps,
  ModalSelectOption,
  VINModalDataObjectType,
  BulkVehicleUploadModalProps,
} from "../../ts/component.types";
import ButtonComponent from "../ButtonComponent";
import {
  CONSTANTS,
  MODAL_TYPE,
  MODAL_TITLE,
  MODAL_FIELD_TYPES,
  MODAL_FIELD_NAMES,
  MODAL_BUTTON_NAMES,
  SIZES,
  TABLE_COLUMNS,
  DATE_TYPE,
  DATE_FORMAT,
  NUMBER,
  VEHICLE_UPDATE_REQUEST_STATUS,
  ADMIN_ROLES,
  MODAL_COMPONENT_FIELD_NAMES,
  BUTTON_LABEL,
} from "../../misc/constants";
import SelectComponent from "../SelectComponent";
import MultiSelect from "../MultiSelectComponent";
import {
  userModalFields,
  dealerModalFields,
  dealerUserModalFields,
  transporterModalFields,
  transporterUserModalFields,
  assignAuctionModalFields,
  requestTransportModalFields,
  requestCRModalFields,
  generateAddVehicleModalFields,
  createAuctionModalFields,
  addLocationFields,
  addAnnouncementFields,
  addRemarkFields,
  getEditableFields,
  spectrumAdminField,
  dealerAdminField,
} from "./fields";

import "./../../styles/ModalComponent.css";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-toolkit";
import { Formik } from "formik";
import { MESSAGES } from "../../misc/messages";

import {
  userSchema,
  dealerSchema,
  dealerUserSchema,
  transporterSchema,
  transporterUserSchema,
  createAuctionSchema,
  assignAuctionSchema,
  requestCRSchema,
  requestTransportSchema,
  generateAddVehicleSchema,
  addLocationSchema,
  addAnnouncementSchema,
  addRemarkSchema,
  userEditSchema,
  transporterUserEditSchema,
  dealerUserEditSchema,
  dealerEditSchema,
  transporterEditSchema,
  updateVehicleSchema,
  bulkCsvSpectrumAdminSchema,
  bulkCsvDealerAdminSchema,
} from "./schema";
import {
  useCreateSpectrumUserMutation,
  useUpdateSpectrumUserMutation,
} from "../../store/reducer/spectrumUser";
import {
  useCreateDealerMutation,
  useLazyGetDealerByIdQuery,
  useLazyGetDealersQuery,
  useUpdateDealerMutation,
} from "../../store/reducer/dealer";
import {
  useCreateTransporterMutation,
  useLazyGetTransportersQuery,
  useUpdateTransporterMutation,
} from "../../store/reducer/transporter";
import {
  useCreateTransporterUserMutation,
  useUpdateTransporterUserMutation,
} from "../../store/reducer/transporterUser";
import {
  useCreateDealerUserMutation,
  useUpdateDealerUserMutation,
} from "../../store/reducer/dealerUser";
import {
  useAssignAuctionMutation,
  useCreateVehicleMutation,
  useCreateVehiclesCSVMutation,
  useLazyGetVehicleByIdQuery,
  useLazyGetVehicleDetailByVINQuery,
  useSendVehicleToOVEMutation,
  useSendVehicleToRerunMutation,
  useSendVehicleToSecondChanceMutation,
  useUpdateCRMutation,
  useUpdatePSIMutation,
  useUpdateTitleMutation,
  useUpdateVehicleMutation,
  useUpdateVehicleRequestMutation,
} from "../../store/reducer/vehicle";
import {
  useCreateLocationMutation,
  useLazyGetLocationsQuery,
  useUpdateLocationMutation,
} from "../../store/reducer/location";
import {
  AuctionStatus,
  ConditionReportStatus,
  DealerType,
  FRONTEND_ROUTES,
  PSIStatus,
  TitleStatus,
  TransportRequestStatus,
  UserTypes,
  VehicleAcquiredFrom,
  VehicleStatus,
  VehicleUpdateRequestStatus,
} from "../../misc/enum";
import Loader from "../Loader";
import {
  useGetUserDataQuery,
  useLazyCheckUsernameQuery,
} from "../../store/reducer/user";
import {
  useCreateAuctionMutation,
  useLazyGetAuctionsQuery,
  useUpdateAuctionMutation,
} from "../../store/reducer/auction";
import {
  areArraysEqual,
  calculateDays,
  checkBoolean,
  formatDate,
  renderAssignAuctionResponseHTML,
  renderRequestTransportResponseHTML,
  setEditModalValues,
  stringToNumberArray,
} from "../../misc/utils";
import { useCreateTransportRequestMutation } from "../../store/reducer/transportRequest";
import Swal from "sweetalert2";
import {
  useCreateAnnouncementMutation,
  useCreateRemarkMutation,
  useLazyGetAnnouncementsQuery,
  useLazyGetRemarksQuery,
  useUpdateAnnouncementMutation,
  useUpdateRemarkMutation,
} from "../../store/reducer/setting";
import { toggleProgressBar } from "../../store/reducer/progressBar";
import {
  createVINModalVehicleData,
  createVINModalAuctionData,
  createVINModalButtons,
} from "../../misc/createData";
import { appendQueryParams } from "../../misc/api.utils";
import { createBrowserHistory } from "history";
import { Actions } from "../../misc/access.enum";
import { ModalValuesType } from "../../ts/api.interface";
import { DynamicKeyValuesWithStringArray } from "../../ts/api.types";
import { useSuccessDispatcher } from "../../hooks/useSuccessDispatcher";
import { useErrorDispatcher } from "../../hooks/useErrorDispatcher";
import { Link, useLocation } from "react-router-dom";

const VINModal = ({ handleClose }: GroupModalProps) => {
  const history = createBrowserHistory();
  const dispatch = useAppDispatch();
  const successDispatcher = useSuccessDispatcher();

  const state = useAppSelector((state) => state);
  const { data } = state.modal;
  const { success, permissions } = state.authorization;
  const [crData, setCrData] = useState({
    url: "",
    grade: "",
  });

  const [showInput, setShowInput] = useState(false);
  const [ovePrice, setOvePrice] = useState<number>(NUMBER.ZERO);
  const [vehicleDetails, setVehicleDetails] =
    useState<Array<VINModalDataObjectType> | null>(null);
  const [auctionDetails, setAuctionDetails] =
    useState<Array<VINModalDataObjectType> | null>(null);
  const [modalButtons, setModalButtons] =
    useState<Array<VINModalDataObjectType> | null>(null);
  const [vehicleTitleData, setVehicleTitleData] = useState({
    make: "",
    model: "",
    year: "",
    vin: "",
    stockNumber: "",
  });

  const [getVehicleById, { data: vehicleStateData }] =
    useLazyGetVehicleByIdQuery();
  const [updateCR] = useUpdateCRMutation();
  const [updateTitle] = useUpdateTitleMutation();
  const [updatePSI] = useUpdatePSIMutation();
  const [sendVehicleToOVE] = useSendVehicleToOVEMutation();
  const [sendVehicleToRerun] = useSendVehicleToRerunMutation();
  const [sendVehicleToSecondChance] = useSendVehicleToSecondChanceMutation();

  const handleCRChange = (type: string, value: string) => {
    let url = crData.url;
    let grade = crData.grade;
    if (type === MODAL_FIELD_TYPES.CR_URL) {
      url = value;
    } else {
      if (!isNaN(Number(value))) {
        grade = value;
      }
    }
    setCrData({
      url,
      grade,
    });
  };

  const handleUpdateCR = async () => {
    const conditionReportId = data?.conditionReportId;
    if (conditionReportId) {
      dispatch(toggleProgressBar(true));
      const response: any = await updateCR({
        id: conditionReportId,
        payload: {
          rating: Number(crData.grade),
          report_url: crData.url,
        },
      });
      dispatch(toggleProgressBar(false));
      if (!response?.error)
        successDispatcher(true, MESSAGES.VIN_MODAL.CR_GRADE_UPDATE_SUCCESS);
      handleClose();
    }
  };

  const handleSelectChange = async (type: string, value: string) => {
    let response: any;
    let successMessage: string;
    const vehicleId = vehicleStateData?.data?.id;
    dispatch(toggleProgressBar(true));
    if (type === MODAL_FIELD_NAMES.PSI) {
      const psi = vehicleStateData?.data?.psi_status;
      response = await updatePSI({
        id: psi?.id,
        vehicleId: vehicleId,
        payload: {
          status: Number(value),
        },
      });

      successMessage = MESSAGES.VIN_MODAL.PSI_UPDATE_SUCCESS;
    } else {
      const title = vehicleStateData?.data?.title;
      response = await updateTitle({
        id: title?.id,
        vehicleId: vehicleId,
        payload: {
          status: Number(value),
        },
      });

      successMessage = MESSAGES.VIN_MODAL.TITLE_UPDATE_SUCCESS;
    }
    dispatch(toggleProgressBar(false));
    if (!response?.error) successDispatcher(true, successMessage);
  };

  const buttonClick = async (type: string) => {
    const vehicleId = vehicleStateData?.data?.id;
    if (vehicleId) {
      let response: any;
      let successMessage: string = "";
      dispatch(toggleProgressBar(true));
      switch (type) {
        case MODAL_BUTTON_NAMES.OVE_EVENT:
          setShowInput(!showInput);
          break;
        case MODAL_BUTTON_NAMES.SECOND_CHANCE:
          response = await sendVehicleToSecondChance(vehicleId);
          successMessage = MESSAGES.VIN_MODAL.MOVE_TO_SECOND_CHANCE_SUCCESS;
          break;
        case MODAL_BUTTON_NAMES.RERUN:
          response = await sendVehicleToRerun(vehicleId);
          successMessage = MESSAGES.VIN_MODAL.MOVE_TO_RERUN_SUCCESS;
          break;
        case MODAL_BUTTON_NAMES.SUBMIT_FOR_OVE_EVENT:
          response = await sendVehicleToOVE({
            id: vehicleId,
            payload: {
              price: ovePrice,
            },
          });
          successMessage = MESSAGES.VIN_MODAL.MOVE_TO_OVE_EVENT_SUCCESS;
          break;
        default:
          break;
      }
      dispatch(toggleProgressBar(false));

      if (!response?.error && type !== MODAL_BUTTON_NAMES.OVE_EVENT) {
        handleClose();
        successDispatcher(true, successMessage);
      }
    }
  };

  const handleOvePrice = (price: string) => {
    if (!isNaN(Number(price))) {
      setOvePrice(Number(price));
    }
  };

  useEffect(() => {
    (async () => {
      const response: any = await getVehicleById(data?.vehicleId as string);
      if (response?.error) handleClose();
    })();
  }, []);

  useEffect(() => {
    if (vehicleStateData) {
      const vehicleData = vehicleStateData?.data;
      const hasAuctionCount = vehicleData?.auction_count > NUMBER.ZERO;
      const lane = vehicleData?.auction_vehicle?.[NUMBER.ZERO]?.lane;
      const run = vehicleData?.auction_vehicle?.[NUMBER.ZERO]?.run;

      const age = calculateDays(vehicleData?.created_at);

      const vehicleDetails = createVINModalVehicleData(
        `${age} ${age === 1 ? "Day" : "Days"}`,
        vehicleData?.dealer?.name || "N/A",
        vehicleData?.location?.name || "N/A",
        vehicleData?.exterior_color || "N/A",
        "N/A",
        vehicleData?.mileage || "N/A",
        vehicleData?.condition_report?.rating || "N/A",
        vehicleData?.condition_report?.report_url || "",
        vehicleData?.acv || "N/A",
        vehicleData?.floor_price || "N/A",
        vehicleData?.dealer_stock_number || "N/A"
      );

      const auctionDetails = createVINModalAuctionData(
        vehicleData?.condition_report?.status ===
          ConditionReportStatus.Requested
          ? MODAL_FIELD_TYPES.CR_INPUT
          : MODAL_FIELD_TYPES.TEXT,
        ConditionReportStatus[vehicleData?.condition_report?.status],
        vehicleData?.condition_report?.rating || "N/A",
        vehicleData?.condition_report?.report_url || "",
        vehicleData?.status === VehicleStatus.TransportRequest
          ? TransportRequestStatus[4]
          : "N/A",
        vehicleData?.status === VehicleStatus.InAuction
          ? `Lane # ${lane}, Run # ${run}`
          : "N/A",
        VehicleStatus[vehicleData?.status],
        checkBoolean(permissions?.[Actions.PSIStatusUpdate]) &&
          vehicleData?.psi_status?.status
          ? MODAL_FIELD_TYPES.SELECT
          : MODAL_FIELD_TYPES.TEXT,
        vehicleData?.psi_status?.status
          ? checkBoolean(permissions?.[Actions.PSIStatusUpdate])
            ? vehicleData?.psi_status?.status
            : PSIStatus[vehicleData?.psi_status?.status]
          : "Not Requested",
        checkBoolean(permissions?.[Actions.TitleStatusUpdate]) &&
          vehicleData?.title?.status
          ? MODAL_FIELD_TYPES.SELECT
          : MODAL_FIELD_TYPES.TEXT,
        vehicleData?.title?.status
          ? checkBoolean(permissions?.[Actions.TitleStatusUpdate])
            ? vehicleData?.title?.status
            : TitleStatus[vehicleData?.title?.status]
          : "N/A",
        history?.location?.pathname === FRONTEND_ROUTES.VEHICLE_SOLD,
        "No arbitration"
      );

      setVehicleTitleData({
        make: vehicleData?.make,
        model: vehicleData?.model,
        year: vehicleData?.year,
        vin: vehicleData?.vin,
        stockNumber: vehicleData?.stock_number,
      });

      const isInventory =
        history?.location?.pathname === FRONTEND_ROUTES.VEHICLE_INVENTORY;

      const modalButtons = createVINModalButtons(
        checkBoolean(permissions?.[Actions.RerunInAuctionButton]) &&
          hasAuctionCount &&
          isInventory,
        checkBoolean(permissions?.[Actions.SecondChanceAuctionButton]) &&
          hasAuctionCount &&
          isInventory,
        checkBoolean(permissions?.[Actions.OVEEventSale]) && isInventory
      );

      setModalButtons(modalButtons);
      setVehicleDetails(vehicleDetails);
      setAuctionDetails(auctionDetails);
    }
  }, [vehicleStateData, success]);

  return (
    <>
      {!auctionDetails && !vehicleDetails ? (
        <Loader />
      ) : (
        <Box className="text-tertiary-200">
          <Box
            onClick={handleClose}
            className="bg-tertiary-100 hover:bg-tertiary-200 p-1 cursor-pointer absolute right-4 top-4 rounded-full flex items-center justify-center"
          >
            <CloseIcon className="text-primary-100" />
          </Box>
          <Box className="mb-2 font-bold text-tertiary-400">
            {vehicleTitleData?.stockNumber} | {vehicleTitleData.year},{" "}
            {vehicleTitleData?.make}, {vehicleTitleData?.model}
          </Box>
          <Box className="flex items-center justify-between mb-2">
            <Box className="text-2xl font-bold">
              VIN: {vehicleTitleData?.vin}
            </Box>
            <Link
              target="_blank"
              to={`${FRONTEND_ROUTES.KBB_REPORT}/${data?.vehicleId}`}
            >
              <ButtonComponent
                type={CONSTANTS.NEW_WINDOW}
                text="View KBB Report"
                color={CONSTANTS.PRIMARY}
                size={SIZES.SMALL}
              />
            </Link>
          </Box>
          <Box className="flex border-t-2 border-tertiary-300">
            <Box className="border-r-2 p-4 border-tertiary-300 pl-0">
              {vehicleDetails?.map((vehicleDetail) => {
                return (
                  <Box className="flex my-1">
                    <Box className="w-32 text-left mr-4 flex items-start">
                      {vehicleDetail?.title}:
                    </Box>
                    <Box className="w-40 flex items-start">
                      {vehicleDetail?.value}
                      {vehicleDetail?.url && (
                        <a
                          href={vehicleDetail?.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Box className="cursor-pointer ml-4 w-[20px] h-[20px] bg-[url(./assets/images/ExternalLink.png)] bg-no-repeat bg-contain"></Box>
                        </a>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box className="p-4 w-[700px]">
              {auctionDetails?.map((auctionDetail) => {
                return (
                  <>
                    <Box className="flex my-1">
                      <Box className="w-40 text-left mr-4 font-bold">
                        {auctionDetail?.title}:
                      </Box>
                      {auctionDetail?.type === MODAL_FIELD_TYPES.TEXT ? (
                        <Box className="w-40">{auctionDetail?.value}</Box>
                      ) : auctionDetail?.type === MODAL_FIELD_TYPES.CR_INPUT ? (
                        <Box className="flex items-center ">
                          <FieldComponent
                            className="w-40"
                            inputClassName="mr-2"
                            placeholder="CR Report URL"
                            value={crData?.url}
                            type={CONSTANTS.TEXT}
                            size={SIZES.SMALL}
                            hideErrorMessage={true}
                            handleChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) =>
                              handleCRChange(
                                MODAL_FIELD_TYPES.CR_URL,
                                event?.target?.value
                              )
                            }
                          />
                          <FieldComponent
                            className="w-40"
                            placeholder="CR Grade"
                            value={crData?.grade}
                            type={CONSTANTS.TEXT}
                            size={SIZES.SMALL}
                            hideErrorMessage={true}
                            handleChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) =>
                              handleCRChange(
                                MODAL_FIELD_TYPES.CR_GRADE,
                                event?.target?.value
                              )
                            }
                          />
                          <ButtonComponent
                            className="w-24"
                            text="Update"
                            color={CONSTANTS.TERTIARY}
                            disabled={!crData?.grade || !crData?.url}
                            onClick={handleUpdateCR}
                          />
                        </Box>
                      ) : auctionDetail?.type === MODAL_FIELD_TYPES.SELECT ? (
                        <SelectComponent
                          className="bg-primary-100 w-60"
                          value={auctionDetail?.value}
                          options={auctionDetail?.options}
                          hideErrorMessage={true}
                          handleChange={(event: SelectChangeEvent<string>) =>
                            handleSelectChange(
                              auctionDetail?.name as string,
                              event?.target?.value
                            )
                          }
                        />
                      ) : null}
                    </Box>
                  </>
                );
              })}

              <Box>
                <Box className="flex items-center mt-6 mb-3">
                  {modalButtons?.map((button) =>
                    button?.show ? (
                      <ButtonComponent
                        className={button?.className}
                        size={SIZES.SMALL}
                        color={CONSTANTS.PRIMARY}
                        text={button?.title}
                        onClick={() => buttonClick(button?.type as string)}
                      />
                    ) : null
                  )}
                </Box>
                <FieldComponent
                  className={showInput ? "visible" : "invisible"}
                  placeholder="OVE Event Price"
                  type={CONSTANTS.TICK}
                  value={ovePrice?.toString()}
                  handleChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleOvePrice(event?.target?.value)
                  }
                  handleIconClick={() =>
                    buttonClick(MODAL_BUTTON_NAMES.SUBMIT_FOR_OVE_EVENT)
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

const UserModal = ({
  type,
  edit = false,
  submitButtonText = "Save and Close",
  handleClose,
}: UserModalProps) => {
  let data: ModalFieldProps[];
  let title;
  let validationSchema;
  let initialValues: DynamicKeyValuesWithStringArray = {};

  const location = useLocation();
  const isAuctionRoute = location.pathname === FRONTEND_ROUTES.AUCTION;

  const formsWithDynamicSelect = [
    MODAL_TYPE.TRANSPORTER,
    MODAL_TYPE.TRANSPORTER_USER,
    MODAL_TYPE.DEALER,
    MODAL_TYPE.DEALER_USER,
    MODAL_TYPE.CREATE_AUCTION,
    MODAL_TYPE.ASSIGN_AUCTION,
    MODAL_TYPE.REQUEST_TRANSPORT,
    MODAL_TYPE.ADD_VEHICLE,
  ];

  const locationActions = [
    MODAL_TYPE.TRANSPORTER_USER,
    MODAL_TYPE.DEALER_USER,
    MODAL_TYPE.CREATE_AUCTION,
    MODAL_TYPE.REQUEST_TRANSPORT,
    MODAL_TYPE.DEALER,
    MODAL_TYPE.TRANSPORTER,
  ];

  const responseModalActions = [
    MODAL_TYPE.ASSIGN_AUCTION,
    MODAL_TYPE.REQUEST_TRANSPORT,
  ];

  const auctionActions = [MODAL_TYPE.ASSIGN_AUCTION];

  const transporterActions = [MODAL_TYPE.TRANSPORTER_USER];

  const dealerActions = [MODAL_TYPE.DEALER_USER];

  const vehicleActions = [MODAL_TYPE.ADD_VEHICLE];

  const addUserModals = [
    MODAL_TYPE.TRANSPORTER,
    MODAL_TYPE.TRANSPORTER_USER,
    MODAL_TYPE.DEALER,
    MODAL_TYPE.DEALER_USER,
    MODAL_TYPE.USER,
  ];

  const dispatch = useAppDispatch();
  const successDispatcher = useSuccessDispatcher();
  const errorDispatcher = useErrorDispatcher();

  const inputRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);

  const state = useAppSelector((state) => state);
  const modalData = state?.modal.data;
  const role = state?.authorization?.role;
  const selectedVehicles = modalData?.selectedVehicles;
  const modalValues = modalData?.modalValues;

  const [fieldsData, setFieldsData] = useState<ModalFieldProps[]>([]);

  const [vehicleVIN, setVehicleVIN] = useState<string>("");

  const { data: userData } = useGetUserDataQuery("");
  const [getDealerById] = useLazyGetDealerByIdQuery();
  const [createSpectrumUser] = useCreateSpectrumUserMutation();
  const [updateSpectrumUser] = useUpdateSpectrumUserMutation();
  const [createDealer] = useCreateDealerMutation();
  const [updateDealer] = useUpdateDealerMutation();
  const [createTransporter] = useCreateTransporterMutation();
  const [updateTransporter] = useUpdateTransporterMutation();
  const [updateTransporterUser] = useUpdateTransporterUserMutation();
  const [updateDealerUser] = useUpdateDealerUserMutation();
  const [createDealerUser] = useCreateDealerUserMutation();
  const [createTransporterUser] = useCreateTransporterUserMutation();
  const [getVehicleInfoByVIN] = useLazyGetVehicleDetailByVINQuery();
  const [createVehicle] = useCreateVehicleMutation();
  const [updateVehicle] = useUpdateVehicleMutation();
  const [createAuction] = useCreateAuctionMutation();
  const [updateAuction] = useUpdateAuctionMutation();
  const [assignAuction] = useAssignAuctionMutation();
  const [createTransportRequest] = useCreateTransportRequestMutation();
  const [createLocation] = useCreateLocationMutation();
  const [updateLocation] = useUpdateLocationMutation();
  const [createAnnouncement] = useCreateAnnouncementMutation();
  const [updateAnnouncement] = useUpdateAnnouncementMutation();
  const [createRemark] = useCreateRemarkMutation();
  const [updateRemark] = useUpdateRemarkMutation();
  const [getAuctions, { data: auctionsData }] = useLazyGetAuctionsQuery();
  const [checkUsername] = useLazyCheckUsernameQuery();
  const [getLocations, { data: locationsData }] = useLazyGetLocationsQuery();

  const [getDealers, { data: dealersData }] = useLazyGetDealersQuery();

  const [getTransporters, { data: transportersData }] =
    useLazyGetTransportersQuery();

  const [getRemarks, { data: remarksData }] = useLazyGetRemarksQuery();

  const [getAnnouncements, { data: announcementsData }] =
    useLazyGetAnnouncementsQuery();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopperClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const popperOpen = Boolean(anchorEl);
  const id = popperOpen ? "simple-popper" : undefined;

  const handleVIN = async (values: any, setValues: any) => {
    const vin = values?.vin;
    if (vin) {
      if (vin?.length !== 17) {
        return;
      }
      dispatch(toggleProgressBar(true));
      const response = await getVehicleInfoByVIN(vin);
      if (!response?.error) {
        const vehicleInfo = response?.data?.data;
        if (vehicleInfo?.vin === vin) {
          setValues({
            ...values,
            year: vehicleInfo?.year,
            make: vehicleInfo?.make,
            model: vehicleInfo?.model,
          });
          setVehicleVIN(vin);
        }
      } else {
        setValues({
          ...values,
          year: "",
          make: "",
          model: "",
        });
      }
      dispatch(toggleProgressBar(false));
    } else {
      errorDispatcher(true, MESSAGES.VEHICLE.INVALID_VIN_LENGTH);
    }
  };

  const phoneNumberParser = (numberWithCode: string) => {
    const splittedContactNumber = numberWithCode?.toString().split(" ");
    const countryCode = splittedContactNumber[NUMBER.ZERO];
    const contactNumber = splittedContactNumber
      .slice(1, splittedContactNumber.length)
      .join("");

    return {
      countryCode,
      contactNumber,
    };
  };

  const parseData = (
    data: ModalFieldProps[],
    locationsData: Array<ModalSelectOption>,
    dealersData?: Array<ModalSelectOption>,
    transportersData?: Array<ModalSelectOption>,
    auctionsData?: Array<ModalSelectOption>,
    announcementsData?: Array<ModalSelectOption>,
    remarksData?: Array<ModalSelectOption>
  ) =>
    data?.map((field) => {
      if (
        field.name === MODAL_COMPONENT_FIELD_NAMES.LOCATION &&
        locationsData
      ) {
        const locations = locationsData?.map((location) => {
          return {
            label: location.name,
            value: location.id,
          };
        });
        return {
          ...field,
          options: locations,
        };
      } else if (
        (field.name === MODAL_COMPONENT_FIELD_NAMES.DEALERSHIP_NAME ||
          field.name === MODAL_COMPONENT_FIELD_NAMES.DEALER) &&
        dealersData
      ) {
        const dealers = dealersData?.map((dealer) => {
          return {
            label: dealer.name,
            value: dealer.id,
          };
        });
        return {
          ...field,
          options: dealers,
        };
      } else if (
        field.name === MODAL_COMPONENT_FIELD_NAMES.COMPANY_NAME &&
        transportersData
      ) {
        const transporters = transportersData?.map((transporter) => {
          return {
            label: transporter.name,
            value: transporter.id,
          };
        });
        return {
          ...field,
          options: transporters,
        };
      } else if (
        field.name === MODAL_COMPONENT_FIELD_NAMES.AUCTION &&
        auctionsData
      ) {
        const auctions = auctionsData?.map((auction) => {
          const auctionDate = auction?.start_time
            ? formatDate(
                auction?.start_time as string,
                DATE_TYPE.TIMESTAMP,
                DATE_FORMAT.YYYY_MM_DD
              )
            : formatDate(
                auction?.auction_date as string,
                DATE_TYPE.DATE_STRING,
                DATE_FORMAT.YYYY_MM_DD
              );
          return {
            label: auctionDate + " : " + auction?.location?.name || "N/A",
            value: auction.id,
          };
        });
        return {
          ...field,
          options: auctions,
        };
      } else if (
        field.name === MODAL_COMPONENT_FIELD_NAMES.ANNOUNCEMENTS &&
        announcementsData
      ) {
        const announcements = announcementsData?.map((announcement) => {
          return {
            label: announcement?.label,
            value: announcement?.id,
          };
        });
        return {
          ...field,
          options: announcements,
        };
      } else if (
        field.name === MODAL_COMPONENT_FIELD_NAMES.REMARKS &&
        remarksData
      ) {
        const remarks = remarksData?.map((remark) => {
          return {
            label: remark?.label,
            value: remark?.id,
          };
        });
        return {
          ...field,
          options: remarks,
        };
      }

      return field;
    });

  const updateAdminOrUser = (
    initialValues: DynamicKeyValuesWithStringArray,
    values: DynamicKeyValuesWithStringArray,
    adminField: string[]
  ) => {
    let updateAdmin = false;
    let updateUser = false;
    Object.entries(values).forEach((value) => {
      if (
        initialValues[value[0]] !== undefined &&
        initialValues[value[0]] !== value[1]
      ) {
        if (adminField.find((elem) => elem === value[NUMBER.ZERO])) {
          updateAdmin = true;
        } else {
          updateUser = true;
        }
      }
    });
    return { updateAdmin, updateUser };
  };

  const getModifiedFields = (
    initialValues: ModalValuesType,
    currentValues: DynamicKeyValuesWithStringArray
  ) => {
    const modalValuesKey: { [key: string]: string } = {
      acquired_from: MODAL_COMPONENT_FIELD_NAMES.ACQUIRED_FROM,
      floor_price: MODAL_COMPONENT_FIELD_NAMES.FLOOR_PRICE,
      remark_ids: MODAL_COMPONENT_FIELD_NAMES.REMARKS,
      announcement_id: MODAL_COMPONENT_FIELD_NAMES.ANNOUNCEMENTS,
      dealer_stock_number: MODAL_COMPONENT_FIELD_NAMES.DEALER_STOCK_NUMBER,
    };

    const modifiedFields: {
      [key: string]: string | boolean | number | Array<string> | Array<number>;
    } = {};
    for (const key in currentValues) {
      if (Array.isArray(currentValues[key])) {
        const areEqual = areArraysEqual(
          currentValues[key] as Array<string>,
          modalValuesKey[key]
            ? (initialValues[modalValuesKey[key]] as Array<string>)
            : (initialValues[key] as Array<string>)
        );
        if (!areEqual)
          modifiedFields[key] = stringToNumberArray(
            currentValues[key] as Array<string>
          );
      } else {
        if (modalValuesKey[key]) {
          if (currentValues[key] !== initialValues[modalValuesKey[key]]) {
            modifiedFields[key] = currentValues[key];
          }
        } else {
          if (currentValues[key] !== initialValues[key]) {
            modifiedFields[key] = currentValues[key];
          }
        }
      }
    }

    return modifiedFields;
  };

  const handleFormSubmit = async (values: DynamicKeyValuesWithStringArray) => {
    let response: any;
    let message = "";
    let id;
    const { countryCode, contactNumber } = values?.contactNumber
      ? phoneNumberParser(values?.contactNumber as string)
      : { countryCode: null, contactNumber: null };

    dispatch(toggleProgressBar(true));

    let usernameExists = false;
    if (addUserModals.includes(type) && !edit) {
      const params = appendQueryParams(
        {
          username: values?.username as string,
        },
        false
      );
      const usernameResponse = await checkUsername(params);

      if (!usernameResponse?.data?.data?.status) {
        usernameExists = true;
        errorDispatcher(true, MESSAGES.USER.USERNAME_EXISTS_ERROR);
      }
    }

    if (!usernameExists) {
      switch (type) {
        case MODAL_TYPE.TRANSPORTER:
          if (!edit) {
            response = await createTransporter({
              name: values?.companyName as string,
              location_id: values?.location as number,
              address: values?.address as string,
              zip: values?.zipCode as string,
              state: values?.state as string,
              email: values?.email as string,
              user_name: values?.username as string,
              country_code: countryCode as string,
              phone_number: contactNumber as string,
              password: values?.password as string,
              first_name: values?.firstName as string,
              last_name: values?.lastName as string,
            });
            message = MESSAGES.STAKEHOLDER.CREATE_TRANSPORTER_SUCCESS;
          } else {
            const { updateAdmin, updateUser } = updateAdminOrUser(
              initialValues,
              values,
              [
                MODAL_COMPONENT_FIELD_NAMES.COMPANY_NAME,
                MODAL_COMPONENT_FIELD_NAMES.ACTIVE,
              ]
            );
            if (updateAdmin) {
              response = await updateTransporter({
                payload: {
                  name: values?.companyName as string,
                },
                id: modalValues?.transporter_id as string,
              });
              message = MESSAGES.STAKEHOLDER.UPDATE_TRANSPORTER_SUCCESS;
            }
            if (updateUser) {
              response = await updateTransporterUser({
                payload: {
                  transporter_id: modalValues?.transporter_id as number,
                  location_id: values?.location as number,
                  address: values?.address as string,
                  zip: values?.zipCode as string,
                  state: values?.state as string,
                  role_id: UserTypes.TransporterAdmin,
                  first_name: values?.firstName as string,
                  last_name: values?.lastName as string,
                  is_active: values?.active as boolean,
                },
                id: modalValues?.transporter_user_id as string,
              });
              message = MESSAGES.USER.UPDATE_TRANSPORTER_USER_SUCCESS;
            }
          }
          break;
        case MODAL_TYPE.TRANSPORTER_USER:
          if (!edit) {
            response = await createTransporterUser({
              transporter_id: values?.companyName as string,
              location_id: values?.location as number,
              address: values?.address as string,
              zip: values?.zipCode as string,
              state: values?.state as string,
              role_id: UserTypes.TransporterUser,
              email: values?.email as string,
              user_name: values?.username as string,
              country_code: countryCode as string,
              phone_number: contactNumber as string,
              password: values?.password as string,
              first_name: values?.firstName as string,
              last_name: values?.lastName as string,
            });
            message = MESSAGES.USER.CREATE_TRANSPORTER_USER_SUCCESS;
          } else {
            response = await updateTransporterUser({
              payload: {
                transporter_id: values?.companyName as number,
                location_id: values?.location as number,
                address: values?.address as string,
                zip: values?.zipCode as string,
                state: values?.state as string,
                role_id: values?.userRole as number,
                first_name: values?.firstName as string,
                last_name: values?.lastName as string,
                is_active: values?.active as boolean,
              },
              id: modalValues?.id as string,
            });
            message = MESSAGES.USER.UPDATE_TRANSPORTER_USER_SUCCESS;
          }
          break;
        case MODAL_TYPE.DEALER:
          if (!edit) {
            response = await createDealer({
              million_number: Number(values?.millionNumber) as number,
              name: values?.dealerName as string,
              type: DealerType.Premium,
              location_id: values?.location as number,
              address: values?.address as string,
              zip: values?.zipCode as string,
              state: values?.state as string,
              email: values?.email as string,
              user_name: values?.username as string,
              country_code: countryCode as string,
              phone_number: contactNumber as string,
              password: values?.password as string,
              first_name: values?.firstName as string,
              last_name: values?.lastName as string,
            });
            message = MESSAGES.STAKEHOLDER.CREATE_DEALER_SUCCESS;
          } else {
            const { updateAdmin, updateUser } = updateAdminOrUser(
              initialValues,
              values,
              [
                MODAL_COMPONENT_FIELD_NAMES.MILLION_NUMBER,
                MODAL_COMPONENT_FIELD_NAMES.DEALER_NAME,
                MODAL_COMPONENT_FIELD_NAMES.ACTIVE,
              ]
            );
            if (updateAdmin) {
              response = await updateDealer({
                payload: {
                  million_number: Number(values?.millionNumber) as number,
                  name: values?.dealerName as string,
                  type: DealerType.Premium,
                },
                id: modalValues?.dealer_id as string,
              });
              message = MESSAGES.STAKEHOLDER.UPDATE_DEALER_SUCCESS;
            }
            if (updateUser) {
              response = await updateDealerUser({
                payload: {
                  dealer_id: modalValues?.dealer_id as number,
                  location_id: values?.location as number,
                  address: values?.address as string,
                  zip: values?.zipCode as string,
                  state: values?.state as string,
                  role_id: undefined,
                  first_name: values?.firstName as string,
                  last_name: values?.lastName as string,
                  is_active: values?.active as boolean,
                },
                id: modalValues?.dealer_user_id as string,
              });
              message = MESSAGES.USER.UPDATE_DEALER_USER_SUCCESS;
            }
          }
          break;
        case MODAL_TYPE.DEALER_USER:
          if (!edit) {
            response = await createDealerUser({
              dealer_id: values?.dealershipName as number,
              location_id: values?.location as number,
              address: values?.address as string,
              zip: values?.zipCode as string,
              state: values?.state as string,
              role_id: values?.dealerRole as number,
              email: values?.email as string,
              user_name: values?.username as string,
              country_code: countryCode as string,
              phone_number: contactNumber as string,
              password: values?.password as string,
              first_name: values?.firstName as string,
              last_name: values?.lastName as string,
            });
            message = MESSAGES.USER.CREATE_DEALER_USER_SUCCESS;
          } else {
            response = await updateDealerUser({
              payload: {
                dealer_id: values?.dealershipName as number,
                location_id: values?.location as number,
                address: values?.address as string,
                zip: values?.zipCode as string,
                state: values?.state as string,
                role_id: values?.dealerRole as number,
                first_name: values?.firstName as string,
                last_name: values?.lastName as string,
                is_active: values?.active as boolean,
              },
              id: modalValues?.id as string,
            });
            message = MESSAGES.USER.UPDATE_DEALER_USER_SUCCESS;
          }
          break;
        case MODAL_TYPE.ASSIGN_AUCTION:
          if (
            typeof selectedVehicles === "object" &&
            !Array.isArray(selectedVehicles)
          ) {
            const assignAuctionObj = {
              ...selectedVehicles,
              auction_id: Number(values?.auction),
              update_location: values?.updateLocation as boolean,
            };
            response = await assignAuction(assignAuctionObj);
            let responseHTML = "";
            response?.data?.data?.forEach((data: any) => {
              responseHTML += renderAssignAuctionResponseHTML(
                data?.status,
                data?.vehicle?.vin,
                data?.vehicle?.make,
                data?.vehicle?.model,
                data?.message
              );
            });

            if (!response?.error) {
              Swal.fire({
                html: responseHTML,
                width: 600,
                position: "top-end",
                title: "Assign Auction Results",
                confirmButtonText: "Continue",
                buttonsStyling: false,
                customClass: {
                  confirmButton:
                    "bg-secondary-200 text-tertiary-200 py-2 px-4 rounded-md",
                  title: "text-tertiary-200",
                },
              });
            }
          }
          break;
        case MODAL_TYPE.CREATE_AUCTION:
          const date = new Date(values?.date?.toString())
            .toISOString()
            .split("T")[0];
          if (!edit) {
            const auction = {
              location_id: values?.location as number,
              auction_date: date,
            };

            response = await createAuction(auction);
            message = MESSAGES.AUCTION.CREATE_AUCTION_SUCCESS;
          } else {
            const auction = {
              payload: {
                location_id: values?.location as number,
                auction_date: date,
                is_active: values?.active as boolean,
              },
              id: modalValues?.id as string,
            };
            response = await updateAuction(auction);
            message = MESSAGES.AUCTION.UPDATE_AUCTION_SUCCESS;
          }
          break;
        case MODAL_TYPE.REQUEST_CR:
          break;
        case MODAL_TYPE.REQUEST_TRANSPORT:
          if (Array.isArray(selectedVehicles)) {
            const requestTransportObj = {
              name: values?.name as string,
              country_code: countryCode as string,
              phone_number: contactNumber as string,
              vehicle_ids: selectedVehicles,
              pickup_date_time: values?.pickupDate as string,
              dropoff_location_id: values?.location as number,
              remarks: values?.remarks as string,
            };
            response = await createTransportRequest(requestTransportObj);
            let responseHTML = "";
            response?.data?.data?.forEach((data: any) => {
              responseHTML += renderRequestTransportResponseHTML(
                data?.status,
                data?.message
              );
            });

            if (!response?.error) {
              Swal.fire({
                html: responseHTML,
                width: 600,
                position: "top-end",
                title: "Transport Request Results",
                confirmButtonText: "Continue",
                buttonsStyling: false,
                customClass: {
                  confirmButton:
                    "bg-secondary-200 text-tertiary-200 py-2 px-4 rounded-md",
                  title: "text-tertiary-200",
                },
              });
            }

            message = MESSAGES.TRANSPORTER.REQUEST_FOR_TRANSPORT_SUCCESS;
          }
          break;
        case MODAL_TYPE.ADD_VEHICLE:
          if (!edit) {
            if (vehicleVIN === values?.vin) {
              const remarkIds = (values?.remarks as Array<string>)?.map(
                (remarkId: string) => Number(remarkId)
              );

              const vehicle = {
                dealer_id:
                  +values?.dealer ||
                  (+userData?.data?.dealer_user?.dealer_id as number),
                location_id:
                  +values?.dealerLocation ||
                  (+userData?.data?.dealer_user?.location_id as number),
                acquired_from: values?.acquiredFrom,
                vin: values?.vin,
                year: +values?.year as number,
                make: values?.make,
                model: values?.model,
                exterior_color: values?.exteriorColor,
                mileage: values?.mileage,
                floor_price: +values?.floorPrice as number,
                remark_ids: remarkIds,
                announcement_id: values?.announcements,
                dealer_stock_number: values?.dealerStockNumber,
                acv: +values?.acv as number,
                cr_rating: NUMBER.ZERO,
                cr_report: "string",
                car_fax: "string",
                comment: values?.comment,
              };
              response = await createVehicle(vehicle);
              message = MESSAGES.VEHICLE.CREATE_VEHICLE_SUCCESS;
            } else {
              errorDispatcher(true, MESSAGES.VEHICLE.INVALID_VIN);
            }
          } else {
            const remarkIds = (values?.remarks as Array<string>)?.map(
              (remarkId: string) => remarkId
            );
            const currentValues = {
              acquired_from: values?.acquiredFrom as number,
              mileage: values?.mileage as string,
              floor_price: +values?.floorPrice as number,
              remark_ids: remarkIds as Array<string>,
              announcement_id: values?.announcements as number,
              dealer_stock_number: values?.dealerStockNumber as string,
              acv: +values?.acv as number,
              exterior_color: values?.exteriorColor as string,
              comment: values?.comment as string,
              location_id: +values?.dealerLocation,
            };
            if (modalValues) {
              const modifiedFields = getModifiedFields(
                modalValues,
                currentValues
              );

              const vehicle = {
                payload: modifiedFields,
                id: modalValues?.id as string,
              };
              response = await updateVehicle(vehicle);
              message = MESSAGES.VEHICLE.UPDATE_VEHICLE_SUCCESS;
            }
          }
          break;
        case MODAL_TYPE.ADD_LOCATION:
          id = modalValues?.id as string;
          const locationObj = {
            time_zone: Number(values?.timezone?.toString()?.replace(":", ".")),
            name: values?.name as string,
          };
          if (!edit) {
            response = await createLocation(locationObj);
            message = MESSAGES.SETTING.CREATE_LOCATION_SUCCESS;
          } else {
            const locationObj = {
              time_zone: Number(
                values?.timezone?.toString()?.replace(":", ".")
              ),
              name: values?.name as string,
              is_active: values?.active as boolean,
            };
            response = await updateLocation({
              id,
              payload: locationObj,
            });
            message = MESSAGES.SETTING.UPDATE_LOCATION_SUCCESS;
          }
          break;
        case MODAL_TYPE.ADD_ANNOUNCEMENT:
          id = modalValues?.id as string;
          if (!edit) {
            const announcementObj = {
              label: values?.label as string,
            };
            response = await createAnnouncement(announcementObj);
            message = MESSAGES.SETTING.CREATE_ANNOUNCEMENT_SUCCESS;
          } else {
            const announcementObj = {
              label: values?.label as string,
              is_deleted: !values?.active as boolean,
            };
            response = await updateAnnouncement({
              id,
              payload: announcementObj,
            });
            message = MESSAGES.SETTING.UPDATE_ANNOUNCEMENT_SUCCESS;
          }
          break;
        case MODAL_TYPE.ADD_REMARKS:
          id = modalValues?.id as string;
          if (!edit) {
            const remarkObj = {
              label: values?.label as string,
            };

            response = await createRemark(remarkObj);
            message = MESSAGES.SETTING.CREATE_REMARK_SUCCESS;
          } else {
            const remarkObj = {
              label: values?.label as string,
              is_deleted: !values?.active as boolean,
            };
            response = await updateRemark({
              id,
              payload: remarkObj,
            });
            message = MESSAGES.SETTING.UPDATE_REMARK_SUCCESS;
          }
          break;
        default:
          if (!edit) {
            response = await createSpectrumUser({
              role_id: values?.userRole as number,
              email: values?.email as string,
              user_name: values?.username as string,
              country_code: countryCode as string,
              phone_number: contactNumber as string,
              password: values?.password as string,
              first_name: values?.firstName as string,
              last_name: values?.lastName as string,
            });
            message = MESSAGES.USER.CREATE_USER_SUCCESS;
          } else {
            response = await updateSpectrumUser({
              payload: {
                first_name: values?.firstName as string,
                last_name: values?.lastName as string,
                is_active: values?.active as boolean,
                role_id: values?.userRole as number,
              },
              id: modalValues?.id as string,
            });
            message = MESSAGES.USER.UPDATE_USER_SUCCESS;
          }
          break;
      }
    }
    dispatch(toggleProgressBar(false));

    if (response?.data?.message === "Success") {
      if (!responseModalActions.includes(type))
        successDispatcher(true, message);
      handleClose();
    }
  };

  const handleDealerLocations = async (
    dealerId: string,
    fieldsData: ModalFieldProps[],
    showProgressBar: boolean
  ) => {
    if (showProgressBar) dispatch(toggleProgressBar(true));
    const dealerResponse = await getDealerById(dealerId, true);
    const locations = dealerResponse?.data?.data?.locations || [];

    const locationOptions = locations?.map((location: any) => {
      return {
        label: location.name,
        value: location.id,
      };
    });

    const modifiedFieldsData = fieldsData?.map((field: any) => {
      if (field?.name === TABLE_COLUMNS.DEALER_LOCATION) {
        return {
          ...field,
          options: locationOptions,
        };
      }
      return field;
    });
    setFieldsData(modifiedFieldsData);

    if (showProgressBar) dispatch(toggleProgressBar(false));
  };

  switch (type) {
    case MODAL_TYPE.TRANSPORTER:
      if (!edit) {
        data = transporterModalFields;
        title = MODAL_TITLE.TRANSPORTER;
        validationSchema = transporterSchema;
      } else {
        data = getEditableFields(transporterModalFields);
        modalValues && setEditModalValues(data, modalValues, initialValues);
        title = MODAL_TITLE.EDIT_TRANSPORTER;
        validationSchema = transporterEditSchema;
      }
      break;
    case MODAL_TYPE.TRANSPORTER_USER:
      if (!edit) {
        data = transporterUserModalFields;
        title = MODAL_TITLE.TRANSPORTER_USER;
        validationSchema = transporterUserSchema;
      } else {
        data = getEditableFields(transporterUserModalFields);
        modalValues && setEditModalValues(data, modalValues, initialValues);
        title = MODAL_TITLE.EDIT_TRANSPORTER_USER;
        validationSchema = transporterUserEditSchema;
      }
      break;
    case MODAL_TYPE.DEALER:
      if (!edit) {
        validationSchema = dealerSchema;
        data = dealerModalFields;
        title = MODAL_TITLE.DEALER;
      } else {
        data = getEditableFields(dealerModalFields);
        modalValues && setEditModalValues(data, modalValues, initialValues);
        validationSchema = dealerEditSchema;
        title = MODAL_TITLE.EDIT_DEALER;
      }
      break;
    case MODAL_TYPE.DEALER_USER:
      if (!edit) {
        data = dealerUserModalFields;
        title = MODAL_TITLE.DEALER_USER;
        validationSchema = dealerUserSchema;
      } else {
        data = getEditableFields(dealerUserModalFields);
        modalValues && setEditModalValues(data, modalValues, initialValues);
        title = MODAL_TITLE.EDIT_DEALER_USER;
        validationSchema = dealerUserEditSchema;
      }
      break;
    case MODAL_TYPE.ASSIGN_AUCTION:
      data = assignAuctionModalFields;
      title = MODAL_TITLE.ASSIGN_AUCTION;
      initialValues[MODAL_COMPONENT_FIELD_NAMES.UPDATE_LOCATION] = false;
      validationSchema = assignAuctionSchema;
      break;
    case MODAL_TYPE.CREATE_AUCTION:
      data = createAuctionModalFields;
      title = MODAL_TITLE.CREATE_AUCTION;
      validationSchema = createAuctionSchema;
      if (!edit) {
        title = MODAL_TITLE.CREATE_AUCTION;
      } else {
        title = MODAL_TITLE.UPDATE_AUCTION;
        initialValues[MODAL_COMPONENT_FIELD_NAMES.LOCATION] =
          modalValues?.location_id as number;
        initialValues[MODAL_COMPONENT_FIELD_NAMES.DATE] =
          modalValues?.auction_date as string;
        initialValues[MODAL_COMPONENT_FIELD_NAMES.ACTIVE] =
          modalValues?.is_active as boolean;
      }
      break;
    case MODAL_TYPE.REQUEST_CR:
      data = requestCRModalFields;
      title = MODAL_TITLE.REQUEST_CR;
      validationSchema = requestCRSchema;
      break;
    case MODAL_TYPE.REQUEST_TRANSPORT:
      data = requestTransportModalFields;
      title = MODAL_TITLE.REQUEST_TRANSPORT;
      validationSchema = requestTransportSchema;
      break;
    case MODAL_TYPE.ADD_VEHICLE:
      if (!edit) {
        data = generateAddVehicleModalFields(role as UserTypes);
        title = MODAL_TITLE.ADD_VEHICLE;
        validationSchema = generateAddVehicleSchema(role as UserTypes);
      } else {
        data = getEditableFields(
          generateAddVehicleModalFields(role as UserTypes),
          isAuctionRoute
        );

        title = MODAL_TITLE.UPDATE_VEHICLE;
        modalValues && setEditModalValues(data, modalValues, initialValues);
        validationSchema = updateVehicleSchema(
          role as UserTypes,
          isAuctionRoute
        );
      }
      break;
    case MODAL_TYPE.ADD_LOCATION:
      data = addLocationFields;
      title = MODAL_TITLE.ADD_LOCATION;
      validationSchema = addLocationSchema;
      if (edit) {
        initialValues[MODAL_COMPONENT_FIELD_NAMES.NAME] =
          (modalValues?.name as string) || "";
        initialValues[MODAL_COMPONENT_FIELD_NAMES.TIMEZONE] =
          (modalValues?.timezoneValue as string) || "";
        initialValues[MODAL_COMPONENT_FIELD_NAMES.ACTIVE] =
          modalValues?.isActive as boolean;
      }
      break;
    case MODAL_TYPE.ADD_ANNOUNCEMENT:
      data = addAnnouncementFields;
      title = MODAL_TITLE.ADD_ANNOUNCEMENT;
      validationSchema = addAnnouncementSchema;
      if (edit) {
        initialValues[MODAL_COMPONENT_FIELD_NAMES.LABEL] =
          (modalValues?.label as string) || "";
        initialValues[MODAL_COMPONENT_FIELD_NAMES.ACTIVE] =
          modalValues?.isActive as boolean;
      }
      break;
    case MODAL_TYPE.ADD_REMARKS:
      data = addRemarkFields;
      title = MODAL_TITLE.ADD_REMARKS;
      validationSchema = addRemarkSchema;
      if (edit) {
        initialValues[MODAL_COMPONENT_FIELD_NAMES.LABEL] =
          (modalValues?.label as string) || "";
        initialValues[MODAL_COMPONENT_FIELD_NAMES.ACTIVE] =
          modalValues?.isActive as boolean;
      }
      break;
    default:
      if (edit) {
        data = getEditableFields(userModalFields);
        modalValues && setEditModalValues(data, modalValues, initialValues);
        validationSchema = userEditSchema;
        title = MODAL_TITLE.EDIT_USER;
      } else {
        data = userModalFields;
        validationSchema = userSchema;
        title = MODAL_TITLE.USER;
      }
      break;
  }

  useEffect(() => {
    if (type && formsWithDynamicSelect.includes(type)) {
      const queryParamObj = {
        isActive: "active",
      };
      const apiParams = appendQueryParams(queryParamObj, false);

      if (locationActions.includes(type)) {
        getLocations(apiParams);
      }
      if (auctionActions.includes(type)) {
        const queryParamObj = {
          isActive: "active",
          status: [AuctionStatus.Scheduled, AuctionStatus.Lock],
        };
        const apiParams = appendQueryParams(queryParamObj, false);
        getAuctions(apiParams);
      }
      if (transporterActions.includes(type)) {
        getTransporters(apiParams);
      }
      if (dealerActions.includes(type)) {
        getDealers(apiParams);
      }
      if (vehicleActions.includes(type)) {
        if (ADMIN_ROLES.includes(role as UserTypes)) {
          getDealers(apiParams);
        }
        const queryParamObj = {
          isDeleted: "inactive",
        };
        const settingsApiParams = appendQueryParams(queryParamObj, false);
        getAnnouncements(settingsApiParams);
        getRemarks(settingsApiParams);
      }
    }
  }, [type]); //eslint-disable-line

  useEffect(() => {
    if (formsWithDynamicSelect.includes(type)) {
      const modifiedFields = parseData(
        data,
        locationsData?.data?.locations,
        dealersData?.data?.dealers,
        transportersData?.data?.transporters,
        auctionsData?.data?.auction,
        announcementsData?.data?.announcements,
        remarksData?.data?.remarks
      );

      if (role === UserTypes.DealerAdmin || (edit && modalValues?.dealer)) {
        handleDealerLocations(
          modalValues?.dealer?.toString() ||
            userData?.data?.dealer_user?.dealer_id,
          modifiedFields,
          false
        );
      } else {
        setFieldsData(modifiedFields);
      }
    } else {
      setFieldsData(data);
    }
  }, [
    locationsData,
    transportersData,
    dealersData,
    auctionsData,
    remarksData,
    announcementsData,
  ]);

  return (
    <>
      <Box className="text-xl font-bold uppercase mb-2">{title}</Box>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={async (
          values: DynamicKeyValuesWithStringArray,
          { setSubmitting }
        ) => {
          handleFormSubmit(values);
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setValues,
          isValid,
          dirty,
          /* and other goodies */
        }) => (
          <>
            <Grid
              container
              className="flex items-center justify-between my-3"
              marginTop={2}
              columnSpacing={2}
            >
              {fieldsData?.length ? (
                fieldsData?.map((field, index) => {
                  return (
                    <Grid item sm={12} md={field?.col} key={index}>
                      {field.type === CONSTANTS.INPUT ? (
                        <>
                          {field?.name ===
                          MODAL_COMPONENT_FIELD_NAMES.CONTACT_NUMBER ? (
                            <Box onBlur={handleBlur}>
                              <MuiTelInput
                                label={field?.placeholder}
                                className="w-full"
                                sx={{
                                  "& .MuiInputBase-input": {
                                    height: "7px",
                                  },
                                }}
                                defaultCountry="US"
                                name={field?.name}
                                value={values[field.name] as string}
                                onChange={(val) =>
                                  setFieldValue(field.name, val)
                                }
                              />
                              <Box
                                className={`text-primary-500 ${
                                  errors &&
                                  field.name &&
                                  errors[field.name] &&
                                  touched[field.name]
                                    ? "visible"
                                    : "invisible"
                                } text-xs self-start my-1`}
                              >
                                {errors &&
                                field.name &&
                                errors[field.name] &&
                                touched[field.name]
                                  ? errors[field.name]
                                  : "error"}
                              </Box>
                            </Box>
                          ) : (
                            <FieldComponent
                              name={field?.name}
                              index={index}
                              inputRefs={inputRefs}
                              className="w-full"
                              inputClassName="bg-primary-100"
                              value={values[field.name] as string}
                              readOnly={field?.readOnly}
                              multiline={field?.multiline}
                              label={field?.placeholder}
                              rows={field?.rows}
                              size={SIZES.SMALL}
                              type={
                                field?.icon
                                  ? CONSTANTS.CURRENCY_INPUT
                                  : field?.name ===
                                      MODAL_COMPONENT_FIELD_NAMES.CONFIRM_PASSWORD ||
                                    field?.name ===
                                      MODAL_COMPONENT_FIELD_NAMES.PASSWORD
                                  ? CONSTANTS.PASSWORD
                                  : CONSTANTS.TEXT
                              }
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              errors={errors}
                              touched={touched}
                              errorTextColor="text-primary-500"
                            />
                          )}
                        </>
                      ) : field.type === CONSTANTS.CHECKBOX && edit ? (
                        <FormGroup className="self-start">
                          <FormControlLabel
                            control={
                              <Checkbox
                                inputRef={(el) =>
                                  el ? (inputRefs.current[index] = el) : null
                                }
                                name={field?.name}
                                onChange={handleChange}
                                checked={values[field.name] as boolean}
                              />
                            }
                            label={
                              <Box className="flex items-center text-tertiary-100">
                                {field?.label}
                                {field?.name ===
                                  MODAL_COMPONENT_FIELD_NAMES.UPDATE_LOCATION && (
                                  <Box
                                    onMouseEnter={handlePopperClick}
                                    onMouseLeave={() => setAnchorEl(null)}
                                  >
                                    <InfoIcon className="ml-1 text-red-500" />
                                  </Box>
                                )}
                              </Box>
                            }
                          />
                          {field?.name ===
                            MODAL_COMPONENT_FIELD_NAMES.UPDATE_LOCATION && (
                            <Popper
                              id={id}
                              open={popperOpen}
                              anchorEl={anchorEl}
                              sx={{ zIndex: 9999 }}
                            >
                              <Box
                                sx={{
                                  textAlign: "center",
                                  border: 1,
                                  p: 1,
                                  bgcolor: "background.paper",
                                  maxWidth: "200px",
                                }}
                              >
                                By enabling this, all vehicle locations will be
                                updated to the auction location.
                              </Box>
                            </Popper>
                          )}
                        </FormGroup>
                      ) : field.type === CONSTANTS.BUTTON ? (
                        <ButtonComponent
                          className="w-full go-button padding-7"
                          size={SIZES.SMALL}
                          color={CONSTANTS.PRIMARY}
                          text={field?.label || ""}
                          onClick={() => handleVIN(values, setValues)}
                        />
                      ) : field.type === CONSTANTS.DATEPICKER ? (
                        <Box className="flex items-center justify-between w-full mb-[24px]">
                          {field.label && (
                            <Box className="w-[267px]">{field.label + ":"}</Box>
                          )}
                          <TextField
                            name={field?.name}
                            label={field?.placeholder}
                            InputLabelProps={{ shrink: true }}
                            value={values[field.name] as string}
                            size={SIZES.SMALL}
                            inputProps={{
                              min:
                                field?.name === TABLE_COLUMNS.DROPOFF_DATE
                                  ? values?.pickupDate
                                  : formatDate(
                                      new Date()?.toString(),
                                      DATE_TYPE.DATE_STRING,
                                      DATE_FORMAT.YYYY_MM_DD
                                    ),
                            }}
                            disabled={
                              field?.name === TABLE_COLUMNS.DROPOFF_DATE &&
                              !values?.pickupDate
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="date"
                            className="w-full"
                          ></TextField>
                        </Box>
                      ) : field.type === CONSTANTS.SELECT ? (
                        <Grid className="flex items-center w-full">
                          {field?.label && (
                            <Grid item sm={4} className="whitespace-nowrap">
                              {field?.label}:
                            </Grid>
                          )}
                          <Grid item sm={field?.label ? 8 : 12}>
                            <SelectComponent
                              inputRefs={inputRefs}
                              index={index}
                              className="bg-primary-100"
                              value={values[field.name] as string}
                              placeholder={field?.placeholder}
                              options={field?.options}
                              name={field?.name}
                              handleChange={(event: SelectChangeEvent) => {
                                if (
                                  field.name === TABLE_COLUMNS.DEALER &&
                                  type === MODAL_TYPE.ADD_VEHICLE &&
                                  ADMIN_ROLES.includes(role as UserTypes)
                                ) {
                                  handleDealerLocations(
                                    event?.target?.value,
                                    fieldsData,
                                    true
                                  );
                                }
                                handleChange(event);
                              }}
                              disabled={
                                field.name === TABLE_COLUMNS.DEALER_LOCATION &&
                                type === MODAL_TYPE.ADD_VEHICLE &&
                                ADMIN_ROLES.includes(role as UserTypes) &&
                                (edit ? false : !values?.dealer)
                                  ? true
                                  : false
                              }
                              errorTextColor="text-primary-500"
                              errors={errors}
                              touched={touched}
                              hideErrorMessage={field?.label ? true : false}
                            />
                          </Grid>
                        </Grid>
                      ) : field.type === CONSTANTS.MULTI_SELECT ? (
                        <Grid item sm={12}>
                          <MultiSelect
                            setFieldValue={setFieldValue}
                            inputRefs={inputRefs}
                            index={index}
                            className="bg-primary-100"
                            value={values[field.name] as string}
                            placeholder={field?.placeholder}
                            options={field?.options}
                            name={field?.name}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  );
                })
              ) : (
                <Loader />
              )}
            </Grid>

            {/* Hided CR Report and Car Faxx Report Work for now, this will be included in future */}
            {/* {type === MODAL_TYPE.ADD_VEHICLE && (
              <Grid sm={12}>
                <Grid container className="mb-3" sm={12}>
                  <Grid sm={5} lg={3} className="m-auto">
                    CarFax
                  </Grid>
                  <Grid
                    sm={7}
                    lg={4}
                    className="flex justify-end lg:justify-center items-center"
                  >
                    <ButtonComponent
                      className="modal-button"
                      size={SIZES.SMALL}
                      color={CONSTANTS.PRIMARY}
                      text="Get Report"
                      onClick={() => {}}
                    />
                  </Grid>
                  <Grid sm={12} lg={5} className="mt-3 lg:mt-0">
                    <FieldComponent
                      className="w-full"
                      fontColor={COLORS.DODGER_BLUE_LIGHT}
                      placeholder="https://samplelinkofconditionrepor..."
                      hideErrorMessage={true}
                      size={SIZES.SMALL}
                      type={CONSTANTS.TEXT}
                      handleChange={() => {}}
                    />
                  </Grid>
                </Grid>
                <Grid container sm={12}>
                  <Grid sm={5} lg={3} className="m-auto">
                    Condition Report
                  </Grid>
                  <Grid
                    sm={7}
                    lg={4}
                    className="flex justify-end lg:justify-center items-center"
                  >
                    <ButtonComponent
                      className="modal-button"
                      size={SIZES.SMALL}
                      color={CONSTANTS.PRIMARY}
                      text="Get Report"
                      onClick={() => {}}
                    />
                  </Grid>
                  <Grid sm={12} lg={5} className="mt-3 lg:mt-0">
                    <FieldComponent
                      className="w-full"
                      fontColor={COLORS.DODGER_BLUE_LIGHT}
                      placeholder="https://samplelinkofconditionrepor..."
                      hideErrorMessage={true}
                      size={SIZES.SMALL}
                      type={CONSTANTS.TEXT}
                      handleChange={() => {}}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )} */}

            <Grid className="mt-3">
              <ButtonComponent
                className="w-full no-margin"
                color={CONSTANTS.PRIMARY}
                text={submitButtonText}
                onClick={() => handleSubmit()}
                disabled={!(isValid && dirty)}
              />
            </Grid>
          </>
        )}
      </Formik>
    </>
  );
};

const VehicleListingModal = () => {
  const { data } = useAppSelector((state) => state.modal);
  return (
    <Box className="w-full text-tertiary-200">
      <Box className="text-4xl font-bold uppercase mb-2">Vehicle Detail</Box>
      <table className="text-left w-full my-5">
        <tr className="border-b">
          <th className="pr-4 py-3">VIN</th>
          <th className="px-4 py-3">Year</th>
          <th className="px-4 py-3">Make</th>
          <th className="px-4 py-3">Model</th>
          <th className="px-4 py-3">Color</th>
        </tr>

        {data?.vehicleData ? (
          <tr className="border-b text-tertiary-100">
            <td className="pr-4 py-2">{data?.vehicleData?.id}</td>
            <td className="px-4 py-2">{data?.vehicleData?.year as string}</td>
            <td className="px-4 py-2">{data?.vehicleData?.make as string}</td>
            <td className="px-4 py-2">{data?.vehicleData?.model as string}</td>
            <td className="px-4 py-2">{data?.vehicleData?.color as string}</td>
          </tr>
        ) : null}
      </table>
    </Box>
  );
};

const VehicleUpdateRequestModal = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const state = useAppSelector((state) => state);
  const { data } = state?.modal;
  const { role } = state?.authorization;
  const vehicle: any = data?.modalValues?.vehicle;
  const updateRequest: any = data?.modalValues?.updateRequest;

  const dispatch = useAppDispatch();
  const successDispatcher = useSuccessDispatcher();
  const [updateVehicleRequest] = useUpdateVehicleRequestMutation();

  const updatedAt = formatDate(
    updateRequest?.updated_at,
    DATE_TYPE.TIMESTAMP,
    DATE_FORMAT.DD_MM_YYYY_TIME
  );

  const vehicleRemarks = vehicle?.remarks?.length
    ? vehicle?.remarks?.map((remark: any) => remark?.label)?.join(", ")
    : "-";
  let updateRequestRemarks = updateRequest?.remarks?.length
    ? updateRequest?.remarks?.map((remark: any) => remark?.label)?.join(", ")
    : "-";

  updateRequestRemarks =
    updateRequestRemarks !== vehicleRemarks ? updateRequestRemarks : "-";

  const updateRequestAcquiredFrom = updateRequest?.acquired_from
    ? updateRequest?.acquired_from !== vehicle?.acquired_from
      ? VehicleAcquiredFrom[updateRequest?.acquired_from as number]
      : "-"
    : "-";

  const updateRequestStockNumber = updateRequest?.dealer_stock_number
    ? vehicle?.dealer_stock_number !== updateRequest?.dealer_stock_number
      ? updateRequest?.dealer_stock_number
      : "-"
    : "-";

  const updateRequestMileage = updateRequest?.mileage
    ? vehicle?.mileage !== updateRequest?.mileage
      ? updateRequest?.mileage
      : "-"
    : "-";

  const updateRequestFloorPrice = updateRequest?.floor_price
    ? vehicle?.floor_price !== updateRequest?.floor_price
      ? updateRequest?.floor_price
      : "-"
    : "-";

  const updateRequestExteriorColor = updateRequest?.exterior_color
    ? vehicle?.exterior_color !== updateRequest?.exterior_color
      ? updateRequest?.exterior_color
      : "-"
    : "-";

  const updateRequestACV = updateRequest?.acv
    ? vehicle?.acv !== updateRequest?.acv
      ? updateRequest?.acv
      : "-"
    : "-";

  const updateRequestAnnouncement = updateRequest?.announcement?.label
    ? updateRequest?.announcement?.label !== vehicle?.announcement?.label
      ? updateRequest?.announcement?.label
      : "-"
    : "-";

  const handleUpdateStatus = async (status: VehicleUpdateRequestStatus) => {
    dispatch(toggleProgressBar(true));
    const response: any = await updateVehicleRequest({
      id: updateRequest?.id,
      payload: {
        status,
      },
    });
    dispatch(toggleProgressBar(false));
    handleClose();
    if (!response?.error)
      successDispatcher(true, MESSAGES.AUCTION.VEHICLE_UPDATE_REQUEST_SUCCESS);
  };

  return (
    <Box className="w-full text-tertiary-200">
      <Box className="text-4xl font-bold uppercase mb-2">
        Vehicle Update Request
        <Box className="text-base font-normal normal-case">
          {"Updated At : " + updatedAt}
        </Box>
      </Box>
      <table className="text-left w-full my-5">
        <tr className="border-b">
          <th className="px-4 py-3">Columns</th>
          <th className="px-4 py-3">Current Value</th>
          <th className="px-4 py-3">Change Request</th>
        </tr>

        <tr className="border-b text-tertiary-100">
          <th className="px-4 py-3">Acquired From</th>
          <th className="px-4 py-3 font-normal">
            {vehicle?.acquired_from
              ? VehicleAcquiredFrom[vehicle?.acquired_from as number]
              : "-"}
          </th>
          <th className="px-4 py-3 font-normal">{updateRequestAcquiredFrom}</th>
        </tr>

        <tr className="border-b text-tertiary-100">
          <th className="px-4 py-3">Dealer Stock Number</th>
          <th className="px-4 py-3 font-normal">
            {vehicle?.dealer_stock_number || "-"}
          </th>
          <th className="px-4 py-3 font-normal">{updateRequestStockNumber}</th>
        </tr>

        <tr className="border-b text-tertiary-100">
          <th className="px-4 py-3">Mileage</th>
          <th className="px-4 py-3 font-normal">{vehicle?.mileage || "-"}</th>
          <th className="px-4 py-3 font-normal">{updateRequestMileage}</th>
        </tr>

        <tr className="border-b text-tertiary-100">
          <th className="px-4 py-3">Floor Price</th>
          <th className="px-4 py-3 font-normal">
            {vehicle?.floor_price || "-"}
          </th>
          <th className="px-4 py-3 font-normal">{updateRequestFloorPrice}</th>
        </tr>

        <tr className="border-b text-tertiary-100">
          <th className="px-4 py-3">ACV</th>
          <th className="px-4 py-3 font-normal">{vehicle?.acv || "-"}</th>
          <th className="px-4 py-3 font-normal">{updateRequestACV}</th>
        </tr>

        <tr className="border-b text-tertiary-100">
          <th className="px-4 py-3">Announcements</th>
          <th className="px-4 py-3 font-normal">
            {vehicle?.announcement?.label || "-"}
          </th>
          <th className="px-4 py-3 font-normal">{updateRequestAnnouncement}</th>
        </tr>

        <tr className="border-b text-tertiary-100">
          <th className="px-4 py-3">Sub Announcements</th>
          <th className="px-4 py-3 font-normal">{vehicleRemarks}</th>
          <th className="px-4 py-3 font-normal">{updateRequestRemarks}</th>
        </tr>

        <tr className="border-b text-tertiary-100">
          <th className="px-4 py-3">Exterior Color</th>
          <th className="px-4 py-3 font-normal">{vehicle?.exterior_color}</th>
          <th className="px-4 py-3 font-normal">
            {updateRequestExteriorColor}
          </th>
        </tr>
      </table>

      {ADMIN_ROLES?.includes(role as UserTypes) && (
        <Box className="flex items-center justify-end">
          <ButtonComponent
            className="w-[120px]"
            color={CONSTANTS.PRIMARY}
            text={"Accept"}
            size={SIZES.SMALL}
            onClick={() =>
              handleUpdateStatus(VEHICLE_UPDATE_REQUEST_STATUS.ACCEPT)
            }
          />
          <ButtonComponent
            className="w-[120px]"
            color={CONSTANTS.PRIMARY}
            text={"Reject"}
            size={SIZES.SMALL}
            onClick={() =>
              handleUpdateStatus(VEHICLE_UPDATE_REQUEST_STATUS.REJECT)
            }
          />
        </Box>
      )}
    </Box>
  );
};

const BulkVehicleUploadModal = ({
  handleClose,
}: BulkVehicleUploadModalProps) => {
  let validationSchema;
  let initialValues: DynamicKeyValuesWithStringArray = {};

  const dispatch = useAppDispatch();
  const errorDispatcher = useErrorDispatcher();

  const state = useAppSelector((state) => state);
  const role = state?.authorization?.role;
  const isDynamicFields =
    ADMIN_ROLES.includes(role as UserTypes) || UserTypes.DealerAdmin === role;

  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [fieldsData, setFieldsData] = useState<ModalFieldProps[]>([]);

  const { data: userData } = useGetUserDataQuery("");
  const [getDealerById] = useLazyGetDealerByIdQuery();
  const [getLocations, { data: locationsData }] = useLazyGetLocationsQuery();
  const [getDealers, { data: dealersData }] = useLazyGetDealersQuery();
  const [createVehicles] = useCreateVehiclesCSVMutation();

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.length) {
      const selectedFile = event?.target?.files?.[0];
      if (selectedFile) {
        // Check the file type using the file name or MIME type
        const fileType = selectedFile.type; // MIME type
        const fileName = selectedFile.name.toLowerCase();
        if (fileType === "text/csv" || fileName.endsWith(".csv")) {
          // File is a CSV
          setFile(selectedFile);
        } else {
          // File is not a CSV
          errorDispatcher(true, MESSAGES.MISC.INVALID_FILE_FORMAT);
          event.target.value = "";
        }
      }
    }
  };

  const popupFileHandler = () => {
    if (inputRef.current) {
      inputRef.current?.click();
    }
  };

  const handleFileReset = () => {
    setFile(null);
    if (inputRef.current) inputRef.current.value = "";
  };

  const handleDownload = () => {
    const link = document?.createElement("a");

    link.href =
      process.env.PUBLIC_URL +
      "/assets/files/Bulk_Vehicle_Import_Template(2.0).xlsx";
    link.download = "Bulk_Vehicle_Import_Template.xlsx";
    document?.body?.appendChild(link);
    link?.click();

    document?.body?.removeChild(link);
  };

  const handleFormSubmit = async (values: DynamicKeyValuesWithStringArray) => {
    dispatch(toggleProgressBar(true));
    const dealer_id =
      +values?.dealer || (+userData?.data?.dealer_user?.dealer_id as number);
    const location_id =
      +values?.dealerLocation ||
      (+userData?.data?.dealer_user?.location_id as number);

    const formData = new FormData();
    formData.append("csv", file as Blob);

    const response: any = await createVehicles({
      dealer_id,
      location_id,
      formData,
    });

    let responseHTML = "";
    response?.data?.data?.forEach((data: any) => {
      responseHTML += renderRequestTransportResponseHTML(
        data?.status,
        data?.message,
        data?.vin,
        true
      );
    });

    if (!response?.error) {
      Swal.fire({
        html: responseHTML,
        width: 600,
        position: "top-end",
        title: "Bulk Vehicle CSV Upload Results",
        confirmButtonText: "Continue",
        buttonsStyling: false,
        customClass: {
          confirmButton:
            "bg-secondary-200 text-tertiary-200 py-2 px-4 rounded-md",
          title: "text-tertiary-200",
        },
      });
    }
    dispatch(toggleProgressBar(false));
    handleClose();
  };

  const handleDealerLocations = async (
    dealerId: string,
    fieldsData: ModalFieldProps[],
    showProgressBar: boolean
  ) => {
    if (showProgressBar) dispatch(toggleProgressBar(true));
    const dealerResponse = await getDealerById(dealerId, true);
    const locations = dealerResponse?.data?.data?.locations || [];

    const locationOptions = locations?.map((location: any) => {
      return {
        label: location.name,
        value: location.id,
      };
    });

    const modifiedFieldsData = fieldsData?.map((field: any) => {
      if (field?.name === TABLE_COLUMNS.DEALER_LOCATION) {
        return {
          ...field,
          options: locationOptions,
        };
      }
      return field;
    });
    setFieldsData(modifiedFieldsData);

    if (showProgressBar) dispatch(toggleProgressBar(false));
  };

  const parseData = (
    data: ModalFieldProps[],
    locationsData: Array<ModalSelectOption>,
    dealersData?: Array<ModalSelectOption>
  ) =>
    data?.map((field) => {
      if (
        field.name === MODAL_COMPONENT_FIELD_NAMES.LOCATION &&
        locationsData
      ) {
        const locations = locationsData?.map((location) => {
          return {
            label: location.name,
            value: location.id,
          };
        });
        return {
          ...field,
          options: locations,
        };
      } else if (
        (field.name === MODAL_COMPONENT_FIELD_NAMES.DEALERSHIP_NAME ||
          field.name === MODAL_COMPONENT_FIELD_NAMES.DEALER) &&
        dealersData
      ) {
        const dealers = dealersData?.map((dealer) => {
          return {
            label: dealer.name,
            value: dealer.id,
          };
        });
        return {
          ...field,
          options: dealers,
        };
      }

      return field;
    });

  if (ADMIN_ROLES.includes(role as UserTypes)) {
    validationSchema = bulkCsvSpectrumAdminSchema;
  } else if (UserTypes.DealerAdmin === role) {
    validationSchema = bulkCsvDealerAdminSchema;
  }

  useEffect(() => {
    const queryParamObj = {
      isActive: "active",
    };
    const apiParams = appendQueryParams(queryParamObj, false);

    if (ADMIN_ROLES.includes(role as UserTypes)) {
      getLocations(apiParams);
      getDealers(apiParams);
    } else if (UserTypes.DealerAdmin === role) {
      getLocations(apiParams);
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (isDynamicFields) {
      const data = ADMIN_ROLES.includes(role as UserTypes)
        ? spectrumAdminField
        : UserTypes.DealerAdmin === role
        ? dealerAdminField
        : [];

      const modifiedFields = parseData(
        data,
        locationsData?.data?.locations,
        dealersData?.data?.dealers
      );
      if (role === UserTypes.DealerAdmin) {
        handleDealerLocations(
          userData?.data?.dealer_user?.dealer_id,
          modifiedFields,
          false
        );
      } else {
        setFieldsData(modifiedFields);
      }
    }
  }, [locationsData, dealersData]); //eslint-disable-line

  return (
    <Box className="w-full text-tertiary-200">
      <Formik
        initialValues={initialValues}
        onSubmit={async (
          values: DynamicKeyValuesWithStringArray,
          { setSubmitting }
        ) => {
          handleFormSubmit(values);
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isValid,
          dirty,
          /* and other goodies */
        }) => (
          <>
            <Box className="text-4xl font-bold uppercase mb-2">
              {MODAL_TITLE.IMPORT_CSV}
            </Box>
            <Box>
              Download the template for your reference and export CSV following
              the below constraints
            </Box>
            <Box>
              <ul className="text-xs m-4">
                <li className="list-disc">
                  Use information sheet for data types and limits.
                </li>
                <li className="list-disc">
                  Do not update any heading in template sheet
                </li>
                <li className="list-disc">
                  Remove dummy record in template sheet and fill out your values
                </li>
                <li className="list-disc">
                  Make sure the data is in correct column and format
                </li>
                <li className="list-disc">
                  For multiple Sub Announcements use pipe "|" as separator
                </li>

                <li className="list-disc">
                  Export "Template" sheet as CSV and upload on spectrum
                </li>
              </ul>
            </Box>
            <Box className="pt-10 flex items-center">
              <Box className="relative">
                {file && (
                  <Box className="flex items-center justify-between w-full absolute top-[-20px] left-0 max-w-[260px] text-sm font-semibold mx-1">
                    <Box className="flex">
                      <DescriptionIcon
                        className="text-black cursor-pointer mr-1"
                        fontSize={SIZES.SMALL}
                      />
                      <Box className="max-w-[200px] whitespace-nowrap overflow-hidden text-ellipsis">
                        {file.name}
                      </Box>
                    </Box>
                    <CloseIcon
                      className="text-black cursor-pointer"
                      fontSize={SIZES.SMALL}
                      onClick={handleFileReset}
                    />
                  </Box>
                )}
                <input
                  type="file"
                  ref={inputRef}
                  accept=".csv"
                  hidden
                  onChange={handleFileUpload}
                />
                <ButtonComponent
                  className="margin-4 button-container w-[260px]"
                  text={BUTTON_LABEL.ATTACH_CSV}
                  color={CONSTANTS.TERTIARY}
                  size={SIZES.LARGE}
                  onClick={popupFileHandler}
                  disabled={file ? true : false}
                />
              </Box>

              <ButtonComponent
                className="margin-4 button-container w-[260px]"
                text={BUTTON_LABEL.DOWNLOAD_TEMPLATE}
                color={CONSTANTS.TERTIARY}
                size={SIZES.LARGE}
                onClick={handleDownload}
              />
            </Box>

            {isDynamicFields ? (
              <>
                <hr className="my-4" />
                {/* Location and Dealer Fields */}
                <Grid
                  container
                  className="flex items-center justify-between my-3"
                  marginTop={2}
                  columnSpacing={2}
                >
                  {fieldsData?.length ? (
                    fieldsData?.map((field, index) => {
                      return (
                        <Grid item sm={12} md={field?.col}>
                          <SelectComponent
                            hideErrorMessage={true}
                            index={index}
                            className="bg-primary-100 my-2"
                            value={values[field.name] as string}
                            placeholder={field?.placeholder}
                            options={field?.options}
                            name={field?.name}
                            handleChange={(event: SelectChangeEvent) => {
                              if (
                                field.name === TABLE_COLUMNS.DEALER &&
                                ADMIN_ROLES.includes(role as UserTypes)
                              ) {
                                handleDealerLocations(
                                  event?.target?.value,
                                  fieldsData,
                                  true
                                );
                              }
                              handleChange(event);
                            }}
                            disabled={
                              field.name === TABLE_COLUMNS.DEALER_LOCATION &&
                              ADMIN_ROLES.includes(role as UserTypes) &&
                              !values?.dealer
                                ? true
                                : false
                            }
                            errorTextColor="text-primary-500"
                            errors={errors}
                            touched={touched}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <Loader />
                  )}
                </Grid>
              </>
            ) : null}

            <hr className="my-4" />
            <Box className="flex items-center justify-between">
              <ButtonComponent
                className="button-container h-[40px] w-[130px]"
                text={BUTTON_LABEL.CANCEL}
                color={CONSTANTS.PRIMARY}
                size={SIZES.LARGE}
                onClick={handleClose}
              />
              <ButtonComponent
                className="button-container h-[40px] w-[130px]"
                text={BUTTON_LABEL.UPLOAD}
                disabled={
                  isDynamicFields ? !(isValid && dirty) || !file : !file
                }
                color={
                  file && (isDynamicFields ? isValid && dirty : true)
                    ? CONSTANTS.PRIMARY
                    : CONSTANTS.SECONDARY
                }
                size={SIZES.LARGE}
                onClick={() => handleSubmit()}
              />
            </Box>
          </>
        )}
      </Formik>
    </Box>
  );
};

const ModalComponent = ({
  open,
  type,
  submitButtonText,
  handleClose,
  edit = false,
}: ModalComponentProps) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          className: "glassmorphism background-white no-border-radius",
        },
      }}
      sx={{
        zIndex: 1200,
      }}
    >
      <Box
        className={`custom-modal bg-primary-100 p-8 ${
          type !== MODAL_TYPE.VEHICLE ? "max-w-[600px]" : ""
        } min-w-[450px] max-h-[75vh] overflow-y-auto shadow-xl rounded-sm absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
      >
        {type === MODAL_TYPE.VEHICLE ? (
          <VINModal handleClose={handleClose} />
        ) : type === MODAL_TYPE.VEHICLE_LISTING ? (
          <VehicleListingModal />
        ) : type === MODAL_TYPE.VEHICLE_UPDATE_REQUEST ? (
          <VehicleUpdateRequestModal handleClose={handleClose} />
        ) : type === MODAL_TYPE.IMPORT_CSV ? (
          <BulkVehicleUploadModal handleClose={handleClose} />
        ) : (
          <UserModal
            edit={edit}
            type={type}
            handleClose={handleClose}
            submitButtonText={submitButtonText}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ModalComponent;
