import { API_ENDPOINT } from "../../misc/enum";
import { GetArbitrationChatPayload } from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const arbitrationChatSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getArbitrationChatById: builder.query({
      query: ({ id, params }: GetArbitrationChatPayload) => ({
        url: `${API_ENDPOINT.ARBITRATION_CHAT}/${id}${
          params ? `?${params}` : ""
        }`,
        method: "GET",
      }),
      providesTags: ["ArbitrationChat"],
    }),
    markReadArbitrationChat: builder.mutation({
      query: (id: string) => ({
        url: `${API_ENDPOINT.ARBITRATION_CHAT}/mark-as-read/${id}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLazyGetArbitrationChatByIdQuery,
  useMarkReadArbitrationChatMutation,
} = arbitrationChatSlice;
