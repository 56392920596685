import { API_ENDPOINT } from "../../misc/enum";
import {
  CreateDealerUserPayload,
  UpdateDealerUserPayload,
  DeleteDealerUserPayload,
} from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const dealerUserSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getDealerUsers: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.USER}?${params}`,
      }),
      providesTags: ["DealerUser"],
    }),
    createDealerUser: builder.mutation({
      query: (payload: CreateDealerUserPayload) => ({
        method: "POST",
        url: `${API_ENDPOINT.DEALER_USER}`,
        body: payload,
      }),
      invalidatesTags: ["DealerUser"],
    }),
    updateDealerUser: builder.mutation({
      query: ({id, payload}: UpdateDealerUserPayload) => ({
        url: `${API_ENDPOINT.DEALER_USER}/${id}`,
        method: "PUT",
        body: {
          ...payload,
        },
      }),
      invalidatesTags: ["DealerUser", "Dealer"],
    }),
    deleteDealerUser: builder.mutation({
      query: ({ id }: DeleteDealerUserPayload) => ({
        url: `${API_ENDPOINT.DEALER_USER}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetDealerUsersQuery,
  useCreateDealerUserMutation,
  useUpdateDealerUserMutation,
  useDeleteDealerUserMutation,
} = dealerUserSlice;
