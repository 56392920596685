import { API_ENDPOINT } from "../../misc/enum";
import {} from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const dashboardSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getGeneralStats: builder.query({
      query: () => ({
        url: `${API_ENDPOINT.DASHBOARD}/general`,
      }),
    }),
    getTransportsStats: builder.query({
      query: () => ({
        url: `${API_ENDPOINT.DASHBOARD}/transport`,
      }),
    }),
    getWeeklySoldStats: builder.query({
      query: () => ({
        url: `${API_ENDPOINT.DASHBOARD}/weekly-vehicle-sold`,
      }),
    }),
    getAllSoldStats: builder.query({
      query: () => ({
        url: `${API_ENDPOINT.DASHBOARD}/dealer-sold-vehicle`,
      }),
    }),
  }),
});

export const {
  useGetGeneralStatsQuery,
  useGetTransportsStatsQuery,
  useLazyGetWeeklySoldStatsQuery,
  useLazyGetAllSoldStatsQuery,
} = dashboardSlice;
