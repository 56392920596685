import { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../hooks/redux-toolkit";
import ButtonContainer from "../components/ButtonContainer";
import FilterAccordion from "../components/FilterAccordion";
import ListingTableComponent from "../components/ListingTableComponent";
import { vehicleInventoryCreateData, vehicleStatus } from "../misc/createData";
import {
  ACTION_BUTTON_TYPES,
  CONSTANTS,
  DATE_FORMAT,
  DATE_TYPE,
  MODAL_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
  SIZES,
  VehicleSortFilter,
} from "../misc/constants";
import { toggleModal } from "../store/reducer/modal";

import {
  useLazyGetVehiclesQuery,
  useRequestCRMutation,
  useUpdateVehicleMutation,
} from "../store/reducer/vehicle";

import { RowSelectionType } from "../ts/component.types";
import {
  VehicleStatus,
  ConditionReportStatus,
  TransportRequestStatus,
  OrderByDirection,
  VehicleOrderColumn,
} from "../misc/enum";
import {
  calculateDays,
  checkBoolean,
  formatDate,
  getEditModalValues,
  getRowData,
  numericValueOrNull,
  prioritizeAndMergeObject,
} from "../misc/utils";
import { toggleProgressBar } from "../store/reducer/progressBar";
import { MESSAGES } from "../misc/messages";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { appendQueryParams } from "../misc/api.utils";
import { useReactToPrint } from "react-to-print";
import { Actions, Tabs } from "../misc/access.enum";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { useSuccessDispatcher } from "../hooks/useSuccessDispatcher";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";
import { isInteger } from "formik";

const VehiclesInventory = () => {
  const dispatch = useAppDispatch();
  const successDispatcher = useSuccessDispatcher();
  const errorDispatcher = useErrorDispatcher();
  const reference = useRef<HTMLTableSectionElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.INVENTORY,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const { permissions } = useAppSelector((state) => state.authorization);
  const [vehicleRows, setVehicleRows] = useState<[] | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState<DynamicKeyValuesWithNumArray>({
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    make: "",
    model: "",
    location: NUMBER.MINUS_ONE,
    announcement: NUMBER.MINUS_ONE,
    dealerName: NUMBER.MINUS_ONE,
    startAge: "",
    endAge: "",
    startYear: "",
    endYear: "",
    vin: "",
    sort: VehicleSortFilter.updateAtDescending,
  });

  const inputRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);

  const [selectedRows, setSelectedRows] = useState<
    Array<RowSelectionType> | []
  >([]);

  const [getVehicles, { data: vehicleStateData }] = useLazyGetVehiclesQuery();
  const [updateVehicle] = useUpdateVehicleMutation();
  const [requestCR] = useRequestCRMutation();

  const handleExport = () => {
    const vehiclesData = vehicleStateData?.data?.vehicles;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.InventoryTab,
      vehiclesData
    );

    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Vehicle Inventory (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const validSelections = () => {
    let hasSelections = true;
    let hasValidTypes = true;

    if (!selectedRows?.length) hasSelections = false;

    const selectedVehicles: {
      vehicle_ids: Array<number>;
      lane: Array<number>;
      run: Array<number>;
    } = {
      vehicle_ids: [],
      run: [],
      lane: [],
    };

    selectedRows?.forEach((row) => {
      if (row?.run && row?.lane) {
        if (isInteger(row?.run) && isInteger(row?.lane)) {
          selectedVehicles.vehicle_ids.push(Number(row?.id));
          selectedVehicles.run.push(Number(row?.run));
          selectedVehicles.lane.push(Number(row?.lane));
        } else {
          hasValidTypes = false;
        }
      } else {
        hasSelections = false;
      }
    });

    return {
      hasSelections,
      hasValidTypes,
      selectedVehicles,
    };
  };

  const getSelectedVehicleIds = () => {
    if (!selectedRows?.length) return false;

    const vehicleIds: Array<number> = [];

    selectedRows?.forEach((row) => {
      vehicleIds.push(Number(row?.id));
    });

    return vehicleIds;
  };

  const getSelectedConditionReportIds = () => {
    if (!selectedRows?.length) return false;

    const conditionReportIds: Array<number> = [];

    selectedRows?.forEach((row) => {
      conditionReportIds.push(Number(row?.conditionReportId));
    });

    return conditionReportIds;
  };

  const handleClick = async (type: string) => {
    let selections;
    let message = "";
    let buttonText = "Submit";
    let edit = false;

    switch (type) {
      case MODAL_TYPE.ADD_VEHICLE:
        selections = true;
        buttonText = "Save and Close";
        break;
      case MODAL_TYPE.ASSIGN_AUCTION:
        edit = true;
        const { selectedVehicles, hasValidTypes, hasSelections } =
          validSelections();
        if (!hasSelections) message = MESSAGES.VEHICLE.ENTER_LANE_AND_RUN;
        if (!hasValidTypes) message = MESSAGES.VEHICLE.INVALID_LANE_RUN_TYPES;
        if (hasSelections && hasValidTypes) selections = selectedVehicles;
        break;
      case MODAL_TYPE.REQUEST_TRANSPORT:
        selections = getSelectedVehicleIds();
        if (!selections) message = MESSAGES.VEHICLE.SELECT_FOR_TR;
        break;
      case MODAL_TYPE.REQUEST_CR:
        selections = getSelectedConditionReportIds();
        if (!selections) message = MESSAGES.VEHICLE.SELECT_FOR_CR;
        break;
      case MODAL_TYPE.IMPORT_CSV:
        selections = true;
        break;
      default:
        break;
    }

    if (!selections) {
      errorDispatcher(true, message);
      return;
    }

    if (type !== MODAL_TYPE.REQUEST_CR) {
      dispatch(
        toggleModal({
          open: true,
          edit: edit,
          modalType: type,
          submitText: buttonText,
          data: {
            selectedVehicles: selections,
          },
        })
      );
    } else {
      const response: any = await requestCR({
        condition_report_ids: selections as Array<number>,
      });
      if (response?.data?.message === "Success") {
        successDispatcher(true, MESSAGES.VEHICLE.CR_REQUEST_SUCCESS);
        setSelectedRows([]);
      }
    }
  };

  const buttons = [
    {
      label: "Add Vehicle",
      color: CONSTANTS.PRIMARY,
      onClick: () => handleClick(MODAL_TYPE.ADD_VEHICLE),
      size: SIZES.SMALL,
      access: checkBoolean(permissions?.[Actions.AddVehicle]),
    },
    {
      label: "Import CSV",
      color: CONSTANTS.SECONDARY,
      onClick: () => handleClick(MODAL_TYPE.IMPORT_CSV),
      size: SIZES.SMALL,
      access: checkBoolean(permissions?.[Actions.AddVehicle]),
    },
    {
      label: "Request for Transport",
      color: CONSTANTS.SECONDARY,
      onClick: () => handleClick(MODAL_TYPE.REQUEST_TRANSPORT),
      size: SIZES.SMALL,
      access: checkBoolean(permissions?.[Actions.AddTransportRequest]),
    },
    //Hided CR Report and Car Faxx Report Work for now, this will be included in future
    // {
    //   label: "Request for CR",
    //   color: CONSTANTS.SECONDARY,
    //   onClick: () => handleClick(MODAL_TYPE.REQUEST_CR),
    //   size: SIZES.SMALL,
    //   access: checkBoolean(permissions?.condition_report_request),
    // },
    {
      label: "Assign Auction",
      color: CONSTANTS.SECONDARY,
      onClick: () => handleClick(MODAL_TYPE.ASSIGN_AUCTION),
      size: SIZES.SMALL,
      access: checkBoolean(permissions?.[Actions.AssignAuction]),
    },
  ];

  const handleActionButton = async (type: string, id: string) => {
    let response: any;
    let message: string = "";
    dispatch(toggleProgressBar(true));
    switch (type) {
      case ACTION_BUTTON_TYPES.EDIT_VEHICLE:
        let selected_vehicle = getRowData(
          +id,
          vehicleStateData?.data?.vehicles
        );

        dispatch(
          toggleModal({
            open: true,
            edit: true,
            submitText: "Save and Close",
            modalType: MODAL_TYPE.ADD_VEHICLE,
            data: {
              modalValues: getEditModalValues(
                MODAL_TYPE.ADD_VEHICLE,
                selected_vehicle
              ),
            },
          })
        );
        break;
      case ACTION_BUTTON_TYPES.REMOVE_VEHICLE:
        response = await updateVehicle({
          id,
          payload: {
            status: VehicleStatus.Removed,
          },
        });
        message = MESSAGES.VEHICLE.VEHICLE_REMOVED_SUCCESS;
        break;
      default:
        break;
    }
    dispatch(toggleProgressBar(false));
    if (!response?.error) successDispatcher(true, message);
  };

  const mapDataToTableRows = (vehiclesData: any) => {
    const pendingStatus = [
      TransportRequestStatus.Picked,
      TransportRequestStatus.Dropped,
    ];
    const droppedStatus = [TransportRequestStatus.Dropped];
    return vehiclesData.map((vehicleData: any) => {
      const age = calculateDays(vehicleData?.created_at);
      const remarks = vehicleData?.remarks?.length
        ? vehicleData?.remarks?.map((remark: any) => remark?.label)?.join(", ")
        : "N/A";

      return vehicleInventoryCreateData(
        vehicleData?.id,
        vehicleData?.condition_report?.id,
        vehicleData?.vin,
        vehicleData?.make,
        vehicleData?.model,
        vehicleData?.year,
        vehicleData?.dealer?.name,
        vehicleData?.location?.name,
        `${age?.toString()} ${age === 1 ? "Day" : "Days"}`,
        vehicleData?.lane || "--",
        vehicleData?.run || "--",
        vehicleData?.announcement?.label,
        remarks,
        vehicleData?.auction_count,
        numericValueOrNull(vehicleData?.acv),
        numericValueOrNull(vehicleData?.dealer_stock_number),
        numericValueOrNull(vehicleData?.floor_price),
        vehicleStatus[`${vehicleData?.status}`],
        vehicleData?.comment || "N/A",
        vehicleData?.condition_report?.rating,
        vehicleData?.condition_report?.status !==
          ConditionReportStatus.Requested &&
          vehicleData?.condition_report?.report_url &&
          vehicleData?.condition_report?.rating,
        pendingStatus?.includes(
          vehicleData?.transport_request?.[NUMBER.ZERO]?.status
        ),
        droppedStatus?.includes(
          vehicleData?.transport_request?.[NUMBER.ZERO]?.status
        ),
        vehicleData?.auction_count > NUMBER.ZERO,
        checkBoolean(permissions?.[Actions.AssignAuction]) &&
          vehicleData?.auction_count === NUMBER.ZERO,
        checkBoolean(permissions?.[Actions.UpdateVehicle]),
        checkBoolean(permissions?.remove_from_lot),
        checkBoolean(permissions?.[Tabs.VINModal])
      );
    });
  };

  async function fetchData(queryParams: DynamicKeyValuesWithNumArray) {
    let apiParams = ``;

    const queryParamObj: DynamicKeyValuesWithNumArray = {
      status: VehicleStatus.Inventory,
      page: (queryParams.page as number) + 1,
      limit: queryParams.rowsPerPage as number,
      make: queryParams?.make as string,
      model: queryParams?.model as string,
      startYear: queryParams?.startYear as string,
      endYear: queryParams?.endYear as string,
      startAge: queryParams?.startAge as string,
      endAge: queryParams?.endAge as string,
      vin: queryParams?.vin as string,
    };

    switch (queryParams?.sort) {
      case VehicleSortFilter.ageAscending:
        queryParamObj["direction"] = OrderByDirection.ASC;
        queryParamObj["column"] = VehicleOrderColumn.CreatedAt;
        break;
      case VehicleSortFilter.ageDescending:
        queryParamObj["direction"] = OrderByDirection.DESC;
        queryParamObj["column"] = VehicleOrderColumn.CreatedAt;
        break;
      case VehicleSortFilter.updatedAtAscending:
        queryParamObj["direction"] = OrderByDirection.ASC;
        queryParamObj["column"] = VehicleOrderColumn.UpdatedAt;
        break;
      case VehicleSortFilter.updateAtDescending:
        queryParamObj["direction"] = OrderByDirection.DESC;
        queryParamObj["column"] = VehicleOrderColumn.UpdatedAt;
        break;
      default:
        break;
    }

    const location = queryParams.location;
    const announcement = queryParams.announcement;
    const dealerName = queryParams.dealerName;

    if (location && Number(location) >= NUMBER.ZERO)
      queryParamObj["location"] = location;
    if (announcement && Number(announcement) >= NUMBER.ZERO)
      queryParamObj["announcementId"] = announcement;
    if (dealerName && Number(dealerName) >= NUMBER.ZERO)
      queryParamObj["dealerId"] = dealerName;

    apiParams += appendQueryParams(queryParamObj, false);

    try {
      const response: any = await getVehicles(apiParams);

      const data = response?.data?.data;
      const vehicles = data?.vehicles;
      const count = data?.count;

      if (!vehicles) {
        setVehicleRows([]);
        return;
      }

      const vehiclesData = mapDataToTableRows(vehicles);

      setVehicleRows(vehiclesData);

      setTotalRowsCount(count);
      setSelectedRows([]);
    } catch (error) {}
  }

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); //eslint-disable-line

  useEffect(() => {
    if (vehicleStateData?.data?.vehicles) {
      let vehiclesData = vehicleStateData?.data?.vehicles;
      vehiclesData = mapDataToTableRows(vehiclesData);
      setVehicleRows(vehiclesData);
      setTotalRowsCount(vehicleStateData?.data?.count);
    }
    setSelectedRows([]);
  }, [vehicleStateData]); //eslint-disable-line

  const applyFilter = (values: DynamicKeyValuesWithNumArray) => {
    setVehicleRows(null);
    const prioritizedQueryParams = prioritizeAndMergeObject(
      values,
      queryParams,
      [
        "page",
        "rowsPerPage",
        "make",
        "model",
        "vin",
        "location",
        "announcement",
        "dealerName",
        "startAge",
        "endAge",
        "startYear",
        "endYear",
        "sort",
      ]
    );

    setQueryParams({
      ...queryParams,
      ...prioritizedQueryParams,
    });
  };

  const triggerFilter = (values: DynamicKeyValuesWithNumArray) => {
    applyFilter({
      ...values,
      vin: (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement)?.value,
      page: NUMBER.ZERO,
    });
  };

  const emptyVin = () => {
    if (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement) {
      (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement).value = "";
    }
  };

  return (
    <Box>
      <ButtonContainer
        buttons={buttons}
        inputRefs={inputRefs}
        hideRightSearchField={false}
        handlePrint={handlePrint}
        handleExport={handleExport}
        handleVinSearch={() => {
          triggerFilter({
            ...queryParams,
            vin: (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement)?.value,
          });
        }}
        disableButtons={vehicleRows ? vehicleRows?.length === 0 : true}
      />
      <FilterAccordion
        status={"In Inventory"}
        triggerFilter={triggerFilter}
        emptyVin={emptyVin}
        sort={queryParams.sort as string}
      />
      <ListingTableComponent
        reference={reference}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        rows={vehicleRows}
        rowCount={totalRowsCount}
        showCheckbox={true}
        applyFilter={applyFilter}
        queryParams={queryParams}
        handleActionButton={handleActionButton}
      />
    </Box>
  );
};

export default VehiclesInventory;
