import { useReactToPrint } from "react-to-print";
import ButtonComponent from "../components/ButtonComponent";
import { CONSTANTS, PDF_PAGE_STYLE, PDF_TITLE, SIZES } from "../misc/constants";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetKBBReportQuery,
  useUpdateKBBReportMutation,
} from "../store/reducer/vehicle";
import Loader from "../components/Loader";
import ErrorPage from "./ErrorPage";

const KbbReport = () => {
  const params = useParams();
  const {
    data,
    isLoading: isGetKBBCIP,
    isError: isGetKBBError,
  } = useGetKBBReportQuery({
    id: params?.vehicleId || "",
  });
  const [
    updateKbbValues,
    { isLoading: isUpdateKBBCIP, isError: isUpdateKBBError },
  ] = useUpdateKBBReportMutation();

  const kbbData = data?.data?.kbb_report;
  const vehicleData = data?.data;
  const vehicleColor =
    vehicleData?.exterior_color.slice(0, 1).toUpperCase() +
    vehicleData?.exterior_color.slice(1);

  const equipmentsArray: any[] = [];
  const vehicleOptionsArray = kbbData?.options?.vehicleOptions.filter(
    (val: any) => {
      if (val.isTypical) {
        if (val.optionType === "Equipment") {
          equipmentsArray.push(val);
          return false;
        }
        return true;
      }
      return false;
    }
  );

  const vehicleInfo =
    vehicleData?.year?.toString() +
    " " +
    kbbData?.options?.makeName +
    " " +
    kbbData?.options?.modelPlusTrimName;
    
    const lendingValue =
    kbbData?.values?.prices?.[0]?.configuredValue?.toFixed(2);
    const adjustedLendingValue = lendingValue - kbbData?.values?.prices?.[0]?.mileageAdjustment;

    const retailValue = kbbData?.values?.prices?.[1]?.configuredValue?.toFixed(2);
    const adjustedRetailValue = retailValue - kbbData?.values?.prices?.[1]?.mileageAdjustment;

    const mileageAdjustment = `$ ${kbbData?.values?.prices?.[0]?.mileageAdjustment}`
    
    const kbbReportUpdatedAt = new Date(+kbbData?.updated_at * 1000);
    const printedAt = kbbReportUpdatedAt.toLocaleDateString('en-gb')

  const promiseResolveRef = useRef<any>(null);
  const reference = useRef<HTMLDivElement>(null);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.KBB_REPORT,
    pageStyle: PDF_PAGE_STYLE.PORTRAIT,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  const handleUpdateReport = () => {
    updateKbbValues({ id: params?.vehicleId });
  };

  if (isUpdateKBBError || isGetKBBError) {
    return <ErrorPage />;
  }

  return (
    <div className="background-white w-full">
      {isGetKBBCIP || isUpdateKBBCIP ? (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      ) : (
        <div
          className="max-w-6xl bg-[#f6eee3] w-full h-full mx-auto p-8"
          ref={reference}
        >
          <div className="flex justify-between items-start">
            <div>
              <div className="text-xl font-semibold">Spectrum Financial</div>
              <div>Lending/Suggested Retail Breakdown</div>
            </div>
            <div className="text-sm">
              Printed on: {printedAt || "--"}
            </div>
          </div>
          <div className="flex justify-between items-start mt-8 mb-2">
            <div>
              <div className="font-bold">VIN: {vehicleData?.vin || "--"}</div>
              <div className="text-sm">
                License Plate #: {kbbData?.license || "--"}
              </div>
            </div>
            <div className="text-sm font-bold">
              Stock #: {vehicleData?.stock_number || "--"}
            </div>
          </div>
          <div className="flex justify-between border-t-4 border-b-2 py-2 border-tertiary-300 font-semibold">
            <div>Vehicle Info</div>
            <div>Lending/Retail</div>
          </div>
          <div className="text-sm">
            <div className="flex justify-between my-1">
              <div>
                {vehicleInfo}
              </div>
              <div>
                $
                {' ' + adjustedLendingValue} / $
                {' ' + adjustedRetailValue}
              </div>
            </div>
            {equipmentsArray.length > 0 &&
              equipmentsArray.map((data: any) => (
                <div className="flex justify-between my-1">
                  <div>{data.optionName}</div>
                  <div>Included/Included</div>
                </div>
              ))}
            <div className="flex justify-between my-1">
              <div>{vehicleColor}</div>
              <div>Included/Included</div>
            </div>
          </div>
          <div className="flex justify-between border-t-2 border-b-2 py-2 border-tertiary-300 font-semibold">
            <div className="w-1/2 flex justify-between mr-1">
              <div>Equipment</div>
              <div>Option Value</div>
            </div>
            <div className="w-1/2 flex justify-between ml-1">
              <div>Equipment</div>
              <div>Option Value</div>
            </div>
          </div>
          <div className="flex justify-between text-sm">
            <div className="w-1/2 mr-1">
              {vehicleOptionsArray
                .slice(0, Math.floor(vehicleOptionsArray.length / 2))
                ?.map((option: any) => {
                  return (
                    <div
                      className="flex justify-between my-1"
                      key={option?.vehicleOptionId}
                    >
                      <div>{option.optionName}</div>
                      <div>Included/Included</div>
                    </div>
                  );
                })}
            </div>
            <div className="w-1/2 ml-1">
              {vehicleOptionsArray
                .slice(Math.floor(vehicleOptionsArray.length / 2))
                ?.map((option: any) => {
                  return (
                    <div
                      className="flex justify-between my-1"
                      key={option?.vehicleOptionId}
                    >
                      <div>{option.optionName}</div>
                      <div>Included/Included</div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className=" border-t-2 border-b-2 py-2 border-tertiary-300">
            <div className="flex justify-between my-1">
              <div>Lending/Retail Value</div>
              <div>
                $
                {' ' + adjustedLendingValue} / $
                {' ' + adjustedRetailValue}
              </div>
            </div>
            <div className="flex justify-between my-1">
              <div>Mileage Adjustments ({vehicleData?.mileage || "--"})</div>
              <div>
                {mileageAdjustment}
              </div>
            </div>
          </div>
          <div className="flex justify-between border-b-4 py-2 border-tertiary-300 font-semibold">
            <div>Kelley Blue Book Lending/Retail Value</div>
            <div>
              ${' ' + lendingValue} / $
              {' ' + retailValue}
            </div>
          </div>
          {!isPrinting && (
            <div className="flex justify-between my-4">
              <ButtonComponent
                onClick={handleUpdateReport}
                type={CONSTANTS.RESTART}
                text="Update Report"
                color={CONSTANTS.TERTIARY}
                size={SIZES.SMALL}
              />
              <ButtonComponent
                onClick={handlePrint}
                type={CONSTANTS.PRINT}
                text="Print Report"
                color={CONSTANTS.TERTIARY}
                size={SIZES.SMALL}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default KbbReport;
