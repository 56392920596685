import { API_ENDPOINT } from "../../misc/enum";
import {
  CreateAnnouncementPayload,
  CreateRemarkPayload,
  UpdateAnnouncementPayload,
  UpdateRemarkPayload,
} from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const announcementSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncements: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.ANNOUNCEMENTS}?${params}`,
      }),
      providesTags: ["Announcement"],
    }),
    getRemarks: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.REMARKS}?${params}`,
      }),
      providesTags: ["Remark"],
    }),
    createAnnouncement: builder.mutation({
      query: (payload: CreateAnnouncementPayload) => ({
        url: `${API_ENDPOINT.ANNOUNCEMENT}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Announcement"],
    }),
    createRemark: builder.mutation({
      query: (payload: CreateRemarkPayload) => ({
        url: `${API_ENDPOINT.REMARK}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Remark"],
    }),
    updateAnnouncement: builder.mutation({
      query: ({ id, payload }: UpdateAnnouncementPayload) => ({
        url: `${API_ENDPOINT.ANNOUNCEMENT}/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Announcement"],
    }),
    updateRemark: builder.mutation({
      query: ({ id, payload }: UpdateRemarkPayload) => ({
        url: `${API_ENDPOINT.REMARK}/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Remark"],
    }),
  }),
});

export const {
  useLazyGetAnnouncementsQuery,
  useCreateAnnouncementMutation,
  useLazyGetRemarksQuery,
  useCreateRemarkMutation,
  useUpdateAnnouncementMutation,
  useUpdateRemarkMutation,
} = announcementSlice;
