import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { FRONTEND_ROUTES } from "../misc/enum";

const ErrorPage = () => {
  return (
    <Box className="w-full h-screen flex flex-col justify-center items-center">
      <Link to={FRONTEND_ROUTES.DASHBOARD}>
        <Box className="bg-spectrumLogo bg-no-repeat bg-contain w-[300px] h-[200px] mb-4"></Box>
      </Link>
      <Box className="font-bold text-6xl my-4">Ooops!</Box>
      <Box className="font-medium text-xl">Something went wrong</Box>
    </Box>
  );
};

export default ErrorPage;
