import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { FRONTEND_ROUTES } from "../misc/enum";

type Props = {};

const PageNotFound = (props: Props) => {
  return (
    <Box className="w-full h-screen flex flex-col justify-center items-center">
      <Link to={FRONTEND_ROUTES.DASHBOARD}>
        <Box className="bg-spectrumLogo bg-no-repeat bg-contain w-[200px] h-[200px] mb-10"></Box>
      </Link>
      <Box className="font-bold text-6xl my-4">Error 404!</Box>
      <Box className="font-medium text-xl">Page Not Found</Box>
    </Box>
  );
};

export default PageNotFound;
