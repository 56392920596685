import { useState, useEffect } from "react";
import { Formik } from "formik";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import FieldComponent from "../components/FieldComponent";

import "./../styles/Login.css";
import Loader from "../components/Loader";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks/redux-toolkit";
import { updatePassword } from "../store/services/auth";
import { FRONTEND_ROUTES } from "../misc/enum";
import { updatePasswordSchema } from "../components/ModalComponent/schema";

type Props = {};

const ChangePassword = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [apiToken, setApiToken] = useState<string>("");

  useEffect(() => {
    const token = searchParams?.get("token");
    if (!apiToken) {
      if (token) {
        setApiToken(token);
        searchParams.delete("token");
        setSearchParams(searchParams);
      } else {
        navigate(FRONTEND_ROUTES.LOGIN);
      }
    }
  }, [searchParams, apiToken]);

  return (
    <div
      className={`h-screen flex flex-col justify-center items-center bg-[url(./assets/images/LoginBg.jpg)] bg-no-repeat bg-center bg-cover`}
    >
      <Box
        className={`w-[200px] h-[60px] xl:w-[320px] xl:h-[80px] bg-[url(./assets/images/SpectrumLogo.png)] bg-contain bg-no-repeat bg-center mb-5 xl:mb-10`}
      ></Box>
      <Paper className="xl:w-[30%] lg:w-[40%] md:w-[60%] w-[90%] glassmorphism">
        <Stack spacing={3} alignItems={"center"} px={3} py={3}>
          <Box className="text-2xl font-bold text-primary-100 uppercase">
            Change Password
          </Box>
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={updatePasswordSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const { password, confirmPassword } = values;
              if (password?.trim() === confirmPassword?.trim() && apiToken) {
                const response: any = await dispatch(
                  updatePassword({
                    password: password?.trim(),
                    token: apiToken,
                  })
                );
                if (response) {
                  navigate(FRONTEND_ROUTES.LOGIN);
                }
              }

              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Box
                className="w-full"
                onKeyDown={(event) => {
                  if (event?.key === "Enter") {
                    const element = event?.target as HTMLElement;
                    if (
                      element?.closest(
                        "[aria-label='toggle password visibility']"
                      )
                    )
                      return;

                    handleSubmit();
                  }
                }}
              >
                <FieldComponent
                  type="password"
                  name="password"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="New Password"
                  className="w-[100%]"
                  inputClassName="bg-primary-100"
                  errorTextColor="text-primary-500"
                  errors={errors}
                  touched={touched}
                />

                <FieldComponent
                  type="password"
                  name="confirmPassword"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="Confirm New Password"
                  className="w-[100%]"
                  inputClassName="bg-primary-100"
                  errorTextColor="text-primary-500"
                  errors={errors}
                  touched={touched}
                />

                <Button
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                  variant="contained"
                  className="w-[100%] button"
                >
                  {isSubmitting ? (
                    <Loader className="items-center" size={25} />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Box>
            )}
          </Formik>
        </Stack>
      </Paper>
    </div>
  );
};

export default ChangePassword;
