import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import { useLazyGetPermissionsQuery } from "../store/reducer/permission";
import { Pages, Tabs, Actions } from "../misc/access.enum";
import { UserTypes } from "../misc/enum";
import { deepCopy } from "../misc/utils";
import "./../styles/AccessRole.css";
import Loader from "../components/Loader";

const columnStyles = {
  minWidth: "180px",
};

const nestedColumnStyles = {
  minWidth: "180px",
};

const actionStyles = {
  minWidth: "250px",
};

const nestedActionStyles = {
  minWidth: "250px",
};

const dummyPermissions = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];

const initialStatePermissions = {
  [Pages.Dashboard]: dummyPermissions,
  [Pages.Vehicle]: dummyPermissions,
  [Pages.Auction]: dummyPermissions,
  [Pages.Arbitration]: dummyPermissions,
  [Pages.User]: dummyPermissions,
  [Pages.Roles]: dummyPermissions,
  [Pages.Stakeholder]: dummyPermissions,
  [Pages.Transportation]: dummyPermissions,
  [Pages.AuditTrail]: dummyPermissions,
  [Pages.Setting]: dummyPermissions,
  [Tabs.InventoryTab]: dummyPermissions,
  [Tabs.SoldTab]: dummyPermissions,
  [Tabs.RemoveFromLotTab]: dummyPermissions,
  [Tabs.AuctionTab]: dummyPermissions,
  [Tabs.OVERequestTab]: dummyPermissions,
  [Tabs.SecondChanceTab]: dummyPermissions,
  [Tabs.ReRunTab]: dummyPermissions,
  [Tabs.AuctionListTab]: dummyPermissions,
  [Tabs.DealersTab]: dummyPermissions,
  [Tabs.TransportersTab]: dummyPermissions,
  [Tabs.UserTab]: dummyPermissions,
  [Tabs.DealerUsersTab]: dummyPermissions,
  [Tabs.TransporterUsersTab]: dummyPermissions,
  [Tabs.TransportationTab]: dummyPermissions,
  [Tabs.UnAssignedTTransporterTab]: dummyPermissions,
  [Tabs.TransportRequestTab]: dummyPermissions,
  [Tabs.ScheduledTransportTab]: dummyPermissions,
  [Tabs.CompletedTransportTab]: dummyPermissions,
  [Tabs.LocationTab]: dummyPermissions,
  [Tabs.RemarksTab]: dummyPermissions,
  [Tabs.AnnouncementTab]: dummyPermissions,
  [Tabs.VINModal]: dummyPermissions,
  [Actions.AddVehicle]: dummyPermissions,
  [Actions.UpdateVehicle]: dummyPermissions,
  [Actions.ConditionReportRequest]: dummyPermissions,
  [Actions.AddTransportRequest]: dummyPermissions,
  [Actions.AssignAuction]: dummyPermissions,
  [Actions.CarFaxReport]: dummyPermissions,
  [Actions.RemoveFromAuction]: dummyPermissions,
  [Actions.OVEEventSale]: dummyPermissions,
  [Actions.PSIStatusUpdate]: dummyPermissions,
  [Actions.TitleStatusUpdate]: dummyPermissions,
  [Actions.RerunInAuctionButton]: dummyPermissions,
  [Actions.SecondChanceAuctionButton]: dummyPermissions,
  [Actions.ConditionReportUpdate]: dummyPermissions,
  [Actions.NewArbitrationCase]: dummyPermissions,
  [Actions.PSIStatus]: dummyPermissions,
  [Actions.TitleStatus]: dummyPermissions,
  [Actions.AddAuction]: dummyPermissions,
  [Actions.UpdateAuction]: dummyPermissions,
  [Actions.RerunInAuction]: dummyPermissions,
  [Actions.SecondChanceAuction]: dummyPermissions,
  [Actions.UpdateOVEStatus]: dummyPermissions,
  [Actions.Offer]: dummyPermissions,
  [Actions.AcceptOffer]: dummyPermissions,
  [Actions.BumpOffer]: dummyPermissions,
  [Actions.CounterOffer]: dummyPermissions,
  [Actions.ArbitrationReason]: dummyPermissions,
  [Actions.AdjustmentAmount]: dummyPermissions,
  [Actions.ArbitrationAction]: dummyPermissions,
  [Actions.AddDealer]: dummyPermissions,
  [Actions.UpdateDealer]: dummyPermissions,
  [Actions.AddTransporter]: dummyPermissions,
  [Actions.UpdateTransporter]: dummyPermissions,
  [Actions.AddAdminUser]: dummyPermissions,
  [Actions.UpdateAdminUser]: dummyPermissions,
  [Actions.AddDealerUser]: dummyPermissions,
  [Actions.UpdateDealerUser]: dummyPermissions,
  [Actions.AddTransporterUser]: dummyPermissions,
  [Actions.UpdateTransporterUser]: dummyPermissions,
  [Actions.AcceptTransportRequest]: dummyPermissions,
  [Actions.AlternateDateRequest]: dummyPermissions,
  [Actions.AcceptAlternateDateRequest]: dummyPermissions,
  [Actions.AssignedTransportActions]: dummyPermissions,
  [Actions.AddLocation]: dummyPermissions,
  [Actions.UpdateLocation]: dummyPermissions,
  [Actions.AddAnnouncement]: dummyPermissions,
  [Actions.UpdateAnnouncement]: dummyPermissions,
  [Actions.AddRemark]: dummyPermissions,
  [Actions.UpdateRemark]: dummyPermissions,
};

const ROLES_NAMES: {
  [key: string]: string;
} = {
  [UserTypes.SuperAdmin]: "Super Admin",
  [UserTypes.AuctionAdmin]: "SF Auction User",
  [UserTypes.InventoryAdmin]: "SF Inventory User",
  [UserTypes.DealerAdmin]: "Dealer Admin",
  [UserTypes.DealerAuctionUser]: "Dealer Auction User",
  [UserTypes.DealerInventoryUser]: "Dealer Inventory User",
  [UserTypes.TransporterAdmin]: "Transporter Admin",
  [UserTypes.TransporterUser]: "Transporter User",
};

const ROLES: Array<UserTypes> = [
  UserTypes.SuperAdmin,
  UserTypes.AuctionAdmin,
  UserTypes.InventoryAdmin,
  UserTypes.DealerAdmin,
  UserTypes.DealerAuctionUser,
  UserTypes.DealerInventoryUser,
  UserTypes.TransporterAdmin,
  UserTypes.TransporterUser,
];

const ParentTable = () => {
  const [getPermissions, { data: permissionsStateData, isFetching }] =
    useLazyGetPermissionsQuery();

  const [permissionsData, setPermissionsData] = useState(
    initialStatePermissions
  );

  const permissionsObject = [
    {
      mainHead: {
        title: "Dashboard",
        permissions: permissionsData[Pages.Dashboard],
      },
      table: [],
    },
    {
      mainHead: {
        title: "Vehicles",
        permissions: permissionsData[Pages.Vehicle],
      },
      table: [
        {
          title: "Inventory",
          permissions: permissionsData[Tabs.InventoryTab],
          actions: [
            {
              title: "Add Vehicle",
              permissions: permissionsData[Actions.AddVehicle],
            },
            {
              title: "Update Vehicle",
              permissions: permissionsData[Actions.UpdateVehicle],
            },
            {
              title: "Remove Vehicle",
              permissions: permissionsData[Actions.UpdateVehicle],
            },
            {
              title: "Request Transport",
              permissions: permissionsData[Actions.AddTransportRequest],
            },
            {
              title: "Assign Auction",
              permissions: permissionsData[Actions.AssignAuction],
            },
          ],
        },
        {
          title: "Sold",
          permissions: permissionsData[Tabs.SoldTab],
          actions: [
            {
              title: "Request PSI",
              permissions: permissionsData[Actions.PSIStatusUpdate],
            },
            {
              title: "Open Arbitration Case",
              permissions: permissionsData[Actions.NewArbitrationCase],
            },
          ],
        },
        {
          title: "Remove From Lot",
          permissions: permissionsData[Tabs.RemoveFromLotTab],
          actions: [],
        },
        {
          title: "VIN Modal",
          permissions: permissionsData[Tabs.VINModal],
          actions: [
            {
              title: "Move to Rerun",
              permissions: permissionsData[Actions.RerunInAuctionButton],
            },
            {
              title: "Move to Second Chance",
              permissions: permissionsData[Actions.SecondChanceAuctionButton],
            },
            {
              title: "Move to OVE Event Sale",
              permissions: permissionsData[Actions.UpdateOVEStatus],
            },
            {
              title: "Update Title Status",
              permissions: permissionsData[Actions.TitleStatusUpdate],
            },
            {
              title: "Update PSI Status",
              permissions: permissionsData[Actions.PSIStatusUpdate],
            },
          ],
        },
      ],
    },
    {
      mainHead: {
        title: "Auction",
        permissions: permissionsData[Pages.Auction],
      },
      table: [
        {
          title: "Auction",
          permissions: permissionsData[Tabs.AuctionTab],
          actions: [
            {
              title: "Offer",
              permissions: permissionsData[Actions.Offer],
            },
            {
              title: "Counter Offer",
              permissions: permissionsData[Actions.CounterOffer],
            },
            {
              title: "BUMP Offer",
              permissions: permissionsData[Actions.BumpOffer],
            },
            {
              title: "Accept Offer",
              permissions: permissionsData[Actions.AcceptOffer],
            },
            {
              title: "Pullback From Auction",
              permissions: permissionsData[Actions.RemoveFromAuction],
            },
          ],
        },
        {
          title: "OVE Request",
          permissions: permissionsData[Tabs.OVERequestTab],
          actions: [
            {
              title: "Update OVE Status",
              permissions: permissionsData[Actions.UpdateOVEStatus],
            },
          ],
        },
        {
          title: "Rerun",
          permissions: permissionsData[Tabs.ReRunTab],
          actions: [
            {
              title: "Assign Auction",
              permissions: permissionsData[Actions.AssignAuction],
            },
          ],
        },
        {
          title: "Second Chance",
          permissions: permissionsData[Tabs.SecondChanceTab],
          actions: [
            {
              title: "Assign Auction",
              permissions: permissionsData[Actions.AssignAuction],
            },
          ],
        },
        {
          title: "Auction List",
          permissions: permissionsData[Tabs.AuctionListTab],
          actions: [
            {
              title: "Create Auction",
              permissions: permissionsData[Actions.AddAuction],
            },
            {
              title: "Update Auction",
              permissions: permissionsData[Actions.UpdateAuction],
            },
          ],
        },
      ],
    },
    {
      mainHead: {
        title: "Arbitration",
        permissions: permissionsData[Pages.Arbitration],
        actions: [],
      },
      table: [
        {
          title: "Arbitration",
          permissions: permissionsData[Pages.Arbitration],
          actions: [
            {
              title: "Perform Arbitration Actions",
              permissions: permissionsData[Actions.ArbitrationAction],
            },
            {
              title: "Update Reason",
              permissions: permissionsData[Actions.ArbitrationReason],
            },
          ],
        },
      ],
    },
    {
      mainHead: {
        title: "Roles",
        permissions: permissionsData[Pages.Roles],
      },
      table: [],
    },
    {
      mainHead: {
        title: "User",
        permissions: permissionsData[Pages.User],
      },
      table: [
        {
          title: "Users",
          permissions: permissionsData[Tabs.UserTab],
          actions: [
            {
              title: "Add User",
              permissions: permissionsData[Actions.AddAdminUser],
            },
            {
              title: "Update User",
              permissions: permissionsData[Actions.UpdateAdminUser],
            },
          ],
        },
        {
          title: "Dealers",
          permissions: permissionsData[Tabs.DealerUsersTab],
          actions: [
            {
              title: "Add Dealer User",
              permissions: permissionsData[Actions.AddDealerUser],
            },
            {
              title: "Update Dealer User",
              permissions: permissionsData[Actions.UpdateDealerUser],
            },
          ],
        },
        {
          title: "Transporters",
          permissions: permissionsData[Tabs.TransporterUsersTab],
          actions: [
            {
              title: "Add Transporter User",
              permissions: permissionsData[Actions.AddTransporterUser],
            },
            {
              title: "Update Transporter User",
              permissions: permissionsData[Actions.UpdateTransporterUser],
            },
          ],
        },
      ],
    },
    {
      mainHead: {
        title: "Stakeholder",
        permissions: permissionsData[Pages.Stakeholder],
      },
      table: [
        {
          title: "Dealers",
          permissions: permissionsData[Tabs.DealersTab],
          actions: [
            {
              title: "Add Dealer",
              permissions: permissionsData[Actions.AddDealer],
            },
            {
              title: "Update Dealer",
              permissions: permissionsData[Actions.UpdateDealer],
            },
          ],
        },
        {
          title: "Transporters",
          permissions: permissionsData[Tabs.TransportersTab],
          actions: [
            {
              title: "Add Transporter",
              permissions: permissionsData[Actions.AddTransporter],
            },
            {
              title: "Update Transporter",
              permissions: permissionsData[Actions.UpdateTransporter],
            },
          ],
        },
      ],
    },
    {
      mainHead: {
        title: "Transporter",
        permissions: permissionsData[Pages.Transportation],
      },
      table: [
        {
          title: "Transportation",
          permissions: permissionsData[Tabs.TransportationTab],
          actions: [],
        },
        {
          title: "Unassigned Transportation",
          permissions: permissionsData[Tabs.UnAssignedTTransporterTab],
          actions: [
            {
              title: "Accept Alternate Date",
              permissions: permissionsData[Actions.AcceptAlternateDateRequest],
            },
            {
              title: "Assign Transport",
              permissions: permissionsData[Actions.AssignedTransportActions],
            },
          ],
        },
        {
          title: "Transport Request",
          permissions: permissionsData[Tabs.TransportRequestTab],
          actions: [
            {
              title: "Accept Transport Request",
              permissions: permissionsData[Actions.AcceptTransportRequest],
            },
            {
              title: "Request Alternate Date",
              permissions: permissionsData[Actions.AlternateDateRequest],
            },
            {
              title: "Accept Alternate Date",
              permissions: permissionsData[Actions.AcceptAlternateDateRequest],
            },
          ],
        },
        {
          title: "Scheduled Task",
          permissions: permissionsData[Tabs.ScheduledTransportTab],
          actions: [],
        },
        {
          title: "Completed Task",
          permissions: permissionsData[Tabs.CompletedTransportTab],
          actions: [],
        },
      ],
    },
    {
      mainHead: {
        title: "Audit Trails",
        permissions: permissionsData[Pages.AuditTrail],
      },
      table: [],
    },
    {
      mainHead: {
        title: "Settings",
        permissions: permissionsData[Pages.Setting],
      },
      table: [
        {
          title: "Location",
          permissions: permissionsData[Tabs.LocationTab],
          actions: [
            {
              title: "Add Location",
              permissions: permissionsData[Actions.AddLocation],
            },
            {
              title: "Update Location",
              permissions: permissionsData[Actions.UpdateLocation],
            },
          ],
        },
        {
          title: "Announcement",
          permissions: permissionsData[Tabs.AnnouncementTab],
          actions: [
            {
              title: "Add Announcement",
              permissions: permissionsData[Actions.AddAnnouncement],
            },
            {
              title: "Update Announcement",
              permissions: permissionsData[Actions.UpdateAnnouncement],
            },
          ],
        },
        {
          title: "Sub Announcements",
          permissions: permissionsData[Tabs.RemarksTab],
          actions: [
            {
              title: "Add Sub Announcement",
              permissions: permissionsData[Actions.AddRemark],
            },
            {
              title: "Update Sub Announcement",
              permissions: permissionsData[Actions.UpdateRemark],
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    if (!permissionsStateData) {
      getPermissions("");
    } else {
      const tempPermissions = deepCopy(permissionsData);
      permissionsStateData?.data?.forEach((data: any) => {
        for (const accessKey in initialStatePermissions) {
          tempPermissions[accessKey][Number(data?.id) - 1] = data[accessKey];
        }
      });
      setPermissionsData(tempPermissions);
    }
  }, [permissionsStateData]);

  return (
    <>
      {!permissionsStateData || isFetching ? (
        <Loader />
      ) : (
        <TableContainer
          component={Paper}
          className="access-table-container access-table-container-main"
        >
          <Table size="small" stickyHeader className="access-table-parent">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#ffffff !important",
                  padding: "20px 5px !important",

                  "& :last-child": {
                    borderRight: "1px solid #e5e7eb !important",
                    borderRadius: "0px 10px 10px 0px !important",
                  },
                }}
              >
                <TableCell
                  style={actionStyles}
                  sx={{
                    fontWeight: "bold",
                    padding: "20px 25px !important",
                    borderTop: "1px solid #e5e7eb !important",
                    borderBottom: "1px solid #e5e7eb !important",
                    borderLeft: "1px solid #e5e7eb !important",
                    borderRadius: "10px 0px 0px 10px !important",
                  }}
                >
                  Action
                </TableCell>

                {ROLES?.map((role: UserTypes) => {
                  return (
                    <TableCell
                      align="center"
                      style={columnStyles}
                      sx={{
                        fontWeight: "bold",
                        padding: "20px 5px !important",
                        borderTop: "1px solid #e5e7eb !important",
                        borderBottom: "1px solid #e5e7eb !important",
                      }}
                    >
                      {ROLES_NAMES[role]}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                borderSpacing: "10px !important",
              }}
            >
              {permissionsObject?.map((permissionObject) => {
                return (
                  <>
                    <TableRow
                      sx={{
                        backgroundColor: "#f9f9f9 !important",
                        border: "0px !important",
                        outline: "0px !important",
                      }}
                    >
                      <TableCell
                        colSpan={9}
                        sx={{ backgroundColor: "#f9f9f9 !important" }}
                      >
                        <ChildTable
                          tableData={{
                            title: permissionObject?.mainHead?.title,
                            permissions:
                              permissionObject?.mainHead?.permissions,
                            actions: permissionObject?.mainHead?.actions,
                          }}
                          isHighlighted={true}
                        />
                      </TableCell>
                    </TableRow>

                    {permissionObject?.table?.map((tableObject, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell colSpan={9}>
                            <ChildTable
                              tableData={tableObject}
                              isHighlighted={false}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const ChildTable = ({
  tableData,
  isHighlighted,
}: {
  tableData: any;
  isHighlighted: boolean;
}) => {
  return (
    <TableContainer
      component={Paper}
      className="access-table-container"
      sx={{
        backgroundColor: isHighlighted ? "#f9f9f9 !important" : "",
        overflowY: "clip !important",
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow className="access-table-row">
            <TableCell
              style={nestedActionStyles}
              className="access-table-cell"
              sx={{
                fontWeight: "bold",
              }}
            >
              {tableData?.title}
            </TableCell>
            {tableData?.permissions?.map((permission: boolean) => {
              return (
                <TableCell
                  align="center"
                  style={nestedColumnStyles}
                  className="access-table-cell"
                >
                  <CheckboxComponent checked={permission} />
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.actions?.map((action: any, index: any) => (
            <TableRow key={index} className="access-table-row">
              <TableCell className="access-table-cell">
                {action?.title}
              </TableCell>
              {action?.permissions?.map((permission: boolean) => {
                return (
                  <TableCell align="center" className="access-table-cell">
                    <CheckboxComponent checked={permission} />
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const CheckboxComponent = ({ checked }: { checked?: boolean }) => {
  return (
    <Checkbox
      checked={checked}
      disabled={true}
      color="primary"
      sx={{
        transform: "scale(1.3)",
      }}
      checkedIcon={
        <CheckIcon
          sx={{ marginLeft: "2px", fontSize: "18px" }}
          className="text-secondary-300 bg-primary-300 border-[1px] border-tertiary-200 rounded-sm"
        />
      }
      inputProps={{
        "aria-label": "select all desserts",
      }}
    />
  );
};
export default ParentTable;
