import { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import FieldComponent from "../FieldComponent";
import ButtonComponent from "../ButtonComponent";
import SelectComponent from "../SelectComponent";
import TagComponent from "../TagComponent";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { SelectChangeEvent, Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { TableCellComponentProps } from "../../ts/component.types";
import {
  BUTTON_SIZES,
  CONSTANTS,
  NUMBER,
  OFFER_BUTTON_TYPES,
  SIZES,
  TABLE_COLUMNS,
} from "../../misc/constants";
import { FunctionContext } from "../../context/function.context";

const TableCellComponent = ({
  middle = false,
  cellRow,
  openVINModal,
  disabled = false,
  id,
}: TableCellComponentProps) => {
  const inputRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    handleRowSelection,
    handleSelect,
    handleSubmit,
    handleOfferChange,
    handleOfferAction,
    handleOfferSubmit,
    handleActionButton,
  } = useContext(FunctionContext);

  const {
    title,
    value,
    placeholder,
    titleWidth,
    valueWidth,
    valueColor,
    type,
    options,
    showVINModal,
    tagOptions,
    actions,
    hide,
    selectType,
  } = cellRow;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Only for edit lane and run from Auction Page
  // this useEffect logic is used to set the value in the input field when the type is INPUT_WITH_CHECKBOX
  useEffect(() => {
    if (type === CONSTANTS.INPUT_WITH_CHECKBOX && value && value !== "--") {
      const inputRef = inputRefs?.current?.[0];
      if (inputRef) {
        inputRef.value = value;
      }
    }
  }, [type]);

  return (
    <Box
      className={`flex items-start justify-center ${
        middle && "my-1"
      } text-tertiary-200`}
    >
      {type === CONSTANTS.TAG ? (
        <Box className="flex items-end self-center">
          {tagOptions?.map((option, index) => (
            <TagComponent
              className={`${index === 1 ? "mx-1" : ""}`}
              text={option?.title}
              color={option?.active ? CONSTANTS.PRIMARY : CONSTANTS.TERTIARY}
            />
          ))}
        </Box>
      ) : type === CONSTANTS.ACTION ? (
        <Box>
          {actions?.map((action) => {
            if (action?.type === "action-menu") {
              return (
                <>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    disabled={
                      !action?.elements ||
                      action?.elements?.length === NUMBER.ZERO
                    }
                  >
                    <MoreHorizIcon className="cursor-pointer" />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    {action?.elements?.map((action) => {
                      return (
                        <MenuItem
                          onClick={() => {
                            handleActionButton &&
                              handleActionButton(action?.type, id);
                            setAnchorEl(null);
                          }}
                        >
                          {action?.title}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </>
              );
            } else if (action?.type === "input-with-buttons") {
              return action?.elements?.length ? (
                action?.elements?.map((element) => (
                  <Box className="flex justify-center items-center my-2 first:mt-0 last:mb-0">
                    {element?.enableText ? (
                      <>
                        <Box
                          className={`font-bold text-right mr-5 whitespace-nowrap w-28`}
                        >
                          {element?.title}:
                        </Box>
                        <Tooltip title={value} placement="bottom">
                          <div className="text-ellipsis overflow-hidden w-[140px]">
                            {element?.value || "-"}
                          </div>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <FieldComponent
                          inputClassName="h-6 w-24 bg-primary-300"
                          type={CONSTANTS.OFFER_INPUT}
                          size={SIZES.SMALL}
                          value={element?.value}
                          handleChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleOfferChange &&
                            handleOfferChange(
                              element?.type,
                              id,
                              event?.target?.value
                            )
                          }
                        />
                        <ButtonComponent
                          className="w-[100px] font-10 whitespace-nowrap"
                          color={CONSTANTS.PRIMARY}
                          size={SIZES.SMALL}
                          text={element?.title || ""}
                          onClick={() =>
                            handleOfferAction &&
                            handleOfferAction(element?.type, id, element?.value)
                          }
                        />
                      </>
                    )}
                  </Box>
                ))
              ) : (
                <Box className="w-28 h-20 mx-auto my-0 bg-[url(./assets/images/Sold.png)] bg-no-repeat bg-contain" />
              );
            } else if (action?.type === "buttons") {
              return (
                <Box className="flex flex-col items-center justify-center">
                  {action?.elements?.length ? (
                    action?.elements?.map((element) => (
                      <Box className="mb-2">
                        <ButtonComponent
                          className="w-[120px]"
                          color={element?.color || ""}
                          text={element.title || ""}
                          size={SIZES.SMALL}
                          disabled={element?.disabled}
                          onClick={() =>
                            handleSubmit && handleSubmit(element?.type, id)
                          }
                        />
                      </Box>
                    ))
                  ) : (
                    <Box className="flex items-center justify-center">
                      <Box className="h-[44px] w-[64px] bg-[url(./assets/images/Transportation.png)] bg-no-repeat bg-contain"></Box>
                    </Box>
                  )}
                </Box>
              );
            }
            return null;
          })}
        </Box>
      ) : (
        <>
          {!hide && (
            <>
              <Box
                className={`font-bold text-right mr-5 whitespace-nowrap ${titleWidth}`}
              >
                {title}:
              </Box>
              <Box
                onClick={() => showVINModal && openVINModal(id || "")}
                className={`text-left capitalize ${valueWidth} ${valueColor} ${
                  showVINModal && "cursor-pointer"
                }`}
              >
                {type === CONSTANTS.INPUT ? (
                  <Box className="flex items-center">
                    <FieldComponent
                      inputClassName="h-7 w-24 bg-primary-300"
                      type={CONSTANTS.OFFER_INPUT}
                      size={SIZES.SMALL}
                      value={value}
                      handleChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) =>
                        handleOfferChange &&
                        handleOfferChange(
                          OFFER_BUTTON_TYPES.OFFER,
                          id,
                          event?.target?.value
                        )
                      }
                      hideErrorMessage={true}
                    />
                    <ButtonComponent
                      color={CONSTANTS.PRIMARY}
                      type={CONSTANTS.DONE}
                      text={""}
                      className={`${BUTTON_SIZES.EXTRA_SMALL}`}
                      onClick={() =>
                        handleOfferSubmit &&
                        handleOfferSubmit(OFFER_BUTTON_TYPES.OFFER, id, value)
                      }
                    />
                  </Box>
                ) : type === CONSTANTS.SELECT ? (
                  <SelectComponent
                    className="bg-primary-300"
                    size={SIZES.SMALL}
                    options={options}
                    value={value || ""}
                    placeholder={placeholder || ""}
                    handleChange={(event: SelectChangeEvent<string>) =>
                      handleSelect &&
                      handleSelect(selectType, id, event.target.value)
                    }
                    hideErrorMessage={true}
                  />
                ) : type === CONSTANTS.INPUT_WITH_CHECKBOX ? (
                  <FieldComponent
                    inputRefs={inputRefs}
                    hideErrorMessage={true}
                    type={CONSTANTS.CHECKBOX}
                    disabled={disabled}
                    handleChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) =>
                      handleRowSelection &&
                      handleRowSelection(
                        id || "",
                        title || "",
                        event.target.value
                      )
                    }
                  />
                ) : (
                  <Tooltip title={value} placement="bottom">
                    <div
                      className={`${
                        title === TABLE_COLUMNS.VIN?.toUpperCase()
                          ? "text-ellipsis overflow-hidden"
                          : "line-clamp-2"
                      } `}
                    >
                      {value}
                    </div>
                  </Tooltip>
                )}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default TableCellComponent;
