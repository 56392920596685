import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import {
  COLUMNS_KEY,
  DATE_FORMAT,
  DATE_TYPE,
  NUMBER,
  USER_TYPE_NAME,
} from "./constants";
import { calculateDays, formatDate } from "./utils";
import {
  AuctionStatus,
  AuctionVehicleStatus,
  OVEEventSaleStatus,
  TransportRequestStatus,
  UserTypes,
} from "./enum";
import { Tabs } from "./access.enum";
import { store } from "./../store";
import { setError } from "../store/reducer/auth";
import { MESSAGES } from "./messages";

export const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const exportToExcel = (
  apiData: any,
  columnsLength: number,
  fileName: string
) => {
  try {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const widthArr = [];

    for (let i = 0; i <= columnsLength; i++) {
      widthArr.push({ wpx: 120 });
    }

    ws["!cols"] = widthArr;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  } catch {
    store?.dispatch(
      setError({
        error: true,
        errorMessage: MESSAGES.MISC.EXPORT_EXCEL_ERROR,
      })
    );
  }
};

export const createExcelReportData = (type: string, data: any) => {
  try {
    let max = 0;
    const excelData = data?.map((row: any) => {
      const excelObject: any = {};

      const createdAt = row?.created_at
        ? formatDate(
            row?.created_at,
            DATE_TYPE.TIMESTAMP,
            DATE_FORMAT.YYYY_MM_DD
          )
        : "N/A";
      const createdByUser = row?.created_by_user;
      const createdBy = createdByUser
        ? `${createdByUser?.first_name} ${createdByUser?.last_name}`
        : "N/A";
      const age = calculateDays(row?.created_at);
      const auction = row?.auction_vehicle?.[NUMBER.ZERO]?.auction;

      const soldDate = auction?.start_time
        ? formatDate(
            auction?.start_time,
            DATE_TYPE.TIMESTAMP,
            DATE_FORMAT.DD_MM_YYYY
          )
        : auction?.auction_date
        ? formatDate(
            auction?.auction_date,
            DATE_TYPE.DATE_STRING,
            DATE_FORMAT.DD_MM_YYYY
          )
        : "N/A";

      const auctionDate = row?.start_time
        ? formatDate(
            row?.start_time,
            DATE_TYPE.TIMESTAMP,
            DATE_FORMAT.DD_MM_YYYY
          )
        : row?.auction_date
        ? formatDate(
            row?.auction_date,
            DATE_TYPE.DATE_STRING,
            DATE_FORMAT.DD_MM_YYYY
          )
        : "N/A";

      const pickupDate = row?.pickup_date_time
        ? formatDate(
            row?.pickup_date_time,
            DATE_TYPE.DATE_STRING,
            DATE_FORMAT.DD_MM_YYYY
          )
        : "N/A";
      const alternateDate = row?.alternate_date_request?.[NUMBER.ZERO]
        ?.alternate_date_time
        ? formatDate(
            row?.alternate_date_request?.[NUMBER.ZERO]?.alternate_date_time,
            DATE_TYPE.DATE_STRING,
            DATE_FORMAT.DD_MM_YYYY
          )
        : "N/A";
      const remarks = row?.remarks?.length
        ? Array.isArray(row?.remarks)
          ? row?.remarks?.map((remark: any) => remark?.label)?.join(", ")
          : "N/A"
        : "N/A";
      const vehicleOffer = row?.offer || "N/A";
      const vehicleBumpOffer = row?.bump_offer || "N/A";
      const vehicleCounterOffer = row?.counter_offer || "N/A";

      switch (type) {
        case Tabs.InventoryTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_VIN] = row?.vin || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MAKE] = row?.make || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_YEAR] = row?.year || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MODEL] = row?.model || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_LOCATION] =
            row?.location?.name || "N/A";
          excelObject[COLUMNS_KEY.DEALER_NAME] = row?.dealer?.name || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_AGE] = `${age?.toString()} ${
            age === 1 ? "Day" : "Days"
          }`;
          excelObject[COLUMNS_KEY.VEHICLE_ANNOUNCEMENT] =
            row?.announcement?.label || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_REMARK] = remarks;
          excelObject[COLUMNS_KEY.AUCTION_COUNT] = row?.auction_count || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_ACV] = row?.acv || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_DEALER_STOCK_NUMBER] = row?.dealer_stock_number || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_FLOOR_PRICE] =
            row?.floor_price || "N/A";

          break;
        case Tabs.SoldTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_VIN] = row?.vin || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MAKE] = row?.make || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_YEAR] = row?.year || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MODEL] = row?.model || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_LOCATION] =
            row?.location?.name || "N/A";
          excelObject[COLUMNS_KEY.DEALER_NAME] = row?.dealer?.name || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_AGE] = `${age?.toString()} ${
            age === 1 ? "Day" : "Days"
          }`;
          excelObject[COLUMNS_KEY.VEHICLE_ANNOUNCEMENT] =
            row?.announcement?.label || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_REMARK] = remarks;
          excelObject[COLUMNS_KEY.AUCTION_COUNT] = row?.auction_count || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_ACV] = row?.acv || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_DEALER_STOCK_NUMBER] = row?.dealer_stock_number || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_FLOOR_PRICE] =
            row?.floor_price || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_SOLD_DATE] = soldDate;
          excelObject[COLUMNS_KEY.VEHICLE_SOLD_PRICE] =
            row?.sale_price || "N/A";

          break;
        case Tabs.RemoveFromLotTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_VIN] = row?.vin || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MAKE] = row?.make || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_YEAR] = row?.year || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MODEL] = row?.model || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_LOCATION] =
            row?.location?.name || "N/A";
          excelObject[COLUMNS_KEY.DEALER_NAME] = row?.dealer?.name || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_AGE] = `${age?.toString()} ${
            age === 1 ? "Day" : "Days"
          }`;
          excelObject[COLUMNS_KEY.VEHICLE_ANNOUNCEMENT] =
            row?.announcement?.label || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_REMARK] = remarks;
          excelObject[COLUMNS_KEY.AUCTION_COUNT] = row?.auction_count || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_ACV] = row?.acv || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_DEALER_STOCK_NUMBER] = row?.dealer_stock_number || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_FLOOR_PRICE] =
            row?.floor_price || "N/A";
          break;
        case Tabs.AuctionTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_VIN] = row?.vehicle?.vin || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MAKE] = row?.vehicle?.make || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_YEAR] = row?.vehicle?.year || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MODEL] = row?.vehicle?.model || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_LOCATION] =
            row?.vehicle?.location?.name || "N/A";
          excelObject[COLUMNS_KEY.DEALER_NAME] =
            row?.vehicle?.dealer?.name || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_AGE] = `${age?.toString()} ${
            age === 1 ? "Day" : "Days"
          }`;
          excelObject[COLUMNS_KEY.VEHICLE_ANNOUNCEMENT] =
            row?.vehicle?.announcement?.label || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_LANE] = row?.lane || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_RUN] = row?.run || "N/A";
          excelObject[COLUMNS_KEY.AUCTION_COUNT] =
            row?.vehicle?.auction_count || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_ACV] = row?.vehicle?.acv || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_DEALER_STOCK_NUMBER] = row?.vehicle?.dealer_stock_number || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_FLOOR_PRICE] =
            row?.vehicle?.floor_price || "N/A";
          excelObject[COLUMNS_KEY.AUCTION_VEHICLE_OFFER] = vehicleOffer;
          excelObject[COLUMNS_KEY.AUCTION_VEHICLE_BUMPER_OFFER] =
            vehicleBumpOffer;
          excelObject[COLUMNS_KEY.AUCTION_VEHICLE_COUNTER_OFFER] =
            vehicleCounterOffer;
          excelObject[COLUMNS_KEY.VEHICLE_SOLD_PRICE] =
            row?.vehicle?.sale_price || "N/A";
          excelObject[COLUMNS_KEY.AUCTION_VEHICLE_STATUS] =
            AuctionVehicleStatus[row?.status] || "N/A";

          break;
        case Tabs.SecondChanceTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_VIN] = row?.vin || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MAKE] = row?.make || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_YEAR] = row?.year || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MODEL] = row?.model || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_LOCATION] =
            row?.location?.name || "N/A";
          excelObject[COLUMNS_KEY.DEALER_NAME] = row?.dealer?.name || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_AGE] = `${age?.toString()} ${
            age === 1 ? "Day" : "Days"
          }`;
          excelObject[COLUMNS_KEY.VEHICLE_ANNOUNCEMENT] =
            row?.announcement?.label || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_REMARK] = remarks;
          excelObject[COLUMNS_KEY.AUCTION_COUNT] = row?.auction_count || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_ACV] = row?.acv || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_DEALER_STOCK_NUMBER] = row?.dealer_stock_number || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_FLOOR_PRICE] =
            row?.floor_price || "N/A";
          break;
        case Tabs.ReRunTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_VIN] = row?.vin || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MAKE] = row?.make || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_YEAR] = row?.year || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MODEL] = row?.model || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_LOCATION] =
            row?.location?.name || "N/A";
          excelObject[COLUMNS_KEY.DEALER_NAME] = row?.dealer?.name || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_AGE] = `${age?.toString()} ${
            age === 1 ? "Day" : "Days"
          }`;
          excelObject[COLUMNS_KEY.VEHICLE_ANNOUNCEMENT] =
            row?.announcement?.label || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_REMARK] = remarks;
          excelObject[COLUMNS_KEY.AUCTION_COUNT] = row?.auction_count || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_ACV] = row?.acv || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_DEALER_STOCK_NUMBER] = row?.dealer_stock_number || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_FLOOR_PRICE] =
            row?.floor_price || "N/A";
          break;
        case Tabs.AuctionListTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_LOCATION] =
            row?.location?.name || "N/A";
          excelObject[COLUMNS_KEY.AUCTION_DATE] = auctionDate;
          excelObject[COLUMNS_KEY.AUCTION_CREATED_BY] = createdBy;
          excelObject[COLUMNS_KEY.AUCTION_CREATION_DATE] = createdAt;
          excelObject[COLUMNS_KEY.ACTIVE] = row?.is_active || "N/A";
          excelObject[COLUMNS_KEY.AUCTION_STATUS] =
            AuctionStatus[row?.status] || "N/A";
          break;
        case Tabs.OVERequestTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_VIN] = row?.vin || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MAKE] = row?.make || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_YEAR] = row?.year || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MODEL] = row?.model || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_LOCATION] =
            row?.location?.name || "N/A";
          excelObject[COLUMNS_KEY.DEALER_NAME] = row?.dealer?.name || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_AGE] = `${age?.toString()} ${
            age === 1 ? "Day" : "Days"
          }`;
          excelObject[COLUMNS_KEY.VEHICLE_ANNOUNCEMENT] =
            row?.announcement?.label || "N/A";

          excelObject[COLUMNS_KEY.VEHICLE_ACV] = row?.acv || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_DEALER_STOCK_NUMBER] = row?.dealer_stock_number || "N/A";
          excelObject[COLUMNS_KEY.OVE_EVENT_STATUS] =
            OVEEventSaleStatus[row?.ove_event_sale?.status] || "N/A";
          excelObject[COLUMNS_KEY.OVE_EVENT_PRICE] =
            row?.ove_event_sale?.price || "N/A";

          break;
        case Tabs.UserTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.EMAIL] = row?.email || "N/A";
          excelObject[COLUMNS_KEY.FIRST_NAME] = row?.first_name || "N/A";
          excelObject[COLUMNS_KEY.LAST_NAME] = row?.last_name || "N/A";
          excelObject[COLUMNS_KEY.CONTACT_NO] =
            row?.country_code + row?.phone_number || "N/A";
          excelObject[COLUMNS_KEY.USER_ROLE] =
            USER_TYPE_NAME[row?.role_id as UserTypes] || "N/A";

          break;
        case Tabs.DealerUsersTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.EMAIL] = row?.email || "N/A";
          excelObject[COLUMNS_KEY.DEALER_NAME] =
            row?.dealer_user?.dealer?.name || "N/A";
          excelObject[COLUMNS_KEY.FIRST_NAME] = row?.first_name || "N/A";
          excelObject[COLUMNS_KEY.LAST_NAME] = row?.last_name || "N/A";
          excelObject[COLUMNS_KEY.CONTACT_NO] =
            row?.country_code + row?.phone_number || "N/A";
          excelObject[COLUMNS_KEY.USER_ROLE] =
            USER_TYPE_NAME[row?.role_id as UserTypes] || "N/A";

          break;
        case Tabs.TransporterUsersTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.EMAIL] = row?.email || "N/A";
          excelObject[COLUMNS_KEY.TRANSPORTER_NAME] =
            row?.transporter_user?.transporter?.name || "N/A";
          excelObject[COLUMNS_KEY.FIRST_NAME] = row?.first_name || "N/A";
          excelObject[COLUMNS_KEY.LAST_NAME] = row?.last_name || "N/A";
          excelObject[COLUMNS_KEY.CONTACT_NO] =
            row?.country_code + row?.phone_number || "N/A";
          excelObject[COLUMNS_KEY.USER_ROLE] =
            USER_TYPE_NAME[row?.role_id as UserTypes] || "N/A";
          break;
        case Tabs.DealersTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.MILLION_NUMBER] =
            row?.million_number || "N/A";
          excelObject[COLUMNS_KEY.NAME] = row?.name || "N/A";
          excelObject[COLUMNS_KEY.LOCATION] =
            row?.dealer_user?.[NUMBER.ZERO]?.location?.name || "N/A";
          excelObject[COLUMNS_KEY.CONTACT_NO] =
            row?.dealer_user?.[NUMBER.ZERO]?.user?.country_code +
              row?.dealer_user?.[NUMBER.ZERO]?.user?.phone_number || "N/A";
          excelObject[COLUMNS_KEY.ZIP_CODE] =
            row?.dealer_user?.[NUMBER.ZERO]?.zip || "N/A";
          excelObject[COLUMNS_KEY.EMAIL] =
            row?.dealer_user?.[NUMBER.ZERO]?.user?.email || "N/A";
          break;
        case Tabs.TransportersTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.NAME] = row?.name || "N/A";
          excelObject[COLUMNS_KEY.EMAIL] =
            row?.transporter_user?.[NUMBER.ZERO]?.user?.email || "N/A";
          excelObject[COLUMNS_KEY.FIRST_NAME] =
            row?.transporter_user?.[NUMBER.ZERO]?.user?.first_name || "N/A";
          excelObject[COLUMNS_KEY.LAST_NAME] =
            row?.transporter_user?.[NUMBER.ZERO]?.user?.last_name || "N/A";
          excelObject[COLUMNS_KEY.CONTACT_NO] =
            row?.transporter_user?.[NUMBER.ZERO]?.user?.country_code +
              row?.transporter_user?.[NUMBER.ZERO]?.user?.phone_number || "N/A";

          break;
        case Tabs.TransportationTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_VIN] = row?.vehicle?.vin || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MAKE] = row?.vehicle?.make || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_YEAR] = row?.vehicle?.year || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MODEL] = row?.vehicle?.model || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_LOCATION] =
            row?.vehicle?.location?.name || "N/A";
          excelObject[COLUMNS_KEY.DEALER_NAME] =
            row?.vehicle?.dealer?.name || "N/A";
          excelObject[COLUMNS_KEY.PICKUP_DATE] = pickupDate;
          excelObject[COLUMNS_KEY.PICKUP_LOCATION] =
            row?.pickup_location?.name || "N/A";
          excelObject[COLUMNS_KEY.DROPOFF_LOCATION] =
            row?.dropoff_location?.name || "N/A";
          excelObject[COLUMNS_KEY.TRANSPORTER_NAME] =
            row?.transporter?.name || "N/A";
          excelObject[COLUMNS_KEY.TRANSPORT_STATUS] =
            TransportRequestStatus[row?.status] || "N/A";
          excelObject[COLUMNS_KEY.ALTERNATE_DATE] = alternateDate;
          break;
        case Tabs.UnAssignedTTransporterTab:
          excelObject[COLUMNS_KEY.ID] = row?.id || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_VIN] = row?.vehicle?.vin || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MAKE] = row?.vehicle?.make || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_YEAR] = row?.vehicle?.year || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_MODEL] = row?.vehicle?.model || "N/A";
          excelObject[COLUMNS_KEY.VEHICLE_LOCATION] =
            row?.vehicle?.location?.name || "N/A";
          excelObject[COLUMNS_KEY.DEALER_NAME] =
            row?.vehicle?.dealer?.name || "N/A";
          excelObject[COLUMNS_KEY.PICKUP_DATE] = pickupDate;
          excelObject[COLUMNS_KEY.PICKUP_LOCATION] =
            row?.pickup_location?.name || "N/A";
          excelObject[COLUMNS_KEY.DROPOFF_LOCATION] =
            row?.dropoff_location?.name || "N/A";
          excelObject[COLUMNS_KEY.TRANSPORT_STATUS] =
            TransportRequestStatus[row?.status] || "N/A";
          break;
        default:
          break;
      }

      const length = Object?.entries(excelObject)?.length;
      max = length > max ? length : max;
      return excelObject;
    });

    return {
      excelData,
      columnsLength: max,
      error: false,
    };
  } catch {
    return {
      excelData: [],
      columnsLength: 0,
      error: true,
    };
  }
};
