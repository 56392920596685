import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Loader from "../Loader";
import { useRef, useState, useEffect } from "react";

type Props = {
  title: string;
  subtitle: string;
  isActive?: boolean;
  subtitleClassName?: string;
  showEditIcon?: boolean;
  showStatus?: boolean;
  loading?: boolean;
  editClickListener?: () => void;
};

const CardComponent = ({
  title,
  subtitle,
  isActive,
  subtitleClassName,
  loading = false,
  showEditIcon = false,
  showStatus = false,
  editClickListener,
}: Props) => {

  const boxRef = useRef<HTMLElement>(null);
  const [isTextOverflowed, setIsTextOverflowed] = useState(false);

  useEffect(() => {
    const boxElement = boxRef.current;
    if (boxElement) {
      setIsTextOverflowed(boxElement.scrollWidth > boxElement.clientWidth);
    }
  }, [subtitle]);
  
  return (
    <Box className="relative flex flex-col items-start justify-between bg-primary-100 rounded-md box-shadow p-6 overflow-hidden">
      <Box className="max-w-[85%] text-tertiary-500 font-semibold">{title}</Box>
      {loading ? (
        <Box className="my-2">
          <Loader />
        </Box>
      ) : (
        <Tooltip title={isTextOverflowed ? subtitle : null}>
          <Box ref={boxRef}
            className={`font-bold ${subtitleClassName} text-ellipsis overflow-hidden w-[100%]`}
          >
            {subtitle}
          </Box>
        </Tooltip>
      )}
      {showStatus && (
        <Box className={`font-semibold mt-2 flex items-center`}>
          Status:{" "}
          <Box
            className={`ml-2 ${
              isActive ? "text-tertiary-600" : "text-secondary-600"
            }`}
          >
            {isActive ? "Active" : "Inactive"}
          </Box>
        </Box>
      )}
      {showEditIcon && editClickListener && (
        <EditIcon
          onClick={editClickListener}
          className="absolute right-2 top-2 cursor-pointer"
          fontSize={"small"}
        />
      )}
    </Box>
  );
};

export default CardComponent;
