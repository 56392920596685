import { ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import usePagination from "@mui/material/usePagination";
import PaginationComponent from "../PaginationComponent";
import Box from "@mui/material/Box";
import TableRowComponent from "../TableRowComponent";
import {
  SelectedPaginationProps,
  ListingTableComponentProps,
  RowSelectionType,
} from "../../ts/component.types";
import { MODAL_TYPE } from "../../misc/constants";

import "./../../styles/TableComponent.css";
import "./../../styles/AuditTableComponent.css";
import { toggleModal } from "../../store/reducer/modal";
import Loader from "../Loader";

import { FunctionContext } from "../../context/function.context";
import { useDebouncedCallback } from "use-debounce";

const ListingTableComponent = ({
  rows,
  showCheckbox,
  applyFilter,
  queryParams,
  selectedRows,
  setSelectedRows,
  handleSelect,
  handleSubmit,
  handleOfferChange,
  handleOfferAction,
  handleOfferSubmit,
  handleActionButton,
  rowCount = 0,
  reference,
}: ListingTableComponentProps) => {
  const dispatch = useDispatch();

  const rowsPerPage = (queryParams?.rowsPerPage as number) || 10;
  const page = (queryParams?.page as number) || 0;

  const totalPages = Math.ceil(rowCount / rowsPerPage);
  const { items } = usePagination({
    count: totalPages,
    page: page + 1,
  });

  const handleOpen = (id: string, conditionReportId: string) => {
    dispatch(
      toggleModal({
        open: true,
        modalType: MODAL_TYPE.VEHICLE,
        data: {
          vehicleId: id,
          conditionReportId,
        },
      })
    );
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    applyFilter &&
      applyFilter({
        rowsPerPage: +event.target.value,
        page: 0,
      });
  };

  const handlePageChange = (selected: SelectedPaginationProps) => {
    if (!selected?.selected) {
      const pageNumber = selected?.page ? selected?.page - 1 : 0;
      applyFilter &&
        applyFilter({
          page: pageNumber,
        });
    }
  };

  const handleRowSelection = (values: RowSelectionType) => {
    let find = false;
    const updatedRows = [];

    selectedRows?.forEach((row) => {
      if (row?.id === values?.id) {
        find = true;
        if (values?.checked) {
          updatedRows.push({
            ...row,
            ...values,
          });
        }
      } else {
        updatedRows.push(row);
      }
    });

    if (!find) {
      updatedRows.push(values);
    }

    setSelectedRows && setSelectedRows(updatedRows);
  };

  const isChecked = (id: string) => {
    return selectedRows?.find((row) => row.id === id && row.checked)
      ? true
      : false;
  };

  const allSelectionToggle = (selectAll: boolean) => {
    if (!selectAll) {
      setSelectedRows && setSelectedRows([]);
      return;
    }
    const updatedRows =
      rows?.map((row) => ({
        id: row?.id,
        conditionReportId: row?.conditionReportId,
        checked: true,
      })) || [];

    setSelectedRows && setSelectedRows(updatedRows);
  };

  const debounced = useDebouncedCallback(
    (id: string, name: string, value: string) => {
      handleRowSelection({
        id,
        checked: true,
        [name?.toLowerCase()]: value,
      });
    },
    50
  );

  return (
    <FunctionContext.Provider
      value={{
        handleRowSelection: debounced,
        handleSelect: handleSelect,
        handleOfferChange: handleOfferChange,
        handleSubmit: handleSubmit,
        handleOfferAction: handleOfferAction,
        handleActionButton: handleActionButton,
        handleOfferSubmit: handleOfferSubmit,
      }}
    >
      <Paper className="table-container audit-table-container">
        {showCheckbox && (
          <Box className="flex items-center justify-between px-4 pb-2 w-full text-[15px] font-regular">
            <Box className="flex items-center">
              <Box
                className="text-secondary-300 mr-3 cursor-pointer"
                onClick={() => allSelectionToggle(true)}
              >
                Select All
              </Box>
              <Box
                className="text-secondary-300 cursor-pointer"
                onClick={() => allSelectionToggle(false)}
              >
                Deselect All
              </Box>
            </Box>
            <Box className="text-tertiary-200">
              {selectedRows?.length} Items Selected
            </Box>
          </Box>
        )}

        <TableContainer className="table-scrollbar" sx={{ maxHeight: "60vh" }}>
          <Table stickyHeader aria-label="sticky table" className="audit-table">
            {!rows ? (
              <Loader />
            ) : !rows?.length ? (
              <Box className="w-full text-center font-bold text-lg text-tertiary-400 tracking-wide">
                No Data Available
              </Box>
            ) : (
              <TableBody ref={reference}>
                {rows
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRowComponent
                        isChecked={isChecked}
                        id={row.id}
                        unreadCount={row?.unreadCount || 0}
                        fade={row?.fade}
                        conditionReportId={row?.conditionReportId}
                        openVINModal={(id) =>
                          handleOpen(id, row?.conditionReportId as string)
                        }
                        row={row?.data}
                        showCheckbox={showCheckbox}
                        handleRowSelection={handleRowSelection}
                      />
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>

      <Box className="flex items-center justify-between py-3">
        <Box className="text-sm font-light">
          Showing {(page + 0) * rowsPerPage || 1} to{" "}
          {(page + 1) * rowsPerPage > rowCount
            ? rowCount
            : (page + 1) * rowsPerPage}{" "}
          of {rowCount} entries
        </Box>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={() => {}}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={() => ""}
          className="bg-primary-300 border-none shadow-none outline-none"
          ActionsComponent={() => {
            return (
              <PaginationComponent
                items={items}
                handlePageChange={handlePageChange}
              />
            );
          }}
        />
      </Box>
    </FunctionContext.Provider>
  );
};

export default ListingTableComponent;
