import Loader from "./components/Loader";
import "./App.css";
import { useAppSelector, useAppDispatch } from "./hooks/redux-toolkit";
import ModalComponent from "./components/ModalComponent";
import { toggleModal } from "./store/reducer/modal";
import { SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { logout } from "./store/services/auth";
import { COLORS } from "./misc/colors";
import { Outlet } from "react-router-dom";
import SocketSnackbar from "./components/SocketSnackbar";
import SnackbarHandler from "./components/SnackbarHandler";

const MainApp = () => {
  const dispatch = useAppDispatch();

  const [progress, setProgress] = useState(0);

  const { role } = useAppSelector((state) => state.authorization);

  if (typeof role === "string") dispatch(logout());

  const { open, submitText, modalType, edit } = useAppSelector(
    (state) => state.modal
  );

  const { loading } = useAppSelector((state) => state.progress);

  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 70 && loading) {
            return oldProgress;
          }
          const diff = Math.random() * 20;
          return Math.min(oldProgress + diff, 99);
        });
      }, 70);
      return () => {
        clearInterval(timer);
      };
    } else {
      if (progress > 0 && progress < 100) {
        setProgress(100);
        setTimeout(() => setProgress(0), 100);
      }
    }
  }, [loading]); //eslint-disable-line

  useEffect(() => {
    dispatch(
      toggleModal({
        open: false,
      })
    );
  }, []); //eslint-disable-line

  return (
    <SnackbarProvider dense Components={{ socketSnackbar: SocketSnackbar }}>
      {(loading || (progress > 0 && progress <= 100)) && (
        <>
          <LinearProgress
            variant="determinate"
            color="primary"
            style={{
              position: "fixed",
              zIndex: "1203",
              width: "100%",
              backgroundColor: COLORS.CHARCOAL_GRAY,
            }}
            value={progress}
            sx={{
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: COLORS.YELLOW,
              },
            }}
          />
          <Loader className="fixed w-screen h-screen z-[1201] backdrop-blur-[1px]" />
        </>
      )}
      <ModalComponent
        open={open}
        edit={edit}
        type={modalType}
        submitButtonText={submitText}
        handleClose={() => dispatch(toggleModal({ open: false }))}
      />
      <SnackbarHandler />
      <Outlet />
    </SnackbarProvider>
  );
};

export default MainApp;
