import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./reducer/auth";
import ModalSlice from "./reducer/modal";
import sidebarSlice from "./reducer/sidebar";
import progressBarSlice from "./reducer/progressBar";
import { baseAPI } from "./reducer/baseSlice";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { requestErrorMiddleware } from "./middleware/requestError.middleware";
import { userSlice } from "./reducer/user";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["authorization"],
};

const rootReducer = combineReducers({
  authorization: AuthSlice,
  modal: ModalSlice,
  progress: progressBarSlice,
  sidebar: sidebarSlice,
  [userSlice.reducerPath]: userSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(requestErrorMiddleware)
      .concat(baseAPI.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
