import { NavLinks } from "../../ts/component.types";
import { Pages } from "../../misc/access.enum";
import { FRONTEND_ROUTES } from "../../misc/enum";
import { PAGE_TITLE } from "../../misc/constants";

export const sidebarLinks: NavLinks[] = [
  {
    path: FRONTEND_ROUTES.DASHBOARD,
    icon: "dashboard-icon",
    title: PAGE_TITLE.DASHBOARD,
    access: Pages.Dashboard,
  },
  {
    path: FRONTEND_ROUTES.VEHICLE,
    icon: "vehicle-icon",
    title: PAGE_TITLE.INVENTORY,
    access: Pages.Vehicle,
  },
  {
    path: FRONTEND_ROUTES.TRANSPORT,
    icon: "transporter-icon",
    title: PAGE_TITLE.TRANSPORTATION,
    access: Pages.Transportation,
  },
  {
    path: FRONTEND_ROUTES.AUCTION,
    icon: "auction-icon",
    title: PAGE_TITLE.AUCTION,
    access: Pages.Auction,
  },
  {
    path: FRONTEND_ROUTES.ARBITRATION,
    icon: "arbitration-icon",
    title: PAGE_TITLE.ARBITRATION,
    access: Pages.Arbitration,
  },
  {
    path: FRONTEND_ROUTES.ROLES,
    icon: "groups-icon",
    title: PAGE_TITLE.ROLES,
    access: Pages.Roles,
  },
  {
    path: FRONTEND_ROUTES.USER,
    icon: "user-icon",
    title: PAGE_TITLE.USERS,
    access: Pages.User,
  },
  {
    path: FRONTEND_ROUTES.STAKEHOLDER,
    icon: "stakeholder-icon",
    title: PAGE_TITLE.STAKEHOLDERS,
    access: Pages.Stakeholder,
  },
  {
    path: FRONTEND_ROUTES.AUDIT_TRAIL,
    icon: "audit-trail-icon",
    title: PAGE_TITLE.AUDIT_TRAILS,
    access: Pages.AuditTrail,
  },
];

export const bottomLinks: NavLinks[] = [
  {
    path: FRONTEND_ROUTES.SETTING,
    icon: "setting-icon",
    title: PAGE_TITLE.SETTINGS,
    access: Pages.Setting,
  },

  {
    path: FRONTEND_ROUTES.LOGIN,
    icon: "logout-icon",
    title: PAGE_TITLE.LOGOUT,
    access: Pages.Logout,
  },
];
