import { API_ENDPOINT } from "../../misc/enum";
import {
  CreateTransporterUserPayload,
  UpdateTransporterUserPayload,
  DeleteTransporterUserPayload,
} from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const transporterUserSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getTransporterUsers: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.USER}?${params}`,
      }),
      providesTags: ["TransporterUser"],
    }),
    createTransporterUser: builder.mutation({
      query: (payload: CreateTransporterUserPayload) => ({
        method: "POST",
        url: `${API_ENDPOINT.TRANSPORTER_USER}`,
        body: payload,
      }),
      invalidatesTags: ["TransporterUser"],
    }),
    updateTransporterUser: builder.mutation({
      query: ({id,payload}: UpdateTransporterUserPayload) => ({
        url: `${API_ENDPOINT.TRANSPORTER_USER}/${id}`,
        method: "PUT",
        body: {
          ...payload
        },
      }),
      invalidatesTags: ["TransporterUser", "Transporter"],
    }),
    deleteTransporterUser: builder.mutation({
      query: ({ id }: DeleteTransporterUserPayload) => ({
        url: `${API_ENDPOINT.TRANSPORTER_USER}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetTransporterUsersQuery,
  useCreateTransporterUserMutation,
  useUpdateTransporterUserMutation,
  useDeleteTransporterUserMutation,
} = transporterUserSlice;
