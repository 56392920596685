import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import ButtonContainer from "../components/ButtonContainer";
import TableComponent from "../components/TableComponent";
import { userColumns } from "../misc/createData";
import {
  DATE_FORMAT,
  DATE_TYPE,
  MODAL_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
  SIZES,
  USER_TYPE_NAME,
} from "../misc/constants";

import { toggleModal } from "../store/reducer/modal";

import { useLazyGetSpectrumUsersQuery } from "./../store/reducer/spectrumUser";

import { userCreateData } from "../misc/createData";
import { UserTypes } from "../misc/enum";
import { appendQueryParams } from "../misc/api.utils";
import { useAppSelector } from "../hooks/redux-toolkit";
import {
  checkBoolean,
  formatDate,
  getRowData,
  getEditModalValues,
  prioritizeAndMergeObject,
} from "../misc/utils";
import { useReactToPrint } from "react-to-print";
import { Actions, Tabs } from "../misc/access.enum";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { MESSAGES } from "../misc/messages";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";

const Users = () => {
  const reference = useRef<HTMLTableElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.USERS,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const dispatch = useDispatch();
  const errorDispatcher = useErrorDispatcher();
  const { permissions } = useAppSelector((state) => state.authorization);

  const [getSpectrumUsers, { data: userStateData }] =
    useLazyGetSpectrumUsersQuery();
  const [userRows, setUserRows] = useState<[] | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    activeStatus: "active",
    userRole: UserTypes.SuperAdmin.toString(),
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    contactNo: "",
  });

  const handleExport = () => {
    const usersData = userStateData?.data?.users;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.UserTab,
      usersData
    );
    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Users (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const handleClick = () => {
    dispatch(
      toggleModal({
        open: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.USER,
      })
    );
  };

  const buttons = [
    {
      label: "Add New User",
      color: "primary",
      onClick: handleClick,
      size: SIZES.SMALL,
      access: checkBoolean(permissions?.[Actions.AddAdminUser]),
    },
  ];

  const mapDataToTableRows = (users: any) => {
    return users.map((user: any) => {
      return userCreateData(
        user?.email,
        user?.first_name,
        user?.last_name,
        user?.country_code + user?.phone_number,
        user?.id,
        USER_TYPE_NAME[user?.role_id as UserTypes],
        user?.is_active,
        !checkBoolean(permissions?.[Actions.UpdateAdminUser])
      );
    });
  };

  async function fetchData(queryParams: DynamicKeyValuesWithNumArray) {
    let role_ids = [];

    if (queryParams.userRole?.toString() === UserTypes.SuperAdmin?.toString()) {
      role_ids.push(UserTypes.SuperAdmin);
    } else if (
      queryParams.userRole?.toString() === UserTypes.AuctionAdmin?.toString()
    ) {
      role_ids.push(UserTypes.AuctionAdmin);
    } else if (
      queryParams.userRole?.toString() === UserTypes.InventoryAdmin?.toString()
    ) {
      role_ids.push(UserTypes.InventoryAdmin);
    } else {
      role_ids.push(UserTypes.AuctionAdmin);
      role_ids.push(UserTypes.InventoryAdmin);
      role_ids.push(UserTypes.SuperAdmin);
    }

    let apiParams = ``;

    const queryParamObj: DynamicKeyValuesWithNumArray = {
      page: (queryParams.page as number) + 1,
      limit: queryParams.rowsPerPage as number,
      isActive: queryParams.activeStatus as string,
      id: queryParams.id as string,
      contactNo: queryParams.contactNo as string,
      firstName: queryParams.firstName as string,
      lastName: queryParams.lastName as string,
      email: queryParams.email as string,
      userRole: role_ids,
    };

    apiParams += appendQueryParams(queryParamObj, false);

    try {
      const response = await getSpectrumUsers(apiParams);
      const data = response?.data?.data;
      const users = data?.users;
      const count = data?.count;

      if (!users) {
        setUserRows([]);
        return;
      }
      const mappedUsers = mapDataToTableRows(users);
      setUserRows(mappedUsers);
      setTotalRowsCount(count);
    } catch (error) {}
  }

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); // eslint-disable-line

  useEffect(() => {
    let users, count;

    users = userStateData?.data?.users;
    if (users) {
      count = userStateData.data.count;
      const usersData = mapDataToTableRows(users);
      setUserRows(usersData);
      setTotalRowsCount(count);
    }
  }, [userStateData]); //eslint-disable-line

  const applyFilter = (values: {
    [key: string]: number | boolean | string;
  }) => {
    setUserRows(null);
    const prioritizedQueryParams = prioritizeAndMergeObject(
      values,
      queryParams,
      [
        "page",
        "rowsPerPage",
        "activeStatus",
        "userRole",
        "id",
        "email",
        "firstName",
        "lastName",
        "contactNo",
      ]
    );

    setQueryParams({
      ...queryParams,
      ...prioritizedQueryParams,
    });
  };

  // Handle Edit Modal
  const onButtonClick = async (row: any) => {
    let selected_user = getRowData(row.id, userStateData?.data?.users);
    dispatch(
      toggleModal({
        open: true,
        edit: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.USER,
        data: {
          modalValues: getEditModalValues(MODAL_TYPE.USER, selected_user),
        },
      })
    );
  };

  return (
    <Box>
      <ButtonContainer
        buttons={buttons}
        hideRightSearchField={true}
        handlePrint={handlePrint}
        handleExport={handleExport}
        disableButtons={userRows ? userRows?.length === 0 : true}
      />
      <TableComponent
        reference={reference}
        rows={userRows}
        columns={userColumns}
        applyFilter={applyFilter}
        rowCount={totalRowsCount}
        queryParams={queryParams}
        onButtonClick={onButtonClick}
      />
    </Box>
  );
};

export default Users;
