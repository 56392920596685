export const MESSAGES = {
  AUTH: {
    LOGIN_SUCCESS: "User Logged In Successfully",
    PASSWORD_RESET_LINK_SUCCESS: "Password reset link sent to your email",
    PASSWORD_UPDATE_SUCCESS: "Password updated successfully",
    TOKEN_INVALID: "Token is invalid",
  },
  DASHBOARD: {},
  VEHICLE: {
    CREATE_VEHICLE_SUCCESS: "Vehicle created successfully",
    UPDATE_VEHICLE_SUCCESS: "Vehicle updated successfully",
    ENTER_LANE_AND_RUN:
      "Please enter lane and run numbers of selected vehicles",
    INVALID_LANE_RUN_TYPES: "Only whole numbers are allowed for Lane and Run!",
    SELECT_FOR_TR: "Select a vehicle to request transport",
    SELECT_FOR_CR: "Select a vehicle to request condition report",
    CR_REQUEST_SUCCESS: "Condition report request sent successfully",
    PSI_REQUEST_SUCCESS: "PSI request submitted successfully",
    ARBITRATION_REQUEST_SUCCESS: "Arbitration case open successfully",
    VEHICLE_REMOVED_SUCCESS: "Vehicle removed successfully",
    OVE_STATUS_UPDATE: "OVE status updated successfully",
    INVALID_VIN: "Incorrect VIN, please recheck and try again!",
    INVALID_VIN_LENGTH: "Incorrect VIN length, please enter 17 characters!",
    MOVED_TO_INVENTORY_SUCCESS: "Vehicle moved to inventory successfully",
  },
  AUCTION: {
    CREATE_AUCTION_SUCCESS: "Auction created successfully",
    UPDATE_AUCTION_SUCCESS: "Auction updated successfully",
    OFFER_ACCEPT_SUCCESS: "Offer Accepted successfully",
    OFFER_SUBMIT_SUCCESS: "Offer submitted successfully",
    COUNTER_OFFER_SUBMIT_SUCCESS: "Counter Offer submitted successfully",
    BUMP_OFFER_SUBMIT_SUCCESS: "BUMP Offer submitted successfully",
    LESS_OFFER_ERROR: "Accept offer cannot be less than offer",
    GREATER_OFFER_ERROR: "Accept offer cannot be greater than offer",
    BUMP_EQUAL_OFFER_ERROR: "Bump Offer must be equal to offer",
    COUNTER_EQUAL_OFFER_ERROR: "Counter Offer must be greater than offer",
    OFFER_MUST_BE_GREATER_THAN_EQUAL_BUMP:
      "Offer must be greater than or equal to BUMP Offer",
    PULLBACK_VEHICLE_SUCCESS: "Vehicle pull back from auction successfully",
    VEHICLE_UPDATE_REQUEST_SUCCESS:
      "Vehicle update request status changed successfully",
    NO_VALUE_PROVIDED: "No Value Provided",
    CANNOT_PERFORM_ACTION: "Can't perform any action before offer",
    LESS_THAN_ONE: "Offer value can't be less than one",
    AUCTION_FINISH: "Auction marked as finished",
    LANE_AND_RUN_UPDATED: "Lane and Run updated successfully",
    LANE_AND_RUN_ERROR: "Lane and Run can't be updated of any vehicle",
  },
  ARBITRATION: {
    ARBITRATION_UPDATE_SUCCESS: "Arbitration updated successfully",
    ADJUSTMENT_AMOUNT_ERROR: "Adjustment amount must be less than sold price",
    ARBITRATION_STATUS_UPDATE_SUCCESS:
      "Arbitration status updated successfully",
  },
  USER: {
    CREATE_TRANSPORTER_USER_SUCCESS: "Transporter User created successfully",
    UPDATE_TRANSPORTER_USER_SUCCESS: "Transporter User updated successfully",
    CREATE_DEALER_USER_SUCCESS: "Dealer User created successfully",
    UPDATE_DEALER_USER_SUCCESS: "Dealer User updated successfully",
    CREATE_USER_SUCCESS: "User created successfully",
    UPDATE_USER_SUCCESS: "User updated successfully",
    USERNAME_EXISTS_ERROR: "Username already exists",
  },
  STAKEHOLDER: {
    CREATE_DEALER_SUCCESS: "Dealer created successfully",
    UPDATE_DEALER_SUCCESS: "Dealer updated successfully",
    CREATE_TRANSPORTER_SUCCESS: "Transporter created successfully",
    UPDATE_TRANSPORTER_SUCCESS: "Transporter updated successfully",
  },
  TRANSPORTER: {
    SELECT_TRANSPORTER: "Select a Transporter",
    REQUEST_FOR_TRANSPORT_SUCCESS:
      "Request for transport submitted successfully",
    ACCEPT_TRANSPORT_REQUEST_SUCCESS: "Transport request accepted successfully",
    ALTERNATE_DATE_REQUEST_SENT: "Alternate date request successfully sent",
    ACCEPT_ALTERNATE_DATE_SUCCESS: "Alternate date accepted successfully",
    VEHICLE_PICKED_SUCCESS: "Vehicle picked up successfully",
    VEHICLE_DROPPED_SUCCESS: "Vehicle dropped off successfully",
    ASSIGN_TRANSPORTER_SUCCESS: "Transporter assigned successfully",
  },
  AUDIT_TRAIL: {},
  SETTING: {
    CREATE_REMARK_SUCCESS: "Sub Announcement created successfully",
    UPDATE_REMARK_SUCCESS: "Sub Announcement updated successfully",
    CREATE_LOCATION_SUCCESS: "Location created successfully",
    UPDATE_LOCATION_SUCCESS: "Location updated successfully",
    CREATE_ANNOUNCEMENT_SUCCESS: "Announcement created successfully",
    UPDATE_ANNOUNCEMENT_SUCCESS: "Announcement updated successfully",
  },
  VIN_MODAL: {
    CR_GRADE_UPDATE_SUCCESS: "CR grade updated successfully",
    PSI_UPDATE_SUCCESS: "PSI status updated successfully",
    TITLE_UPDATE_SUCCESS: "Title status updated successfully",
    MOVE_TO_SECOND_CHANCE_SUCCESS: "Vehicle moved to second chance auction",
    MOVE_TO_RERUN_SUCCESS: "Vehicle moved to rerun auction",
    MOVE_TO_OVE_EVENT_SUCCESS: "Vehicle moved to OVE event",
  },
  API: {
    SUCCESS: "Success",
    ERROR_OCCURED: "Error Occured!",
    INTERNET_CONNECTED: "Internet connection restored!",
    INTERNET_DISCONNECTED: "You're currently offline!",
  },
  MISC: {
    RECAPTCHA: "Security check! solve reCAPTCHA.",
    EXPORT_EXCEL_ERROR: "Error occurred, export report failed!",
    INVALID_FILE_FORMAT: "Invalid File Type, Upload CSV file only!",
  },
};
