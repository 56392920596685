import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box/Box";
import TransportationTable from "../components/ListingTableComponent";
import { unssignedTransportationCreateData } from "../misc/createData";
import {
  useLazyGetUnassignedTransportRequestsQuery,
  useAcceptAlternateTransportRequestMutation,
  useAcceptTransportRequestMutation,
} from "../store/reducer/transportRequest";
import { CellRow, SelectOption } from "../ts/component.types";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { appendQueryParams } from "../misc/api.utils";
import {
  ACTION_BUTTON_TYPES,
  ADMIN_ROLES,
  DATE_FORMAT,
  DATE_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
  SELECT_TYPES,
} from "../misc/constants";
import { useAppDispatch, useAppSelector } from "../hooks/redux-toolkit";
import { useLazyGetTransportersQuery } from "../store/reducer/transporter";
import { toggleProgressBar } from "../store/reducer/progressBar";
import { checkBoolean, formatDate, hasAccess } from "../misc/utils";
import { TransportationRequest, UserTypes } from "../misc/enum";
import { MESSAGES } from "../misc/messages";
import ButtonContainer from "../components/ButtonContainer";
import { useReactToPrint } from "react-to-print";
import { Actions, Tabs } from "../misc/access.enum";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { useSuccessDispatcher } from "../hooks/useSuccessDispatcher";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";

type QueryParamType = {
  page: number;
  rowsPerPage: number;
  activeStatus: string;
  vin: string;
};

const UnassignedTransportation = () => {
  const reference = useRef<HTMLTableSectionElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.UNASSIGNED_TRANSPORTATION,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const dispatch = useAppDispatch();
  const successDispatcher = useSuccessDispatcher();
  const errorDispatcher = useErrorDispatcher();
  const { role, permissions } = useAppSelector((state) => state.authorization);
  const [getTransporters, { data: transporterStateData }] =
    useLazyGetTransportersQuery();
  const [getTransportRequests, { data: transportRequestStateData }] =
    useLazyGetUnassignedTransportRequestsQuery();
  const [acceptAlternateTransport] =
    useAcceptAlternateTransportRequestMutation();
  const [acceptTransportRequest] = useAcceptTransportRequestMutation();
  const [transportRequestRows, setTransportRequestRows] = useState<Array<{
    id: string;
    conditionReportId?: string;
    data: CellRow[][];
  }> | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    activeStatus: "active",
    vin: "",
    page: NUMBER.ZERO,
    rowsPerPage: 10,
  });
  const [selectedRow, setSelectedRow] = useState<
    Array<{
      transportRequestId: string;
      selectedId: string;
      selectType: string;
    }>
  >([]);

  const inputRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);

  const handleExport = () => {
    const transportationData =
      transportRequestStateData?.data?.transportRequests;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.UnAssignedTTransporterTab,
      transportationData
    );
    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Unassigned Transportation (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const mapDataToTableRows = (
    transportRequests: any,
    transporters: Array<SelectOption>,
    selectType: string = "",
    transportRequestId: string = "",
    selectedId: string = ""
  ) => {
    return transportRequests.map((transportRequest: any) => {
      let alternateDate: Array<SelectOption> = [];
      let alternateDateId = "";
      let transporterId = "";

      if (transportRequest?.alternate_date_request?.length) {
        transportRequest?.alternate_date_request?.forEach((request: any) => {
          const date = formatDate(
            request?.alternate_date_time,
            DATE_TYPE.UTC_DATE_STRING,
            DATE_FORMAT.DD_MM_YYYY
          );
          alternateDate.push({
            label: `${date} - ${request?.transporter?.name || "N/A"}`,
            value: request?.id,
          });
        });
      }

      if (selectType) {
        let selectedIdToAssign = selectedId;
        if (
          transportRequest?.id?.toString() !== transportRequestId?.toString()
        ) {
          const currentTransportRequest = selectedRow?.find((selected) => {
            return (
              selected?.transportRequestId?.toString() ===
              transportRequest?.id?.toString()
            );
          });
          if (currentTransportRequest) {
            selectedIdToAssign = currentTransportRequest?.selectedId;
            if (
              currentTransportRequest?.selectType === SELECT_TYPES.TRANSPORTER
            )
              transporterId = selectedIdToAssign;
            else if (
              currentTransportRequest?.selectType ===
              SELECT_TYPES.ALTERNATE_DATE
            )
              alternateDateId = selectedIdToAssign;
          }
        } else {
          if (selectType === SELECT_TYPES.TRANSPORTER)
            transporterId = selectedIdToAssign;
          else if (selectType === SELECT_TYPES.ALTERNATE_DATE)
            alternateDateId = selectedIdToAssign;
        }
      }

      const pickupDate = formatDate(
        transportRequest?.pickup_date_time,
        DATE_TYPE.UTC_DATE_STRING,
        DATE_FORMAT.DD_MM_YYYY
      );

      return unssignedTransportationCreateData(
        transportRequest?.id,
        transportRequest?.vehicle?.vin,
        false,
        transportRequest?.vehicle?.make,
        transportRequest?.vehicle?.model,
        transportRequest?.vehicle?.year,
        transportRequest?.vehicle?.dealer?.name,
        transportRequest?.vehicle?.location?.name,
        transportRequest?.pickup_location?.name,
        pickupDate,
        transportRequest?.dropoff_location?.name,
        TransportationRequest[TransportationRequest.Pending],
        alternateDate,
        !alternateDate?.length && !transporters ? false : true,
        transporters,
        transporterId,
        alternateDateId,
        !checkBoolean(permissions?.[Actions.AcceptTransportRequest]) ||
          !transporterId,
        !checkBoolean(permissions?.[Actions.AcceptAlternateDateRequest]) ||
          !alternateDateId
      );
    });
  };

  const fetchData = async (queryParams: QueryParamType) => {
    let apiParams = `limit=${queryParams.rowsPerPage}&page=${
      queryParams.page + 1
    }${queryParams.vin && `&vin=${queryParams.vin}`}&is_active=${
      queryParams.activeStatus === "active" ? true : false
    }`;

    const queryParamObj: DynamicKeyValuesWithNumArray = {};

    apiParams += appendQueryParams(queryParamObj, true);

    try {
      const response: any = await getTransportRequests(apiParams);

      const data = response?.data?.data;
      const transportRequests = data?.transportRequests;
      const transporters = transporterStateData?.data?.transporters;
      const count = data?.count;

      if (!transportRequests) {
        setTransportRequestRows([]);
        return;
      }

      const transporterOptions = transporters?.map((transporter: any) => {
        return {
          label: transporter?.name,
          value: transporter?.id,
        };
      });

      const rows = mapDataToTableRows(transportRequests, transporterOptions);
      setTransportRequestRows(rows);
      setTotalRowsCount(count);
    } catch (error) {}
  };

  useEffect(() => {
    const transportRequests =
      transportRequestStateData?.data?.transportRequests;
    const transporters = transporterStateData?.data?.transporters;

    if (transportRequests) {
      const transporterOptions = transporters?.map((transporter: any) => {
        return {
          label: transporter?.name,
          value: transporter?.id,
        };
      });

      const count = transportRequestStateData?.data?.count;
      const rows = mapDataToTableRows(transportRequests, transporterOptions);
      setTransportRequestRows(rows);
      setTotalRowsCount(count);
    }
  }, [transportRequestStateData, transporterStateData]); //eslint-disable-line

  useEffect(() => {
    (async () => {
      if (hasAccess(ADMIN_ROLES, role as UserTypes)) {
        await getTransporters("");
      }
    })();
  }, []); //eslint-disable-line

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams, transporterStateData]); //eslint-disable-line

  const applyFilter = (values: DynamicKeyValuesWithNumArray) => {
    setTransportRequestRows(null);
    setQueryParams({
      ...queryParams,
      vin: "vin" in values ? (values?.vin as string) : "",
      activeStatus:
        (values?.activeStatus as string) || queryParams.activeStatus,
      page: values?.page as number,
      rowsPerPage: (values?.rowsPerPage as number) || queryParams.rowsPerPage,
    });
  };

  const handleSelect = (
    selectType: string,
    transportRequestId: number,
    selectedId: number
  ) => {
    const transporters = transporterStateData?.data?.transporters;
    const transportRequests =
      transportRequestStateData?.data?.transportRequests;

    if (transportRequests) {
      const transporterOptions = transporters?.map((transporter: any) => {
        return {
          label: transporter?.name,
          value: transporter?.id,
        };
      });

      const count = transportRequestStateData?.data?.count;

      const rows = mapDataToTableRows(
        transportRequests,
        transporterOptions,
        selectType,
        transportRequestId?.toString(),
        selectedId?.toString()
      );

      let find = false;
      const modifiedState = selectedRow?.map((selected) => {
        if (selected?.transportRequestId === transportRequestId?.toString()) {
          find = true;
          return {
            ...selected,
            selectedId: selectedId?.toString(),
          };
        }
        return selected;
      });

      if (!find)
        modifiedState.push({
          transportRequestId: transportRequestId?.toString(),
          selectedId: selectedId?.toString(),
          selectType,
        });

      setSelectedRow(modifiedState);
      setTransportRequestRows(rows);
      setTotalRowsCount(count);
    }
  };

  const handleSubmit = async (type: string, transportRequestId: number) => {
    dispatch(toggleProgressBar(true));
    const transportRequest = selectedRow?.find(
      (selectedRow) =>
        selectedRow?.transportRequestId?.toString() ===
        transportRequestId?.toString()
    );
    let successMessage = "";
    let response: any;
    if (
      type === ACTION_BUTTON_TYPES.ACCEPT_ALTERNATE_DATE &&
      transportRequest
    ) {
      response = await acceptAlternateTransport(transportRequest?.selectedId);
      successMessage = MESSAGES.TRANSPORTER.ACCEPT_ALTERNATE_DATE_SUCCESS;
    } else if (
      type === ACTION_BUTTON_TYPES.ASSIGN_TRANPORT &&
      transportRequest
    ) {
      response = await acceptTransportRequest({
        id: transportRequest?.transportRequestId,
        payload: {
          transporter_id: Number(transportRequest?.selectedId),
        },
      });
      successMessage = MESSAGES.TRANSPORTER.ASSIGN_TRANSPORTER_SUCCESS;
    }
    dispatch(toggleProgressBar(false));

    if (successMessage && !response?.error)
      successDispatcher(true, successMessage);

    setSelectedRow([]);
  };

  return (
    <Box className="py-5">
      <ButtonContainer
        hideRightSearchField={false}
        handlePrint={handlePrint}
        handleExport={handleExport}
        inputRefs={inputRefs}
        handleVinSearch={() => {
          applyFilter({
            ...queryParams,
            vin: (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement)?.value,
            page: NUMBER.ZERO,
          });
        }}
        disableButtons={
          transportRequestRows ? transportRequestRows?.length === 0 : true
        }
      />
      <TransportationTable
        reference={reference}
        rows={transportRequestRows}
        rowCount={totalRowsCount}
        handleSelect={handleSelect}
        handleSubmit={handleSubmit}
        applyFilter={applyFilter}
        queryParams={queryParams}
      />
    </Box>
  );
};

export default UnassignedTransportation;
