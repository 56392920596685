import { API_ENDPOINT } from "../../misc/enum";
import { baseAPI } from "./baseSlice";

export const permissionSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getPermissions: builder.query({
      query: () => ({
        url: `${API_ENDPOINT.ROLE_PERMISSION}`,
      }),
    }),
  }),
});

export const { useLazyGetPermissionsQuery } = permissionSlice;
