import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ButtonContainer from "../components/ButtonContainer";
import { useAppDispatch, useAppSelector } from "../hooks/redux-toolkit";
import { toggleModal } from "../store/reducer/modal";
import { MODAL_TYPE, NUMBER, SIZES } from "../misc/constants";
import { useLazyGetRemarksQuery } from "../store/reducer/setting";
import Loader from "../components/Loader";
import CardComponent from "../components/CardComponent";
import { RemarkType } from "../ts/component.types";
import { checkBoolean } from "../misc/utils";
import { Actions } from "../misc/access.enum";

const Remarks = () => {
  const dispatch = useAppDispatch();
  const { permissions } = useAppSelector((state) => state.authorization);
  const [remarks, setRemarks] = useState<Array<RemarkType> | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [getRemarks, { data: remarksStateDate }] = useLazyGetRemarksQuery();

  const handleClick = () => {
    dispatch(
      toggleModal({
        open: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.ADD_REMARKS,
      })
    );
  };

  const buttons = [
    {
      label: "Add New Sub Announcement",
      color: "primary",
      onClick: handleClick,
      size: SIZES.SMALL,
      access: checkBoolean(permissions?.[Actions.AddRemark]),
    },
  ];

  const mapDataToRows = (remarks: any) => {
    return remarks.map((remark: any) => {
      return {
        label: remark?.label,
        id: remark?.id,
        isActive: !remark?.is_deleted,
      };
    });
  };

  useEffect(() => {
    let remarks, count;

    remarks = remarksStateDate?.data?.remarks;
    count = remarks ? remarksStateDate.data.count : NUMBER.ZERO;
    const remarksData = remarks ? mapDataToRows(remarks) : [];
    setRemarks(remarksData);
    setTotalRowsCount(count);
  }, [remarksStateDate]); //eslint-disable-line

  useEffect(() => {
    (async () => {
      await getRemarks("");
    })();
  }, []); //eslint-disable-line

  const handleEdit = (remark: RemarkType) => {
    dispatch(
      toggleModal({
        open: true,
        edit: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.ADD_REMARKS,
        data: {
          modalValues: { ...remark },
        },
      })
    );
  };

  return (
    <Box>
      <ButtonContainer
        buttons={buttons}
        hideRightSearchField={true}
        hideReportButtons={true}
      />
      {!remarks ? (
        <Loader />
      ) : (
        <Box>
          <Box className="flex items-center">
            Total Sub Announcements :{" "}
            <Box className="font-bold ml-1">{totalRowsCount}</Box>
          </Box>
          <Box className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 my-4">
            {remarks?.map((remark) => {
              return (
                <CardComponent
                  title={remark?.label}
                  subtitle={remark?.id}
                  isActive={remark?.isActive}
                  showStatus={true}
                  showEditIcon={checkBoolean(
                    permissions?.[Actions.UpdateRemark]
                  )}
                  editClickListener={() => handleEdit(remark)}
                  subtitleClassName="text-6xl mt-3"
                />
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Remarks;
