import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import ButtonContainer from "../components/ButtonContainer";
import TableComponent from "../components/TableComponent";
import { transporterColumns, transporterCreateData } from "../misc/createData";
import {
  DATE_FORMAT,
  DATE_TYPE,
  MODAL_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
  SIZES,
} from "../misc/constants";
import { toggleModal } from "../store/reducer/modal";
import { useLazyGetTransportersQuery } from "../store/reducer/transporter";
import { appendQueryParams } from "../misc/api.utils";
import {
  checkBoolean,
  formatDate,
  getRowData,
  prioritizeAndMergeObject,
  getEditModalValues,
} from "../misc/utils";
import { useAppSelector } from "../hooks/redux-toolkit";
import { useReactToPrint } from "react-to-print";
import { Actions, Tabs } from "../misc/access.enum";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { MESSAGES } from "../misc/messages";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";

const Transporter = () => {
  const reference = useRef<HTMLTableElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.TRANSPORTERS,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const dispatch = useDispatch();
  const errorDispatcher = useErrorDispatcher();
  const { permissions } = useAppSelector((state) => state.authorization);

  const [getTransporters, { data: transporterStateData }] =
    useLazyGetTransportersQuery();

  const [transporterRows, setTransporterRows] = useState<[] | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    activeStatus: "active",
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    id: "",
    transporterName: "",
    email: "",
    firstName: "",
    lastName: "",
    contactNo: "",
  });

  const handleExport = () => {
    const usersData = transporterStateData?.data?.transporters;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.TransportersTab,
      usersData
    );
    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Transporters (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const handleTransporter = () => {
    dispatch(
      toggleModal({
        open: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.TRANSPORTER,
      })
    );
  };

  const buttons = [
    {
      label: "Add New Transporter",
      color: "primary",
      onClick: handleTransporter,
      size: SIZES.SMALL,
      access: checkBoolean(permissions?.[Actions.AddTransporter]),
    },
  ];

  const mapDataToTableRows = (users: any) => {
    return users.map((user: any) => {
      return transporterCreateData(
        user?.id,
        user?.name,
        user?.transporter_user?.[NUMBER.ZERO]?.user?.email || "N/A",
        user?.transporter_user?.[NUMBER.ZERO]?.user?.first_name || "N/A",
        user?.transporter_user?.[NUMBER.ZERO]?.user?.last_name || "N/A",
        user?.transporter_user?.[NUMBER.ZERO]?.user?.country_code +
          user?.transporter_user?.[NUMBER.ZERO]?.user?.phone_number || "N/A",
        user?.is_active,
        !checkBoolean(permissions?.[Actions.UpdateTransporter])
      );
    });
  };

  async function fetchData(queryParams: DynamicKeyValuesWithNumArray) {
    let apiParams = ``;

    const queryParamObj = {
      page: (queryParams.page as number) + 1,
      limit: queryParams.rowsPerPage as number,
      isActive: queryParams.activeStatus as string,
      id: queryParams.id as string,
      contactNo: queryParams.contactNo as string,
      firstName: queryParams.firstName as string,
      lastName: queryParams.lastName as string,
      email: queryParams.email as string,
      transporterName: queryParams.transporterName as string,
    };

    apiParams += appendQueryParams(queryParamObj, false);

    try {
      const response = await getTransporters(apiParams);

      const data = response?.data?.data;
      const users = data?.transporters;
      const count = data?.count;

      if (!users) {
        setTransporterRows([]);

        return;
      }

      const mappedUsers = mapDataToTableRows(users);

      setTransporterRows(mappedUsers);

      setTotalRowsCount(count);
    } catch (error) {}
  }

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); //eslint-disable-line

  useEffect(() => {
    let users, count;

    users = transporterStateData?.data?.transporters;
    if (users) {
      count = transporterStateData.data.count;
      const usersData = mapDataToTableRows(users);
      setTransporterRows(usersData);
      setTotalRowsCount(count);
    }
  }, [transporterStateData]); //eslint-disable-line

  const applyFilter = (values: {
    [key: string]: number | boolean | string;
  }) => {
    setTransporterRows(null);

    const prioritizedQueryParams = prioritizeAndMergeObject(
      values,
      queryParams,
      [
        "id",
        "page",
        "rowsPerPage",
        "activeStatus",
        "transporterName",
        "email",
        "contactNo",
        "firstName",
        "lastName",
      ]
    );

    setQueryParams({
      ...queryParams,
      ...prioritizedQueryParams,
    });
  };

  const onButtonClick = async (row: any) => {
    let selected_transporter = getRowData(
      row.id,
      transporterStateData?.data?.transporters
    );
    dispatch(
      toggleModal({
        open: true,
        edit: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.TRANSPORTER,
        data: {
          modalValues: getEditModalValues(
            MODAL_TYPE.TRANSPORTER,
            selected_transporter
          ),
        },
      })
    );
  };

  return (
    <Box>
      <ButtonContainer
        buttons={buttons}
        hideRightSearchField={true}
        handlePrint={handlePrint}
        handleExport={handleExport}
        disableButtons={transporterRows ? transporterRows?.length === 0 : true}
      />
      <TableComponent
        reference={reference}
        rows={transporterRows}
        columns={transporterColumns}
        rowCount={totalRowsCount}
        applyFilter={applyFilter}
        queryParams={queryParams}
        onButtonClick={onButtonClick}
      />
    </Box>
  );
};

export default Transporter;
