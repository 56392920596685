import { API_ENDPOINT } from "../../misc/enum";
import {
  CreateDealerPayload,
  UpdateDealerPayload,
  DeleteDealerPayload,
} from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const dealerSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getDealers: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.DEALER}?${params}`,
      }),
      providesTags: ["Dealer"],
    }),
    getDealerById: builder.query({
      query: (id: string) => ({
        url: `${API_ENDPOINT.DEALER}/${id}`,
      }),
    }),
    createDealer: builder.mutation({
      query: (payload: CreateDealerPayload) => ({
        method: "POST",
        url: `${API_ENDPOINT.DEALER}`,
        body: payload,
      }),
      invalidatesTags: ["Dealer"],
    }),
    updateDealer: builder.mutation({
      query: ({ id, payload }: UpdateDealerPayload) => ({
        url: `${API_ENDPOINT.DEALER}/${id}`,
        method: "PUT",
        body: { ...payload },
      }),
      invalidatesTags: ["Dealer"],
    }),
    deleteDealer: builder.mutation({
      query: ({ id }: DeleteDealerPayload) => ({
        url: `${API_ENDPOINT.DEALER}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetDealersQuery,
  useLazyGetDealerByIdQuery,
  useCreateDealerMutation,
  useUpdateDealerMutation,
  useDeleteDealerMutation,
} = dealerSlice;
