import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { SelectComponentProps } from "../../ts/component.types";
import Box from "@mui/material/Box";
import { MULTI_SELECT_REASON, SIZES } from "../../misc/constants";

export default function MultiSelect({
  setFieldValue,
  label,
  value: selectedValues,
  placeholder,
  options,
  errors,
  hideErrorMessage = false,
  touched,
  className,
  variant = "outlined",
  name,
}: SelectComponentProps) {
  const filterValues = options?.filter((option) =>
    (selectedValues as Array<string>)?.includes(
      option?.value?.toString() as string
    )
  );

  return (
    <>
      {options ? (
        <Autocomplete
          multiple
          options={options}
          getOptionLabel={(option) => option?.label}
          disableCloseOnSelect
          value={filterValues}
          onChange={(event, values, reason) => {
            let updated: Array<string> = [];
            if (reason === MULTI_SELECT_REASON.SELECT_OPTION) {
              updated = selectedValues
                ? [...(selectedValues as Array<string>)]
                : [];
              updated.push(
                values?.[values?.length - 1]?.value?.toString() as string
              );
            } else if (reason === MULTI_SELECT_REASON.REMOVE_OPTION) {
              updated = values?.map(
                (value) => value.value?.toString() as string
              );
            }
            setFieldValue(name, updated);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={variant}
              label={label}
              placeholder={placeholder}
              name={name}
              size={SIZES.SMALL}
              className={className}
            />
          )}
        />
      ) : null}
      {!hideErrorMessage && (
        <Box
          className={`text-primary-500 ${
            errors && name && errors[name] && touched[name]
              ? "visible my-1"
              : "invisible my-0"
          } text-xs self-start`}
        >
          {errors && name && errors[name] && touched[name]
            ? errors[name]
            : "error"}
        </Box>
      )}
    </>
  );
}
