import { io, Socket } from "socket.io-client";
import { SocketEventName } from "../../misc/enum";

let socket: Socket | null;
export function getSocket(token: string): Socket {
  if (!socket) {
    socket = io(`${process.env.REACT_APP_BACKEND_BASE_URL}`, {
      query: {
        token,
      },
      path: "/socket",
      transports: ["websocket"],
    });
  }
  return socket;
}

export const deActivateSocket = (disconnect: boolean) => {
  if (socket) {
    socket.off(SocketEventName.NewNotification);
    socket.off(SocketEventName.ArbitrationMessage);
    socket.off(SocketEventName.ArbitrationMessageError);
    socket.off(SocketEventName.AuthorizeSuccess);
    socket.off(SocketEventName.AuthorizeError);
    if (disconnect) {
      socket.disconnect();
      socket = null;
    }
  }
};
