import { API_ENDPOINT } from "../../misc/enum";
import {
  OpenArbitrationPayload,
  UpdateArbitrationPayload,
  UpdateArbitrationStatusPayload,
} from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const arbitrationSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getArbitrations: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.ARBITRATION}?${params}`,
      }),
    }),
    openArbitration: builder.mutation({
      query: (body: OpenArbitrationPayload) => ({
        url: `${API_ENDPOINT.ARBITRATION}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    getArbitrationById: builder.query({
      query: (id: string) => ({
        url: `${API_ENDPOINT.ARBITRATION}/${id}`,
        method: "GET",
      }),
      providesTags: ["ArbitrationById"],
    }),
    updateArbitration: builder.mutation({
      query: ({ id, body }: UpdateArbitrationPayload) => ({
        url: `${API_ENDPOINT.ARBITRATION}/${id}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["ArbitrationById"],
    }),
    updateArbitrationStatus: builder.mutation({
      query: ({ id, body }: UpdateArbitrationStatusPayload) => ({
        url: `${API_ENDPOINT.ARBITRATION}/status/${id}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["ArbitrationById"],
    }),
  }),
});

export const {
  useLazyGetArbitrationsQuery,
  useOpenArbitrationMutation,
  useLazyGetArbitrationByIdQuery,
  useUpdateArbitrationMutation,
  useUpdateArbitrationStatusMutation,
} = arbitrationSlice;
