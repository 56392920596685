export enum EMAILS {
  DEALER = "dealer@spectrum.com",
  TRANSPORTER = "transporter@spectrum.com",
  ADMIN = "admin@spectrum.com",
}

export enum API_ENDPOINT {
  LOGIN = "/auth/login",
  LOGOUT = "/auth/logout",
  RESET_PASSWORD = "/auth/reset-password",
  UPDATE_PASSWORD = "/auth/update-password",
  DASHBOARD = "/dashboard",
  SPECTRUM_USER = "/admin",
  DEALER = "/dealer",
  DEALER_USER = "/dealer-user",
  TRANSPORTER = "/transporter",
  TRANSPORTER_USER = "/transporter-user",
  LOCATION = "/location",
  VEHICLE = "/vehicle",
  USER = "/user",
  AUCTION = "/auction",
  TRANSPORT_REQUEST = "/transport-request",
  SETTING = "/setting",
  ANNOUNCEMENT = "/announcement",
  ANNOUNCEMENTS = "/announcements",
  REMARK = "/remark",
  REMARKS = "/remarks",
  AUDIT_TRAIL = "/audit-trail",
  AUCTION_VEHICLE = "/auction-vehicle",
  ARBITRATION = "/arbitration",
  ARBITRATION_CHAT = "/arbitration-chat",
  ROLE_PERMISSION = "/role-permission",
  NOTIFICATION = "/notification",
}

export enum FRONTEND_ROUTES {
  ROOT = "/",
  DASHBOARD = "/",
  VEHICLE = "/vehicle",
  VEHICLE_INVENTORY = "/vehicle",
  VEHICLE_SOLD = "/vehicle/sold",
  VEHICLE_TRASH = "/vehicle/trash",
  AUCTION = "/auction",
  RERUN = "/auction/rerun",
  REQUEST_OVE = "/auction/ove",
  AUCTION_LIST = "/auction/list",
  SECOND_CHANCE = "/auction/second-chance",
  ARBITRATION = "/arbitration",
  ROLES = "/roles",
  USER = "/user",
  DEALER_USER = "/user/dealer",
  TRANSPORTER_USER = "/user/transporter",
  STAKEHOLDER = "/stakeholder",
  DEALER_STAKEHOLDER = "/stakeholder",
  TRANSPORTER_STAKEHOLDER = "/stakeholder/transporter",
  TRANSPORT = "/transport",
  TRANSPORT_UNASSIGNED = "/transport/unassigned",
  TRANSPORT_REQUEST = "/transport/request",
  TRANSPORT_SCHEDULED = "/transport/request/schedule",
  TRANSPORT_COMPLETED = "/transport/request/complete",
  SETTING = "/setting",
  SETTING_LOCATION = "/setting",
  SETTING_ANNOUNCEMENT = "/setting/announcement",
  SETTING_REMARK = "/setting/sub-announcement",
  AUDIT_TRAIL = "/audit-trail",
  AUTH = "/auth",
  LOGIN = "/auth/login",
  RESET_PASSWORD = "/auth/reset-password",
  CHANGE_PASSWORD = "/auth/change-password",
  KBB_REPORT = "/kbb-report",
}

export enum ADMIN_TYPES {
  SuperAdmin = 1,
  AuctionUser = 2,
  InventoryUser = 3,
}

export enum UserTypes {
  SuperAdmin = 1,
  AuctionAdmin = 2,
  InventoryAdmin = 3,
  DealerAdmin = 4,
  DealerAuctionUser = 5,
  DealerInventoryUser = 6,
  TransporterAdmin = 7,
  TransporterUser = 8,
}

export enum DealerType {
  Premium = 1,
  Normal = 2,
}

export enum VehicleAcquiredFrom {
  Trade = 1,
  Frontline = 2,
  Wholesale = 3,
}
export enum VehicleAnnouncements {
  Guaranteed = 1,
  Limited_Guaranteed = 2,
  As_Is = 3,
}

export enum VehicleStatus {
  Inventory = 1,
  TransportRequest = 2,
  InAuction = 3,
  Sold = 4,
  Removed = 5,
  OVEEventSale = 6,
  SecondChanceAuction = 7,
  RerunInAuction = 8,
}

export enum AuditType {
  CREATE = 1,
  UPDATE = 2,
  DELETE = 3,
}

export enum ConditionReportStatus {
  NotRequested = 0,
  Requested = 1,
  Fulfilled = 2,
}

export enum TransportRequestStatus {
  Requested = 1,
  Accepted = 2,
  Picked = 3,
  Dropped = 4,
}

export enum TransportationRequest {
  Pending = 1,
  Requested = 2,
  Accepted = 3,
}

export enum AuctionStatus {
  Scheduled = 1,
  Lock = 2,
  InProgress = 3,
  Completed = 4,
}

export enum AuctionStatusName {
  Scheduled = AuctionStatus.Scheduled,
  Lock = AuctionStatus.Lock,
  "In Progress" = AuctionStatus.InProgress,
  Completed = AuctionStatus.Completed,
}

export enum AuctionVehicleStatus {
  UnSold = 0,
  Sold = 1,
}

export enum TitleStatus {
  Pending = 1,
  Validated = 2,
  Exception = 3,
  Absent = 4,
}

export enum PSIStatus {
  Requested = 1,
  Passed = 2,
  Failed = 3,
  NotApplicable = 4,
}

export enum SocketEventName {
  AuthorizeError = "auth_error",
  AuthorizeSuccess = "auth_success",
  NewNotification = "new_notification",
  ArbitrationMessage = "arbitration_message",
  ArbitrationMessageError = "arbitration_message_error",
}

export enum NotificationType {
  DummyNotification = 0,
  // User Notifications
  NewUserAdded = 1,
  // Vehicles Notifications
  VehicleAdded = 5,
  VehicleUpdated = 4,
  VehicleRemovedFromLot = 6,
  VehicleTitlesUpdated = 7,
  VehicleAddedForSecondChance = 8,
  VehicleAddedForRerun = 9,
  VehicleAddedToAuctionFromInventory = 10,
  VehicleAddedToAuctionFromSecondChance = 11,
  VehicleAddedToAuctionFromRerun = 12,
  NewOVEEventVehicle = 15,
  OVEEventVehicleUpdated = 16,
  NewVehiclePSIRequest = 17,
  VehiclePSIRequestUpdated = 18,
  // Auctions Notifications
  NewAuctionCreated = 20,
  AuctionStatusUpdate = 21,
  // Auction Vehicle Notification
  AuctionVehicleAction = 25,
  VehicleRemovedFromAuction = 26,
  NewVehicleUpdateRequest = 27,
  VehicleUpdateRequestUpdated = 28,
  // Transport Requests Notifications
  NewTransportRequest = 30,
  TransportRequestAccepted = 31,
  NewAlternateDateRequest = 32,
  AlternateDateRequestAccepted = 33,
  VehiclePicked = 34,
  VehicleDropped = 35,
  // Arbitration Notifications
  ArbitrationCaseOpened = 40,
  ArbitrationCaseClosed = 41,
  ArbitrationCaseDetailsUpdated = 42,
}

export enum ArbitrationStatus {
  Open = 1,
  Withdraw = 2,
  Bought = 3,
}

export enum OVEEventSaleStatus {
  Pending = 0,
  Posted = 1,
}

export enum ArbitrationChatMessageSenderType {
  Admin = 1,
  Dealer = 2,
}

export enum VehicleUpdateRequestStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
}

export enum UNREAD_MESSAGE_STATE {
  EMPTY = 1,
  PENDING = 2,
  AVAILABLE = 3,
}

export enum AuctionSearchType {
  SELECT = 1,
  VIN = 2,
}

export enum OrderByDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum VehicleOrderColumn {
  CreatedAt = "created_at",
  UpdatedAt = "updated_at",
}

export enum AuctionVehicleOrderColumn {
  Lane = "lane",
  Run = "run",
}
