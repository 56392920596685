import { setNewMessages, setSuccess } from "../reducer/auth";
import { createBrowserHistory } from "history";
import {
  AuctionStatus,
  NotificationType,
  OrderByDirection,
  UserTypes,
  VehicleOrderColumn,
} from "../../misc/enum";
import { FRONTEND_ROUTES } from "../../misc/enum";
import { NUMBER, QUERIES, SNACKBAR_VARIANT } from "../../misc/constants";
import { getQueryParams, isSender } from "../../misc/utils";
import { current } from "@reduxjs/toolkit";
import { auctionVehicleSlice } from "../reducer/auctionVehicle";
import { arbitrationChatSlice } from "../reducer/arbitrationChat";
import { arbitrationSlice } from "../reducer/arbitration";

type EVENT_TYPE = {
  type: NotificationType;
  title: string;
  body: string;
  data: any;
  user_id: number;
};

const vehicleAddedToAuctionRoutes: Array<string> = [
  FRONTEND_ROUTES.VEHICLE_INVENTORY,
  FRONTEND_ROUTES.AUCTION,
];

const newVehicleAddedRoutes: Array<string> = [
  FRONTEND_ROUTES.VEHICLE_INVENTORY,
];

const vehicleUpdatedRoutes: Array<string> = [
  FRONTEND_ROUTES.VEHICLE_INVENTORY,
  FRONTEND_ROUTES.RERUN,
  FRONTEND_ROUTES.SECOND_CHANCE,
];

const vehicleRemovedRoutes: Array<string> = [
  FRONTEND_ROUTES.VEHICLE_INVENTORY,
  FRONTEND_ROUTES.VEHICLE_TRASH,
];

const vehicleAddedToRerunRoutes: Array<string> = [
  FRONTEND_ROUTES.VEHICLE_INVENTORY,
  FRONTEND_ROUTES.RERUN,
];

const vehicleAddedToSecondChanceRoutes: Array<string> = [
  FRONTEND_ROUTES.VEHICLE_INVENTORY,
  FRONTEND_ROUTES.SECOND_CHANCE,
];

const vehicleAddedToAuctionFromSecondChanceRoutes: Array<string> = [
  FRONTEND_ROUTES.AUCTION,
  FRONTEND_ROUTES.SECOND_CHANCE,
];

const vehicleAddedToAuctionFromRerunRoutes: Array<string> = [
  FRONTEND_ROUTES.RERUN,
  FRONTEND_ROUTES.AUCTION,
];

const vehicleAddedToOVERoutes: Array<string> = [
  FRONTEND_ROUTES.REQUEST_OVE,
  FRONTEND_ROUTES.VEHICLE_INVENTORY,
];

const OVEStatusUpdatedRoutes: Array<string> = [FRONTEND_ROUTES.REQUEST_OVE];

const newTransportRequestRoutes: Array<string> = [
  FRONTEND_ROUTES.TRANSPORT_REQUEST,
  FRONTEND_ROUTES.TRANSPORT_UNASSIGNED,
  FRONTEND_ROUTES.VEHICLE_INVENTORY,
];

const updateVehicleRequestRoutes: Array<string> = [FRONTEND_ROUTES.AUCTION];

const transportRequestAcceptedRoutes: Array<string> = [
  FRONTEND_ROUTES.TRANSPORT,
  FRONTEND_ROUTES.TRANSPORT_UNASSIGNED,
];

const newAlternateRequestRoutes: Array<string> = [
  FRONTEND_ROUTES.TRANSPORT_REQUEST,
  FRONTEND_ROUTES.TRANSPORT_UNASSIGNED,
];

const alternateRequestAcceptedRoutes: Array<string> = [
  ...newAlternateRequestRoutes,
  FRONTEND_ROUTES.TRANSPORT_SCHEDULED,
  FRONTEND_ROUTES.TRANSPORT,
];

const auctionStatusUpdateRoutes: Array<string> = [
  FRONTEND_ROUTES.AUCTION_LIST,
  FRONTEND_ROUTES.AUCTION,
];

const auctionCompletedRoutes: Array<string> = [
  FRONTEND_ROUTES.AUCTION,
  FRONTEND_ROUTES.VEHICLE_SOLD,
];

const vehiclePickedRoutes: Array<string> = [
  FRONTEND_ROUTES.TRANSPORT,
  FRONTEND_ROUTES.TRANSPORT_SCHEDULED,
];

const vehicleDroppedRoutes: Array<string> = [
  ...vehiclePickedRoutes,
  FRONTEND_ROUTES.TRANSPORT_COMPLETED,
  FRONTEND_ROUTES.VEHICLE_INVENTORY,
];

const arbitrationCaseRoutes: Array<string> = [FRONTEND_ROUTES.ARBITRATION];

export const handleEvents = async (
  event: EVENT_TYPE,
  stateQueries: any,
  dispatch: any
) => {
  const history = createBrowserHistory();

  let isSimpleNotification = true;

  // Socket events will be handle over here
  switch (event?.type) {
    case NotificationType.VehicleAdded:
      isSimpleNotification = !newVehicleAddedRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.VehicleUpdated:
      isSimpleNotification = !vehicleUpdatedRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.VehicleRemovedFromLot:
      isSimpleNotification = !vehicleRemovedRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.VehicleAddedForSecondChance:
      isSimpleNotification = !vehicleAddedToSecondChanceRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.VehicleAddedForRerun:
      isSimpleNotification = !vehicleAddedToRerunRoutes?.includes(
        history?.location?.pathname
      );
      break;

    case NotificationType.VehicleAddedToAuctionFromSecondChance:
      isSimpleNotification =
        !vehicleAddedToAuctionFromSecondChanceRoutes?.includes(
          history?.location?.pathname
        );
      break;
    case NotificationType.VehicleAddedToAuctionFromRerun:
      isSimpleNotification = !vehicleAddedToAuctionFromRerunRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.VehicleAddedToAuctionFromInventory:
      isSimpleNotification = !vehicleAddedToAuctionRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.VehicleRemovedFromAuction:
      isSimpleNotification = !vehicleAddedToAuctionRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.NewOVEEventVehicle:
      isSimpleNotification = !vehicleAddedToOVERoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.OVEEventVehicleUpdated:
      isSimpleNotification = !OVEStatusUpdatedRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.NewAuctionCreated:
      isSimpleNotification = !auctionStatusUpdateRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.NewTransportRequest:
      isSimpleNotification = !newTransportRequestRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.TransportRequestAccepted:
      isSimpleNotification = !transportRequestAcceptedRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.AuctionStatusUpdate:
      const auctionStatus = event?.data?.status;
      if (auctionStatus?.toString() === AuctionStatus.Completed?.toString()) {
        isSimpleNotification = !auctionCompletedRoutes?.includes(
          history?.location?.pathname
        );
      } else {
        isSimpleNotification = !auctionStatusUpdateRoutes?.includes(
          history?.location?.pathname
        );
      }
      break;
    case NotificationType.AuctionVehicleAction:
      await checkAndUpdateOffers(event, stateQueries, dispatch);
      break;
    case NotificationType.NewVehicleUpdateRequest:
      isSimpleNotification = !updateVehicleRequestRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.VehicleUpdateRequestUpdated:
      isSimpleNotification = !updateVehicleRequestRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.NewAlternateDateRequest:
      isSimpleNotification = !newAlternateRequestRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.AlternateDateRequestAccepted:
      isSimpleNotification = !alternateRequestAcceptedRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.VehiclePicked:
      isSimpleNotification = !vehiclePickedRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.VehicleDropped:
      isSimpleNotification = !vehicleDroppedRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.ArbitrationCaseOpened:
      await checkAndUpdateArbitration(event, stateQueries, dispatch);
      isSimpleNotification = !arbitrationCaseRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.ArbitrationCaseClosed:
      await checkAndUpdateArbitration(event, stateQueries, dispatch);
      isSimpleNotification = !arbitrationCaseRoutes?.includes(
        history?.location?.pathname
      );
      break;
    case NotificationType.ArbitrationCaseDetailsUpdated:
      await checkAndUpdateArbitration(event, stateQueries, dispatch);
      break;
    default:
      break;
  }

  await dispatch(
    setSuccess({
      success: true,
      successMessage: event?.body,
      variant: isSimpleNotification
        ? SNACKBAR_VARIANT.SUCCESS
        : SNACKBAR_VARIANT.RELOAD_BUTTON_SNACKBAR,
    })
  );
};

const checkAndUpdateOffers = async (
  event: EVENT_TYPE,
  stateQueries: any,
  dispatch: any
) => {
  const queries = getQueryParams(stateQueries)?.get(
    QUERIES.GET_AUCTION_VEHICLES
  );
  if (queries?.length) {
    const eventData = event?.data;
    for (const query of queries) {
      if (query?.includes(`page=${NUMBER.ONE}`)) {
        if (
          !query?.includes(`status=${AuctionStatus.Scheduled}`) &&
          !query?.includes(`status=${AuctionStatus.Lock}`) &&
          query?.includes(`direction=${OrderByDirection.DESC}`) &&
          query?.includes(`column=${VehicleOrderColumn.UpdatedAt}`)
        ) {
          await dispatch(
            auctionVehicleSlice.util.updateQueryData(
              QUERIES.GET_AUCTION_VEHICLES,
              query,
              (draft) => {
                const cachedDraft = { ...current(draft) };
                const cached = {
                  ...cachedDraft?.data,
                };
                const auctionVehicles = [...cached?.auction_vehicles];
                const foundAuctionVehicleIndex = auctionVehicles.findIndex(
                  (auctionVehicle: any) =>
                    auctionVehicle?.id?.toString() === eventData?.id?.toString()
                );
                let filtered;

                if (foundAuctionVehicleIndex !== NUMBER.MINUS_ONE) {
                  const foundAuctionVehicle = auctionVehicles.splice(
                    foundAuctionVehicleIndex,
                    NUMBER.ONE
                  )[NUMBER.ZERO];

                  const updatedFoundAuctionVehicle = [
                    {
                      ...foundAuctionVehicle,
                      ...eventData,
                    },
                  ];

                  filtered = updatedFoundAuctionVehicle.concat(auctionVehicles);
                } else {
                  const rowsPerPageValues = [
                    NUMBER.TEN,
                    NUMBER.TWENTY_FIVE,
                    NUMBER.HUNDRED,
                  ];
                  if (rowsPerPageValues?.includes(auctionVehicles.length)) {
                    auctionVehicles.pop();
                  }
                  filtered = [
                    {
                      ...eventData,
                    },
                  ].concat(auctionVehicles);
                }

                const row = {
                  ...cachedDraft,
                  data: {
                    ...cached,
                    auction_vehicles: filtered,
                  },
                };
                return row;
              }
            )
          );
        }
      }
    }
  }
};

export const handleArbitrationChat = async (
  event: any,
  stateQueries: any,
  dispatch: any,
  role: UserTypes
) => {
  const history = createBrowserHistory();
  const queries = getQueryParams(stateQueries)?.get(
    QUERIES.GET_ARBITRATION_CHAT
  );

  if (queries?.length) {
    for (const query of queries) {
      const arbitrationMessage = event?.arbitrationMessage;
      const arbitrationId = arbitrationMessage?.arbitration_id;

      if (query?.id?.toString() === arbitrationId?.toString()) {
        await dispatch(
          arbitrationChatSlice.util.updateQueryData(
            QUERIES.GET_ARBITRATION_CHAT,
            query,
            (draft) => {
              const cachedDraft = { ...current(draft) };
              const cached = {
                ...cachedDraft?.data,
              };
              const messages = [...cached?.messages];
              const updatedMessages = [arbitrationMessage].concat(messages);
              const isMessageSender = isSender(
                role,
                event?.arbitrationMessage?.sender_type
              );
              if (!isMessageSender) {
                const caseId = Number(
                  event?.arbitrationMessage?.arbitration_id
                );
                dispatch(setNewMessages({ status: true, caseId }));
              }
              const row = {
                ...cachedDraft,
                data: {
                  ...cached,
                  messages: updatedMessages,
                },
              };
              return row;
            }
          )
        );
      }
    }
  }

  const pattern = /^\/arbitration\/\d+$/;
  const isArbitrationPage = pattern.test(history?.location?.pathname);

  const isMessageSender = isSender(
    role,
    event?.arbitrationMessage?.sender_type
  );

  if (!isArbitrationPage && !isMessageSender) {
    await dispatch(
      setSuccess({
        success: true,
        successMessage: `Message recieved on Arbitration Case ID ${event?.arbitrationMessage?.arbitration_id}`,
        variant: SNACKBAR_VARIANT.SUCCESS,
      })
    );
  }
};

export const handleArbitrationCount = async (
  event: any,
  stateQueries: any,
  dispatch: any,
  role: UserTypes
) => {
  const queries = getQueryParams(stateQueries)?.get(QUERIES.GET_ARBITRATIONS);

  if (queries?.length) {
    for (const query of queries) {
      const arbitrationMessage = event?.arbitrationMessage;
      const arbitrationId = arbitrationMessage?.arbitration_id;

      await dispatch(
        arbitrationSlice.util.updateQueryData(
          QUERIES.GET_ARBITRATIONS,
          query,
          (draft) => {
            const cachedDraft = { ...current(draft) };
            const cached = {
              ...cachedDraft?.data,
            };

            const arbitrations = [...cached?.arbitrations];
            const modifiedArbitrations = arbitrations?.map((arbitration) => {
              if (arbitration?.id?.toString() === arbitrationId?.toString()) {
                const isMessageSender = isSender(
                  role,
                  event?.arbitrationMessage?.sender_type
                );
                if (!isMessageSender) {
                  return {
                    ...arbitration,
                    unread_count: Number(arbitration?.unread_count) + 1,
                  };
                }
              }

              return arbitration;
            });

            const row = {
              ...cachedDraft,
              data: {
                ...cached,
                arbitrations: modifiedArbitrations,
              },
            };
            return row;
          }
        )
      );
    }
  }
};

const checkAndUpdateArbitration = async (
  event: EVENT_TYPE,
  stateQueries: any,
  dispatch: any
) => {
  const queries = getQueryParams(stateQueries)?.get(
    QUERIES.GET_ARBITRATION_BY_ID
  );
  if (queries?.length) {
    const eventData = event?.data;
    for (const query of queries) {
      if (query?.toString() === eventData?.id?.toString()) {
        await dispatch(
          arbitrationSlice.util.updateQueryData(
            QUERIES.GET_ARBITRATION_BY_ID,
            query,
            (draft) => {
              const cachedDraft = { ...current(draft) };
              const updatedData = {
                ...eventData,
              };

              const row = {
                ...cachedDraft,
                data: {
                  ...updatedData,
                },
              };
              return row;
            }
          )
        );
      }
    }
  }
};
