import {
  VehicleOrderColumn,
  OrderByDirection,
  UserTypes,
  VehicleUpdateRequestStatus,
  FRONTEND_ROUTES,
  AuctionVehicleOrderColumn,
} from "./enum";

export const CONSTANTS = {
  PASSWORD: "password",
  TEXT: "text",
  INPUT: "input",
  SELECT: "select",
  MULTI_SELECT: "multi-select",
  CHECKBOX: "checkbox",
  DATEPICKER: "datepicker",
  DATE_RANGE_PICKER: "dateRangePicker",
  PHONE_NUMBER: "contactNo",
  INPUT_WITH_CHECKBOX: "inputWithCheckbox",
  OFFER_INPUT: "offer-input",
  BUTTON: "button",
  TEXTAREA: "textarea",
  TAG: "tag",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  QUATERNARY: "quaternary",
  ACTION: "action",
  SEARCH: "search",
  RESTART: "restart",
  DONE: "done",
  PRINT: "print",
  NEW_WINDOW: "new-window",
  CURRENCY_INPUT: "currency-input",
  SEND: "send",
  TICK: "tick",
  MULTIPLE_VALUES: "multiple-values",
};

export const SIZES: {
  SMALL: "small";
  LARGE: "large";
} = {
  SMALL: "small",
  LARGE: "large",
};

export const COLOR_CLASSES = {
  YELLOW: "color-yellow",
  CHARCOAL: "color-charcoal",
  RED: "color-red",
  GREEN: "color-green",
  PINK: "color-pink",
  DODGER_BLUE: "color-dodger-blue",
};

export const BUTTON_SIZES = {
  SMALL: "small-button",
  LARGE: "large-button",
  EXTRA_SMALL: "extra-small-button",
};

export const DATA_TYPE = {
  STRING: "string",
  NUMBER: "number",
  BOOLEAN: "boolean",
  ARRAY: "array",
  OBJECT: "object",
};

export const ROLE = {
  USER: "user",
  TRANSPORTER: "transporter",
  TRANSPORTER_USER: "transporterUser",
  DEALER: "dealer",
  DEALER_USER: "dealerUser",
};

export const MODAL_TYPE = {
  GROUP: "group",
  ASSIGN_AUCTION: "assign-auction",
  CREATE_AUCTION: "create-auction",
  REQUEST_CR: "request-cr",
  REQUEST_TRANSPORT: "request-transport",
  ADD_VEHICLE: "add-vehicle",
  VEHICLE: "vehicle",
  VEHICLE_LISTING: "vehicle-listing",
  ADD_LOCATION: "add-location",
  ADD_ANNOUNCEMENT: "add-announcement",
  ADD_REMARKS: "add-remarks",
  VEHICLE_UPDATE_REQUEST: "vehicle-update-request",
  IMPORT_CSV: "import-csv",
  ...ROLE,
};

export const MODAL_TITLE = {
  USER: "Add New User",
  EDIT_USER: "Update User",
  TRANSPORTER: "Add New Transporter",
  EDIT_TRANSPORTER: "Update Transporter",
  TRANSPORTER_USER: "Add New Transporter User",
  EDIT_TRANSPORTER_USER: "update Transporter User",
  DEALER: "Add New Dealer",
  EDIT_DEALER: "Update Dealer",
  DEALER_USER: "Add New Dealer User",
  EDIT_DEALER_USER: "Update Dealer User",
  ASSIGN_AUCTION: "Assign Auction",
  CREATE_AUCTION: "Create New Auction",
  UPDATE_AUCTION: "Update Auction",
  REQUEST_CR: "Request for CR",
  REQUEST_TRANSPORT: "Request for Transport",
  ADD_VEHICLE: "Add Vehicle",
  UPDATE_VEHICLE: "Update Vehicle",
  ADD_LOCATION: "Add Location",
  ADD_ANNOUNCEMENT: "Add Announcement",
  ADD_REMARKS: "Add Sub Announcement",
  IMPORT_CSV: "Import Vehicles CSV",
};

export const TRANSPORTATION_COLUMNS_ID = {
  VEHICLE_INFO: "vehicleInfo",
  PICKUP_DETAILS: "pickupDetails",
  DROPOFF_DETAILS: "dropoffDetails",
  STATUS_DETAILS: "statusDetails",
  AUCTION_INFO: "auctionInfo",
  MISC_INFO: "miscInfo",
  VEHICLE_NUMBERS: "vehicleNumbers",
  STATUS_AND_GRADE_INFO: "statusAndGradeInfo",
  SOLD_INFO: "soldInfo",
  OVE_DETAILS: "oveDetails",
  AUCTION_BID_INFO: "auctionBidInfo",
  arbitrationInfo: "arbitrationInfo",
  arbitrationAuctionInfo: "arbitrationAuctionInfo",
  caseInfo: "caseInfo",
};

export const TRANSPORTATION_STATUS = {
  PENDING: "pending",
  WAITING: "waiting",
  PICKED_UP: "Picked Up",
};

export const TABS = {
  AUCTION: "auction",
};

export const TABLE_COLUMNS = {
  ID: "id",
  VIN: "vin",
  NAME: "name",
  EMAIL: "email",
  DATE: "date",
  USER: "user",
  IP: "ip",
  ROLE: "role",
  BODY: "body",
  EVENT_TYPE: "eventType",
  MESSAGE: "message",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  CONTACT_NO: "contactNo",
  CONTACT_INFO: "contactInfo",
  DEALER: "dealer",
  DEALER_NAME: "dealerName",
  TRANSPORTER_NAME: "transporterName",
  USER_ROLE: "userRole",
  ACTIVE_STATUS: "activeStatus",
  STATUS: "status",
  ACTION: "action",
  MORE_ACTION: "moreAction",
  LOCATION: "location",
  DEALER_LOCATION: "dealerLocation",
  ZIP_CODE: "zipCode",
  DROPOFF_DATE: "dropoffDate",
  PICKUP_DATE: "pickupDate",
  PICKUP_LOCATION: "pickupLocation",
  DROPOFF_LOCATION: "dropoffLocation",
  AUCTION_LOCATION: "auctionLocation",
  AUCTION_STATUS: "auctionStatus",
  AUCTION_DATE: "auctionDate",
  ALTERNATE_DATE: "alternateDate",
  CASE_INFO: "caseInfo",
  VEHICLE_INFO: "vehicleInfo",
  AUCTION_INFO: "auctionInfo",
  MISC_INFO: "miscInfo",
  SOLD_INFO: "soldInfo",
  AUCTION_BID_INFO: "auctionBidInfo",
  ARBITRATION_INFO: "arbitrationInfo",
  ARBITRATION_AUCTION_INFO: "arbitrationAuctionInfo",
  VEHICLE_NUMBERS: "vehicleNumbers",
  STATUS_AND_GRADE_INFO: "statusAndGradeInfo",
  PICKUP_DETAILS: "pickupDetails",
  DROPOFF_DETAILS: "dropoffDetails",
  STATUS_DETAILS: "statusDetails",
  OVE_DETAILS: "oveDetails",
  MILLION_NUMBER: "millionNumber",
  CREATED_BY: "createdBy",
  CREATION_DATE: "creationDate",
};

export const OFFER_BUTTON_TYPES = {
  OFFER: "offer",
  COUNTER_OFFER: "counter-offer",
  BUMP_OFFER: "bump-offer",
  ACCEPT_OFFER: "accept-offer",
};

export const ACTION_BUTTON_TYPES = {
  PSI: "psi",
  ASSIGN_TRANPORT: "assign-transport",
  ACCEPT_ALTERNATE_DATE: "accept-alternate-date",
  EDIT_VEHICLE: "edit-vehicle",
  REMOVE_VEHICLE: "remove-vehicle",
  OPEN_ARBITRATION: "open-arbitration",
  PULLBACK_FROM_AUCTION: "pullback-from-auction",
  VEHICLE_UPDATE_REQUEST: "update-vehicle-request",
  MOVE_TO_INVENTORY: "move-to-inventory",
};

export const MODAL_FIELD_TYPES = {
  CR_INPUT: "cr-input",
  TEXT: "text",
  SELECT: "select",
  CR_URL: "cr-url",
  CR_GRADE: "cr-grade",
};

export const MODAL_FIELD_NAMES = {
  PSI: "psi",
  TITLE: "title",
};

export const MODAL_COMPONENT_FIELD_NAMES = {
  PASSWORD: "password",
  CONFIRM_PASSWORD: "confirmPassword",
  USERNAME: "username",
  EMAIL: "email",
  CONTACT_NUMBER: "contactNumber",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  COMPANY_NAME: "companyName",
  LOCATION: "location",
  PICKUP_DATE: "pickupDate",
  DROPPOFF_DATE: "dropoffDate",
  REMARKS: "remarks",
  NAME: "name",
  TIMEZONE: "timezone",
  ACTIVE: "active",
  DATE: "date",
  AUCTION: "auction",
  UPDATE_LOCATION: "updateLocation",
  DEALER: "dealer",
  DEALER_LOCATION: "dealerLocation",
  VIN: "vin",
  YEAR: "year",
  MAKE: "make",
  MODEL: "model",
  GO: "go",
  DEALER_STOCK_NUMBER: "dealerStockNumber",
  ACQUIRED_FROM: "acquiredFrom",
  EXTERIOR_COLOR: "exteriorColor",
  MILEAGE: "mileage",
  FLOOR_PRICE: "floorPrice",
  ACV: "acv",
  MMR: "mmr",
  USER_ROLE: "userRole",
  STATE: "state",
  ADDRESS: "address",
  ZIP_CODE: "zipCode",
  MILLION_NUMBER: "millionNumber",
  DEALER_NAME: "dealerName",
  DEALERSHIP_NAME: "dealershipName",
  DEALER_ROLE: "dealerRole",
  TIME: "time",
  ANNOUNCEMENTS: "announcements",
  SUB_ANNOUNCEMENTS: "subAnnouncements",
  LABEL: "label",
  COMMENT: "comment",
};

export const MODAL_BUTTON_NAMES = {
  SECOND_CHANCE: "second-chance",
  RERUN: "re-run",
  OVE_EVENT: "ove-event",
  SUBMIT_FOR_OVE_EVENT: "submit-ove-event",
};

export const SELECT_TYPES = {
  ALTERNATE_DATE: "alternate-date",
  TRANSPORTER: "transporter",
  AUCTIONS: "auctions",
  AUCTION_STATUS: "auction-status",
  ARBITRATION_STATUS: "auction-status",
  OVE_STATUS: "ove-status",
};

export const ADMIN_ROLES: Array<UserTypes> = [
  UserTypes.SuperAdmin,
  UserTypes.AuctionAdmin,
  UserTypes.InventoryAdmin,
];

export const DEALER_ROLES: Array<UserTypes> = [
  UserTypes.DealerAdmin,
  UserTypes.DealerAuctionUser,
  UserTypes.DealerInventoryUser,
];

export const TRANSPORTER_ROLES: Array<UserTypes> = [
  UserTypes.TransporterAdmin,
  UserTypes.TransporterUser,
];

export const LOCAL_STORAGE_KEYS = {
  QUERIES: "queries",
};

export const QUERIES = {
  GET_VEHICLES: "getVehicles" as "getVehicles",
  GET_AUCTION_VEHICLES: "getAuctionVehicles" as "getAuctionVehicles",
  GET_ARBITRATION_CHAT: "getArbitrationChatById" as "getArbitrationChatById",
  GET_ARBITRATION_BY_ID: "getArbitrationById" as "getArbitrationById",
  GET_ARBITRATIONS: "getArbitrations" as "getArbitrations",
};

export const DATE_FORMAT = {
  YYYY_MM_DD: "YYYY-MM-DD",
  YYYY_MMM_DD: "YYYY-MMM-DD",
  DD_MM_YYYY: "DD-MM-YYYY",
  DD_MM_YYYY_TIME: "DD-MM-YYYY hh:mm a",
  YYYY_MM_DD_TIME: "YYYY-MM-DD hh:mm a",
};

export const DATE_TYPE = {
  TIMESTAMP: "timestamp",
  DATE_STRING: "date-string",
  UTC_DATE_STRING: "utc-date-string",
  START_DATE_RANGE: "start-date-string",
  END_DATE_RANGE: "end-date-string",
};

export const PAGE_TITLE = {
  DASHBOARD: "Dashboard",
  VEHICLES: "Vehicles",
  AUCTION: "Auction",
  ARBITRATION: "Arbitration",
  ROLES: "Roles",
  USERS: "Users",
  STAKEHOLDERS: "Stakeholders",
  TRANSPORTER: "Transporter",
  TRANSPORTATION: "Transportation",
  AUDIT_TRAILS: "Audit Trails",
  SETTINGS: "Settings",
  LOGOUT: "Logout",
  INVENTORY: "Inventory",
};

export const SNACKBAR_VARIANT = {
  SUCCESS: "success",
  ERROR: "error",
  RELOAD_BUTTON_SNACKBAR: "socketSnackbar",
};

export const NUMBER: {
  [key: string]: number;
} = {
  MINUS_ONE: -1,
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  TEN: 10,
  TWENTY_FIVE: 25,
  HUNDRED: 100,
};

export const MULTI_SELECT_REASON = {
  SELECT_OPTION: "selectOption",
  REMOVE_OPTION: "removeOption",
  CLEAR_OPTION: "clear",
};

export const LISTING_TYPE = {
  AUDIT_TRAIL: "audit-trail",
  NOTFICATION: "notification",
};

export const ARBITRATION_CHAT_FIELDS = {
  VIN: "vin",
  YEAR: "year",
  MAKE: "make",
  MODEL: "model",
  SOLD_PRICE: "sold-price",
  SOLD_DATE: "sold-date",
  PSI_STATUS: "psi-status",
  VEHICLE_LOCATION: "vehicle-location",
  REASON_OF_ARBITRATION: "reason-of-arbitration",
  REMOVED_FROM_LOT: "removed-from-lot",
  RECIEVED_BY_BUYER: "received-by-buyer",
  ADJUSTMENT_AMOUNT: "adjustment-amount",
  ARBITRATION_STATUS: "arbitration-status",
  SAVE: "save",
  BUYER_WITHDRAW: "buyer-withdraw",
  BUYER_BOUGHT: "buyer-bought",
};

export const PDF_TITLE = {
  INVENTORY: "Vehicle Inventory",
  SOLD: "Vehicle Sold",
  TRASH: "Removed from Lot",
  AUCTION: "Auction",
  REQUEST_FOR_OVE: "Request for OVE",
  SECOND_CHANCE: "Second Chance",
  RERUN: "Rerun",
  AUCTION_LIST: "Auction List",
  USERS: "Users",
  DEALER_USERS: "Dealer Users",
  TRANSPORTER_USERS: "Transporter Users",
  DEALERS: "Dealers",
  TRANSPORTERS: "Transporters",
  AUDIT_TRAILS: "Audit Trails",
  TRANSPORTATION: "Transportation",
  UNASSIGNED_TRANSPORTATION: "Unassigned Transportation",
  TRANSPORT_REQUEST: "Transport Request",
  SCHEDULED_TASK: "Scheduled Task",
  COMPLETED_TASK: "Completed Task",
  KBB_REPORT: "KBB Report",
};

export const PDF_PAGE_STYLE = {
  LEGAL: "@page { size: legal landscape }",
  PORTRAIT: "@page { size: tabloid portrait }",
};

export const VEHICLE_UPDATE_REQUEST_STATUS = {
  ACCEPT: VehicleUpdateRequestStatus.Approved,
  REJECT: VehicleUpdateRequestStatus.Rejected,
};

export const USER_TYPE_NAME = {
  [UserTypes.SuperAdmin]: "Super Admin",
  [UserTypes.AuctionAdmin]: "Auction Admin",
  [UserTypes.InventoryAdmin]: "Inventory Admin",
  [UserTypes.DealerAdmin]: "Dealer Admin",
  [UserTypes.DealerAuctionUser]: "Dealer Auction User",
  [UserTypes.DealerInventoryUser]: "Dealer Inventory User",
  [UserTypes.TransporterAdmin]: "Transporter Admin",
  [UserTypes.TransporterUser]: "Transporter User",
};

export const COLUMNS_KEY = {
  AUCTION_VEHICLE: "Auction Vehicle",
  ID: "ID",
  EMAIL: "Email",
  NAME: "Name",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  CONTACT_NO: "Contact No",
  USER_ROLE: "User Role",
  LOCATION: "Location",
  ZIP_CODE: "Zip Code",
  MILLION_NUMBER: "Million Number",
  ACTIVE: "Active",
  VEHICLE_VIN: "VIN",
  VEHICLE_MAKE: "Make",
  VEHICLE_MODEL: "Model",
  VEHICLE_YEAR: "Year",
  VEHICLE_DEALER_STOCK_NUMBER: "Dealer Stock Number",
  VEHICLE_ACV: "ACV",
  VEHICLE_FLOOR_PRICE: "Floor Price",
  AUCTION_VEHICLE_VIN: "VIN",
  AUCTION_VEHICLE_MAKE: "Make",
  AUCTION_VEHICLE_MODEL: "Model",
  AUCTION_VEHICLE_YEAR: "Year",
  AUCTION_VEHICLE_MMR: "MMR",
  AUCTION_VEHICLE_ACV: "ACV",
  AUCTION_VEHICLE_FLOOR_PRICE: "Floor Price",
  AUCTION_DEALER_NAME: "Dealer Name",
  AUCTION_VEHICLE_LOCATION: "Vehicle Location",
  AUCTION_VEHICLE_AGE: "Age",
  AUCTION_VEHICLE_ANNOUNCEMENT: "Announcement",
  DEALER_NAME: "Dealer Name",
  TRANSPORTER_NAME: "Transporter Name",
  VEHICLE_LOCATION: "Vehicle Location",
  VEHICLE_AGE: "Age",
  VEHICLE_LANE: "Lane",
  VEHICLE_RUN: "Run",
  VEHICLE_ANNOUNCEMENT: "Announcement",
  AUCTION_VEHICLE_SOLD_PRICE: "Sold Price",
  VEHICLE_REMARK: "Sub Announcement",
  AUCTION_COUNT: "Auction Count",
  TRANSPORT_STATUS: "Transport Status",
  VEHICLE_SOLD_PRICE: "Sold Price",
  VEHICLE_SOLD_DATE: "Sold Date",
  AUCTION_VEHICLE_OFFER: "Offer",
  AUCTION_VEHICLE_COUNTER_OFFER: "Counter Offer",
  AUCTION_VEHICLE_BUMPER_OFFER: "BUMP Offer",
  AUCTION_VEHICLE_STATUS: "Status",
  OVE_EVENT_PRICE: "OVE Event Price",
  OVE_EVENT_STATUS: "OVE Event Status",
  AUCTION_DATE: "Auction Date",
  AUCTION_LOCATION: "Auction Location",
  AUCTION_CREATED_BY: "Created By",
  AUCTION_CREATION_DATE: "Creation Date",
  AUCTION_STATUS: "Status",
  PICKUP_LOCATION: "Pickup Location",
  DROPOFF_LOCATION: "Dropoff Location",
  PICKUP_DATE: "Pickup Date",
  ALTERNATE_DATE: "Alternate Date",
};

export const BUTTON_LABEL = {
  ATTACH_CSV: "Attach CSV",
  DOWNLOAD_TEMPLATE: "Download Template",
  UPLOAD: "Upload",
  CANCEL: "Cancel",
};

export const VehicleSortFilter = {
  ageAscending: `${VehicleOrderColumn.CreatedAt}-${OrderByDirection.ASC}`,
  ageDescending: `${VehicleOrderColumn.CreatedAt}-${OrderByDirection.DESC}`,
  updatedAtAscending: `${VehicleOrderColumn.UpdatedAt}-${OrderByDirection.ASC}`,
  updateAtDescending: `${VehicleOrderColumn.UpdatedAt}-${OrderByDirection.DESC}`,
};

export const AuctionSortFilter = {
  laneAscending: `${AuctionVehicleOrderColumn.Lane}-${OrderByDirection.ASC}`,
  laneDescending: `${AuctionVehicleOrderColumn.Lane}-${OrderByDirection.DESC}`,
  runAscending: `${AuctionVehicleOrderColumn.Run}-${OrderByDirection.ASC}`,
  runDescending: `${AuctionVehicleOrderColumn.Run}-${OrderByDirection.DESC}`,
};

export const RouteWithMultipleTabs: Array<string> = [
  FRONTEND_ROUTES.VEHICLE,
  FRONTEND_ROUTES.AUCTION,
  FRONTEND_ROUTES.USER,
  FRONTEND_ROUTES.STAKEHOLDER,
  FRONTEND_ROUTES.TRANSPORT,
  FRONTEND_ROUTES.SETTING,
];
