import Box from "@mui/material/Box";
import { Listing } from "../../ts/component.types";
import Loader from "../Loader";

type Props = {
  type: string;
  title: string;
  isFetching: boolean;
  data: Listing[] | null;
  onClickSeeMore?: (type: string) => void;
  path?: string;
  innerContainerClassname?: string;
  className?: string;
};

const ListingComponent = ({
  type,
  isFetching,
  data,
  title,
  className,
  onClickSeeMore,
  innerContainerClassname,
}: Props) => {
  return (
    <Box
      className={`bg-primary-100 rounded-md box-shadow p-6 max-h-[640px] ${className}`}
    >
      <Box className="flex justify-between items-center">
        <Box className="pb-4 text-tertiary-500">{title}</Box>
        {onClickSeeMore && (
          <Box
            className="text-xs pb-4 text-secondary-300 cursor-pointer hover:underline"
            onClick={() => onClickSeeMore(type)}
          >
            See More
          </Box>
        )}
      </Box>
      <Box
        className={`text-secondary-300 notification-container overflow-y-auto max-h-[550px] ${innerContainerClassname}`}
      >
        {!data || isFetching ? (
          <Loader />
        ) : data?.length ? (
          data?.map((row) => (
            <Box
              key={row?.id}
              className="border-b border-secondary-400 py-2 mx-2 cursor-pointer"
            >
              {row?.title}
            </Box>
          ))
        ) : (
          <Box className="text-tertiary-500">No Date Available</Box>
        )}
      </Box>
    </Box>
  );
};

export default ListingComponent;
