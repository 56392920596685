export const COLORS = {
  INHERIT: "inherit",
  TRANSPARENT: "transparent",
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  OFF_WHITE: "#F9F9F9",
  PERIWINKLE_BLUE: "#A9B5DF",
  LAVENDER_BLUE: "#F3F4FD",
  DODGER_BLUE: "#1a90ff",
  DODGER_BLUE_LIGHT: "#3096F0",
  CORN_FLOWER_BLUE: "#645FE4",
  YELLOW: "#FDEE30",
  GRAY: "#959595",
  DARK_GRAY: "#707070",
  CHARCOAL_GRAY: "#3E3E3E",
  LIGHT_GRAYISH_COLOR: "#EBEBE4",
};
