import { ChangeEvent, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import usePagination from "@mui/material/usePagination";
import PaginationComponent from "../PaginationComponent";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FieldComponent from "../FieldComponent";
import SelectComponent from "../SelectComponent";
import ButtonComponent from "../ButtonComponent";
import TagComponent from "../TagComponent";
import {
  COLOR_CLASSES,
  CONSTANTS,
  SIZES,
  TABLE_COLUMNS,
} from "../../misc/constants";
import {
  SelectOptions,
  SelectedPaginationProps,
  TableComponentProps,
  TagOption,
  MultipleValues,
  TransportStatusType,
} from "../../ts/component.types";

import "./../../styles/TableComponent.css";
import Loader from "../Loader";
import { TransportRequestStatus, TransportationRequest } from "../../misc/enum";
import dayjs from "dayjs";
import CustomDatePickerInput from "../CustomDatePickerInput";

export default function TableComponent({
  rows,
  columns,
  handleOpen,
  applyFilter,
  queryParams,
  rowCount = 0,
  handleAlternateDate,
  handleTransportRequest,
  handleScheduledTransportRequest,
  onButtonClick,
  reference,
}: TableComponentProps) {
  const rowsPerPage = (queryParams?.rowsPerPage as number) || 10;
  const page = (queryParams?.page as number) || 0;

  const totalPages = Math.ceil(rowCount / rowsPerPage);
  const { items } = usePagination({
    count: totalPages,
    page: page + 1,
  });

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    applyFilter &&
      applyFilter({
        rowsPerPage: +event.target.value,
        page: 0,
      });
  };

  const handlePageChange = (selected: SelectedPaginationProps) => {
    if (!selected?.selected) {
      const pageNumber = selected?.page ? selected?.page - 1 : 0;
      applyFilter &&
        applyFilter({
          page: pageNumber,
        });
    }
  };

  const [initialValues, setInitialValues] = useState<{
    [key: string]: number | boolean | string;
  }>({});

  useEffect(() => {
    let initVals: {
      [key: string]: number | boolean | string;
    } = {};
    columns &&
      columns?.forEach((column) => {
        if (column.id !== "action") {
          initVals[column.id] =
            (queryParams?.[column.id] as string | number | boolean) || "";
        }
      });
    setInitialValues(initVals);
  }, [columns]);

  return (
    <>
      <Paper className="table-container">
        <TableContainer className="table-scrollbar" sx={{ maxHeight: "60vh" }}>
          <Table stickyHeader aria-label="sticky table" ref={reference}>
            <TableHead>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (
                  values: {
                    [key: string]:
                      | number
                      | boolean
                      | string
                      | { [key: string]: Date };
                  },
                  { setSubmitting, resetForm }
                ) => {
                  applyFilter &&
                    applyFilter({
                      ...values,
                      page: 0,
                      rowsPerPage: queryParams?.rowsPerPage as number,
                    });
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <TableRow sx={{ width: "100%" }}>
                    {columns.map((column) =>
                      column?.filter ? (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <Box
                            className={`mb-3 text-sm ${
                              column?.hideLabel && "invisible"
                            }`}
                          >
                            {column?.label || "invisible text"}
                          </Box>
                          {column?.filter === CONSTANTS.INPUT ? (
                            <FieldComponent
                              name={column.id}
                              value={values[column.id] as string}
                              hideErrorMessage={true}
                              inputClassName="bg-primary-300"
                              size={"small"}
                              type="text"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          ) : column.filter === CONSTANTS.SELECT ? (
                            <SelectComponent
                              name={column.id}
                              value={values[column.id] as string}
                              hideErrorMessage={true}
                              className="bg-primary-300"
                              options={column.options}
                              handleChange={handleChange}
                            />
                          ) : column.filter === CONSTANTS.DATE_RANGE_PICKER ? (
                            <DatePicker
                              isClearable={true}
                              minDate={
                                ((
                                  values[column.id] as {
                                    [key: string]: Date;
                                  }
                                )?.startDate as Date)
                                  ? ((
                                      values[column.id] as {
                                        [key: string]: Date;
                                      }
                                    )?.startDate as Date)
                                  : null
                              }
                              startDate={
                                ((
                                  values[column.id] as {
                                    [key: string]: Date;
                                  }
                                )?.startDate as Date)
                                  ? ((
                                      values[column.id] as {
                                        [key: string]: Date;
                                      }
                                    )?.startDate as Date)
                                  : null
                              }
                              endDate={
                                ((
                                  values[column.id] as {
                                    [key: string]: Date;
                                  }
                                )?.endDate as Date)
                                  ? ((
                                      values[column.id] as {
                                        [key: string]: Date;
                                      }
                                    )?.endDate as Date)
                                  : null
                              }
                              onChange={(date: [Date, Date]) => {
                                const startDate = date[0] || null;
                                const endDate = date[1] || null;
                                setFieldValue(column?.id, {
                                  startDate,
                                  endDate,
                                });
                              }}
                              selectsRange={true}
                              customInput={
                                <CustomDatePickerInput
                                  value={""}
                                  style={{
                                    minWidth: column.minWidth,
                                    height: "40px",
                                  }}
                                />
                              }
                              className="border rounded-md py-2 px-2 w-full text-left text-slate-400 bg-primary-300"
                            />
                          ) : column.filter === CONSTANTS.BUTTON ? (
                            <ButtonComponent
                              onClick={() => handleSubmit()}
                              disabled={isSubmitting}
                              text={column?.label}
                              color={CONSTANTS.PRIMARY}
                              size={SIZES.LARGE}
                            />
                          ) : null}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                )}
              </Formik>
            </TableHead>
            {!rows ? (
              <TableRow className="relative" sx={{ height: "100px" }}>
                <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <Loader />
                </Box>
              </TableRow>
            ) : !rows?.length ? (
              <Box className="w-full py-3 text-center font-bold text-lg text-tertiary-400 tracking-wide">
                No Data Available
              </Box>
            ) : (
              <TableBody>
                {rows
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      row && (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row?.id}
                        >
                          {columns.map((column) => {
                            if (column.dataType === CONSTANTS.BUTTON) {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {row?.actionType ===
                                  TransportationRequest.Pending ? (
                                    <ButtonComponent
                                      className="whitespace-nowrap font-12 action-button"
                                      text={
                                        row?.alternateDate
                                          ? "Request"
                                          : "Accept"
                                      }
                                      disabled={
                                        row?.alternateDate
                                          ? (row?.alternateDateAccess as boolean)
                                          : (row?.acceptAccess as boolean)
                                      }
                                      color={CONSTANTS.PRIMARY}
                                      size={SIZES.SMALL}
                                      onClick={() =>
                                        handleTransportRequest &&
                                        handleTransportRequest(row)
                                      }
                                    />
                                  ) : row?.actionType ===
                                    TransportationRequest.Requested ? (
                                    <ButtonComponent
                                      className="whitespace-nowrap font-12 action-button"
                                      text="Requested"
                                      color={CONSTANTS.SECONDARY}
                                      size={SIZES.SMALL}
                                    />
                                  ) : row?.actionType ===
                                    TransportationRequest.Accepted ? (
                                    <Box className="flex items-center justify-center">
                                      {TransportRequestStatus[
                                        row?.status as TransportStatusType
                                      ] === TransportRequestStatus.Accepted ? (
                                        <ButtonComponent
                                          className="whitespace-nowrap font-12 action-button"
                                          text="Pick Up"
                                          color={CONSTANTS.TERTIARY}
                                          size={SIZES.SMALL}
                                          disabled={
                                            row?.transportActionAccess as boolean
                                          }
                                          onClick={() =>
                                            handleScheduledTransportRequest &&
                                            handleScheduledTransportRequest(
                                              TransportRequestStatus.Picked,
                                              row?.id
                                            )
                                          }
                                        />
                                      ) : (
                                        <ButtonComponent
                                          className="whitespace-nowrap font-12 action-button"
                                          text={
                                            TransportRequestStatus[
                                              row?.status as TransportStatusType
                                            ] === TransportRequestStatus.Picked
                                              ? "Drop Off"
                                              : "Dropped"
                                          }
                                          color={
                                            TransportRequestStatus[
                                              row?.status as TransportStatusType
                                            ] === TransportRequestStatus.Picked
                                              ? CONSTANTS.TERTIARY
                                              : CONSTANTS.QUATERNARY
                                          }
                                          disabled={
                                            TransportRequestStatus[
                                              row?.status as TransportStatusType
                                            ] ===
                                              TransportRequestStatus.Dropped ||
                                            (row?.transportActionAccess as boolean)
                                          }
                                          size={SIZES.SMALL}
                                          onClick={() =>
                                            handleScheduledTransportRequest &&
                                            handleScheduledTransportRequest(
                                              TransportRequestStatus.Dropped,
                                              row?.id
                                            )
                                          }
                                        />
                                      )}
                                    </Box>
                                  ) : row?.disabled &&
                                    !row?.finishAuctionButton ? (
                                    <></>
                                  ) : (
                                    <ButtonComponent
                                      text={
                                        row?.finishAuctionButton
                                          ? "Finish Auction"
                                          : "Edit"
                                      }
                                      color={
                                        row?.finishAuctionButton
                                          ? CONSTANTS.TERTIARY
                                          : CONSTANTS.PRIMARY
                                      }
                                      size={SIZES.SMALL}
                                      onClick={() =>
                                        onButtonClick &&
                                        onButtonClick(
                                          row,
                                          row?.finishAuctionButton as boolean
                                        )
                                      }
                                    />
                                  )}
                                </TableCell>
                              );
                            } else if (
                              column?.dataType === CONSTANTS.DATEPICKER
                            ) {
                              const value = row[column.id]
                                ? dayjs(row[column.id] as Date)
                                    .utc(false)
                                    .toDate()
                                : null;

                              const disabled =
                                row?.actionType ===
                                  TransportationRequest.Requested ||
                                (row?.alternateDateAccess as boolean);

                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <DatePicker
                                    isClearable={!disabled}
                                    onChange={(date: Date) =>
                                      handleAlternateDate &&
                                      handleAlternateDate(Number(row?.id), date)
                                    }
                                    selected={value}
                                    disabled={disabled}
                                    selectsEnd
                                    minDate={new Date()}
                                    customInput={
                                      <CustomDatePickerInput
                                        value={""}
                                        style={{
                                          minWidth: columns[6].minWidth,
                                          height: "40px",
                                        }}
                                      />
                                    }
                                    placeholderText="Alternate Date"
                                    className="border rounded-md py-2 px-2 w-full text-left bg-primary-300"
                                  />
                                </TableCell>
                              );
                            } else if (column?.dataType === CONSTANTS.SELECT) {
                              const value = row[column.id] as SelectOptions;
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <SelectComponent
                                    hideErrorMessage={true}
                                    className="bg-primary-300"
                                    size={SIZES.SMALL}
                                    options={value.options}
                                    value={value.currentValue}
                                    handleChange={() => {}}
                                  />
                                </TableCell>
                              );
                            } else if (column?.dataType === CONSTANTS.TAG) {
                              const value = row[column?.id] as TagOption;
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <TagComponent
                                    text={value?.label}
                                    color={value?.color}
                                  />
                                </TableCell>
                              );
                            } else if (
                              column?.dataType === CONSTANTS.MULTIPLE_VALUES
                            ) {
                              const values = row[column?.id] as MultipleValues;
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <Box>
                                    <Box>{values?.value1}</Box>
                                    <Box>{values?.value2}</Box>
                                  </Box>
                                </TableCell>
                              );
                            } else {
                              const value = row[column?.id] as string;
                              return (
                                <TableCell
                                  onClick={() =>
                                    (column?.type === TABLE_COLUMNS.VIN ||
                                      column?.type === TABLE_COLUMNS.ID) &&
                                    handleOpen &&
                                    handleOpen(row?.id)
                                  }
                                  key={column.id}
                                  align={column.align}
                                >
                                  <Box
                                    className={`${
                                      column?.type === TABLE_COLUMNS.VIN ||
                                      column?.type ===
                                        TABLE_COLUMNS.MILLION_NUMBER ||
                                      column?.type === TABLE_COLUMNS.ID
                                        ? `${COLOR_CLASSES.DODGER_BLUE} cursor-pointer`
                                        : ""
                                    } `}
                                  >
                                    {column?.format && typeof value === "number"
                                      ? column?.format(value)
                                      : column.id === CONSTANTS.PHONE_NUMBER
                                      ? value?.slice(0, 2) +
                                        " " +
                                        value?.slice(2)
                                      : value}
                                  </Box>
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      )
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
      <Box className="flex items-center justify-between py-3">
        <Box className="text-sm font-light">
          Showing {(page + 0) * rowsPerPage || 1} to{" "}
          {(page + 1) * rowsPerPage > rowCount
            ? rowCount
            : (page + 1) * rowsPerPage}{" "}
          of {rowCount} entries
        </Box>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={() => {}}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={() => ""}
          className="bg-primary-300 border-none shadow-none outline-none"
          ActionsComponent={() => {
            return (
              <PaginationComponent
                items={items}
                handlePageChange={handlePageChange}
              />
            );
          }}
        />
      </Box>
    </>
  );
}
