import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/redux-toolkit";
import { Pages } from "../misc/access.enum";
import { TRANSPORTER_ROLES } from "../misc/constants";
import { FRONTEND_ROUTES, UserTypes } from "../misc/enum";

export const ProtectedRoute = ({ children, access }: any) => {
  const { isAuthenticated, permissions, role } = useAppSelector(
    (state) => state.authorization
  );

  const hasPageAccess = (key: string) => {
    if (key === Pages.KbbReport) return true;
    if (key === Pages.Sidebar) return true;
    else if (permissions[key]) return true;
    return false;
  };

  if (!isAuthenticated || !permissions)
    return <Navigate to={FRONTEND_ROUTES.LOGIN} />;

  if (!hasPageAccess(access))
    return TRANSPORTER_ROLES.includes(role as UserTypes) ? (
      <Navigate to={FRONTEND_ROUTES.TRANSPORT_REQUEST} />
    ) : (
      <Navigate to={FRONTEND_ROUTES.DASHBOARD} />
    );

  return <>{children}</>;
};
