import { DynamicKeyValuesWithNumArray } from "../ts/api.types";

export function appendQueryParams(
  params: DynamicKeyValuesWithNumArray,
  concat: boolean
) {
  const queryParams = [];

  if ("page" in params) queryParams.push(`page=${params.page}`);
  if ("limit" in params) queryParams.push(`limit=${params.limit}`);
  if (params?.id) queryParams.push(`id=${params.id}`);
  if (params?.isActive)
    queryParams.push(
      `is_active=${params?.isActive === "active" ? true : false}`
    );
  if (params?.isDeleted)
    queryParams.push(
      `is_deleted=${params?.isDeleted === "active" ? true : false}`
    );
  if (params?.contactNo) {
    params?.contactNo.toString().slice(0, 1) !== "+"
      ? queryParams.push(`phone_number=${params.contactNo}`)
      : queryParams.push(
          `phone_number=${params.contactNo.toString().slice(1)}`
        );
  }
  if (params?.lane) queryParams.push(`lane=${params.lane}`);
  if (params?.run) queryParams.push(`run=${params.run}`);
  if (params?.direction) queryParams.push(`direction=${params.direction}`);
  if (params?.column) queryParams.push(`column=${params.column}`);
  if (params?.firstName) queryParams.push(`first_name=${params.firstName}`);
  if (params?.vin) queryParams.push(`vin=${params.vin}`);
  if (params?.lastName) queryParams.push(`last_name=${params.lastName}`);
  if (params?.email) queryParams.push(`email=${params.email}`);
  if (params?.username) queryParams.push(`user_name=${params.username}`);
  if (params?.location) queryParams.push(`location_id=${params.location}`);
  if (params?.auctionId) queryParams.push(`auction_id=${params.auctionId}`);
  if (params?.announcementId)
    queryParams.push(`announcement_id=${params.announcementId}`);
  if (params?.dealerId) queryParams.push(`dealer_id=${params.dealerId}`);
  if (params?.transporterId)
    queryParams.push(`transporter_id=${params.transporterId}`);
  if (params?.make) queryParams.push(`make=${params.make}`);
  if (params?.model) queryParams.push(`model=${params.model}`);
  if (params?.pickupLocation)
    queryParams.push(`pickup_location_id=${params.pickupLocation}`);
  if (params?.dropoffLocation)
    queryParams.push(`dropoff_location_id=${params.dropoffLocation}`);
  if (params?.zipCode) queryParams.push(`zip=${params.zipCode}`);
  if (params?.startSoldDate)
    queryParams.push(`sale_date_from=${params.startSoldDate}`);
  if (params?.endSoldDate)
    queryParams.push(`sale_date_to=${params.endSoldDate}`);
  if (params?.startAge) queryParams.push(`age_from=${params.startAge}`);
  if (params?.endAge) queryParams.push(`age_to=${params.endAge}`);
  if (params?.startYear) queryParams.push(`year_from=${params.startYear}`);
  if (params?.endYear) queryParams.push(`year_to=${params.endYear}`);
  if (params?.contactInfo)
    queryParams.push(`contact_info=${params.contactInfo}`);
  if (params?.alternateDate)
    queryParams.push(`alternate_date=${params.alternateDate}`);
  if (params?.alternateStartDate)
    queryParams.push(`alternate_date_from=${params?.alternateStartDate}`);
  if (params?.alternateEndDate)
    queryParams.push(`alternate_date_to=${params?.alternateEndDate}`);
  if (params?.dropoffStartDate)
    queryParams.push(`dropoff_start_date=${params?.dropoffStartDate}`);
  if (params?.dropoffEndDate)
    queryParams.push(`dropoff_end_date=${params?.dropoffEndDate}`);
  if (params?.pickupStartDate)
    queryParams.push(`pickup_date_from=${params?.pickupStartDate}`);
  if (params?.pickupEndDate)
    queryParams.push(`pickup_date_to=${params?.pickupEndDate}`);
  if (params?.createdAt) queryParams.push(`created_at=${params.createdAt}`);
  if (params?.createdBy) queryParams.push(`name=${params.createdBy}`);
  if (params?.auctionStartDate)
    queryParams.push(`auction_date_from=${params?.auctionStartDate}`);
  if (params?.auctionEndDate)
    queryParams.push(`auction_date_to=${params?.auctionEndDate}`);
  if (params?.createdAtStartDate)
    queryParams.push(`created_from=${params?.createdAtStartDate}`);
  if (params?.createdAtEndDate)
    queryParams.push(`created_to=${params?.createdAtEndDate}`);
  if (params?.millionNumber)
    queryParams.push(`million_number=${params.millionNumber}`);
  if (params?.dealerName) queryParams.push(`name=${params.dealerName}`);
  if (params?.transporterName)
    queryParams.push(`name=${params.transporterName}`);
  if (params?.arbitrationStatus) {
    queryParams.push(`status=${params.arbitrationStatus}`);
  }
  if (params?.status) {
    if (Array.isArray(params?.status)) {
      params?.status?.forEach((status) => {
        queryParams.push(`status[]=${status}`);
      });
    } else {
      queryParams.push(`status=${params.status}`);
    }
  }

  if (params?.userRole) {
    if (Array.isArray(params?.userRole)) {
      params?.userRole?.forEach((role) => {
        queryParams.push(`role_ids[]=${role}`);
      });
    } else {
      queryParams.push(`role_ids=${params.userRole}`);
    }
  }

  return queryParams?.length
    ? concat
      ? "&" + queryParams.join("&")
      : queryParams.join("&")
    : "";
}
