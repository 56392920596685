import {
  ChangeEvent,
  useDeferredValue,
  useEffect,
  useState,
  useRef,
} from "react";
import Box from "@mui/material/Box/Box";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  ADMIN_ROLES,
  ARBITRATION_CHAT_FIELDS,
  CONSTANTS,
  DATE_FORMAT,
  DATE_TYPE,
  NUMBER,
  PAGE_TITLE,
  SIZES,
} from "../misc/constants";
import FieldComponent from "../components/FieldComponent";
import ButtonComponent from "../components/ButtonComponent";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArbitrationStatus,
  FRONTEND_ROUTES,
  PSIStatus,
  SocketEventName,
  UserTypes,
  UNREAD_MESSAGE_STATE,
} from "../misc/enum";
import {
  useLazyGetArbitrationByIdQuery,
  useUpdateArbitrationMutation,
  useUpdateArbitrationStatusMutation,
} from "../store/reducer/arbitration";
import {
  useLazyGetArbitrationChatByIdQuery,
  useMarkReadArbitrationChatMutation,
} from "../store/reducer/arbitrationChat";
import { getSocket } from "../store/socket";
import { useAppDispatch, useAppSelector } from "../hooks/redux-toolkit";
import { Socket } from "socket.io-client";
import {
  checkBoolean,
  formatDate,
  formatTimeDifference,
  isSender,
} from "../misc/utils";
import Loader from "../components/Loader";
import { toggleProgressBar } from "../store/reducer/progressBar";
import { setNewMessages } from "../store/reducer/auth";
import { MESSAGES } from "../misc/messages";
import { Permissions } from "../ts/api.types";
import { Actions } from "../misc/access.enum";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  FieldProps,
  ChatBubbleProps,
  MessageStateObject,
  ArbitrationStateObject,
} from "../ts/component.types";
import { useSuccessDispatcher } from "../hooks/useSuccessDispatcher";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";

const renderFields = (
  data: ArbitrationStateObject,
  permissions: Permissions
): FieldProps[] => {
  const hasAccessToModifyFields =
    data?.chatEnabled &&
    checkBoolean(permissions?.[Actions?.ArbitrationReason]);

  return [
    {
      type: CONSTANTS.TEXT,
      title: "Arbitration Status",
      value: data?.arbitrationStatus,
      key: ARBITRATION_CHAT_FIELDS.ARBITRATION_STATUS,
    },
    {
      type: CONSTANTS.TEXT,
      title: "VIN",
      value: data?.vehicle?.vin,
      key: ARBITRATION_CHAT_FIELDS.VIN,
    },
    {
      type: CONSTANTS.TEXT,
      title: "Year",
      value: data?.vehicle?.year,
      key: ARBITRATION_CHAT_FIELDS.YEAR,
    },
    {
      type: CONSTANTS.TEXT,
      title: "Make",
      value: data?.vehicle?.make,
      key: ARBITRATION_CHAT_FIELDS.MAKE,
    },
    {
      type: CONSTANTS.TEXT,
      title: "Model",
      value: data?.vehicle?.model,
      key: ARBITRATION_CHAT_FIELDS.MODEL,
    },
    {
      type: CONSTANTS.TEXT,
      title: "Sold Price",
      value: data?.vehicle?.soldPrice,
      key: ARBITRATION_CHAT_FIELDS.SOLD_PRICE,
    },
    {
      type: CONSTANTS.TEXT,
      title: "Sold Date",
      value: data?.vehicle?.soldDate,
      key: ARBITRATION_CHAT_FIELDS.SOLD_DATE,
    },
    {
      type: CONSTANTS.TEXT,
      title: "PSI Status",
      value: data?.vehicle?.psiStatus,
      key: ARBITRATION_CHAT_FIELDS.PSI_STATUS,
    },
    {
      type: CONSTANTS.TEXT,
      title: "Location of Vehicle",
      value: data?.vehicle?.location,
      key: ARBITRATION_CHAT_FIELDS.VEHICLE_LOCATION,
    },
    {
      inputIndex: 0,
      type: hasAccessToModifyFields ? CONSTANTS.TEXTAREA : CONSTANTS.TEXT,
      title: "Reason of Arbitration",
      value: hasAccessToModifyFields ? data?.reason : data?.reason || "-",
      key: ARBITRATION_CHAT_FIELDS.REASON_OF_ARBITRATION,
    },
    {
      inputIndex: 1,
      type: hasAccessToModifyFields ? CONSTANTS.INPUT : CONSTANTS.TEXT,
      title: "Recieved by Buyer",
      value: hasAccessToModifyFields
        ? data?.receivedByBuyer
        : data?.receivedByBuyer || "-",
      key: ARBITRATION_CHAT_FIELDS.RECIEVED_BY_BUYER,
    },
    {
      inputIndex: 2,
      type: hasAccessToModifyFields ? CONSTANTS.INPUT : CONSTANTS.TEXT,
      title: "Adjustment Amount",
      value: hasAccessToModifyFields
        ? data?.adjustmentAmount
        : data?.adjustmentAmount || "-",
      key: ARBITRATION_CHAT_FIELDS.ADJUSTMENT_AMOUNT,
    },
    {
      type: CONSTANTS.BUTTON,
      title: "",
      value: "",
      key: ARBITRATION_CHAT_FIELDS.SAVE,
      hide: !hasAccessToModifyFields,
    },
  ];
};

const ChatBubble = ({ sender, message, timestamp }: ChatBubbleProps) => {
  return (
    <Box
      className={`m-3 flex flex-col ${sender ? "items-end" : "items-start"}`}
    >
      <Box className={`flex flex-col ${sender ? "items-end" : "items-start"}`}>
        <Box
          className={`w-fit px-[25px] py-[15px] text-[16px] rounded-[15px] max-w-[450px] break-words text-tertiary-700 ${
            sender ? "bg-primary-700" : "bg-primary-800"
          }
        `}
        >
          {message}
        </Box>
        <Box
          className={`text-xs m-2 mb-0 text-secondary-400
      ${sender ? "self-end" : "self-start"}
      `}
        >
          {timestamp}
        </Box>
      </Box>
    </Box>
  );
};

const ArbitrationChat = () => {
  const chatDivRef = useRef<HTMLElement>();
  const inputRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);
  const caseDetailRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);

  const dispatch = useAppDispatch();
  const successDisptacher = useSuccessDispatcher();
  const errorDispatcher = useErrorDispatcher();
  const navigate = useNavigate();
  const params = useParams();
  const [message, setMessage] = useState("");
  const [allMessages, setAllMessages] =
    useState<Array<MessageStateObject> | null>(null);
  const [arbitrationData, setArbitrationData] =
    useState<ArbitrationStateObject | null>(null);
  const deferredArbitration = useDeferredValue(arbitrationData);
  const [fieldsData, setFieldsData] = useState<Array<FieldProps> | null>(null);
  const [socket, setSocket] = useState<Socket>();
  const [isVisible, setIsVisible] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(
    UNREAD_MESSAGE_STATE.EMPTY
  );
  const deferredIsVisible = useDeferredValue(isVisible);

  const auth = useAppSelector((state) => state?.authorization);
  const { token, role, permissions, newMessages, caseId } = auth;

  const isCurrentCase = Number(params?.caseId) === caseId;

  const hasAccessToPerformActions =
    deferredArbitration?.chatEnabled &&
    checkBoolean(permissions?.[Actions?.ArbitrationAction]);

  const [getArbitration, { data: arbitrationStateData }] =
    useLazyGetArbitrationByIdQuery();

  const [getChat, { data: chatStateData }] =
    useLazyGetArbitrationChatByIdQuery();

  const [updateArbitration] = useUpdateArbitrationMutation();

  const [updateArbitrationStatus] = useUpdateArbitrationStatusMutation();

  const [markAsRead] = useMarkReadArbitrationChatMutation();

  const handleSend = () => {
    if (message?.trim()) {
      if (socket?.connected) {
        const messageObject = {
          message: message?.trim(),
          arbitration_id: params?.caseId,
          timestamp: new Date().getTime(),
        };
        socket.emit(SocketEventName.ArbitrationMessage, messageObject);
        setMessage("");

        if (deferredIsVisible) setTimeout(() => scrollToBottom(), 400);

        const inputElement = inputRefs?.current?.[0] as HTMLInputElement;
        inputElement.value = "";
      }
    }
  };

  const mapDataToChatData = (chatData: any) => {
    return chatData?.map((chat: any) => {
      return {
        id: chat?.id,
        message: chat?.message,
        timestamp: formatTimeDifference(chat?.created_at),
        sender: isSender(role as UserTypes, chat?.sender_type),
      };
    });
  };

  const handleChange = (
    key: string,
    value: string,
    selection: number | null
  ) => {
    if (arbitrationData) {
      if (
        key === ARBITRATION_CHAT_FIELDS.ADJUSTMENT_AMOUNT &&
        !isNaN(Number(value))
      ) {
        setArbitrationData({
          ...arbitrationData,
          vehicle: {
            ...arbitrationData?.vehicle,
          },
          adjustmentAmount: value,
          adjustmentAmountSelection: selection || 0,
        });
      } else if (key === ARBITRATION_CHAT_FIELDS.REASON_OF_ARBITRATION) {
        setArbitrationData({
          ...arbitrationData,
          vehicle: {
            ...arbitrationData?.vehicle,
          },
          reason: value,
          reasonSelection: selection || 0,
        });
      } else if (key === ARBITRATION_CHAT_FIELDS.RECIEVED_BY_BUYER) {
        setArbitrationData({
          ...arbitrationData,
          vehicle: {
            ...arbitrationData?.vehicle,
          },
          receivedByBuyer: value,
          receivedByBuyerSelection: selection || 0,
        });
      }
    }
  };

  const handleSaveUpdate = async () => {
    const reason = arbitrationData?.reason?.trim();
    const adjustmentAmount = Number(
      arbitrationData?.adjustmentAmount?.toString()?.trim()
    );
    const receivedByBuyer = arbitrationData?.receivedByBuyer?.trim();
    const soldPrice = Number(arbitrationData?.vehicle?.soldPrice);
    if (adjustmentAmount >= soldPrice) {
      errorDispatcher(true, MESSAGES.ARBITRATION.ADJUSTMENT_AMOUNT_ERROR);
      return;
    }
    const hasChange = hasValueChanged();

    if (hasChange) {
      dispatch(toggleProgressBar(true));
      const response: any = await updateArbitration({
        id: arbitrationData?.id || "",
        body: {
          reason: reason || "",
          adjustment_amount: adjustmentAmount || 0,
          received_by_buyer: receivedByBuyer || "",
        },
      });
      dispatch(toggleProgressBar(false));

      if (!response?.error) {
        successDisptacher(
          true,
          MESSAGES.ARBITRATION.ARBITRATION_UPDATE_SUCCESS
        );
      }
    }
  };

  const handleStatusUpdate = async (key: string) => {
    if (arbitrationData) {
      dispatch(toggleProgressBar(true));
      const response: any = await updateArbitrationStatus({
        id: arbitrationData?.id,
        body: {
          status:
            key === ARBITRATION_CHAT_FIELDS.BUYER_BOUGHT
              ? ArbitrationStatus.Bought
              : ArbitrationStatus.Withdraw,
        },
      });
      dispatch(toggleProgressBar(false));

      if (!response?.error) {
        successDisptacher(
          true,
          MESSAGES.ARBITRATION.ARBITRATION_STATUS_UPDATE_SUCCESS
        );
      }
    }
  };

  const scrollToBottom = () => {
    chatDivRef.current?.scrollTo({ behavior: "smooth", top: 0 });
    if (newMessages && isCurrentCase)
      dispatch(setNewMessages({ status: false }));
  };

  const isAtBottomOfChatDiv = () => {
    if (chatDivRef.current) {
      const scrollableElement = chatDivRef.current;
      let isAtBottom =
        scrollableElement.scrollHeight - scrollableElement.scrollTop ===
        scrollableElement.clientHeight;

      if (scrollableElement.scrollTop > -300) {
        isAtBottom = true;
      }
      return isAtBottom;
    }
    return false;
  };

  const handleScrollChange = () => {
    if (chatDivRef.current) {
      const isAtBottom = isAtBottomOfChatDiv();

      if (newMessages && isCurrentCase && isAtBottom) {
        dispatch(setNewMessages({ status: false }));
        if (unreadMessages === UNREAD_MESSAGE_STATE.AVAILABLE) {
          setUnreadMessages(UNREAD_MESSAGE_STATE.PENDING);
        }
      }

      setIsVisible(!isAtBottom);
    }
  };

  const handleMarkAsRead = () => {
    const caseId = params?.caseId;
    if (caseId) {
      markAsRead(caseId);
    }
  };

  const hasValueChanged = () => {
    const oldStateData = {
      reason: arbitrationStateData?.data?.reason?.trim(),
      adjustmentAmount: Number(
        arbitrationStateData?.data?.adjustment_amount?.toString()?.trim()
      ),
      receivedByBuyer: arbitrationStateData?.data?.received_by_buyer?.trim(),
    };
    const currentData = {
      reason: arbitrationData?.reason?.trim(),
      adjustmentAmount: Number(
        arbitrationData?.adjustmentAmount?.toString()?.trim()
      ),
      receivedByBuyer: arbitrationData?.receivedByBuyer?.trim(),
    };

    return (
      oldStateData?.adjustmentAmount !== currentData?.adjustmentAmount ||
      oldStateData?.reason !== currentData?.reason ||
      oldStateData?.receivedByBuyer !== currentData?.receivedByBuyer
    );
  };

  useEffect(() => {
    if (unreadMessages === UNREAD_MESSAGE_STATE.PENDING) {
      handleMarkAsRead();
      setUnreadMessages(UNREAD_MESSAGE_STATE.EMPTY);
    }
  }, [unreadMessages]);

  useEffect(() => {
    const scrollableElement = chatDivRef.current;

    if (fieldsData && allMessages) {
      scrollableElement?.addEventListener("scroll", handleScrollChange);
    }

    return () => {
      scrollableElement?.removeEventListener("scroll", handleScrollChange);
    };
  }, [chatDivRef, fieldsData, allMessages, unreadMessages]);

  useEffect(() => {
    if (params?.caseId) {
      getArbitration(params?.caseId);
      getChat({ id: params?.caseId });
      setSocket(getSocket(token));
    }
  }, []);

  useEffect(() => {
    if (arbitrationStateData) {
      const data = arbitrationStateData?.data;
      const vehicle = data?.auction_vehicle?.vehicle;
      const auction = data?.auction_vehicle?.auction;
      const soldDate = auction?.start_time
        ? formatDate(
            auction?.start_time,
            DATE_TYPE.TIMESTAMP,
            DATE_FORMAT.DD_MM_YYYY
          )
        : auction?.auction_date
        ? formatDate(
            auction?.auction_date,
            DATE_TYPE.DATE_STRING,
            DATE_FORMAT.DD_MM_YYYY
          )
        : "-";
      const name = ADMIN_ROLES?.includes(role as UserTypes)
        ? vehicle?.dealer?.name
        : "Spectrum Representative";

      const unreadCount = data?.unread_count ? Number(data?.unread_count) : 0;

      const arbitrationData: ArbitrationStateObject = {
        name: name,
        id: data?.id,
        reason: data?.reason,
        receivedByBuyer: data?.received_by_buyer,
        chatEnabled: data?.chat_enabled,
        adjustmentAmount: data?.adjustment_amount,
        arbitrationStatus: ArbitrationStatus[data?.status],
        reasonSelection: data?.reason?.length || 0,
        adjustmentAmountSelection: data?.adjustment_amount?.length || 0,
        receivedByBuyerSelection: data?.received_by_buyer?.length || 0,
        vehicle: {
          vin: vehicle?.vin || "-",
          year: vehicle?.year || "-",
          make: vehicle?.make || "-",
          model: vehicle?.model || "-",
          soldPrice: vehicle?.sale_price || "-",
          soldDate: soldDate,
          location: vehicle?.location?.name || "-",
          psiStatus: vehicle?.psi_status?.status
            ? PSIStatus[vehicle?.psi_status?.status]
            : "Not Requested",
        },
      };

      if (unreadCount > 0) {
        handleMarkAsRead();
      }

      setArbitrationData(arbitrationData);
    }
  }, [arbitrationStateData]);

  useEffect(() => {
    if (deferredArbitration) {
      const fields = renderFields(deferredArbitration, permissions);
      setFieldsData(fields);
    }
  }, [deferredArbitration]);

  useEffect(() => {
    const inputsArr = caseDetailRefs?.current;
    if (inputsArr?.length) {
      for (let i = 0; i < inputsArr?.length; i++) {
        switch (i) {
          case NUMBER.ZERO:
            (inputsArr[i] as HTMLInputElement)?.setSelectionRange(
              arbitrationData?.reasonSelection || 0,
              arbitrationData?.reasonSelection || 0
            );
            break;
          case NUMBER.ONE:
            (inputsArr[i] as HTMLInputElement)?.setSelectionRange(
              arbitrationData?.receivedByBuyerSelection || 0,
              arbitrationData?.receivedByBuyerSelection || 0
            );
            break;
          case NUMBER.TWO:
            (inputsArr[i] as HTMLInputElement)?.setSelectionRange(
              arbitrationData?.adjustmentAmountSelection || 0,
              arbitrationData?.adjustmentAmountSelection || 0
            );
            break;
          default:
            break;
        }
      }
    }
  }, [fieldsData]);

  useEffect(() => {
    if (chatStateData) {
      const isAtBottom = isAtBottomOfChatDiv();
      const chatData = mapDataToChatData(chatStateData?.data?.messages);
      setAllMessages(chatData);
      if (newMessages && isCurrentCase) {
        if (isAtBottom) {
          handleMarkAsRead();
          dispatch(setNewMessages({ status: false }));
        } else {
          if (unreadMessages === UNREAD_MESSAGE_STATE.EMPTY) {
            setUnreadMessages(UNREAD_MESSAGE_STATE.AVAILABLE);
          }
        }
      }
    } else {
      dispatch(setNewMessages({ status: false }));
    }
  }, [chatStateData]);

  return (
    <Box className="w-full h-full">
      <Box className="pb-6">
        <KeyboardBackspaceIcon
          fontSize={SIZES.LARGE}
          className="cursor-pointer"
          onClick={() =>
            navigate(FRONTEND_ROUTES.ARBITRATION, {
              state: { title: PAGE_TITLE.ARBITRATION },
            })
          }
        />
      </Box>
      {!fieldsData || !allMessages ? (
        <Loader />
      ) : (
        <Box className="h-[75vh] grid grid-cols-1 xl:grid-cols-6 gap-0 xl:gap-4">
          <Box className="bg-primary-100 border-[1px] h-full rounded-lg col-span-4 xl:col-span-4 relative">
            <Box className="border-b-[1px] p-4">
              {deferredArbitration?.name}
            </Box>
            <Box
              className="h-[60vh] p-4 overflow-y-auto flex flex-col-reverse table-scrollbar"
              ref={chatDivRef}
            >
              {!allMessages?.length ? (
                <Box className="mx-auto text-sm text-gray-400">
                  Start Conversation
                </Box>
              ) : (
                allMessages?.map((message) => (
                  <ChatBubble
                    key={message.id}
                    sender={message?.sender}
                    message={message?.message}
                    timestamp={message?.timestamp}
                  />
                ))
              )}

              {deferredIsVisible && (
                <Box className="absolute left-0 right-0 mx-auto w-fit flex flex-col items-center justify-center">
                  {newMessages && isCurrentCase && (
                    <Box className="text-xs mb-2 text-tertiary-100">
                      New Messages
                    </Box>
                  )}
                  <Box
                    className="shadow rounded-full w-8 h-8 flex items-center justify-center bottom-[90px] cursor-pointer border-tertiary-400 border hover:border-tertiary-100"
                    onClick={scrollToBottom}
                  >
                    <ArrowDownwardIcon />
                  </Box>
                </Box>
              )}
            </Box>
            <Box className="p-4 border-t-[1px]">
              <FieldComponent
                inputRefs={inputRefs}
                className="w-full"
                inputClassName="bg-primary-300"
                placeholder="Write Something..."
                size={SIZES.SMALL}
                type={CONSTANTS.SEND}
                // value={deferredValue}
                hideErrorMessage={true}
                handleChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setMessage(event?.target?.value)
                }
                handleSend={handleSend}
                onPressEnter={handleSend}
                disabled={!deferredArbitration?.chatEnabled}
              />
            </Box>
          </Box>

          <Box className="bg-primary-100 border-[1px] h-full rounded-lg min-h-[75vh] overflow-auto table-scrollbar py-14 px-8 mt-4 xl:mt-0 xl:col-span-2">
            {fieldsData?.map((field) => (
              <Box className="flex items-start justify-center my-2">
                <Box className="font-bold text-right mr-5 w-48 whitespace-nowrap  min-w-[200px]">
                  {field?.title}
                  {field?.type !== CONSTANTS.BUTTON && ":"}
                </Box>
                <Box
                  className={`text-left w-64 min-w-[200px] ${
                    field?.key === ARBITRATION_CHAT_FIELDS.ARBITRATION_STATUS &&
                    "font-bold"
                  }`}
                >
                  {field?.type === CONSTANTS.INPUT ||
                  field?.type === CONSTANTS.TEXTAREA ? (
                    <FieldComponent
                      index={field?.inputIndex}
                      inputRefs={caseDetailRefs}
                      rows={3}
                      className="w-full"
                      inputClassName="bg-primary-300"
                      size={SIZES.SMALL}
                      value={field?.value}
                      type={CONSTANTS.TEXT}
                      hideErrorMessage={true}
                      multiline={field?.type === CONSTANTS.TEXTAREA && true}
                      handleChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleChange(
                          field?.key,
                          event?.target?.value,
                          event?.target?.selectionStart
                        )
                      }
                    />
                  ) : field?.type === CONSTANTS.BUTTON && !field?.hide ? (
                    <ButtonComponent
                      text="Save"
                      className="w-full max-w-[250px] self-end no-margin"
                      size={SIZES.SMALL}
                      color={CONSTANTS.PRIMARY}
                      onClick={handleSaveUpdate}
                    />
                  ) : (
                    field?.value
                  )}
                </Box>
              </Box>
            ))}

            {hasAccessToPerformActions && (
              <Box className="flex flex-col items-center justify-center w-full mt-10">
                <Box className="flex items-center justify-center w-full">
                  <ButtonComponent
                    text="Buyer Bought"
                    className="w-full max-w-[250px]"
                    size={SIZES.SMALL}
                    color={CONSTANTS.PRIMARY}
                    onClick={() =>
                      handleStatusUpdate(ARBITRATION_CHAT_FIELDS.BUYER_BOUGHT)
                    }
                  />
                  <ButtonComponent
                    text="Buyer Withdraw"
                    className="w-full max-w-[250px]"
                    size={SIZES.SMALL}
                    color={CONSTANTS.PRIMARY}
                    onClick={() =>
                      handleStatusUpdate(ARBITRATION_CHAT_FIELDS.BUYER_WITHDRAW)
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ArbitrationChat;
