import * as Yup from "yup";
import {
  passwordRegex,
  // phoneRegexUnitedStates
} from "../../misc/utils";
import { ERROR_MESSAGES } from "../../misc/errorMessages";
import { UserTypes } from "../../misc/enum";
import { ADMIN_ROLES } from "../../misc/constants";

export const userSchema = Yup.object().shape({
  username: Yup.string()
    .min(6, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.USERNAME),
  firstName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.FIRST_NAME),
  lastName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.LAST_NAME),
  contactNumber: Yup.string()
    .required(ERROR_MESSAGES.COMMON.CONTACT_NUMBER)
    .test("minLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MIN, (value) =>
      value ? value.replace(/\s/g, "").length > 11 : false
    )
    .test("maxLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MAX, (value) =>
      value ? value.replace(/\s/g, "").length < 17 : false
    ),
  // .matches(phoneRegexUnitedStates, ERROR_MESSAGES.COMMON.US_CONTACT_NUMBER),
  email: Yup.string()
    .email(ERROR_MESSAGES.COMMON.INVALID_EMAIL)
    .required(ERROR_MESSAGES.COMMON.EMAIL),
  password: Yup.string()
    .required(ERROR_MESSAGES.COMMON.PASSWORD)
    .min(8, ERROR_MESSAGES.COMMON.PASSWORD_LENGTH)
    .matches(passwordRegex, ERROR_MESSAGES.COMMON.PASSWORD_VALIDATION),
  confirmPassword: Yup.string()
    .required(ERROR_MESSAGES.COMMON.CONFIRM_PASSWORD)
    .oneOf([Yup.ref("password"), ""], ERROR_MESSAGES.COMMON.PASSWORD_MATCH),
  userRole: Yup.number().required(ERROR_MESSAGES.COMMON.USER_ROLE),
});

export const userEditSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.FIRST_NAME),
  lastName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.LAST_NAME),
  userRole: Yup.number().required(ERROR_MESSAGES.COMMON.USER_ROLE),
});

export const dealerSchema = Yup.object().shape({
  millionNumber: Yup.string()
    .min(6, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.MILLION_NUMBER),
  email: Yup.string()
    .email(ERROR_MESSAGES.COMMON.INVALID_EMAIL)
    .required(ERROR_MESSAGES.COMMON.EMAIL),
  dealerName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.DEALER_NAME),
  firstName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.FIRST_NAME),
  lastName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.LAST_NAME),
  username: Yup.string()
    .min(6, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.USERNAME),
  password: Yup.string()
    .required(ERROR_MESSAGES.COMMON.PASSWORD)
    .min(8, ERROR_MESSAGES.COMMON.PASSWORD_LENGTH)
    .matches(passwordRegex, ERROR_MESSAGES.COMMON.PASSWORD_VALIDATION),
  contactNumber: Yup.string()
    .required(ERROR_MESSAGES.COMMON.CONTACT_NUMBER)
    .test("minLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MIN, (value) =>
      value ? value.replace(/\s/g, "").length > 11 : false
    )
    .test("maxLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MAX, (value) =>
      value ? value.replace(/\s/g, "").length < 17 : false
    ),
  // .matches(phoneRegexUnitedStates, ERROR_MESSAGES.COMMON.US_CONTACT_NUMBER),
  location: Yup.string().required(ERROR_MESSAGES.COMMON.LOCATION),
  state: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.STATE),
  address: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.ADDRESS),
  zipCode: Yup.string()
    .length(5, ERROR_MESSAGES.COMMON.ZIP_CODE_LENGTH)
    .required(ERROR_MESSAGES.COMMON.ZIP_CODE),
});

export const dealerEditSchema = Yup.object().shape({
  millionNumber: Yup.string()
    .min(6, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.MILLION_NUMBER),
  dealerName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.DEALER_NAME),
  firstName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.FIRST_NAME),
  lastName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.LAST_NAME),
  location: Yup.string().required(ERROR_MESSAGES.COMMON.LOCATION),
  state: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.STATE),
  address: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.ADDRESS),
  zipCode: Yup.string()
    .length(5, ERROR_MESSAGES.COMMON.ZIP_CODE_LENGTH)
    .required(ERROR_MESSAGES.COMMON.ZIP_CODE),
});

export const dealerUserSchema = Yup.object().shape({
  dealershipName: Yup.string().required(ERROR_MESSAGES.COMMON.DEALER_SHIP),
  email: Yup.string()
    .email(ERROR_MESSAGES.COMMON.INVALID_EMAIL)
    .required(ERROR_MESSAGES.COMMON.EMAIL),
  firstName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.FIRST_NAME),
  lastName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.LAST_NAME),
  username: Yup.string()
    .min(6, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.USERNAME),
  password: Yup.string()
    .required(ERROR_MESSAGES.COMMON.PASSWORD)
    .min(8, ERROR_MESSAGES.COMMON.PASSWORD_LENGTH)
    .matches(passwordRegex, ERROR_MESSAGES.COMMON.PASSWORD_VALIDATION),
  contactNumber: Yup.string()
    .required(ERROR_MESSAGES.COMMON.CONTACT_NUMBER)
    .test("minLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MIN, (value) =>
      value ? value.replace(/\s/g, "").length > 11 : false
    )
    .test("maxLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MAX, (value) =>
      value ? value.replace(/\s/g, "").length < 17 : false
    ),
  // .matches(phoneRegexUnitedStates, ERROR_MESSAGES.COMMON.US_CONTACT_NUMBER),
  location: Yup.string().required(ERROR_MESSAGES.COMMON.LOCATION),
  dealerRole: Yup.string().required(ERROR_MESSAGES.COMMON.DEALER_ROLE),
  state: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.STATE),
  address: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.ADDRESS),
  zipCode: Yup.string()
    .length(5, ERROR_MESSAGES.COMMON.ZIP_CODE_LENGTH)
    .required(ERROR_MESSAGES.COMMON.ZIP_CODE),
});

export const dealerUserEditSchema = Yup.object().shape({
  dealershipName: Yup.string().required(ERROR_MESSAGES.COMMON.DEALER_SHIP),
  firstName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.FIRST_NAME),
  lastName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.LAST_NAME),
  location: Yup.string().required(ERROR_MESSAGES.COMMON.LOCATION),
  dealerRole: Yup.string().required(ERROR_MESSAGES.COMMON.DEALER_ROLE),
  state: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.STATE),
  address: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.ADDRESS),
  zipCode: Yup.string()
    .length(5, ERROR_MESSAGES.COMMON.ZIP_CODE_LENGTH)
    .required(ERROR_MESSAGES.COMMON.ZIP_CODE),
});

export const transporterSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(6, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.COMPANY_NAME),
  email: Yup.string()
    .email(ERROR_MESSAGES.COMMON.INVALID_EMAIL)
    .required(ERROR_MESSAGES.COMMON.EMAIL),
  firstName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.FIRST_NAME),
  lastName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.LAST_NAME),
  username: Yup.string()
    .min(6, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.USERNAME),
  password: Yup.string()
    .required(ERROR_MESSAGES.COMMON.PASSWORD)
    .min(8, ERROR_MESSAGES.COMMON.PASSWORD_LENGTH)
    .matches(passwordRegex, ERROR_MESSAGES.COMMON.PASSWORD_VALIDATION),
  contactNumber: Yup.string()
    .required(ERROR_MESSAGES.COMMON.CONTACT_NUMBER)
    .test("minLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MIN, (value) =>
      value ? value.replace(/\s/g, "").length > 11 : false
    )
    .test("maxLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MAX, (value) =>
      value ? value.replace(/\s/g, "").length < 17 : false
    ),
  // .matches(phoneRegexUnitedStates, ERROR_MESSAGES.COMMON.US_CONTACT_NUMBER),
  location: Yup.string().required(ERROR_MESSAGES.COMMON.LOCATION),
  state: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.STATE),
  address: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.ADDRESS),
  zipCode: Yup.string()
    .length(5, ERROR_MESSAGES.COMMON.ZIP_CODE_LENGTH)
    .required(ERROR_MESSAGES.COMMON.ZIP_CODE),
});

export const transporterEditSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(6, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.COMPANY_NAME),
  firstName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.FIRST_NAME),
  lastName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.LAST_NAME),
  location: Yup.string().required(ERROR_MESSAGES.COMMON.LOCATION),
  state: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.STATE),
  address: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.ADDRESS),
  zipCode: Yup.string()
    .length(5, ERROR_MESSAGES.COMMON.ZIP_CODE_LENGTH)
    .required(ERROR_MESSAGES.COMMON.ZIP_CODE),
});

export const transporterUserSchema = Yup.object().shape({
  companyName: Yup.string().required(ERROR_MESSAGES.COMMON.COMPANY_NAME),
  email: Yup.string()
    .email(ERROR_MESSAGES.COMMON.INVALID_EMAIL)
    .required(ERROR_MESSAGES.COMMON.EMAIL),
  firstName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.FIRST_NAME),
  lastName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.LAST_NAME),
  username: Yup.string()
    .min(6, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.USERNAME),
  password: Yup.string()
    .required(ERROR_MESSAGES.COMMON.PASSWORD)
    .min(8, ERROR_MESSAGES.COMMON.PASSWORD_LENGTH)
    .matches(passwordRegex, ERROR_MESSAGES.COMMON.PASSWORD_VALIDATION),
  contactNumber: Yup.string()
    .required(ERROR_MESSAGES.COMMON.CONTACT_NUMBER)
    .test("minLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MIN, (value) =>
      value ? value.replace(/\s/g, "").length > 11 : false
    )
    .test("maxLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MAX, (value) =>
      value ? value.replace(/\s/g, "").length < 17 : false
    ),
  // .matches(phoneRegexUnitedStates, ERROR_MESSAGES.COMMON.US_CONTACT_NUMBER),
  location: Yup.string().required(ERROR_MESSAGES.COMMON.LOCATION),
  state: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.STATE),
  address: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.ADDRESS),
  zipCode: Yup.string()
    .length(5, ERROR_MESSAGES.COMMON.ZIP_CODE_LENGTH)
    .required(ERROR_MESSAGES.COMMON.ZIP_CODE),
});

export const transporterUserEditSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.FIRST_NAME),
  lastName: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.LAST_NAME),
  location: Yup.string().required(ERROR_MESSAGES.COMMON.LOCATION),
  state: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.STATE),
  address: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.ADDRESS),
  zipCode: Yup.string()
    .length(5, ERROR_MESSAGES.COMMON.ZIP_CODE_LENGTH)
    .required(ERROR_MESSAGES.COMMON.ZIP_CODE),
});

export const createAuctionSchema = Yup.object().shape({
  location: Yup.string().required(ERROR_MESSAGES.COMMON.LOCATION),
  date: Yup.string().required(ERROR_MESSAGES.COMMON.DATE),
});

export const assignAuctionSchema = Yup.object().shape({
  auction: Yup.string().required(ERROR_MESSAGES.COMMON.AUCTION),
});

export const requestCRSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.NAME),
  contactNumber: Yup.string()
    .required(ERROR_MESSAGES.COMMON.CONTACT_NUMBER)
    .test("minLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MIN, (value) =>
      value ? value.replace(/\s/g, "").length > 11 : false
    )
    .test("maxLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MAX, (value) =>
      value ? value.replace(/\s/g, "").length < 17 : false
    ),
  // .matches(phoneRegexUnitedStates, ERROR_MESSAGES.COMMON.US_CONTACT_NUMBER),
  date: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.DATE),
  time: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.TIME),
  remarks: Yup.string(),
});

export const requestTransportSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.NAME),
  contactNumber: Yup.string()
    .required(ERROR_MESSAGES.COMMON.CONTACT_NUMBER)
    .test("minLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MIN, (value) =>
      value ? value.replace(/\s/g, "").length > 11 : false
    )
    .test("maxLength", ERROR_MESSAGES.COMMON.CONTACT_NUMBER_MAX, (value) =>
      value ? value.replace(/\s/g, "").length < 17 : false
    ),
  // .matches(phoneRegexUnitedStates, ERROR_MESSAGES.COMMON.US_CONTACT_NUMBER),
  pickupDate: Yup.string().required(ERROR_MESSAGES.COMMON.PICKUP_DATE),
  location: Yup.string().required(ERROR_MESSAGES.COMMON.DROPOFF_LOCATION),
  remarks: Yup.string(),
});

export const bulkCsvDealerAdminSchema = Yup.object().shape({
  dealerLocation: Yup.string().required(ERROR_MESSAGES.COMMON.LOCATION),
});

export const bulkCsvSpectrumAdminSchema = Yup.object().shape({
  dealer: Yup.string().required(ERROR_MESSAGES.COMMON.DEALER),
  dealerLocation: Yup.string().required(ERROR_MESSAGES.COMMON.LOCATION),
});

export const generateAddVehicleSchema = (role: UserTypes) => {
  const isSpectrumAdmin = ADMIN_ROLES.includes(role);
  const isDealerAdmin = role === UserTypes.DealerAdmin;

  const schema: any = {
    acquiredFrom: Yup.string().required(ERROR_MESSAGES.COMMON.ACQUIRED_FROM),
    year: Yup.string()
      .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
      .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
      .required(ERROR_MESSAGES.COMMON.YEAR),
    make: Yup.string()
      .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
      .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
      .required(ERROR_MESSAGES.COMMON.MAKE),
    model: Yup.string()
      .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
      .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
      .required(ERROR_MESSAGES.COMMON.MODEL),
    exteriorColor: Yup.string()
      .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
      .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
      .required(ERROR_MESSAGES.COMMON.EXTERIOR_COLOR),
    mileage: Yup.string()
      .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
      .max(7, ERROR_MESSAGES.COMMON.TOO_LONG)
      .required(ERROR_MESSAGES.COMMON.MILEAGE),
    floorPrice: Yup.number()
      .typeError(ERROR_MESSAGES.COMMON.ONLY_NUMERIC_VALUE)
      .min(0, ERROR_MESSAGES.COMMON.ONLY_POSITIVE_VALUE)
      .notRequired(),
    announcements: Yup.string().required(ERROR_MESSAGES.COMMON.ANNOUNCEMENT),
    remarks: Yup.array().of(Yup.string()),
    dealerStockNumber: Yup.string().notRequired(),
    acv: Yup.number()
      .typeError(ERROR_MESSAGES.COMMON.ONLY_NUMERIC_VALUE)
      .min(0, ERROR_MESSAGES.COMMON.ONLY_POSITIVE_VALUE)
      .notRequired(),
    vin: Yup.string().length(17, ERROR_MESSAGES.COMMON.VIN_LENGTH),
    comment: Yup.string().notRequired(),
  };

  if (isDealerAdmin) {
    schema["dealerLocation"] = Yup.string().required(
      ERROR_MESSAGES.COMMON.LOCATION
    );
  }
  if (isSpectrumAdmin) {
    schema["dealer"] = Yup.string().required(ERROR_MESSAGES.COMMON.DEALER);
    schema["dealerLocation"] = Yup.string().required(
      ERROR_MESSAGES.COMMON.LOCATION
    );
  }

  return Yup.object().shape(schema);
};

export const updateVehicleSchema = (
  role: UserTypes,
  isAuctionRoute: boolean
) => {
  const isSpectrumAdmin = ADMIN_ROLES.includes(role);
  const isDealerAdmin = role === UserTypes.DealerAdmin;

  const schema: any = {
    acquiredFrom: Yup.string().required(ERROR_MESSAGES.COMMON.ACQUIRED_FROM),
    mileage: Yup.string()
      .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
      .max(7, ERROR_MESSAGES.COMMON.TOO_LONG)
      .required(ERROR_MESSAGES.COMMON.MILEAGE),
    floorPrice: Yup.number()
      .typeError(ERROR_MESSAGES.COMMON.ONLY_NUMERIC_VALUE)
      .min(0, ERROR_MESSAGES.COMMON.ONLY_POSITIVE_VALUE)
      .required(ERROR_MESSAGES.COMMON.FLOOR_PRICE),
    exteriorColor: Yup.string()
      .min(2, ERROR_MESSAGES.COMMON.TOO_SHORT)
      .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
      .required(ERROR_MESSAGES.COMMON.EXTERIOR_COLOR),
    announcements: Yup.string().required(ERROR_MESSAGES.COMMON.ANNOUNCEMENT),
    remarks: Yup.array().of(Yup.string()),
    dealerStockNumber: Yup.string().notRequired(),
    acv: Yup.number()
      .typeError(ERROR_MESSAGES.COMMON.ONLY_NUMERIC_VALUE)
      .notRequired()
      .min(0, ERROR_MESSAGES.COMMON.ONLY_POSITIVE_VALUE),
    comment: Yup.string().notRequired(),
  };

  if ((isDealerAdmin || isSpectrumAdmin) && !isAuctionRoute) {
    schema["dealerLocation"] = Yup.string().required(
      ERROR_MESSAGES.COMMON.DEALER
    );
  }

  return Yup.object().shape(schema);
};

export const loginSchema = Yup.object().shape({
  signature: Yup.string()
    .min(6, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.PROVIDE_EMAIL_OR_USERNAME),
  password: Yup.string()
    .required(ERROR_MESSAGES.COMMON.PASSWORD)
    .min(8, ERROR_MESSAGES.COMMON.PASSWORD_LENGTH)
    .matches(passwordRegex, ERROR_MESSAGES.COMMON.PASSWORD_VALIDATION),
});

export const resetPasswordSchema = Yup.object().shape({
  signature: Yup.string()
    .min(6, ERROR_MESSAGES.COMMON.TOO_SHORT)
    .max(50, ERROR_MESSAGES.COMMON.TOO_LONG)
    .required(ERROR_MESSAGES.COMMON.PROVIDE_EMAIL_OR_USERNAME),
});

export const updatePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(ERROR_MESSAGES.COMMON.PASSWORD)
    .min(8, ERROR_MESSAGES.COMMON.PASSWORD_LENGTH)
    .matches(passwordRegex, ERROR_MESSAGES.COMMON.PASSWORD_VALIDATION),
  confirmPassword: Yup.string()
    .required(ERROR_MESSAGES.COMMON.CONFIRM_PASSWORD)
    .oneOf([Yup.ref("password"), ""], ERROR_MESSAGES.COMMON.PASSWORD_MATCH),
});

export const addLocationSchema = Yup.object().shape({
  name: Yup.string().required(ERROR_MESSAGES.COMMON.LOCATION_NAME),
  timezone: Yup.string().required(ERROR_MESSAGES.COMMON.TIMEZONE),
});

export const addAnnouncementSchema = Yup.object().shape({
  label: Yup.string().required(ERROR_MESSAGES.COMMON.LABEL),
});

export const addRemarkSchema = Yup.object().shape({
  label: Yup.string().required(ERROR_MESSAGES.COMMON.LABEL),
});

export const filterSchema = () => {
  return Yup.object().shape(
    {
      startAge: Yup.date().when("endAge", {
        is: (val: Date) => val && true,
        then: (schema) => schema.required(ERROR_MESSAGES.COMMON.SELECT_FROM),
      }),
      endAge: Yup.date().when("startAge", {
        is: (val: Date) => val && true,
        then: (schema) => schema.required(ERROR_MESSAGES.COMMON.SELECT_TO),
      }),
      startYear: Yup.date().when("endYear", {
        is: (val: Date) => val && true,
        then: (schema) => schema.required(ERROR_MESSAGES.COMMON.SELECT_FROM),
      }),
      endYear: Yup.date().when("startYear", {
        is: (val: Date) => val && true,
        then: (schema) => schema.required(ERROR_MESSAGES.COMMON.SELECT_TO),
      }),
      startSoldDate: Yup.date().when("endSoldDate", {
        is: (val: Date) => val && true,
        then: (schema) => schema.required(ERROR_MESSAGES.COMMON.SELECT_FROM),
      }),
      endSoldDate: Yup.date().when("startSoldDate", {
        is: (val: Date) => val && true,
        then: (schema) => schema.required(ERROR_MESSAGES.COMMON.SELECT_TO),
      }),
    },
    [
      ["startAge", "endAge"],
      ["startYear", "endYear"],
      ["startSoldDate", "endSoldDate"],
    ]
  );
};
