import { Dispatch } from "@reduxjs/toolkit";
import { axiosBaseInstance } from "../../config/axios.config";
import {
  resetAuthData,
  setAuthData,
  setError,
  setSuccess,
} from "../reducer/auth";
import {
  LoginPayload,
  ResetPasswordPayload,
  UpdatePasswordPayload,
} from "../../ts/api.types";
import { API_ENDPOINT } from "../../misc/enum";
import { RootState, persistor } from "../index";
import { baseAPI } from "../reducer/baseSlice";
import { MESSAGES } from "../../misc/messages";
import { deActivateSocket } from "../socket";
import { toggleModal } from "../reducer/modal";
import { toggleProgressBar } from "../reducer/progressBar";

export const login = (payload: LoginPayload) => async (dispatch: Dispatch) => {
  try {
    const response = await axiosBaseInstance.post(API_ENDPOINT.LOGIN, payload);
    if (response?.data?.message === MESSAGES.API.SUCCESS) {
      dispatch(
        setAuthData({
          signature: payload.signature,
          role: Number(response?.data?.data?.user?.role_id),
          token: response?.data?.data?.token,
          permissions: response?.data?.data?.user?.permission,
          userId: response?.data?.data?.user?.id,
          isAuthenticated: true,
        })
      );
      dispatch(
        setSuccess({
          successMessage: MESSAGES.AUTH.LOGIN_SUCCESS,
          success: true,
        })
      );
      return true;
    } else {
      dispatch(
        setError({
          error: true,
          errorMessage: MESSAGES.API.ERROR_OCCURED,
        })
      );
      return false;
    }
  } catch (err: any) {
    dispatch(
      setError({
        error: true,
        errorMessage:
          err?.response?.data?.message ||
          err?.message ||
          MESSAGES.API.ERROR_OCCURED,
      })
    );
    return false;
  }
};

export const resetPassword =
  (payload: ResetPasswordPayload) => async (dispatch: Dispatch) => {
    try {
      const response = await axiosBaseInstance.post(
        API_ENDPOINT.RESET_PASSWORD,
        payload
      );
      if (response?.data?.message === MESSAGES.API.SUCCESS) {
        dispatch(
          setSuccess({
            successMessage: MESSAGES.AUTH.PASSWORD_RESET_LINK_SUCCESS,
            success: true,
          })
        );
        return true;
      } else {
        dispatch(
          setError({
            error: true,
            errorMessage: MESSAGES.API.ERROR_OCCURED,
          })
        );
        return false;
      }
    } catch (err: any) {
      dispatch(
        setError({
          error: true,
          errorMessage:
            err?.response?.data?.message ||
            err?.message ||
            MESSAGES.API.ERROR_OCCURED,
        })
      );
      return false;
    }
  };

export const updatePassword =
  (payload: UpdatePasswordPayload) => async (dispatch: Dispatch) => {
    try {
      const response = await axiosBaseInstance.post(
        API_ENDPOINT.UPDATE_PASSWORD,
        payload
      );
      if (response?.data?.message === MESSAGES.API.SUCCESS) {
        dispatch(
          setSuccess({
            successMessage: MESSAGES.AUTH.PASSWORD_UPDATE_SUCCESS,
            success: true,
          })
        );
        return true;
      } else {
        dispatch(
          setError({
            error: true,
            errorMessage: MESSAGES.API.ERROR_OCCURED,
          })
        );
        return false;
      }
    } catch (err: any) {
      dispatch(
        setError({
          error: true,
          errorMessage:
            err?.response?.data?.message ||
            err?.message ||
            MESSAGES.API.ERROR_OCCURED,
        })
      );
      return false;
    }
  };

export const logout =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const { authorization: auth } = getState();
    try {
      await axiosBaseInstance.post(API_ENDPOINT.LOGOUT, null, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
    } catch {
    } finally {
      await persistor.purge();
      deActivateSocket(true);
      dispatch(resetAuthData());
      dispatch(baseAPI.util.resetApiState());
      dispatch(
        toggleModal({
          open: false,
        })
      );
      dispatch(toggleProgressBar(false));
    }
  };
