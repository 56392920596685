import { API_ENDPOINT } from "../../misc/enum";
import {
  CreateSpectrumUserPayload,
  UpdateSpectrumUserPayload,
  DeleteSpectrumUserPayload,
} from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const spectrumUserSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getSpectrumUsers: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.USER}?${params}`,
      }),
      providesTags: ["SpectrumUser"],
    }),
    createSpectrumUser: builder.mutation({
      query: (payload: CreateSpectrumUserPayload) => ({
        method: "POST",
        url: `${API_ENDPOINT.SPECTRUM_USER}`,
        body: payload,
      }),
      invalidatesTags: ["SpectrumUser"],
    }),
    updateSpectrumUser: builder.mutation({
      query: ({id, payload}: UpdateSpectrumUserPayload) => ({
        url: `${API_ENDPOINT.SPECTRUM_USER}/${id}`,
        method: "PUT",
        body: {...payload},
      }),
      invalidatesTags: ["SpectrumUser"],
    }),
    deleteSpectrumUser: builder.mutation({
      query: ({ id }: DeleteSpectrumUserPayload) => ({
        url: `${API_ENDPOINT.SPECTRUM_USER}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetSpectrumUsersQuery,
  useCreateSpectrumUserMutation,
  useUpdateSpectrumUserMutation,
  useDeleteSpectrumUserMutation,
} = spectrumUserSlice;
