import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import TableComponent from "./../components/TableComponent";
import Box from "@mui/material/Box";
import ButtonContainer from "../components/ButtonContainer";
import { dealerUserColumns, dealerUserCreateData } from "../misc/createData";
import { useLazyGetDealerUsersQuery } from "../store/reducer/dealerUser";
import {
  DATE_FORMAT,
  DATE_TYPE,
  MODAL_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
  SIZES,
  TABLE_COLUMNS,
  USER_TYPE_NAME,
} from "../misc/constants";

import { toggleModal } from "../store/reducer/modal";
import { UserTypes } from "../misc/enum";
import { appendQueryParams } from "../misc/api.utils";
import { useAppSelector } from "../hooks/redux-toolkit";
import {
  checkBoolean,
  formatDate,
  getRowData,
  prioritizeAndMergeObject,
  getEditModalValues,
} from "../misc/utils";
import { useReactToPrint } from "react-to-print";
import { Actions, Tabs } from "../misc/access.enum";
import { Column, ModalSelectOption } from "../ts/component.types";
import { useLazyGetDealersQuery } from "../store/reducer/dealer";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { MESSAGES } from "../misc/messages";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";

const DealerUsers = () => {
  const reference = useRef<HTMLTableElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.DEALER_USERS,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const dispatch = useDispatch();
  const errorDispatcher = useErrorDispatcher();
  const { permissions } = useAppSelector((state) => state.authorization);
  const [getDealerUsers, { data: dealerUserStateData }] =
    useLazyGetDealerUsersQuery();
  const [getDealers, { data: dealerStateData }] = useLazyGetDealersQuery();
  const [fieldsData, setFieldsData] = useState<Column[]>([]);
  const [dealerUserRows, setDealerUserRows] = useState<[] | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    activeStatus: "active",
    userRole: UserTypes.DealerAuctionUser.toString(),
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    contactNo: "",
    dealerName: "",
  });

  const handleExport = () => {
    const usersData = dealerUserStateData?.data?.users;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.DealerUsersTab,
      usersData
    );

    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Dealer Users (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const handleDealerUser = () => {
    dispatch(
      toggleModal({
        open: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.DEALER_USER,
      })
    );
  };

  const buttons = [
    {
      label: "Add New Dealer User",
      color: "primary",
      onClick: handleDealerUser,
      size: SIZES.SMALL,
      access: checkBoolean(permissions?.[Actions.AddDealerUser]),
    },
  ];

  const mapDataToTableRows = (users: any) => {
    return users.map((user: any) => {
      return dealerUserCreateData(
        user?.email || "N/A",
        user?.first_name || "N/A",
        user?.last_name || "N/A",
        user?.country_code + user?.phone_number || "N/A",
        user?.dealer_user?.dealer?.name || "N/A",
        user?.id || "N/A",
        USER_TYPE_NAME[user?.role_id as UserTypes],
        user?.is_active,
        !checkBoolean(permissions?.[Actions.UpdateDealerUser])
      );
    });
  };

  const parseData = (
    data: readonly Column[],
    dealersData: Array<ModalSelectOption>
  ) =>
    data?.map((field) => {
      if (field.id === TABLE_COLUMNS.DEALER_NAME && dealersData) {
        const dealers = dealersData?.map((dealer) => {
          return {
            label: dealer.name,
            value: dealer.id,
          };
        });
        dealers.push({
          label: "All",
          value: NUMBER.MINUS_ONE,
        });
        return {
          ...field,
          options: dealers,
        };
      }

      return field;
    });

  useEffect(() => {
    const queryParamObj = {
      isActive: "active",
    };
    const apiParams = appendQueryParams(queryParamObj, false);
    if (!dealerStateData) {
      getDealers(apiParams);
      return;
    }
    const modifiedFields = parseData(
      dealerUserColumns,
      dealerStateData?.data?.dealers
    );
    setFieldsData(modifiedFields);
  }, [dealerStateData]); //eslint-disable-line

  async function fetchData(queryParams: DynamicKeyValuesWithNumArray) {
    let role_ids = [];

    if (
      queryParams.userRole?.toString() ===
      UserTypes.DealerAuctionUser?.toString()
    ) {
      role_ids.push(UserTypes.DealerAuctionUser);
    } else if (
      queryParams.userRole?.toString() ===
      UserTypes.DealerInventoryUser?.toString()
    ) {
      role_ids.push(UserTypes.DealerInventoryUser);
    } else {
      role_ids.push(UserTypes.DealerAuctionUser);
      role_ids.push(UserTypes.DealerInventoryUser);
    }

    let apiParams = ``;

    const queryParamObj: DynamicKeyValuesWithNumArray = {
      page: (queryParams.page as number) + 1,
      limit: queryParams.rowsPerPage as number,
      id: queryParams.id as string,
      isActive: queryParams.activeStatus as string,
      contactNo: queryParams.contactNo as string,
      firstName: queryParams.firstName as string,
      lastName: queryParams.lastName as string,
      email: queryParams.email as string,
      userRole: role_ids,
    };

    const dealerId = queryParams.dealerName;

    if (dealerId && Number(dealerId) >= NUMBER.ZERO)
      queryParamObj["dealerId"] = dealerId;

    apiParams += appendQueryParams(queryParamObj, false);

    try {
      const response = await getDealerUsers(apiParams);

      const data = response?.data?.data;
      const users = data?.users;
      const count = data?.count;

      if (!users) {
        setDealerUserRows([]);
        return;
      }

      const mappedUsers = mapDataToTableRows(users);
      setDealerUserRows(mappedUsers);
      setTotalRowsCount(count);
    } catch (error) {}
  }

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); // eslint-disable-line

  useEffect(() => {
    let users, count;
    users = dealerUserStateData?.data?.users;
    if (users) {
      count = dealerUserStateData.data.count;
      const usersData = mapDataToTableRows(users);
      setDealerUserRows(usersData);
      setTotalRowsCount(count);
    }
  }, [dealerUserStateData]); //eslint-disable-line

  const applyFilter = (values: {
    [key: string]: number | boolean | string;
  }) => {
    setDealerUserRows(null);
    const prioritizedQueryParams = prioritizeAndMergeObject(
      values,
      queryParams,
      [
        "page",
        "rowsPerPage",
        "activeStatus",
        "dealerName",
        "userRole",
        "id",
        "email",
        "firstName",
        "lastName",
        "contactNo",
      ]
    );

    setQueryParams({
      ...queryParams,
      ...prioritizedQueryParams,
    });
  };

  const onButtonClick = async (row: any) => {
    let selected_dealer_user = getRowData(
      row.id,
      dealerUserStateData?.data?.users
    );
    dispatch(
      toggleModal({
        open: true,
        edit: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.DEALER_USER,
        data: {
          modalValues: getEditModalValues(
            MODAL_TYPE.DEALER_USER,
            selected_dealer_user
          ),
        },
      })
    );
  };

  return (
    <Box>
      <ButtonContainer
        buttons={buttons}
        hideRightSearchField={true}
        handlePrint={handlePrint}
        handleExport={handleExport}
        disableButtons={dealerUserRows ? dealerUserRows?.length === 0 : true}
      />
      <TableComponent
        reference={reference}
        rows={dealerUserRows}
        columns={fieldsData}
        rowCount={totalRowsCount}
        applyFilter={applyFilter}
        queryParams={queryParams}
        onButtonClick={onButtonClick}
      />
    </Box>
  );
};

export default DealerUsers;
