import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ButtonContainer from "../components/ButtonContainer";
import { useAppDispatch, useAppSelector } from "../hooks/redux-toolkit";
import { toggleModal } from "../store/reducer/modal";
import { MODAL_TYPE, NUMBER, SIZES } from "../misc/constants";
import CardComponent from "../components/CardComponent";
import { useLazyGetLocationsQuery } from "../store/reducer/location";
import Loader from "../components/Loader";
import {
  checkBoolean,
  convertToGMT,
  timezoneToGMTValueFormat,
} from "../misc/utils";
import { LocationType } from "../ts/component.types";
import { Actions } from "../misc/access.enum";

const Locations = () => {
  const dispatch = useAppDispatch();
  const { permissions } = useAppSelector((state) => state.authorization);
  const [locations, setLocations] = useState<Array<LocationType> | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);

  const [getLocations, { data: locationsStateData }] =
    useLazyGetLocationsQuery();

  const handleClick = () => {
    dispatch(
      toggleModal({
        open: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.ADD_LOCATION,
      })
    );
  };

  const buttons = [
    {
      label: "Add New Location",
      color: "primary",
      onClick: handleClick,
      size: SIZES.SMALL,
      access: checkBoolean(permissions?.[Actions.AddLocation]),
    },
  ];

  const mapDataToRows = (locations: any) => {
    return locations.map((location: any) => {
      const timezone = convertToGMT(Number(location?.time_zone));
      const timezoneValue = timezoneToGMTValueFormat(
        Number(location?.time_zone)
      );

      return {
        timezone,
        timezoneValue,
        id: location?.id,
        name: location?.name,
        isActive: location?.is_active,
      };
    });
  };

  useEffect(() => {
    let locations, count;

    locations = locationsStateData?.data?.locations;
    count = locations ? locationsStateData.data.count : NUMBER.ZERO;
    const locationsData = locations ? mapDataToRows(locations) : [];
    setLocations(locationsData);
    setTotalRowsCount(count);
  }, [locationsStateData]); //eslint-disable-line

  useEffect(() => {
    (async () => {
      await getLocations("");
    })();
  }, []); //eslint-disable-line

  const handleEdit = (location: LocationType) => {
    dispatch(
      toggleModal({
        open: true,
        edit: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.ADD_LOCATION,
        data: {
          modalValues: { ...location },
        },
      })
    );
  };

  return (
    <Box>
      <ButtonContainer
        buttons={buttons}
        hideRightSearchField={true}
        hideReportButtons={true}
      />
      {!locations ? (
        <Loader />
      ) : (
        <Box>
          <Box className="flex items-center">
            Total Locations :{" "}
            <Box className="font-bold ml-1">{totalRowsCount}</Box>
          </Box>
          <Box className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 my-4">
            {locations?.map((location) => {
              return (
                <CardComponent
                  title={location?.timezone}
                  subtitle={location?.name}
                  isActive={location?.isActive}
                  showStatus={true}
                  showEditIcon={checkBoolean(permissions?.[Actions.UpdateLocation])}
                  editClickListener={() => handleEdit(location)}
                  subtitleClassName="text-4xl mt-3"
                />
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Locations;
