import Box from "@mui/material/Box";
import { ApexOptions } from "apexcharts";
import { useAppSelector, useAppDispatch } from "../hooks/redux-toolkit";
import { useNavigate } from "react-router-dom";
import TransportationStats from "../components/TransportationStats";
import ListingComponent from "../components/ListingComponent";
import QuickLinks from "../components/QuickLinks";

import {
  DEALER_ROLES,
  LISTING_TYPE,
  MODAL_TITLE,
  MODAL_TYPE,
  PAGE_TITLE,
} from "../misc/constants";
import { Listing, TransportStatObjectType } from "../ts/component.types";
import { createDateTimeChart, createAreaChart } from "../config/charts.config";

import { toggleModal } from "../store/reducer/modal";
import { FRONTEND_ROUTES, UserTypes } from "../misc/enum";
import {
  useGetGeneralStatsQuery,
  useGetTransportsStatsQuery,
  useLazyGetAllSoldStatsQuery,
  useLazyGetWeeklySoldStatsQuery,
} from "../store/reducer/dashboard";
import { useEffect, useState } from "react";
import {
  checkBoolean,
  getRouteOfTabs,
  prependZero,
  sumArray,
} from "../misc/utils";
import { useLazyGetNotificationsQuery } from "../store/reducer/notification";
import { useLazyGetAuditTrailsQuery } from "../store/reducer/auditTrail";

import { Actions, Pages, Tabs } from "../misc/access.enum";
import StatsGrid from "../components/StatsGrid";
import SoldVehiclesDateTimeChart from "../components/SoldVehiclesDateTimeChart";
import WeeklySoldVehiclesAreaGraph from "../components/WeeklySoldVehiclesAreaGraph";
import { appendQueryParams } from "../misc/api.utils";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { role, permissions } = useAppSelector((state) => state.authorization);

  const {
    data: generalStats,
    isFetching: fetchingGeneralStats,
    refetch: refetchGeneralStats,
  } = useGetGeneralStatsQuery("");

  const {
    data: transportStats,
    isFetching: fetchingTranportStats,
    refetch: refetchTransportStats,
  } = useGetTransportsStatsQuery("");

  const [
    getNotifications,
    { data: notificationStateData, isFetching: fetchingNotifications },
  ] = useLazyGetNotificationsQuery();

  const [getWeeklyStats, { data: weeklyStatsStateData }] =
    useLazyGetWeeklySoldStatsQuery();
  const [getAllSoldStats, { data: soldStatsStateData }] =
    useLazyGetAllSoldStatsQuery();

  const [
    getAuditTrails,
    { data: auditTrailsStateData, isFetching: fetchingAuditTrails },
  ] = useLazyGetAuditTrailsQuery();

  const [notifications, setNotifications] = useState<Array<Listing> | null>(
    null
  );

  const [auditTrails, setAuditTrails] = useState<Array<Listing> | null>(null);

  const [areaChart, setAreaChart] = useState<{
    chart: { series: ApexOptions["series"]; options: ApexOptions };
    sum: number;
  } | null>(null);

  const [timeSeriesChart, setTimeSeriesChart] = useState<{
    chart: { series: ApexOptions["series"]; options: ApexOptions };
    changePercentage: string;
    lastAuctionSold: number;
  } | null>(null);

  const [vehicleDetails, setVehicleDetails] = useState<
    Array<TransportStatObjectType>
  >([]);

  const addVehicle = () => {
    dispatch(
      toggleModal({
        open: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.ADD_VEHICLE,
      })
    );
  };

  const navigateToAuction = () => {
    let finalPath = FRONTEND_ROUTES.AUCTION;
    if (!checkBoolean(permissions?.[Tabs.AuctionTab])) {
      finalPath = FRONTEND_ROUTES.AUCTION_LIST;
    }
    navigate(finalPath, { state: { title: PAGE_TITLE.AUCTION } });
  };

  const navigateToTransportation = () => {
    const finalPath = getRouteOfTabs(FRONTEND_ROUTES.TRANSPORT, permissions);
    navigate(finalPath, {
      state: { title: PAGE_TITLE.TRANSPORTATION },
    });
  };

  const navigateToInventory = () => {
    const finalPath = getRouteOfTabs(FRONTEND_ROUTES.VEHICLE, permissions);
    navigate(finalPath, {
      state: { title: PAGE_TITLE.VEHICLES },
    });
  };

  const createNewAuction = () => {
    dispatch(
      toggleModal({
        open: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.CREATE_AUCTION,
      })
    );
  };

  const generateDealerQuickLinks = (): Listing[] => {
    const dealerQuickLinks: Listing[] = [];

    if (checkBoolean(permissions?.[Actions.AddVehicle]))
      dealerQuickLinks.push({
        id: "1",
        title: MODAL_TITLE.ADD_VEHICLE,
        action: addVehicle,
      });

    if (checkBoolean(permissions?.[Pages.Auction]))
      dealerQuickLinks.push({
        id: "2",
        title: PAGE_TITLE.AUCTION,
        action: navigateToAuction,
      });

    if (checkBoolean(permissions?.[Pages.Transportation]))
      dealerQuickLinks.push({
        id: "3",
        title: PAGE_TITLE.TRANSPORTATION,
        action: navigateToTransportation,
      });

    return dealerQuickLinks;
  };

  const generateAdminQuickLinks = (): Listing[] => {
    const adminQuickLinks: Listing[] = [];

    if (checkBoolean(permissions?.[Pages.Vehicle]))
      adminQuickLinks.push({
        id: "1",
        title: "Inventory",
        action: navigateToInventory,
      });

    if (checkBoolean(permissions?.[Pages.Auction]))
      adminQuickLinks.push({
        id: "2",
        title: PAGE_TITLE.AUCTION,
        action: navigateToAuction,
      });

    if (checkBoolean(permissions?.[Pages.Transportation]))
      adminQuickLinks.push({
        id: "3",
        title: PAGE_TITLE.TRANSPORTATION,
        action: navigateToTransportation,
      });

    if (checkBoolean(permissions?.[Actions.AddAuction]))
      adminQuickLinks.push({
        id: "4",
        title: MODAL_TITLE.CREATE_AUCTION,
        action: createNewAuction,
      });

    return adminQuickLinks;
  };

  const mapDataToRows = (type: string, data: any) => {
    return data?.map((row: any) => ({
      id: row?.id,
      title:
        type === LISTING_TYPE.AUDIT_TRAIL
          ? `${row?.user?.first_name} ${row?.user?.last_name} | ${row?.message}`
          : row?.message,
    }));
  };

  const handleSeeMore = (type: string) => {
    if (type === LISTING_TYPE.AUDIT_TRAIL) {
      navigate(FRONTEND_ROUTES.AUDIT_TRAIL, {
        state: { title: PAGE_TITLE.AUDIT_TRAILS },
      });
    }
  };

  useEffect(() => {
    const apiParams = appendQueryParams(
      {
        page: 0,
        limit: 20,
      },
      false
    );

    refetchGeneralStats();
    refetchTransportStats();
    getWeeklyStats("");
    getAllSoldStats("");
    getNotifications(apiParams);
    if (checkBoolean(permissions?.[Pages.AuditTrail])) {
      getAuditTrails(apiParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    refetchGeneralStats,
    refetchTransportStats,
    getNotifications,
    getAuditTrails,
    getWeeklyStats,
    getAllSoldStats,
  ]);

  useEffect(() => {
    if (notificationStateData) {
      const notifications = mapDataToRows(
        LISTING_TYPE.NOTFICATION,
        notificationStateData?.data?.notifications
      );
      setNotifications(notifications);
    }
  }, [notificationStateData]);

  useEffect(() => {
    if (auditTrailsStateData) {
      const auditTrails = mapDataToRows(
        LISTING_TYPE.AUDIT_TRAIL,
        auditTrailsStateData?.data?.audit_trail
      );
      setAuditTrails(auditTrails);
    }
  }, [auditTrailsStateData]);

  useEffect(() => {
    if (transportStats?.data) {
      const vehicleDetails = [
        {
          title: "Waiting to be picked up",
          value: prependZero(transportStats?.data?.accepted?.toString()),
        },
        {
          title: "Picked up",
          value: prependZero(transportStats?.data?.picked?.toString()),
        },
        {
          title: "Drop off",
          value: prependZero(transportStats?.data?.dropped?.toString()),
        },
      ];
      setVehicleDetails(vehicleDetails);
    }
  }, [transportStats]);

  useEffect(() => {
    if (weeklyStatsStateData) {
      const vehicles = weeklyStatsStateData?.data?.vehicles;
      const dates = weeklyStatsStateData?.data?.dates;

      if (vehicles && dates) {
        const chartData = [];
        for (let i = 0; i < vehicles.length; i++) {
          chartData.push([new Date(dates[i]).getTime(), vehicles[i]]);
        }
        const chart = createAreaChart("Vehicles Sold", chartData);
        const sum = sumArray(vehicles);

        setAreaChart({
          chart,
          sum,
        });
      }
    }
  }, [weeklyStatsStateData]);

  useEffect(() => {
    if (soldStatsStateData) {
      const statsData = soldStatsStateData?.data;
      const series = statsData?.series;
      const changePercentage = statsData?.change_percentage;
      const lastAuctionSold = statsData?.last_auction_sale;
      if (series) {
        const chart = createDateTimeChart("Vehicles Sold", series);
        setTimeSeriesChart({
          chart,
          changePercentage,
          lastAuctionSold,
        });
      }
    }
  }, [soldStatsStateData]);

  return (
    <Box>
      <QuickLinks
        quickLinks={
          DEALER_ROLES?.includes(role as UserTypes)
            ? generateDealerQuickLinks()
            : generateAdminQuickLinks()
        }
      />
      <StatsGrid
        fetching={fetchingGeneralStats}
        generalStats={{
          inventoryVehicles: generalStats?.data?.inventory_vehicles,
          soldVehicles: generalStats?.data?.sold_vehicles,
          arbitrationCases: generalStats?.data?.open_arbitration_cases,
          titlesPending: generalStats?.data?.title_pending,
          upcomingAuctions: generalStats?.data?.upcoming_auctions,
        }}
      />

      <Box className="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
        <Box className="flex flex-col">
          <TransportationStats
            loading={fetchingTranportStats}
            vehicleDetails={vehicleDetails}
          />

          <SoldVehiclesDateTimeChart
            role={role as UserTypes}
            timeSeriesChart={timeSeriesChart}
          />

          {checkBoolean(permissions?.[Pages.AuditTrail]) && (
            <ListingComponent
              type={LISTING_TYPE.AUDIT_TRAIL}
              isFetching={fetchingAuditTrails}
              data={auditTrails}
              title="Recent Activity"
              className="xl:mx-0 my-4"
              innerContainerClassname="h-[345px]"
              onClickSeeMore={handleSeeMore}
            />
          )}
        </Box>

        <ListingComponent
          type={LISTING_TYPE.NOTFICATION}
          data={notifications}
          title="Notification"
          isFetching={fetchingNotifications}
        />

        <WeeklySoldVehiclesAreaGraph areaChart={areaChart} />
      </Box>
    </Box>
  );
};

export default Dashboard;
