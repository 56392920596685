import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from "@mui/material/CircularProgress";

import { COLORS } from "../../misc/colors";

type Props = {
  className?: string;
  size?: number;
};

// Inspired by the former Facebook spinners.
function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: COLORS.YELLOW,
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: COLORS.CHARCOAL_GRAY,
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const Loader = ({ className, size }: Props) => {
  return (
    <Box
      className={`${className} flex items-center justify-center`}
      sx={{ flexGrow: 1 }}
    >
      <FacebookCircularProgress size={size} />
    </Box>
  );
};

export default Loader;
