import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { Link } from "react-router-dom";
import { Formik } from "formik";
import FieldComponent from "../components/FieldComponent";

import "./../styles/Login.css";
import { FRONTEND_ROUTES } from "../misc/enum";
import { useAppDispatch } from "../hooks/redux-toolkit";
import { resetPassword } from "../store/services/auth";
import Loader from "../components/Loader";
import { resetPasswordSchema } from "../components/ModalComponent/schema";

type Props = {};

const ResetPassword = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div className="h-screen flex flex-col justify-center items-center bg-[url(./assets/images/ResetBg.jpg)] bg-no-repeat bg-center bg-cover">
      <Box
        className={`w-[200px] h-[60px] xl:w-[320px] xl:h-[80px] bg-[url(./assets/images/SpectrumLogo.png)] bg-contain bg-no-repeat bg-center mb-5 xl:mb-10`}
      ></Box>
      <Paper className="xl:w-[30%] lg:w-[40%] md:w-[60%] w-[90%] glassmorphism">
        <Stack spacing={3} alignItems={"center"} px={3} py={3}>
          <Box className="text-2xl font-bold text-primary-100 uppercase">
            Password Reset
          </Box>
          <Box className="text-sm text-primary-200">
            You will receive instruction for resetting your password.
          </Box>

          <Formik
            initialValues={{ signature: "" }}
            validationSchema={resetPasswordSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const { signature } = values;
              const response: any = await dispatch(
                resetPassword({
                  signature: signature?.trim(),
                })
              );
              if (response) {
                navigate(FRONTEND_ROUTES.LOGIN);
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Box
                className="w-full"
                onKeyDown={(event) => {
                  if (event?.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <FieldComponent
                  type="text"
                  name="signature"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="Username or Email Address"
                  className="w-[100%]"
                  inputClassName="bg-primary-100"
                  errorTextColor="text-primary-500"
                  errors={errors}
                  touched={touched}
                />

                <Button
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                  variant="contained"
                  className="w-[100%] button"
                >
                  {isSubmitting ? (
                    <Loader className="items-center" size={25} />
                  ) : (
                    "Send"
                  )}
                </Button>
              </Box>
            )}
          </Formik>

          <Box className="text-sm text-primary-200">
            <Link to={FRONTEND_ROUTES.LOGIN}>Back to Login</Link>
          </Box>
        </Stack>
      </Paper>
    </div>
  );
};

export default ResetPassword;
