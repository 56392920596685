import { setSuccess } from "../store/reducer/auth";
import { useAppDispatch } from "./redux-toolkit";

export const useSuccessDispatcher = () => {
  const dispatch = useAppDispatch();

  return (success: boolean, payload: string) => {
    dispatch(
      setSuccess({
        success,
        successMessage: payload,
      })
    );
  };
};
