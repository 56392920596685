import { API_ENDPOINT } from "../../misc/enum";
import { baseAPI } from "./baseSlice";

export const notificationSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.NOTIFICATION}?${params}`,
      }),
    }),
  }),
});

export const { useLazyGetNotificationsQuery } = notificationSlice;
