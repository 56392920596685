import { GroupAccess, ModalFieldProps } from "../../ts/component.types";

import {
  adminTypes,
  transporterCompanyNameOptions,
  dummyOptions,
  acquiredFromOptions,
  announcementOptions,
  timezone,
  dealerUserRoleOptions,
} from "../../misc/createData";
import {
  ADMIN_ROLES,
  CONSTANTS,
  MODAL_COMPONENT_FIELD_NAMES,
} from "../../misc/constants";
import { UserTypes } from "../../misc/enum";

export const getEditableFields = (
  modalFields: ModalFieldProps[],
  isAuctionRoute?: boolean
) => {
  let forbiddenFields: any = {
    username: MODAL_COMPONENT_FIELD_NAMES.USERNAME,
    password: MODAL_COMPONENT_FIELD_NAMES.PASSWORD,
    contactNumber: MODAL_COMPONENT_FIELD_NAMES.CONTACT_NUMBER,
    email: MODAL_COMPONENT_FIELD_NAMES.EMAIL,
    confirmPassword: MODAL_COMPONENT_FIELD_NAMES.CONFIRM_PASSWORD,
    vin: MODAL_COMPONENT_FIELD_NAMES.VIN,
    make: MODAL_COMPONENT_FIELD_NAMES.MAKE,
    model: MODAL_COMPONENT_FIELD_NAMES.MODEL,
    year: MODAL_COMPONENT_FIELD_NAMES.YEAR,
    go: MODAL_COMPONENT_FIELD_NAMES.GO,
    dealer: MODAL_COMPONENT_FIELD_NAMES.DEALER,
  };

  if (isAuctionRoute) {
    forbiddenFields.dealerLocation =
      MODAL_COMPONENT_FIELD_NAMES.DEALER_LOCATION;
  }

  let filteredFields = modalFields.filter((field) => {
    return !(field?.name in forbiddenFields);
  });

  filteredFields = filteredFields.map((field) => {
    if (field?.name === MODAL_COMPONENT_FIELD_NAMES.DEALER_STOCK_NUMBER) {
      return {
        ...field,
        col: 12,
      };
    }
    return field;
  });

  return filteredFields;
};

export const groupAccess: GroupAccess[] = [
  { title: "Auction Manager", view: false, edit: false },
  { title: "Users", view: false, edit: false },
  { title: "Vehicle", view: true, edit: false },
  { title: "Dealer", view: false, edit: true },
  { title: "Auction", view: false, edit: false },
  { title: "Transporter", view: true, edit: false },
];

export const userModalFields: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.USERNAME,
    placeholder: "User Name",
    type: "input",
    col: 12,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.FIRST_NAME,
    dataType: "string",
    placeholder: "First Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LAST_NAME,
    dataType: "string",
    placeholder: "Last Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.EMAIL,
    placeholder: "Email",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.CONTACT_NUMBER,
    placeholder: "Contact No.",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.PASSWORD,
    placeholder: "Password",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.CONFIRM_PASSWORD,
    placeholder: "Confirm Password",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.USER_ROLE,
    placeholder: "User Role",
    dataType: "string",
    type: "select",
    col: 6,
    options: adminTypes?.slice(1, adminTypes?.length),
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ACTIVE,
    placeholder: "",
    dataType: "boolean",
    type: "checkbox",
    col: 6,
    label: "Active",
  },
];

export const transporterModalFields: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.COMPANY_NAME,
    dataType: "string",
    placeholder: "Company Name",
    type: "input",
    col: 12,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.EMAIL,
    placeholder: "Email",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.CONTACT_NUMBER,
    placeholder: "Contact No.",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.USERNAME,
    placeholder: "Username",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.PASSWORD,
    placeholder: "Password",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.FIRST_NAME,
    dataType: "string",
    placeholder: "First Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LAST_NAME,
    dataType: "string",
    placeholder: "Last Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LOCATION,
    placeholder: "Location",
    dataType: "number",
    type: "select",
    col: 6,
    options: dummyOptions,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.STATE,
    placeholder: "State",
    dataType: "string",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ADDRESS,
    dataType: "string",
    placeholder: "Address",
    type: "input",
    col: 12,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ZIP_CODE,
    dataType: "string",
    placeholder: "Zip Code",
    type: "input",
    col: 6,
  },
];

export const transporterUserModalFields: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.COMPANY_NAME,
    placeholder: "Transporter Company Name",
    dataType: "number",
    type: "select",
    col: 12,
    options: transporterCompanyNameOptions,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.EMAIL,
    placeholder: "Email",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.CONTACT_NUMBER,
    placeholder: "Contact No.",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.USERNAME,
    placeholder: "Username",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.PASSWORD,
    placeholder: "Password",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.FIRST_NAME,
    dataType: "string",
    placeholder: "First Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LAST_NAME,
    dataType: "string",
    placeholder: "Last Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LOCATION,
    placeholder: "Location",
    dataType: "number",
    type: "select",
    col: 6,
    options: dummyOptions,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.STATE,
    placeholder: "State",
    dataType: "string",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ADDRESS,
    placeholder: "Address",
    dataType: "string",
    type: "input",
    col: 12,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ZIP_CODE,
    placeholder: "Zip Code",
    dataType: "string",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ACTIVE,
    placeholder: "",
    dataType: "boolean",
    type: "checkbox",
    col: 6,
    label: "Active",
  },
];

export const dealerModalFields: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.MILLION_NUMBER,
    placeholder: "5 Million Number",
    dataType: "string",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.DEALER_NAME,
    dataType: "string",
    placeholder: "Dealer Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.EMAIL,
    placeholder: "Email",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.CONTACT_NUMBER,
    placeholder: "Contact No.",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.USERNAME,
    placeholder: "Username",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.PASSWORD,
    placeholder: "Password",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.FIRST_NAME,
    dataType: "string",
    placeholder: "First Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LAST_NAME,
    dataType: "string",
    placeholder: "Last Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LOCATION,
    placeholder: "Location",
    dataType: "number",
    type: "select",
    col: 6,
    options: dummyOptions,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.STATE,
    dataType: "string",
    placeholder: "State",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ADDRESS,
    dataType: "string",
    placeholder: "Address",
    type: "input",
    col: 12,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ZIP_CODE,
    dataType: "string",
    placeholder: "Zip Code",
    type: "input",
    col: 6,
  },
];

export const dealerUserModalFields: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.DEALERSHIP_NAME,
    placeholder: "Dealership Name",
    dataType: "number",
    type: "select",
    col: 12,
    options: transporterCompanyNameOptions,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.EMAIL,
    placeholder: "Email",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.CONTACT_NUMBER,
    placeholder: "Contact No.",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.USERNAME,
    placeholder: "Username",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.PASSWORD,
    placeholder: "Password",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.FIRST_NAME,
    dataType: "string",
    placeholder: "First Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LAST_NAME,
    dataType: "string",
    placeholder: "Last Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LOCATION,
    placeholder: "Location",
    dataType: "number",
    type: "select",
    col: 6,
    options: dummyOptions,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.DEALER_ROLE,
    placeholder: "Dealer Role",
    dataType: "number",
    type: "select",
    col: 6,
    options: dealerUserRoleOptions?.slice(1, dealerUserRoleOptions?.length),
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.STATE,
    placeholder: "State",
    dataType: "string",
    type: "input",
    col: 12,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ADDRESS,
    placeholder: "Address",
    dataType: "string",
    type: "input",
    col: 12,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ZIP_CODE,
    placeholder: "Zip Code",
    dataType: "string",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ACTIVE,
    placeholder: "",
    dataType: "boolean",
    type: "checkbox",
    col: 12,
    label: "Active",
  },
];

export const assignAuctionModalFields: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.AUCTION,
    value: "all",
    placeholder: "Auctions List",
    label: "Auctions",
    type: "select",
    col: 12,
    options: dummyOptions,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.UPDATE_LOCATION,
    placeholder: "",
    type: "checkbox",
    col: 12,
    label: "Update Locations",
  },
];

export const createAuctionModalFields: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.DATE,
    placeholder: "",
    label: "Auction Date",
    type: CONSTANTS.DATEPICKER,
    col: 12,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LOCATION,
    value: "all",
    placeholder: "Location",
    label: "Auction Location",
    type: "select",
    col: 12,
    options: dummyOptions,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ACTIVE,
    placeholder: "",
    type: "checkbox",
    col: 12,
    label: "Active",
  },
];

export const requestCRModalFields: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.NAME,
    placeholder: "Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.CONTACT_NUMBER,
    placeholder: "Contact No.",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.DATE,
    placeholder: "Date",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.TIME,
    placeholder: "Time",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.REMARKS,
    placeholder: "Sub Announcements",
    type: "input",
    col: 12,
    multiline: true,
    rows: 3,
  },
];

export const requestTransportModalFields: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.NAME,
    placeholder: "Name",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.CONTACT_NUMBER,
    placeholder: "Contact No",
    type: "input",
    col: 6,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.PICKUP_DATE,
    placeholder: "Pickup Date",
    type: "datepicker",
    col: 12,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LOCATION,
    placeholder: "Dropoff Location",
    type: "select",
    col: 12,
    options: dummyOptions,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.REMARKS,
    placeholder: "Sub Announcements",
    type: "input",
    col: 12,
    multiline: true,
    rows: 3,
  },
];

export const spectrumAdminField: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.DEALER,
    placeholder: "Dealer",
    type: "select",
    col: 12,
    options: dummyOptions,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.DEALER_LOCATION,
    placeholder: "Location",
    type: "select",
    col: 12,
    dataType: "number",
    options: dummyOptions,
  },
];

export const dealerAdminField: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.DEALER_LOCATION,
    placeholder: "Location",
    type: "select",
    col: 12,
    dataType: "number",
    options: dummyOptions,
  },
];

export const generateAddVehicleModalFields = (role: UserTypes) => {
  const isSpectrumAdmin = ADMIN_ROLES.includes(role);
  const isDealerAdmin = role === UserTypes.DealerAdmin;

  const addVehicleModalFields: ModalFieldProps[] = [
    {
      name: MODAL_COMPONENT_FIELD_NAMES.ACQUIRED_FROM,
      placeholder: "Acquired From",
      type: "select",
      dataType: "number",
      col: 12,
      options: acquiredFromOptions,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.VIN,
      placeholder: "VIN",
      type: "input",
      col: 9,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.GO,
      placeholder: "Go",
      label: "Go",
      type: "button",
      col: 3,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.YEAR,
      placeholder: "Year",
      type: "input",
      readOnly: true,
      col: 6,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.MAKE,
      placeholder: "Make",
      type: "input",
      readOnly: true,
      col: 6,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.MODEL,
      placeholder: "Model",
      type: "input",
      readOnly: true,
      col: 6,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.EXTERIOR_COLOR,
      placeholder: "Exterior Color",
      dataType: "string",
      type: "input",
      col: 6,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.MILEAGE,
      placeholder: "Mileage",
      dataType: "string",
      type: "input",
      col: 6,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.FLOOR_PRICE,
      placeholder: "Floor Price",
      dataType: "string",
      type: "input",
      col: 6,
      icon: true,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.ACV,
      placeholder: "ACV",
      dataType: "string",
      type: "input",
      col: 6,
      icon: true,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.DEALER_STOCK_NUMBER,
      placeholder: "Dealer Stock Number",
      type: "input",
      dataType: "string",
      col: 6,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.ANNOUNCEMENTS,
      placeholder: "Announcements",
      dataType: "number",
      type: "select",
      col: 12,
      options: announcementOptions,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.REMARKS,
      placeholder: "Sub Announcements",
      dataType: "Array<string>",
      type: "multi-select",
      col: 12,
      options: dummyOptions,
    },
    {
      name: MODAL_COMPONENT_FIELD_NAMES.COMMENT,
      placeholder: "Comments",
      dataType: "string",
      type: "input",
      col: 12,
      multiline: true,
      rows: 3,
    },
  ];

  const finalFields = isSpectrumAdmin
    ? spectrumAdminField.concat(addVehicleModalFields)
    : isDealerAdmin
    ? dealerAdminField.concat(addVehicleModalFields)
    : addVehicleModalFields;

  return finalFields;
};

export const addLocationFields: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.NAME,
    placeholder: "Location Name",
    type: "input",
    col: 12,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.TIMEZONE,
    placeholder: "Timezone",
    type: "select",
    col: 12,
    options: timezone,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ACTIVE,
    placeholder: "",
    type: "checkbox",
    col: 12,
    label: "Active",
  },
];

export const addAnnouncementFields: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LABEL,
    placeholder: "Label",
    type: "input",
    col: 12,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ACTIVE,
    placeholder: "",
    type: "checkbox",
    col: 12,
    label: "Active",
  },
];

export const addRemarkFields: ModalFieldProps[] = [
  {
    name: MODAL_COMPONENT_FIELD_NAMES.LABEL,
    placeholder: "Label",
    type: "input",
    col: 12,
  },
  {
    name: MODAL_COMPONENT_FIELD_NAMES.ACTIVE,
    placeholder: "",
    type: "checkbox",
    col: 12,
    label: "Active",
  },
];
