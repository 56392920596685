import { API_ENDPOINT } from "../../misc/enum";
import {
  CreateTransportRequestPayload,
  UpdateTransportRequestPayload,
  DeleteTransportRequestPayload,
  AcceptTransportRequestPayload,
  AlternateTransportRequestPayload,
} from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const transportRequestSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAssignedTransportRequests: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.TRANSPORT_REQUEST}/assigned?${params}`,
      }),
      providesTags: ["TransportRequest"],
    }),
    getUnassignedTransportRequests: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.TRANSPORT_REQUEST}/unassigned?${params}`,
      }),
      providesTags: ["TransportRequest"],
    }),
    createTransportRequest: builder.mutation({
      query: (payload: CreateTransportRequestPayload) => ({
        method: "POST",
        url: `${API_ENDPOINT.TRANSPORT_REQUEST}/`,
        body: payload,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    updateTransportRequest: builder.mutation({
      query: ({ id }: UpdateTransportRequestPayload) => ({
        url: `${API_ENDPOINT.TRANSPORT_REQUEST}/${id}`,
        method: "PUT",
        body: {},
      }),
    }),
    acceptTransportRequest: builder.mutation({
      query: ({ id, payload }: AcceptTransportRequestPayload) => ({
        url: `${API_ENDPOINT.TRANSPORT_REQUEST}/accept/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["TransportRequest"],
    }),
    alternateTransportRequest: builder.mutation({
      query: ({ id, payload }: AlternateTransportRequestPayload) => ({
        url: `${API_ENDPOINT.TRANSPORT_REQUEST}/alternate/${id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["TransportRequest"],
    }),
    acceptAlternateTransportRequest: builder.mutation({
      query: (id: string) => ({
        url: `${API_ENDPOINT.TRANSPORT_REQUEST}/alternate/accept/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["TransportRequest"],
    }),
    pickedTransport: builder.mutation({
      query: (id: string) => ({
        url: `${API_ENDPOINT.TRANSPORT_REQUEST}/picked/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["TransportRequest"],
    }),
    droppedTransport: builder.mutation({
      query: (id: string) => ({
        url: `${API_ENDPOINT.TRANSPORT_REQUEST}/dropped/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["TransportRequest"],
    }),
    deleteTransportRequest: builder.mutation({
      query: ({ id }: DeleteTransportRequestPayload) => ({
        url: `${API_ENDPOINT.TRANSPORT_REQUEST}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetAssignedTransportRequestsQuery,
  useLazyGetUnassignedTransportRequestsQuery,
  useCreateTransportRequestMutation,
  useUpdateTransportRequestMutation,
  useDeleteTransportRequestMutation,
  useAcceptTransportRequestMutation,
  useAlternateTransportRequestMutation,
  useAcceptAlternateTransportRequestMutation,
  usePickedTransportMutation,
  useDroppedTransportMutation,
} = transportRequestSlice;
