import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";

import ListingTableComponent from "../components/ListingTableComponent";
import {
  useLazyGetVehiclesQuery,
  useUpdateOveStatusMutation,
} from "../store/reducer/vehicle";

import { oveRequestCreateData } from "../misc/createData";

import { OVEEventSaleStatus, VehicleStatus } from "../misc/enum";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import {
  calculateDays,
  checkBoolean,
  formatDate,
  numericValueOrNull,
  prioritizeAndMergeObject,
} from "../misc/utils";
import {
  CONSTANTS,
  DATE_FORMAT,
  DATE_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
  SELECT_TYPES,
} from "../misc/constants";
import { useAppDispatch, useAppSelector } from "../hooks/redux-toolkit";
import { toggleProgressBar } from "../store/reducer/progressBar";
import { MESSAGES } from "../misc/messages";
import ButtonContainer from "../components/ButtonContainer";
import { useReactToPrint } from "react-to-print";
import { Actions, Tabs } from "../misc/access.enum";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { useSuccessDispatcher } from "../hooks/useSuccessDispatcher";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";
import { appendQueryParams } from "../misc/api.utils";

const RequestOVE = () => {
  const reference = useRef<HTMLTableSectionElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.REQUEST_FOR_OVE,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const { permissions } = useAppSelector((state) => state?.authorization);
  const dispatch = useAppDispatch();
  const successDisptacher = useSuccessDispatcher();
  const errorDispatcher = useErrorDispatcher();
  const [vehicleRows, setVehicleRows] = useState<[] | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [updateOveStatus] = useUpdateOveStatusMutation();
  const [queryParams, setQueryParams] = useState({
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    vin: "",
  });
  const [getVehicles, { data: vehicleStateData }] = useLazyGetVehiclesQuery();

  const inputRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);

  const handleExport = () => {
    const vehiclesData = vehicleStateData?.data?.vehicles;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.OVERequestTab,
      vehiclesData
    );

    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `OVE Event Sale (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const mapDataToTableRows = (vehiclesData: any) => {
    return vehiclesData.map((vehicleData: any) => {
      const age = calculateDays(vehicleData?.created_at);
      const isPostedStatus =
        vehicleData?.ove_event_sale?.status === OVEEventSaleStatus.Posted;

      return oveRequestCreateData(
        vehicleData?.ove_event_sale?.id,
        vehicleData?.vin,
        vehicleData?.make,
        vehicleData?.model,
        vehicleData?.year,
        vehicleData?.dealer?.name,
        vehicleData?.location?.name,
        `${age?.toString()} ${age === 1 ? "Day" : "Days"}`,
        vehicleData?.announcement?.label,
        vehicleData?.condition_report?.rating,
        numericValueOrNull(vehicleData?.acv),
        numericValueOrNull(vehicleData?.dealer_stock_number),
        isPostedStatus || !checkBoolean(permissions?.[Actions.UpdateOVEStatus])
          ? OVEEventSaleStatus[vehicleData?.ove_event_sale?.status]
          : vehicleData?.ove_event_sale?.status?.toString(),
        isPostedStatus || !checkBoolean(permissions?.[Actions.UpdateOVEStatus])
          ? CONSTANTS.TEXT
          : CONSTANTS.SELECT,
        vehicleData?.ove_event_sale?.price,
        false
      );
    });
  };

  const fetchData = async (queryParams: DynamicKeyValuesWithNumArray) => {
    let apiParams = ``;

    const queryParamObj: DynamicKeyValuesWithNumArray = {
      status: VehicleStatus.OVEEventSale,
      page: (queryParams.page as number) + 1,
      limit: queryParams.rowsPerPage as number,
      vin: queryParams?.vin as string,
    };

    apiParams += appendQueryParams(queryParamObj, false);
    try {
      const response: any = await getVehicles(apiParams);

      const data = response?.data?.data;
      const vehicles = data?.vehicles;
      const count = data?.count;

      if (!vehicles) {
        setVehicleRows([]);
        return;
      }

      const vehiclesData = mapDataToTableRows(vehicles);

      setVehicleRows(vehiclesData);

      setTotalRowsCount(count);
    } catch {}
  };

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); //eslint-disable-line

  useEffect(() => {
    if (vehicleStateData?.data?.vehicles) {
      let vehiclesData = vehicleStateData?.data?.vehicles;
      vehiclesData = mapDataToTableRows(vehiclesData);
      setVehicleRows(vehiclesData);
      setTotalRowsCount(vehicleStateData?.data?.count);
    }
  }, [vehicleStateData]); //eslint-disable-line

  const applyFilter = (values: DynamicKeyValuesWithNumArray) => {
    setVehicleRows(null);
    const prioritizedQueryParams = prioritizeAndMergeObject(
      values,
      queryParams,
      ["page", "rowsPerPage", "vin"]
    );

    setQueryParams({
      ...queryParams,
      ...prioritizedQueryParams,
    });
  };

  const handleSelect = async (
    selectType: string,
    vehicleId: number,
    status: number
  ) => {
    if (
      selectType === SELECT_TYPES.OVE_STATUS &&
      status === OVEEventSaleStatus.Posted
    ) {
      dispatch(toggleProgressBar(true));
      const payload = {
        id: vehicleId?.toString(),
        payload: {
          status,
        },
      };
      const response: any = await updateOveStatus(payload);
      dispatch(toggleProgressBar(false));
      if (!response?.error)
        successDisptacher(true, MESSAGES.VEHICLE.OVE_STATUS_UPDATE);
    }
  };

  const handleSearch = () => {
    applyFilter({
      ...queryParams,
      vin: (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement)?.value,
      page: NUMBER.ZERO,
    });
  };

  return (
    <Box>
      <ButtonContainer
        hideRightSearchField={false}
        handlePrint={handlePrint}
        handleExport={handleExport}
        inputRefs={inputRefs}
        handleVinSearch={handleSearch}
        disableButtons={vehicleRows ? vehicleRows?.length === 0 : true}
      />
      <ListingTableComponent
        reference={reference}
        rows={vehicleRows}
        rowCount={totalRowsCount}
        handleSelect={handleSelect}
        applyFilter={applyFilter}
        queryParams={queryParams}
      />
    </Box>
  );
};

export default RequestOVE;
