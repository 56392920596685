import { useEffect, useRef, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

import TableCellComponent from "../TableCellComponent";

import { TableRowComponentProps } from "../../ts/component.types";

import CheckIcon from "@mui/icons-material/Check";

const TableRowComponent = ({
  row,
  showCheckbox = false,
  openVINModal,
  unreadCount = 0,
  id,
  fade = false,
  conditionReportId = "",
  handleRowSelection,
  isChecked,
}: TableRowComponentProps) => {
  const tableCellRef = useRef<HTMLDataElement>();

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (tableCellRef.current?.clientHeight)
      setHeight(tableCellRef.current?.clientHeight - 30);
  }, []);

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      className={`audit-table-row table-row ${fade ? "dull-row" : ""}`}
    >
      <TableCell padding="checkbox" className="vertical-text-top">
        {showCheckbox && (
          <Checkbox
            checked={isChecked(id)}
            onChange={() =>
              handleRowSelection({
                checked: !isChecked(id),
                id,
                conditionReportId,
              })
            }
            color="primary"
            sx={{
              transform: "scale(1.3)",
            }}
            checkedIcon={
              <CheckIcon
                sx={{ marginLeft: "2px", fontSize: "18px" }}
                className="text-secondary-300 bg-primary-300 border-[1px] border-tertiary-200 rounded-sm"
              />
            }
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        )}
        {unreadCount > 0 && (
          <Box className="bg-secondary-200 text-tertiary-100 rounded-full flex items-center justify-center h-[26px] w-[26px] text-[10px] font-semibold m-4 leading-none">
            {unreadCount > 99 ? `99+` : unreadCount}
          </Box>
        )}
      </TableCell>
      {row?.map((cell, index) => {
        let content = null;

        content = (
          <TableCell
            ref={tableCellRef}
            key={index}
            align="center"
            sx={{
              verticalAlign: "top",
            }}
            className={`${
              cell?.[0]?.actions?.[0]?.type === "action-menu" &&
              "vertical-text-top"
            }`}
          >
            {cell?.map((cellRow, cellIndex) => {
              return cellRow?.type === "divider" ? (
                <Box
                  className={`flex mx-4 bg-tertiary-200 w-[1px]`}
                  sx={{
                    height: `${height}px`,
                  }}
                ></Box>
              ) : (
                <TableCellComponent
                  id={id}
                  disabled={!isChecked(id)}
                  cellRow={cellRow}
                  middle={cellIndex % 2 === 0}
                  openVINModal={openVINModal}
                />
              );
            })}
          </TableCell>
        );

        return content;
      })}
    </TableRow>
  );
};

export default TableRowComponent;
