import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectComponentProps } from "../../ts/component.types";
import { SIZES } from "../../misc/constants";
import "./../../styles/SelectComponent.css";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SelectComponent = ({
  disabled,
  size,
  value,
  placeholder,
  label,
  options,
  errors,
  errorTextColor,
  hideErrorMessage = false,
  touched,
  className,
  handleChange,
  variant = "outlined",
  inputRefs,
  index = 0,
  name,
}: SelectComponentProps) => {
  return (
    <Box className={`${className}`} sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant={variant}>
        <Select
          disabled={disabled}
          inputRef={(el) =>
            inputRefs ? (inputRefs.current[index] = el?.node) : null
          }
          name={name}
          labelId="select-label"
          id="select"
          size={SIZES.SMALL}
          value={(value as string) || "placeholder"}
          label={label}
          onChange={handleChange}
          className={`${size && "small-select"} ${
            variant === "standard" && "standard-select"
          }`}
          IconComponent={(props) => {
            return (
              <ExpandMoreIcon
                sx={{
                  fontSize: "1rem",
                }}
                {...props}
              />
            );
          }}
        >
          <MenuItem disabled value="placeholder">
            <em>{placeholder}</em>
          </MenuItem>
          {options?.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {!hideErrorMessage && (
        <Box
          className={`${errorTextColor || "text-white"} ${
            errors && name && errors[name] && touched[name]
              ? "visible"
              : "invisible"
          } text-xs self-start my-1`}
        >
          {errors && name && errors[name] && touched[name]
            ? errors[name]
            : "error"}
        </Box>
      )}
    </Box>
  );
};

export default SelectComponent;
