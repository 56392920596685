import Box from "@mui/material/Box";
import Loader from "../Loader";
import { TransportationStatsProps } from "../../ts/component.types";

const TransportationStats = ({
  vehicleDetails,
  loading,
}: TransportationStatsProps) => {
  return (
    <Box className="bg-primary-100 rounded-md box-shadow p-6">
      <Box className="border-b border-tertiary-300 pb-4 text-tertiary-500">
        Transportation
      </Box>
      <Box className="pt-4 border-tertiary-300">
        {!loading ? (
          vehicleDetails?.map((vehicleDetail, index) => {
            return (
              <Box className="flex my-1" key={index}>
                <Box className="w-72 text-left mr-2 font-bold">
                  {vehicleDetail?.title}
                </Box>
                <Box className="w-40 flex items-center">
                  <Box className="mr-6 font-bold">:</Box>
                  {vehicleDetail?.value}
                </Box>
              </Box>
            );
          })
        ) : (
          <Loader />
        )}
      </Box>
    </Box>
  );
};

export default TransportationStats;
