import Box from "@mui/material/Box";
import { CONSTANTS } from "../../misc/constants";
import { TagComponentProps } from "../../ts/component.types";

const TagComponent = ({ color, text, className }: TagComponentProps) => {
  return (
    <Box
      className={`rounded-md uppercase text-xs font-medium px-4 py-2 w-fit ${
        color === CONSTANTS.SECONDARY || color === CONSTANTS.TERTIARY ? "text-primary-100" : "text-tertiary-100"
      } 
      ${
        color === CONSTANTS.PRIMARY
          ? "bg-secondary-200"
          : color === CONSTANTS.TERTIARY
          ? "bg-tertiary-400"
          : "bg-tertiary-200"
      } ${className}`}
    >
      {text}
    </Box>
  );
};

export default TagComponent;
