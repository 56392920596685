import Box from "@mui/material/Box";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import ButtonComponent from "./../ButtonComponent";
import FieldComponent from "../FieldComponent";
import SelectComponent from "../SelectComponent";
import { ButtonContainerProps } from "../../ts/component.types";
import { CONSTANTS, SIZES } from "../../misc/constants";
import { SelectChangeEvent, Tooltip } from "@mui/material";

const ButtonContainer = ({
  buttons,
  className,
  selectLabel = "",
  selectPlaceholder = "",
  selectOptions,
  selectValue,
  hideReportButtons,
  hideMainButtons,
  hideRightSearchField,
  hideExportButton,
  disableButtons,
  selectsData,
  inputRefs,
  handleSelectChange,
  handleDynamicSelectChange,
  handlePrint,
  handleExport,
  handleVinSearch,
}: ButtonContainerProps) => {
  return (
    <Box
      className={`flex justify-between items-center w-full py-5 mx-[1px] ${className}`}
    >
      <Box className="flex justify-start items-center flex-wrap mr-10">
        {hideMainButtons ? (
          <FieldComponent
            className="bg-primary-100"
            type={CONSTANTS.SEARCH}
            size={SIZES.SMALL}
            placeholder="Search"
            handleChange={() => {}}
            hideErrorMessage={true}
          />
        ) : (
          <>
            {selectOptions ? (
              <Box className="flex items-center m-2">
                <Box className="mr-3 whitespace-nowrap">{selectLabel}</Box>
                <SelectComponent
                  className="w-60 bg-primary-100"
                  size="small"
                  placeholder={selectPlaceholder}
                  value={selectValue}
                  options={selectOptions}
                  handleChange={handleSelectChange}
                  hideErrorMessage={true}
                />
              </Box>
            ) : null}

            {selectsData?.length
              ? selectsData?.map((selectData) => (
                  <Box className="flex items-center m-2">
                    {selectData?.label && (
                      <Box className="mr-3 whitespace-nowrap">
                        {selectData?.label}
                      </Box>
                    )}
                    <SelectComponent
                      className="w-60 bg-primary-100"
                      size="small"
                      placeholder={selectData?.placeholder}
                      value={selectData?.selectedValue}
                      options={selectData?.options}
                      disabled={selectData?.disabled}
                      handleChange={(event: SelectChangeEvent<string>) =>
                        handleDynamicSelectChange &&
                        handleDynamicSelectChange(
                          selectData?.key,
                          event?.target?.value
                        )
                      }
                      hideErrorMessage={true}
                    />
                  </Box>
                ))
              : null}

            {buttons?.map((button) => {
              if (button?.access) {
                return (
                  <ButtonComponent
                    className="margin-4 button-container w-[230px]"
                    key={button.label}
                    text={button.label}
                    color={button.color}
                    size={button.size}
                    onClick={button.onClick}
                  />
                );
              }
              return null;
            })}
          </>
        )}
      </Box>
      <Box className="flex items-center">
        {!hideRightSearchField && (
          <FieldComponent
            className="bg-primary-100 min-width-200"
            hideErrorMessage={true}
            type={CONSTANTS.SEARCH}
            size={SIZES.SMALL}
            placeholder="Search VIN"
            inputRefs={inputRefs}
            onPressEnter={() => handleVinSearch && handleVinSearch()}
            handleSearch={() => handleVinSearch && handleVinSearch()}
          />
        )}
        {!hideReportButtons && (
          <Box className="flex justify-evenly items-center">
            <Tooltip title="Print">
              <Box
                onClick={() =>
                  !disableButtons ? handlePrint && handlePrint() : null
                }
              >
                <PrintRoundedIcon
                  className={`cerulean-blue mx-3 ${
                    !disableButtons ? "cursor-pointer cerulean-blue" : "cursor-not-allowed desaturated-blue"
                  }`}
                />
              </Box>
            </Tooltip>
            {!hideExportButton && (
              <Tooltip title="Export">
                <Box
                  onClick={() =>
                    !disableButtons ? handleExport && handleExport() : null
                  }
                >
                  <DescriptionRoundedIcon
                    className={`${
                      !disableButtons ? "cursor-pointer cerulean-blue" : "cursor-not-allowed desaturated-blue"
                    }`}
                  />
                </Box>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ButtonContainer;
