import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";

import ButtonContainer from "../components/ButtonContainer";
import TableComponent from "../components/TableComponent";

import { auctionListColumns, auctionListCreateData } from "../misc/createData";
import {
  CONSTANTS,
  DATE_FORMAT,
  DATE_TYPE,
  MODAL_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
  SIZES,
  TABLE_COLUMNS,
} from "../misc/constants";

import { toggleModal } from "../store/reducer/modal";

import {
  useLazyGetAuctionsQuery,
  useFinishAuctionMutation,
} from "../store/reducer/auction";
import { checkBoolean, formatDate, getRowData } from "../misc/utils";
import { useAppSelector } from "../hooks/redux-toolkit";
import { appendQueryParams } from "../misc/api.utils";
import { Column, ModalSelectOption } from "../ts/component.types";
import { useLazyGetLocationsQuery } from "../store/reducer/location";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { useReactToPrint } from "react-to-print";
import { AuctionStatus } from "../misc/enum";
import { Actions, Tabs } from "../misc/access.enum";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { MESSAGES } from "../misc/messages";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";
import { toggleProgressBar } from "../store/reducer/progressBar";
import { useSuccessDispatcher } from "../hooks/useSuccessDispatcher";

type DateRangeType = {
  startDate: string;
  endDate: string;
};

type QueryParamType = {
  activeStatus: string;
  page: number;
  rowsPerPage: number;
  id: string;
  auctionLocation: string;
  auctionStatus: string;
  auctionDate: DateRangeType;
  createdBy: string;
  creationDate: DateRangeType;
};

const AuctionList = () => {
  const reference = useRef<HTMLTableElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.AUCTION_LIST,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const dispatch = useDispatch();
  const errorDispatcher = useErrorDispatcher();
  const successDispatcher = useSuccessDispatcher();
  const { permissions } = useAppSelector((state) => state.authorization);
  const [getAuctions, { data: auctionStateData }] = useLazyGetAuctionsQuery();
  const [auctionRows, setAuctionRows] = useState<[] | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [fieldsData, setFieldsData] = useState<Column[]>([]);
  const [queryParams, setQueryParams] = useState({
    activeStatus: "active",
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    id: "",
    auctionLocation: "-1",
    auctionStatus: "-1",
    auctionDate: {
      startDate: "",
      endDate: "",
    },
    createdBy: "",
    creationDate: {
      startDate: "",
      endDate: "",
    },
  });
  const [getLocations, { data: locationsData }] = useLazyGetLocationsQuery();
  const [finishAuction] = useFinishAuctionMutation();

  const handleExport = () => {
    const auctionsData = auctionStateData?.data?.auction;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.AuctionListTab,
      auctionsData
    );
    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Auction List (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const handleOpen = () => {
    dispatch(
      toggleModal({
        open: true,
        modalType: MODAL_TYPE.CREATE_AUCTION,
        submitText: "Create",
      })
    );
  };

  async function fetchData(queryParams: QueryParamType) {
    const page = queryParams.page + 1;
    const limit = queryParams.rowsPerPage;
    const location = Number(queryParams?.auctionLocation);
    const status = Number(queryParams?.auctionStatus);
    let apiParams = `limit=${limit}&page=${page}`;
    const queryParamObj: DynamicKeyValuesWithNumArray = {
      id: queryParams.id,
      isActive: queryParams.activeStatus,
      createdBy: queryParams.createdBy,
      auctionStartDate:
        queryParams?.auctionDate?.startDate &&
        formatDate(
          queryParams?.auctionDate?.startDate,
          DATE_TYPE.START_DATE_RANGE
        ),
      auctionEndDate:
        queryParams?.auctionDate?.endDate &&
        formatDate(queryParams?.auctionDate?.endDate, DATE_TYPE.END_DATE_RANGE),
      createdAtStartDate:
        queryParams?.creationDate?.startDate &&
        formatDate(
          queryParams?.creationDate?.startDate,
          DATE_TYPE.START_DATE_RANGE
        ),
      createdAtEndDate:
        queryParams?.creationDate?.endDate &&
        formatDate(
          queryParams?.creationDate?.endDate,
          DATE_TYPE.END_DATE_RANGE
        ),
    };
    if (location && location >= NUMBER.ZERO)
      queryParamObj["location"] = location;
    if (status && status >= NUMBER.ZERO) queryParamObj["status"] = [status];

    apiParams += appendQueryParams(queryParamObj, true);

    try {
      const response = await getAuctions(apiParams);
      const data = response?.data?.data;
      const auctions = data?.auction;
      const count = data?.count;
      if (!auctions) {
        setAuctionRows([]);
        return;
      }
      const mappedUsers = mapDataToTableRows(auctions);
      setAuctionRows(mappedUsers);
      setTotalRowsCount(count);
    } catch (error) {}
  }

  const buttons = [
    {
      label: "Create New Auction",
      color: CONSTANTS.PRIMARY,
      onClick: handleOpen,
      size: SIZES.SMALL,
      access: checkBoolean(permissions?.[Actions.AddAuction]),
    },
  ];

  const mapDataToTableRows = (auctions: any) => {
    return auctions.map((auction: any) => {
      const createdAt = formatDate(
        auction?.created_at,
        DATE_TYPE.TIMESTAMP,
        DATE_FORMAT.YYYY_MM_DD
      );

      // Auction 'start_time' is in UNIX Timestamp and will be used for future Dates
      // Previously created records will use 'auction_date' as Date
      // In Future, if changes are needed in this logic, make sure to test it properly
      const auctionDate = auction?.start_time
        ? formatDate(
            auction?.start_time,
            DATE_TYPE.TIMESTAMP,
            DATE_FORMAT.YYYY_MM_DD_TIME
          )
        : formatDate(
            auction?.auction_date,
            DATE_TYPE.DATE_STRING,
            DATE_FORMAT.YYYY_MM_DD
          );

      const createdByUser = auction?.created_by_user;
      const createdBy = createdByUser
        ? `${createdByUser?.first_name} ${createdByUser?.last_name}`
        : "N/A";

      const showMarkAuctionComplete =
        checkBoolean(permissions?.[Actions.UpdateAuction]) &&
        auction?.status === AuctionStatus.InProgress &&
        +auction?.finish_time * 1000 < new Date().getTime();

      return auctionListCreateData(
        auction?.id,
        auction?.location?.name,
        auctionDate || "N/A",
        createdBy,
        createdAt || "N/A",
        auction?.is_active,
        auction?.status,
        !checkBoolean(permissions?.[Actions.UpdateAuction]) ||
          auction?.status !== AuctionStatus.Scheduled,
        showMarkAuctionComplete
      );
    });
  };

  const parseData = (
    data: readonly Column[],
    locationsData: Array<ModalSelectOption>
  ) =>
    data?.map((field) => {
      if (
        (field.id === TABLE_COLUMNS.PICKUP_LOCATION ||
          field.id === TABLE_COLUMNS.DROPOFF_LOCATION ||
          field.id === TABLE_COLUMNS.AUCTION_LOCATION) &&
        locationsData
      ) {
        const locations = locationsData?.map((location) => {
          return {
            label: location.name,
            value: location.id,
          };
        });
        locations.push({
          label: "All",
          value: NUMBER.MINUS_ONE,
        });
        return {
          ...field,
          options: locations,
        };
      }
      return field;
    });

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); //eslint-disable-line

  useEffect(() => {
    if (auctionStateData?.data?.auction) {
      let auctionsData = auctionStateData?.data?.auction;
      auctionsData = mapDataToTableRows(auctionsData);
      setAuctionRows(auctionsData);
      setTotalRowsCount(auctionStateData?.data?.count);
    }
  }, [auctionStateData]); //eslint-disable-line

  useEffect(() => {
    const queryParamObj = {
      isActive: "active",
    };
    const apiParams = appendQueryParams(queryParamObj, false);
    if (!locationsData) {
      getLocations(apiParams);
      return;
    }
    const modifiedFields = parseData(
      auctionListColumns,
      locationsData?.data?.locations
    );
    setFieldsData(modifiedFields);
  }, [locationsData]); //eslint-disable-line

  const applyFilter = (values: {
    [key: string]:
      | number
      | boolean
      | string
      | {
          startDate: string;
          endDate: string;
        };
  }) => {
    setAuctionRows(null);
    setQueryParams({
      ...queryParams,
      activeStatus:
        (values?.activeStatus as string) || queryParams.activeStatus,
      page: values?.page as number,
      rowsPerPage: (values?.rowsPerPage as number) || queryParams.rowsPerPage,
      id: "id" in values ? (values?.id as string) : queryParams?.id,
      createdBy:
        "createdBy" in values
          ? (values?.createdBy as string)
          : queryParams?.createdBy,
      auctionLocation:
        "auctionLocation" in values
          ? (values?.auctionLocation as string)
          : queryParams?.auctionLocation,
      auctionStatus:
        "auctionStatus" in values
          ? (values?.auctionStatus as string)
          : queryParams?.auctionStatus,
      auctionDate: {
        startDate:
          "auctionDate" in values
            ? (values?.auctionDate as DateRangeType)?.startDate
            : queryParams?.auctionDate?.startDate,
        endDate:
          "auctionDate" in values
            ? (values?.auctionDate as DateRangeType)?.endDate
            : queryParams?.auctionDate?.endDate,
      },
      creationDate: {
        startDate:
          "creationDate" in values
            ? (values?.creationDate as DateRangeType)?.startDate
            : queryParams?.creationDate?.startDate,
        endDate:
          "creationDate" in values
            ? (values?.creationDate as DateRangeType)?.endDate
            : queryParams?.creationDate?.endDate,
      },
    });
  };

  const onButtonClick = async (row: any, finish?: boolean) => {
    if (finish) {
      dispatch(toggleProgressBar(true));
      const response: any = await finishAuction(row?.id);
      if (!response?.error)
        successDispatcher(true, MESSAGES.AUCTION.AUCTION_FINISH);
      dispatch(toggleProgressBar(false));
      return;
    }

    let selected_auction = getRowData(row.id, auctionStateData?.data?.auction);
    dispatch(
      toggleModal({
        open: true,
        edit: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.CREATE_AUCTION,
        data: {
          modalValues: {
            location_id: +selected_auction?.location_id,
            auction_date: selected_auction?.auction_date,
            is_active: selected_auction?.is_active,
            id: selected_auction?.id,
          },
        },
      })
    );
  };

  return (
    <Box>
      <ButtonContainer
        buttons={buttons}
        hideRightSearchField={true}
        handlePrint={handlePrint}
        handleExport={handleExport}
        disableButtons={auctionRows ? auctionRows?.length === 0 : true}
      />
      <TableComponent
        reference={reference}
        rows={auctionRows}
        applyFilter={applyFilter}
        queryParams={queryParams}
        rowCount={totalRowsCount}
        columns={fieldsData}
        onButtonClick={onButtonClick}
      />
    </Box>
  );
};

export default AuctionList;
