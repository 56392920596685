import { API_ENDPOINT } from "../../misc/enum";
import {
  CreateLocationPayload,
  UpdateLocationPayload,
} from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const locationSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.LOCATION}?${params}`,
      }),
      providesTags: ["Location"],
    }),
    createLocation: builder.mutation({
      query: (payload: CreateLocationPayload) => ({
        url: `${API_ENDPOINT.LOCATION}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Location"],
    }),
    updateLocation: builder.mutation({
      query: ({ id, payload }: UpdateLocationPayload) => ({
        url: `${API_ENDPOINT.LOCATION}/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Location"],
    }),
  }),
});

export const {
  useLazyGetLocationsQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
} = locationSlice;
