import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Tabs } from "./access.enum";
import {
  ADMIN_ROLES,
  DEALER_ROLES,
  LOCAL_STORAGE_KEYS,
  MODAL_TYPE,
  NUMBER,
  QUERIES,
} from "./constants";
import {
  ArbitrationChatMessageSenderType,
  FRONTEND_ROUTES,
  UserTypes,
} from "./enum";
import { DATE_TYPE } from "./constants";
import {
  DynamicKeyValuesWithNumArray,
  DynamicKeyValuesWithStringArray,
} from "../ts/api.types";
import { ModalFieldProps } from "../ts/component.types";
import { ModalValuesType } from "../ts/api.interface";

dayjs.extend(utc);

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+/;
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const phoneRegexUnitedStates =
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

export const isValidPassword = (password: string) => {
  return passwordRegex.test(password);
};

export const isValidEmail = (email: string) => {
  return emailRegex.test(email);
};

export const convertToGMT = (timeString: number) => {
  const mantissa = Math.abs(timeString % 1);
  const exponent = Math.abs(timeString) - mantissa;
  const isNegative = timeString < 0;

  return (
    `GMT ${isNegative ? "-" : "+"}` +
    String(exponent).padStart(2, "0") +
    ":" +
    String(mantissa * 60).padStart(2, "0")
  );
};

export const timezoneToGMTValueFormat = (timeString: number) => {
  const mantissa = Math.abs(timeString % 1);
  const exponent = Math.abs(timeString) - mantissa;
  const isNegative = timeString < 0;

  return (
    `${isNegative ? "-" : "+"}` +
    String(exponent).padStart(2, "0") +
    ":" +
    String(mantissa * 100).padStart(2, "0")
  );
};

export const getRowData = (id: number, stateData: any) => {
  return stateData.find((data: any) => {
    return id?.toString() === data.id?.toString();
  });
};

export const isEmptyObject = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

export const checkBoolean = (value: any): boolean => value === true;

export const hasAccess = (rolesArr: Array<UserTypes>, role: UserTypes) =>
  rolesArr.includes(role);

export const getRouteOfTabs = (
  path: string,
  permissions: {
    [key: string]: boolean;
  }
) => {
  let finalRoute = path;

  if (path === FRONTEND_ROUTES.TRANSPORT) {
    switch (true) {
      case checkBoolean(permissions?.[Tabs.TransportationTab]):
        finalRoute = path;
        break;
      case checkBoolean(permissions?.[Tabs.UnAssignedTTransporterTab]):
        finalRoute = FRONTEND_ROUTES.TRANSPORT_UNASSIGNED;
        break;
      case checkBoolean(permissions?.[Tabs.TransportRequestTab]):
        finalRoute = FRONTEND_ROUTES.TRANSPORT_REQUEST;
        break;
      case checkBoolean(permissions?.[Tabs.ScheduledTransportTab]):
        finalRoute = FRONTEND_ROUTES.TRANSPORT_SCHEDULED;
        break;
      case checkBoolean(permissions?.[Tabs.CompletedTransportTab]):
        finalRoute = FRONTEND_ROUTES.TRANSPORT_COMPLETED;
        break;
      default:
        break;
    }
  } else if (path === FRONTEND_ROUTES.AUCTION) {
    switch (true) {
      case checkBoolean(permissions?.[Tabs.AuctionTab]):
        finalRoute = path;
        break;
      case checkBoolean(permissions?.[Tabs.OVERequestTab]):
        finalRoute = FRONTEND_ROUTES.REQUEST_OVE;
        break;
      case checkBoolean(permissions?.[Tabs.SecondChanceTab]):
        finalRoute = FRONTEND_ROUTES.SECOND_CHANCE;
        break;
      case checkBoolean(permissions?.[Tabs.ReRunTab]):
        finalRoute = FRONTEND_ROUTES.RERUN;
        break;
      case checkBoolean(permissions?.[Tabs.AuctionListTab]):
        finalRoute = FRONTEND_ROUTES.AUCTION_LIST;
        break;
      default:
        break;
    }
  } else if (path === FRONTEND_ROUTES.VEHICLE) {
    switch (true) {
      case checkBoolean(permissions?.[Tabs.InventoryTab]):
        finalRoute = path;
        break;
      case checkBoolean(permissions?.[Tabs.SoldTab]):
        finalRoute = FRONTEND_ROUTES.VEHICLE_SOLD;
        break;
      case checkBoolean(permissions?.[Tabs.RemoveFromLotTab]):
        finalRoute = FRONTEND_ROUTES.VEHICLE_TRASH;
        break;
      default:
        break;
    }
  } else if (path === FRONTEND_ROUTES.SETTING) {
    switch (true) {
      case checkBoolean(permissions?.[Tabs.LocationTab]):
        finalRoute = path;
        break;
      case checkBoolean(permissions?.[Tabs.AnnouncementTab]):
        finalRoute = FRONTEND_ROUTES.SETTING_ANNOUNCEMENT;
        break;
      case checkBoolean(permissions?.[Tabs.RemarksTab]):
        finalRoute = FRONTEND_ROUTES.SETTING_REMARK;
        break;
      default:
        break;
    }
  } else if (path === FRONTEND_ROUTES.USER) {
    switch (true) {
      case checkBoolean(permissions?.[Tabs.UserTab]):
        finalRoute = path;
        break;
      case checkBoolean(permissions?.[Tabs.DealerUsersTab]):
        finalRoute = FRONTEND_ROUTES.DEALER_USER;
        break;
      case checkBoolean(permissions?.[Tabs.TransporterUsersTab]):
        finalRoute = FRONTEND_ROUTES.TRANSPORTER_USER;
        break;
      default:
        break;
    }
  } else if (path === FRONTEND_ROUTES.STAKEHOLDER) {
    switch (true) {
      case checkBoolean(permissions?.[Tabs.DealersTab]):
        finalRoute = path;
        break;
      case checkBoolean(permissions?.[Tabs.TransportersTab]):
        finalRoute = FRONTEND_ROUTES.TRANSPORTER_STAKEHOLDER;
        break;
      default:
        break;
    }
  }

  return finalRoute;
};

export const renderAssignAuctionResponseHTML = (
  status: boolean,
  vin: string,
  model: string,
  make: string,
  message: string
) => {
  return `
  <div style="display: flex; flex-direction:column; align-items: flex-start; background-color: ${
    status ? "#4BB543CC" : "#CC0000CC"
  };color: #ffffff; padding: 0.5rem; border-radius: 0.375rem; margin: 0.5rem 0;font-size: 15px;width: 100%; position:relative">
  
  <div><strong style="margin-right: 0.5rem;">VIN:</strong>${vin}</div>
  <div><strong style="margin-right: 0.5rem;">Make:</strong>${make}</div>
  <div><strong style="margin-right: 0.5rem;">Model:</strong>${model}</div>
  <div><strong style="margin-right: 0.5rem;">Status: </strong>${
    message || ""
  }</div>

  
  ${
    status
      ? `<div style="position:absolute; right: 10px; top:10px;top: 50%;transform: translateY(-50%);">
    <svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#ffffff}</style><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
    </div>`
      : `<div style="position:absolute; right: 10px; top:10px;top: 50%;transform: translateY(-50%);">
    <svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#ffffff}</style><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
    </div>`
  }
</div>
`;
};

export const renderRequestTransportResponseHTML = (
  status: boolean,
  message: string,
  vin?: string,
  showVIN?: boolean
) => {
  return `
  <div style="display: flex; flex-direction:column; align-items: flex-start; background-color: ${
    status ? "#4BB543CC" : "#CC0000CC"
  };color: #ffffff; padding: 10px; border-radius: 0.375rem; margin: 0.5rem 0;font-size: 15px;width: 100%; position:relative">
  
  ${
    showVIN
      ? `<div><strong style="margin-right: 0.5rem;">VIN: </strong>${
          vin || ""
        }</div>`
      : ""
  }
  
  <div><strong style="margin-right: 0.5rem;">Status: </strong>${
    message || ""
  }</div>
  
  ${
    status
      ? `<div style="position:absolute; right: 10px; top:10px;top: 50%;transform: translateY(-50%);">
    <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#ffffff}</style><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
    </div>`
      : `<div style="position:absolute; right: 10px; top:10px;top: 50%;transform: translateY(-50%);">
    <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#ffffff}</style><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
    </div>`
  }
</div>
`;
};

export const prependZero = (value: string) => {
  if (value?.length === 1) {
    return "0" + value;
  }
  return value;
};

export const getItemFromLocaleStorage = (key: string) => {
  return window.localStorage?.getItem(key);
};

export const setItemToLocaleStorage = (key: string, value: any) => {
  return window.localStorage?.setItem(key, value);
};

export const deleteItemFromLocaleStorage = (key: string) => {
  return window.localStorage?.removeItem(key);
};

export const saveQuery = (endpointName: string, originalArgs: string) => {
  try {
    let queries = getItemFromLocaleStorage(LOCAL_STORAGE_KEYS.QUERIES);
    if (queries) {
      const parsedQueries = new Map(JSON?.parse(queries)) as Map<
        string,
        Array<string>
      >;
      const currentQueries = parsedQueries?.get(endpointName);
      if (currentQueries?.length) {
        if (!currentQueries?.includes(originalArgs)) {
          currentQueries?.push(originalArgs);
          parsedQueries?.set(endpointName, currentQueries);
        }
      } else {
        parsedQueries?.set(endpointName, [originalArgs]);
      }
      setItemToLocaleStorage(
        LOCAL_STORAGE_KEYS.QUERIES,
        JSON?.stringify(Array?.from(parsedQueries?.entries()))
      );
    } else {
      const queries = new Map();
      queries?.set(endpointName, [originalArgs]);
      setItemToLocaleStorage(
        LOCAL_STORAGE_KEYS.QUERIES,
        JSON?.stringify(Array?.from(queries?.entries()))
      );
    }
  } catch {}
};

export const getArgs = (endpointName: string) => {
  try {
    let queries = getItemFromLocaleStorage(LOCAL_STORAGE_KEYS.QUERIES);
    if (queries) {
      const parsedQueries = new Map(JSON?.parse(queries)) as Map<
        string,
        Array<string>
      >;
      const currentQueries = parsedQueries?.get(endpointName);
      if (currentQueries?.length) {
        return currentQueries;
      }
    }
    return [];
  } catch {}
};

export const getQueryParams = (queries: any) => {
  const cacheQueries = [
    QUERIES.GET_AUCTION_VEHICLES,
    QUERIES.GET_VEHICLES,
    QUERIES.GET_ARBITRATION_CHAT,
    QUERIES.GET_ARBITRATION_BY_ID,
    QUERIES.GET_ARBITRATIONS,
  ];
  const queryMap = new Map();
  for (const query in queries) {
    if (cacheQueries?.includes(queries[query]?.endpointName)) {
      const cached = queryMap.get(queries[query]?.endpointName);

      if (cached) {
        const updated = [...cached, queries[query]?.originalArgs];
        queryMap.set(queries[query]?.endpointName, updated);
      } else {
        queryMap.set(queries[query]?.endpointName, [
          queries[query]?.originalArgs,
        ]);
      }
    }
  }
  return queryMap;
};

export const formatDate = (date: string, type: string, format?: string) => {
  let formattedDate = "";

  if (type === DATE_TYPE.TIMESTAMP) {
    formattedDate = dayjs(Number(date) * 1000)
      .utc(true)
      .format(format);
  } else if (type === DATE_TYPE.DATE_STRING) {
    formattedDate = dayjs(date).utc(true).format(format);
  } else if (type === DATE_TYPE.UTC_DATE_STRING) {
    const dateString = date?.split("T")?.[0];
    formattedDate = dayjs(dateString).utc(true).format(format);
  } else if (type === DATE_TYPE.START_DATE_RANGE) {
    formattedDate = dayjs(date).startOf("day").toISOString();
  } else if (type === DATE_TYPE.END_DATE_RANGE) {
    formattedDate = dayjs(date).endOf("day").toISOString();
  }

  return formattedDate;
};

export const calculateDays = (date: string) => {
  const givenDate = dayjs(Number(date) * 1000).utc(true);
  const today = dayjs().utc(true);
  const days = today.diff(givenDate, "day");

  return days;
};

export const deepCopy = (obj: object) => {
  // Check if the input is an object
  if (typeof obj !== "object" || obj === null) {
    return obj; // Return the input if it's not an object
  }

  // Use JSON.stringify to serialize the object to a JSON-formatted string
  const jsonString = JSON.stringify(obj);

  // Use JSON.parse to create a new object from the serialized string
  const copiedObj = JSON.parse(jsonString);

  return copiedObj;
};

export const formatTimeDifference = (timestamp: number) => {
  const now = dayjs().utc(true);
  const messageTime = dayjs(timestamp * 1000).utc(true);

  const diffInMinutes = now.diff(messageTime, "minutes");
  const diffInHours = now.diff(messageTime, "hours");
  const diffInDays = now.diff(messageTime, "days");

  if (diffInMinutes < 60) {
    if (diffInMinutes === NUMBER.ZERO) {
      return "Just now";
    } else if (diffInMinutes === NUMBER.ONE) {
      return `${diffInMinutes} minute ago`;
    }
    return `${diffInMinutes} minutes ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hours ago`;
  } else {
    return `${diffInDays} days ago`;
  }
};

export const sumArray = (array: number[]) =>
  array.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    0
  ) || 0;

export const getValueByKeyPath = (obj: any, keyPath: Array<string>): any => {
  if (keyPath.length === 0) {
    return obj; // Base case: Return the current object
  }

  const nextKey = keyPath[0];
  if (!obj.hasOwnProperty(nextKey) || obj[nextKey] === undefined) {
    return undefined; // Property doesn't exist, return undefined
  }

  return getValueByKeyPath(obj[nextKey], keyPath.slice(1));
};

export const isSender = (
  role: UserTypes,
  senderType: ArbitrationChatMessageSenderType
) => {
  const isAdmin = senderType === ArbitrationChatMessageSenderType.Admin;
  const isDealer = senderType === ArbitrationChatMessageSenderType.Dealer;

  return (
    (isAdmin && ADMIN_ROLES?.includes(role)) ||
    (isDealer && DEALER_ROLES?.includes(role))
  );
};

export const logger = (...args: any) => {
  if (process.env.REACT_APP_ENV === "development") console.log(...args);
};

export const areArraysEqual = (arr1: Array<string>, arr2: Array<string>) => {
  // Check if both arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Compare each element in the arrays
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false; // If any element is different, arrays are not equal
    }
  }

  return true; // Arrays are equal
};

export const stringToNumberArray = (arrayOfStrings: Array<string>) => {
  return arrayOfStrings?.map((element: string) => Number(element));
};

export const prioritizeAndMergeObject = (
  obj1: DynamicKeyValuesWithNumArray,
  obj2: DynamicKeyValuesWithNumArray,
  keys: Array<string>
) => {
  if (!Array.isArray(keys)) {
    throw new Error("Keys must be provided as an array");
  }

  const mergedObj: DynamicKeyValuesWithNumArray = {};

  for (const key of keys) {
    if (key in obj1) {
      mergedObj[key] = obj1[key];
    } else if (key in obj2) {
      mergedObj[key] = obj2[key];
    }
  }

  return {
    ...obj1,
    ...obj2,
    ...mergedObj,
  };
};

export const getEditModalValues = (
  modalType: string,
  selectedEntity: any
): {
  [key: string]: string | boolean | number | Array<string>;
} => {
  switch (modalType) {
    case MODAL_TYPE.USER:
      return {
        firstName: selectedEntity?.first_name,
        lastName: selectedEntity?.last_name,
        userRole: selectedEntity?.role_id && parseInt(selectedEntity?.role_id),
        active: selectedEntity?.is_active,
        id: selectedEntity?.id,
      };
    case MODAL_TYPE.DEALER_USER:
      return {
        firstName: selectedEntity?.first_name,
        lastName: selectedEntity?.last_name,
        location: selectedEntity?.dealer_user?.location_id,
        dealershipName: selectedEntity?.dealer_user?.dealer.id,
        dealerRole:
          selectedEntity?.role_id && parseInt(selectedEntity?.role_id),
        state: selectedEntity?.dealer_user?.state,
        address: selectedEntity?.dealer_user?.address,
        zipCode: selectedEntity?.dealer_user?.zip,
        active: selectedEntity?.is_active,
        id: selectedEntity?.dealer_user?.id,
      };
    case MODAL_TYPE.TRANSPORTER_USER:
      return {
        firstName: selectedEntity?.first_name,
        lastName: selectedEntity?.last_name,
        location: selectedEntity?.transporter_user?.location_id,
        companyName: selectedEntity?.transporter_user?.transporter?.id,
        state: selectedEntity?.transporter_user?.state,
        address: selectedEntity?.transporter_user?.address,
        zipCode: selectedEntity?.transporter_user?.zip,
        active: selectedEntity?.is_active,
        id: selectedEntity?.transporter_user?.id,
      };
    case MODAL_TYPE.DEALER:
      return {
        dealerName: selectedEntity?.name,
        millionNumber: selectedEntity?.million_number,
        firstName: selectedEntity?.dealer_user?.[NUMBER.ZERO]?.user?.first_name,
        lastName: selectedEntity?.dealer_user?.[NUMBER.ZERO]?.user?.last_name,
        address: selectedEntity?.dealer_user?.[NUMBER.ZERO]?.address,
        zipCode: selectedEntity?.dealer_user?.[NUMBER.ZERO]?.zip,
        state: selectedEntity?.dealer_user?.[NUMBER.ZERO]?.state,
        location: selectedEntity?.dealer_user?.[NUMBER.ZERO]?.location_id,
        dealer_user_id: selectedEntity?.dealer_user?.[NUMBER.ZERO]?.id,
        dealer_id: selectedEntity?.id,
        membership: selectedEntity?.type,
      };
    case MODAL_TYPE.TRANSPORTER:
      return {
        companyName: selectedEntity?.name,
        firstName:
          selectedEntity?.transporter_user?.[NUMBER.ZERO]?.user?.first_name,
        lastName:
          selectedEntity?.transporter_user?.[NUMBER.ZERO]?.user?.last_name,
        address: selectedEntity?.transporter_user?.[NUMBER.ZERO]?.address,
        zipCode: selectedEntity?.transporter_user?.[NUMBER.ZERO]?.zip,
        state: selectedEntity?.transporter_user?.[NUMBER.ZERO]?.state,
        location: selectedEntity?.transporter_user?.[NUMBER.ZERO]?.location_id,
        countryCode:
          selectedEntity?.transporter_user?.[NUMBER.ZERO]?.user?.country_code,
        transporter_user_id:
          selectedEntity?.transporter_user?.[NUMBER.ZERO]?.id,
        transporter_id: selectedEntity?.id,
      };
    case MODAL_TYPE.ADD_VEHICLE:
      return {
        location_id: +selectedEntity?.location_id,
        acquiredFrom: selectedEntity?.acquired_from,
        mileage: selectedEntity?.mileage,
        floorPrice: selectedEntity?.floor_price,
        remarks: selectedEntity?.remark_ids,
        announcements: +selectedEntity?.announcement_id,
        dealerStockNumber: selectedEntity?.dealer_stock_number,
        exteriorColor: selectedEntity?.exterior_color,
        acv: selectedEntity?.acv,
        car_fax: selectedEntity?.car_fax,
        status: selectedEntity?.status,
        id: +selectedEntity?.id,
        comment: selectedEntity?.comment,
        dealer: +selectedEntity?.dealer_id || +selectedEntity?.dealer?.id,
        dealerLocation: +selectedEntity?.location?.id,
      };
    default:
      return {};
  }
};

export const setEditModalValues = (
  editableFields: ModalFieldProps[],
  modalValues: ModalValuesType,
  initialValues: DynamicKeyValuesWithStringArray
) => {
  for (let i = 0; i < editableFields.length; i++) {
    switch (editableFields[i].dataType) {
      case "string":
        initialValues[editableFields[i].name] = modalValues[
          editableFields[i].name
        ] as string;
        break;
      case "Array<string>":
        initialValues[editableFields[i].name] =
          (modalValues[editableFields[i].name] as Array<string>) || [];
        break;
      case "boolean":
        initialValues[editableFields[i].name] = modalValues[
          editableFields[i].name
        ] as boolean;
        break;
      case "number":
        initialValues[editableFields[i].name] = modalValues[
          editableFields[i].name
        ] as number;
        break;
    }
  }
};

export const numericValueOrNull = (value: any) => {
  return typeof value === "number" ? value?.toString() : "N/A";
};
