import { TextField, Box, IconButton, InputAdornment } from "@mui/material";
import { useState, useEffect } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SearchIcon from "@mui/icons-material/Search";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { FieldComponentProps } from "../../ts/component.types";
import { CONSTANTS } from "../../misc/constants";
import SendIcon from "@mui/icons-material/Send";
import DoneIcon from "@mui/icons-material/Done";
import { COLORS } from "../../misc/colors";

const FieldComponent = ({
  size,
  type,
  name,
  value,
  readOnly = false,
  placeholder,
  label,
  className,
  inputClassName,
  multiline = false,
  hideErrorMessage = false,
  rows,
  errors,
  errorTextColor,
  touched,
  disabled = false,
  fontColor,
  handleBlur,
  handleSend,
  handleChange,
  handleSearch,
  handleIconClick,
  onPressEnter,
  inputRefs,
  index = 0,
}: FieldComponentProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    setTimeout(() => {
      let element;
      let tempValue = "";
      if (inputRefs?.current[index]) {
        element = inputRefs.current[index] as HTMLInputElement;
        tempValue = element?.value || "";
      }

      element?.setSelectionRange(tempValue?.length, tempValue?.length);
    }, 0);
  }, [showPassword]); //eslint-disable-line

  return (
    <>
      <TextField
        label={label}
        size={size}
        value={disabled ? "" : value}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        className={`${className}`}
        multiline={multiline}
        rows={rows}
        inputRef={(el) => (inputRefs ? (inputRefs.current[index] = el) : null)}
        onKeyDown={(event) =>
          onPressEnter && event?.key === "Enter" && onPressEnter(event)
        }
        inputProps={{
          readOnly: readOnly,
        }}
        type={
          type === CONSTANTS.PASSWORD
            ? showPassword
              ? CONSTANTS.TEXT
              : CONSTANTS.PASSWORD
            : CONSTANTS.TEXT
        }
        style={{
          minWidth: type === CONSTANTS.SEARCH ? "200px" : "",
        }}
        InputProps={{
          style: {
            paddingLeft: type === CONSTANTS.CHECKBOX ? 4 : 0,
            padding:
              type === CONSTANTS.CHECKBOX
                ? 4
                : type === CONSTANTS.OFFER_INPUT
                ? 4
                : type === CONSTANTS.TICK
                ? 8
                : "",
            width:
              type === CONSTANTS.CHECKBOX
                ? 75
                : type === CONSTANTS.TICK
                ? 280
                : "",
            height:
              type === CONSTANTS.CHECKBOX
                ? 25
                : type === CONSTANTS.TICK
                ? 42
                : "",
            backgroundColor:
              type === CONSTANTS.CHECKBOX
                ? disabled
                  ? COLORS.LIGHT_GRAYISH_COLOR
                  : ""
                : type === CONSTANTS.TICK
                ? COLORS.LAVENDER_BLUE
                : "",
            fontSize:
              type === CONSTANTS.CHECKBOX || type === CONSTANTS.OFFER_INPUT
                ? 12
                : type === CONSTANTS.TICK
                ? 14
                : "",
            color: fontColor,
            borderRadius: type === CONSTANTS.TICK ? "8px" : "",
          },
          disabled: disabled,
          endAdornment: (
            <>
              {type === CONSTANTS.PASSWORD ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : type === CONSTANTS.SEARCH ? (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle search" onClick={handleSearch} edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ) : type === CONSTANTS.CHECKBOX ? (
                <></>
              ) : type === CONSTANTS.CURRENCY_INPUT ? (
                <InputAdornment position="end">
                  <AttachMoneyIcon />
                </InputAdornment>
              ) : type === CONSTANTS.SEND ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle search"
                    edge="end"
                    onClick={handleSend}
                    disabled={disabled}
                  >
                    <Box
                      className={`flex items-center justify-center px-2 py-1 rounded-sm ${
                        disabled ? "bg-gray-300" : "bg-secondary-200"
                      }`}
                    >
                      <SendIcon
                        className="text-tertiary-100 "
                        sx={{ width: "18px", height: "18px" }}
                      />
                    </Box>
                  </IconButton>
                </InputAdornment>
              ) : type === CONSTANTS.TICK ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle search"
                    edge="end"
                    onClick={handleIconClick}
                  >
                    <Box className="flex items-center justify-center bg-secondary-300 px-3 py-2 rounded-lg">
                      <DoneIcon
                        className="text-primary-100 "
                        sx={{ width: "18px", height: "18px" }}
                      />
                    </Box>
                  </IconButton>
                </InputAdornment>
              ) : null}
            </>
          ),
          startAdornment: (
            <>
              {type === CONSTANTS.OFFER_INPUT ? (
                <InputAdornment position="start">
                  <AttachMoneyIcon fontSize="small" />
                </InputAdornment>
              ) : null}
            </>
          ),
          classes: {
            root: `${inputClassName}`,
          },
        }}
      />

      {!hideErrorMessage && (
        <Box
          className={`${errorTextColor || "text-white"} ${
            errors && name && errors[name] && touched[name]
              ? "visible"
              : "invisible"
          } text-xs self-start my-1`}
        >
          {errors && name && errors[name] && touched[name]
            ? errors[name]
            : "error"}
        </Box>
      )}
    </>
  );
};

export default FieldComponent;
