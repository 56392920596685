import { forwardRef, useCallback, useEffect, useState } from "react";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

interface ReportCompleteProps extends CustomContentProps {
  allowDownload?: boolean;
}

const SocketSnackbar = forwardRef<HTMLDivElement, ReportCompleteProps>(
  ({ id, ...props }, ref) => {
    const { pathname } = useLocation();
    const { closeSnackbar } = useSnackbar();
    const [firstRender, setFirstRender] = useState(false);

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    useEffect(() => {
      setFirstRender(true);
      if (firstRender) {
        closeSnackbar(id);
      }
    }, [pathname]); //eslint-disable-line

    return (
      <SnackbarContent ref={ref} className="flex flex-col text-tertiary-500 ">
        <Card
          style={{
            backgroundColor: "#FDEE30",
            width: "100%",
          }}
        >
          <CardActions classes={{ root: "flex items-center justify-between" }}>
            <Box className="flex items-center">
              <CheckCircleIcon className="mr-2 text-tertiary-500" />
              <Typography variant="body2">
                Data updated on this page!
              </Typography>
            </Box>
            <Box>
              <IconButton size="small" onClick={handleDismiss}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </CardActions>
          <Paper className="p-4 ">
            <Typography
              gutterBottom
              variant="caption"
              style={{
                display: "block",
                maxWidth: "300px",
              }}
            >
              {props.message}
            </Typography>

            <Box
              className="cursor-pointer text-sm text-secondary-300 hover:underline"
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload Page
            </Box>
          </Paper>
        </Card>
      </SnackbarContent>
    );
  }
);

SocketSnackbar.displayName = "SocketSnackbar";

export default SocketSnackbar;
