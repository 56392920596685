import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import ButtonContainer from "../components/ButtonContainer";
import TableComponent from "../components/TableComponent";
import {
  transporterUserColumns,
  transporterUserCreateData,
} from "../misc/createData";
import {
  DATE_FORMAT,
  DATE_TYPE,
  MODAL_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
  SIZES,
  TABLE_COLUMNS,
  USER_TYPE_NAME,
} from "../misc/constants";
import { toggleModal } from "../store/reducer/modal";
import { useLazyGetTransporterUsersQuery } from "../store/reducer/transporterUser";
import { UserTypes } from "../misc/enum";
import { appendQueryParams } from "../misc/api.utils";
import { useAppSelector } from "../hooks/redux-toolkit";
import {
  checkBoolean,
  formatDate,
  getRowData,
  getEditModalValues,
  prioritizeAndMergeObject,
} from "../misc/utils";
import { useReactToPrint } from "react-to-print";
import { Actions, Tabs } from "../misc/access.enum";
import { Column, ModalSelectOption } from "../ts/component.types";
import { useLazyGetTransportersQuery } from "../store/reducer/transporter";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { MESSAGES } from "../misc/messages";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";

const TransporterUsers = () => {
  const reference = useRef<HTMLTableElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.TRANSPORTER_USERS,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const dispatch = useDispatch();
  const errorDispatcher = useErrorDispatcher();
  const { permissions } = useAppSelector((state) => state.authorization);
  const [getTransporterUsers, { data: transporterUserStateData }] =
    useLazyGetTransporterUsersQuery();
  const [getTransporters, { data: transporterStateData }] =
    useLazyGetTransportersQuery();
  const [fieldsData, setFieldsData] = useState<Column[]>([]);
  const [transporterUserRows, setTransporterUserRows] = useState<[] | null>(
    null
  );

  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    activeStatus: "active",
    userRole: UserTypes.TransporterUser.toString(),
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    contactNo: "",
    transporterName: "",
  });

  const handleExport = () => {
    const usersData = transporterUserStateData?.data?.users;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.TransporterUsersTab,
      usersData
    );

    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Transporter Users (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const handleTransporterUser = () => {
    dispatch(
      toggleModal({
        open: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.TRANSPORTER_USER,
      })
    );
  };

  const buttons = [
    {
      label: "Add New Transporter User",
      color: "primary",
      onClick: handleTransporterUser,
      size: SIZES.SMALL,
      access: checkBoolean(permissions?.[Actions.AddTransporterUser]),
    },
  ];

  const mapDataToTableRows = (users: any) => {
    return users.map((user: any) => {
      return transporterUserCreateData(
        user?.email,
        user?.first_name,
        user?.last_name,
        user?.country_code + user?.phone_number,
        user?.transporter_user?.transporter?.name || "N/A",
        user?.id,
        USER_TYPE_NAME[user?.role_id as UserTypes],
        user?.is_active,
        !checkBoolean(permissions?.[Actions.UpdateTransporterUser])
      );
    });
  };

  async function fetchData(queryParams: DynamicKeyValuesWithNumArray) {
    let role_ids = [];

    if (
      queryParams.userRole?.toString() === UserTypes.TransporterUser?.toString()
    ) {
      role_ids.push(UserTypes.TransporterUser);
    } else {
      role_ids.push(UserTypes.TransporterUser);
    }

    let apiParams = ``;

    const queryParamObj: DynamicKeyValuesWithNumArray = {
      page: (queryParams.page as number) + 1,
      limit: queryParams.rowsPerPage as number,
      id: queryParams.id as string,
      isActive: queryParams.activeStatus as string,
      contactNo: queryParams.contactNo as string,
      firstName: queryParams.firstName as string,
      lastName: queryParams.lastName as string,
      email: queryParams.email as string,
      userRole: role_ids,
    };

    const transporterId = queryParams.transporterName;

    if (transporterId && Number(transporterId) >= NUMBER.ZERO)
      queryParamObj["transporterId"] = transporterId;

    apiParams += appendQueryParams(queryParamObj, false);

    try {
      const response = await getTransporterUsers(apiParams);

      const data = response?.data?.data;
      const users = data?.users;
      const count = data?.count;

      if (!users) {
        setTransporterUserRows([]);
        return;
      }

      const mappedUsers = mapDataToTableRows(users);

      setTransporterUserRows(mappedUsers);

      setTotalRowsCount(count);
    } catch (error) {}
  }

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); //eslint-disable-line

  useEffect(() => {
    let users, count;
    users = transporterUserStateData?.data?.users;
    if (users) {
      count = transporterUserStateData.data.count;
      const usersData = mapDataToTableRows(users);
      setTransporterUserRows(usersData);
      setTotalRowsCount(count);
    }
  }, [transporterUserStateData]); //eslint-disable-line

  const applyFilter = (values: {
    [key: string]: number | boolean | string;
  }) => {
    setTransporterUserRows(null);
    const prioritizedQueryParams = prioritizeAndMergeObject(
      values,
      queryParams,
      [
        "page",
        "rowsPerPage",
        "activeStatus",
        "transporterName",
        "userRole",
        "id",
        "email",
        "firstName",
        "lastName",
        "contactNo",
      ]
    );

    setQueryParams({
      ...queryParams,
      ...prioritizedQueryParams,
    });
  };

  const parseData = (
    data: readonly Column[],
    transporterData: Array<ModalSelectOption>
  ) =>
    data?.map((field) => {
      if (field.id === TABLE_COLUMNS.TRANSPORTER_NAME && transporterData) {
        const transporters = transporterData?.map((transporter) => {
          return {
            label: transporter.name,
            value: transporter.id,
          };
        });
        transporters.push({
          label: "All",
          value: NUMBER.MINUS_ONE,
        });
        return {
          ...field,
          options: transporters,
        };
      }

      return field;
    });

  useEffect(() => {
    if (!transporterStateData) {
      getTransporters("");
      return;
    }
    const modifiedFields = parseData(
      transporterUserColumns,
      transporterStateData?.data?.transporters
    );
    setFieldsData(modifiedFields);
  }, [transporterStateData]); //eslint-disable-line

  const onButtonClick = async (row: any) => {
    let selected_transporter_user = getRowData(
      row.id,
      transporterUserStateData?.data?.users
    );
    dispatch(
      toggleModal({
        open: true,
        edit: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.TRANSPORTER_USER,
        data: {
          modalValues: getEditModalValues(
            MODAL_TYPE.TRANSPORTER_USER,
            selected_transporter_user
          ),
        },
      })
    );
  };

  return (
    <Box>
      <ButtonContainer
        buttons={buttons}
        hideRightSearchField={true}
        handlePrint={handlePrint}
        handleExport={handleExport}
        disableButtons={
          transporterUserRows ? transporterUserRows?.length === 0 : true
        }
      />
      <TableComponent
        reference={reference}
        rows={transporterUserRows}
        columns={fieldsData}
        rowCount={totalRowsCount}
        applyFilter={applyFilter}
        queryParams={queryParams}
        onButtonClick={onButtonClick}
      />
    </Box>
  );
};

export default TransporterUsers;
