export const ERROR_MESSAGES = {
  COMMON: {
    TOO_SHORT: "* Too Short!",
    TOO_LONG: "* Too Long!",
    ONLY_NUMERIC_VALUE: "* Only Numeric Value Allowed",
    ONLY_POSITIVE_VALUE: "* Only Positive Value Allowed",
    PROVIDE_EMAIL_OR_USERNAME: "Username or Email Address Required",

    USERNAME: "* Username Required",
    FIRST_NAME: "* First Name Required",
    LAST_NAME: "* Last Name Required",
    CONTACT_NUMBER: "* Contact Number Required",
    CONTACT_NUMBER_MIN: "* Contact number contain minimum 10 digits",
    CONTACT_NUMBER_MAX: "* Contact number contain maximum 14 digits",
    US_CONTACT_NUMBER: "* Invalid Contact Number *Accepts US Contact Number*",
    PASSWORD: "* Password Required",
    CONFIRM_PASSWORD: "* Confirm Password Required",
    PASSWORD_LENGTH: "* Your password must contain at least 8 characters.",
    PASSWORD_VALIDATION:
      "* Password must contain: 1 Uppercase, 1 Lowercase, 1 Digit.",
    PASSWORD_MATCH: "* Passwords must match",
    USER_ROLE: "* User Role Required",
    EMAIL: "* Email Required",
    INVALID_EMAIL: "* Invalid email",
    COMPANY_NAME: "* Company Name Required",
    ZIP_CODE: "* Zip Code Required",
    ADDRESS: "* Address Required",
    STATE: "* State Required",
    LOCATION: "* Location Required",
    DEALER: "* Dealer Required",
    DEALER_ROLE: "* Dealer Role Required",
    MEMBERSHIP: "* Membership Required",
    MILLION_NUMBER: "* 5 Million Number Required",
    DEALER_NAME: "* Dealer Name Required",
    DEALER_SHIP: "* Dealership Required",
    DATE: "* Date Required",
    AUCTION: "* Auction Required",
    NAME: "* Name Required",
    TIME: "* Time Required",
    REMARKS: "* Sub Announcements Required",
    ANNOUNCEMENT: "* Announcement Required",
    FLOOR_PRICE: "* Floor Price Required",
    ACQUIRED_FROM: "* Acquired From Required",
    PICKUP_DATE: "* Pickup Date Required",
    DROPOFF_DATE: "* Dropoff Date Required",
    DROPOFF_LOCATION: "* Dropoff Location Required",
    YEAR: "* Year Required",
    MAKE: "* Make Required",
    MODEL: "* Model Required",
    MMR: "* MMR Required",
    VIN: "* VIN Required",
    ACV: "* ACV Required",
    MILEAGE: "* Mileage Required",
    EXTERIOR_COLOR: "* Exterior Color Required",
    STOCK_NUMBER: "* Stock Number Required",
    LOCATION_NAME: "* Location Name Required",
    TIMEZONE: "* Timezone Required",
    LABEL: "* Label Required",
    SELECT_FROM: "* Select From Date",
    SELECT_TO: "* Select To Date",
    VIN_LENGTH: "VIN must be 17 characters long.",
    ZIP_CODE_LENGTH: "ZIP Code must be 5 characters long.",
  },
};
