import { ButtonHTMLAttributes, forwardRef } from "react";

interface CustomDatePickerInputProp
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  value: string;
}

const CustomDatePickerInput = forwardRef<
  HTMLButtonElement,
  CustomDatePickerInputProp
>(({ value, placeholder, onClick, style, ...rest }, ref) => (
  <button
    className="text-left"
    style={{
      ...style,
      color: value === "" ? "gray" : "black",
    
    }}
    onClick={onClick}
    ref={ref}
    {...rest}
  >
    {value === "" ? placeholder : value}
  </button>
));

export default CustomDatePickerInput;
