import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { persistor } from "./../index";
import { resetAuthData, setError } from "./../reducer/auth";
import { toggleModal } from "../reducer/modal";
import { MESSAGES } from "../../misc/messages";
import { toggleProgressBar } from "../reducer/progressBar";
import { deActivateSocket } from "../socket";
import { baseAPI } from "../reducer/baseSlice";
import { logger } from "../../misc/utils";

const errorStatus = [401];
export const requestErrorMiddleware: Middleware =
  ({ dispatch, getState }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    try {
      if (isRejectedWithValue(action)) {
        let errorMessage = "";
        let isInvalidMessage = false;
        const isInvalidMessageState =
          getState()?.authorization?.isInvalidMessage;

        const token = getState()?.authorization?.token;

        if (Array.isArray(action?.payload?.data?.message)) {
          action?.payload?.data?.message?.forEach(
            (message: string) => (errorMessage += "\n" + message)
          );
        } else {
          if (!action?.payload?.data?.message) {
            isInvalidMessage = true;
          }
          errorMessage =
            action?.payload?.data?.message || MESSAGES.API.ERROR_OCCURED;
        }

        if (errorStatus.includes(action.payload.status) && token) {
          isInvalidMessage = true;
          errorMessage =
            action?.payload?.data?.message || MESSAGES.API.ERROR_OCCURED;

          persistor.purge();
          deActivateSocket(true);
          dispatch(resetAuthData());
          dispatch(baseAPI.util.resetApiState());
          dispatch(
            toggleModal({
              open: false,
            })
          );
          dispatch(toggleProgressBar(false));
        }

        if (!isInvalidMessageState && token) {
          dispatch(
            setError({
              error: true,
              errorMessage: errorMessage,
              isInvalidMessage,
            })
          );
        }
      }
    } catch (error) {
      logger("error occured==>", error);
    } finally {
      // logger("next==>", action);
      return next(action);
    }
  };
