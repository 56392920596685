import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/redux-toolkit";
import { FRONTEND_ROUTES } from "../misc/enum";

export const PublicRoute = ({ children }: any) => {
  const { isAuthenticated } = useAppSelector((state) => state.authorization);

  if (isAuthenticated) return <Navigate to={FRONTEND_ROUTES.DASHBOARD} />;

  return <>{children}</>;
};
