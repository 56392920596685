import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box/Box";
import ListingTableComponent from "../components/ListingTableComponent";

import { transportationCreateData } from "../misc/createData";
import { useLazyGetAssignedTransportRequestsQuery } from "../store/reducer/transportRequest";
import { TransportRequestStatus } from "../misc/enum";
import { CellRow } from "../ts/component.types";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { appendQueryParams } from "../misc/api.utils";
import { formatDate } from "../misc/utils";
import {
  DATE_FORMAT,
  DATE_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
} from "../misc/constants";
import { useReactToPrint } from "react-to-print";
import ButtonContainer from "../components/ButtonContainer";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { Tabs } from "../misc/access.enum";
import { MESSAGES } from "../misc/messages";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";

type QueryParamType = {
  page: number;
  rowsPerPage: number;
  activeStatus: string;
  vin: string;
};

const Transportation = () => {
  const errorDispatcher = useErrorDispatcher();
  const reference = useRef<HTMLTableSectionElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.TRANSPORTATION,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const [getTransportRequests, { data: transportRequestStateData }] =
    useLazyGetAssignedTransportRequestsQuery();
  const [transportRequestRows, setTransportRequestRows] = useState<Array<{
    id: string;
    conditionReportId?: string;
    data: CellRow[][];
  }> | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    activeStatus: "active",
    vin: "",
    page: NUMBER.ZERO,
    rowsPerPage: 10,
  });

  const inputRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);
  const handleExport = () => {
    const transportationData =
      transportRequestStateData?.data?.transportRequests;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.TransportationTab,
      transportationData
    );

    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Transportation (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const mapDataToTableRows = (transportRequests: any) => {
    return transportRequests.map((transportRequest: any) => {
      let alternateDate;
      if (transportRequest?.alternate_date_request?.length) {
        alternateDate = formatDate(
          transportRequest?.alternate_date_request?.[NUMBER.ZERO]
            ?.alternate_date_time,
          DATE_TYPE.UTC_DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY
        );
      }

      const pickupDate = formatDate(
        transportRequest?.pickup_date_time,
        DATE_TYPE.UTC_DATE_STRING,
        DATE_FORMAT.DD_MM_YYYY
      );

      return transportationCreateData(
        transportRequest?.vehicle?.vin,
        transportRequest?.vehicle?.make,
        transportRequest?.vehicle?.model,
        transportRequest?.vehicle?.year,
        transportRequest?.vehicle?.dealer?.name,
        transportRequest?.vehicle?.location?.name,
        transportRequest?.pickup_location?.name,
        pickupDate,
        transportRequest?.dropoff_location?.name,
        TransportRequestStatus[transportRequest?.status],
        transportRequest?.transporter?.name || "N/A",
        alternateDate || "N/A",
        false
      );
    });
  };

  const fetchData = async (queryParams: QueryParamType) => {
    let apiParams = `limit=${queryParams.rowsPerPage}&page=${
      queryParams.page + 1
    }${queryParams.vin && `&vin=${queryParams.vin}`}&is_active=${
      queryParams.activeStatus === "active" ? true : false
    }&status[]=2&status[]=3&status[]=4`;

    const queryParamObj: DynamicKeyValuesWithNumArray = {};

    apiParams += appendQueryParams(queryParamObj, true);

    try {
      const response: any = await getTransportRequests(apiParams);

      const data = response?.data?.data;
      const transportRequests = data?.transportRequests;
      const count = data?.count;

      if (!transportRequests) {
        setTransportRequestRows([]);
        return;
      }

      const transportRequestsData = mapDataToTableRows(transportRequests);

      setTransportRequestRows(transportRequestsData);

      setTotalRowsCount(count);
    } catch (error) {}
  }; //eslint-disable-line

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); //eslint-disable-line

  useEffect(() => {
    if (transportRequestStateData?.data?.transportRequests) {
      let transportRequests =
        transportRequestStateData?.data?.transportRequests;
      const count = transportRequestStateData?.data?.count;
      const rows = mapDataToTableRows(transportRequests);
      setTransportRequestRows(rows);
      setTotalRowsCount(count);
    }
  }, [transportRequestStateData]);

  const applyFilter = (values: DynamicKeyValuesWithNumArray) => {
    setTransportRequestRows(null);
    setQueryParams({
      ...queryParams,
      vin: "vin" in values ? (values?.vin as string) : "",
      activeStatus:
        (values?.activeStatus as string) || queryParams.activeStatus,
      page: values?.page as number,
      rowsPerPage: (values?.rowsPerPage as number) || queryParams.rowsPerPage,
    });
  };

  return (
    <Box className="py-5">
      <ButtonContainer
        hideRightSearchField={false}
        handlePrint={handlePrint}
        handleExport={handleExport}
        inputRefs={inputRefs}
        handleVinSearch={() => {
          applyFilter({
            ...queryParams,
            vin: (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement)?.value,
            page: NUMBER.ZERO,
          });
        }}
        disableButtons={
          transportRequestRows ? transportRequestRows?.length === 0 : true
        }
      />
      <ListingTableComponent
        reference={reference}
        rows={transportRequestRows}
        rowCount={totalRowsCount}
        applyFilter={applyFilter}
        queryParams={queryParams}
      />
    </Box>
  );
};

export default Transportation;
