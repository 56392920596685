import { useEffect } from "react";
import { useSuccessDispatcher } from "../../hooks/useSuccessDispatcher";
import { useErrorDispatcher } from "../../hooks/useErrorDispatcher";
import { useAppSelector } from "../../hooks/redux-toolkit";
import { useSnackbar } from "notistack";
import { SNACKBAR_VARIANT } from "../../misc/constants";
import {
  errorSnackbarConfig,
  reloadButtonSnackbarConfig,
  successSnackbarConfig,
} from "../../config/snackbar.config";

type Props = {};

const SnackbarHandler = (props: Props) => {
  const successDispatcher = useSuccessDispatcher();
  const errorDispatcher = useErrorDispatcher();

  const {
    error,
    errorMessage,
    success,
    successMessage,
    variant,
    isInvalidMessage,
  } = useAppSelector((state) => state.authorization);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (success && successMessage) {
      enqueueSnackbar(
        successMessage,
        variant === SNACKBAR_VARIANT.RELOAD_BUTTON_SNACKBAR
          ? reloadButtonSnackbarConfig
          : successSnackbarConfig
      );
      successDispatcher(false, "");
    } else if (error && errorMessage) {
      enqueueSnackbar(errorMessage, errorSnackbarConfig);
      errorDispatcher(false, "", isInvalidMessage);
    }
  }, [success, error, successMessage, errorMessage]); //eslint-disable-line

  return <></>;
};

export default SnackbarHandler;
