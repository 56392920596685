import { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import ButtonContainer from "../components/ButtonContainer";
import FilterAccordion from "../components/FilterAccordion";
import ListingTableComponent from "../components/ListingTableComponent";
import { vehicleSoldCreateData, vehicleStatus } from "../misc/createData";
import { VehicleStatus } from "../misc/enum";
import {
  useLazyGetVehiclesQuery,
  useRequestPSIMutation,
} from "../store/reducer/vehicle";
import {
  ACTION_BUTTON_TYPES,
  DATE_FORMAT,
  DATE_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
} from "../misc/constants";
import { useAppDispatch, useAppSelector } from "../hooks/redux-toolkit";
import { toggleProgressBar } from "../store/reducer/progressBar";
import {
  calculateDays,
  checkBoolean,
  formatDate,
  numericValueOrNull,
  prioritizeAndMergeObject,
} from "../misc/utils";
import { MESSAGES } from "../misc/messages";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { appendQueryParams } from "../misc/api.utils";
import { useOpenArbitrationMutation } from "./../store/reducer/arbitration";
import { useReactToPrint } from "react-to-print";
import { Actions, Tabs } from "../misc/access.enum";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { useSuccessDispatcher } from "../hooks/useSuccessDispatcher";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";

const VehiclesSold = () => {
  const reference = useRef<HTMLTableSectionElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.SOLD,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const dispatch = useAppDispatch();
  const successDispatcher = useSuccessDispatcher();
  const errorDispatcher = useErrorDispatcher();
  const { permissions } = useAppSelector((state) => state.authorization);
  const [vehicleRows, setVehicleRows] = useState<[] | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    make: "",
    model: "",
    location: NUMBER.MINUS_ONE,
    announcement: NUMBER.MINUS_ONE,
    dealerName: NUMBER.MINUS_ONE,
    startAge: "",
    endAge: "",
    startYear: "",
    endYear: "",
    vin: "",
  });

  const inputRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);

  const [getVehicles, { data: vehicleStateData }] = useLazyGetVehiclesQuery();
  const [requestPSI] = useRequestPSIMutation();
  const [openArbitration] = useOpenArbitrationMutation();

  const handleExport = () => {
    const vehiclesData = vehicleStateData?.data?.vehicles;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.SoldTab,
      vehiclesData
    );

    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Vehicle Sold (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const mapDataToTableRows = (vehiclesData: any) => {
    return vehiclesData.map((vehicleData: any) => {
      const age = calculateDays(vehicleData?.created_at);
      const auction = vehicleData?.auction_vehicle?.[NUMBER.ZERO]?.auction;

      const soldDate = auction?.start_time
        ? formatDate(
            auction?.start_time,
            DATE_TYPE.TIMESTAMP,
            DATE_FORMAT.YYYY_MM_DD
          )
        : auction?.auction_date
        ? formatDate(
            auction?.auction_date,
            DATE_TYPE.DATE_STRING,
            DATE_FORMAT.YYYY_MM_DD
          )
        : "-";

      const remarks = vehicleData?.remarks?.length
        ? vehicleData?.remarks?.map((remark: any) => remark?.label)?.join(", ")
        : "N/A";

      return vehicleSoldCreateData(
        vehicleData?.id,
        vehicleData?.vin,
        vehicleData?.make,
        vehicleData?.model,
        vehicleData?.year,
        vehicleData?.dealer?.name,
        vehicleData?.location?.name,
        `${age?.toString()} ${age === 1 ? "Day" : "Days"}`,
        vehicleData?.announcement?.label,
        remarks,
        numericValueOrNull(vehicleData?.acv),
        numericValueOrNull(vehicleData?.dealer_stock_number),
        numericValueOrNull(vehicleData?.floor_price),
        vehicleStatus[`${vehicleData?.status}`],
        vehicleData?.comment || "N/A",
        soldDate,
        vehicleData?.sale_price || "N/A",
        checkBoolean(permissions?.[Actions.PSIStatus]),
        checkBoolean(permissions?.[Actions.NewArbitrationCase]),
        checkBoolean(permissions?.[Tabs.VINModal])
      );
    });
  };

  const getVehicleById = (id: string) => {
    const vehicles = vehicleStateData?.data?.vehicles;
    const filteredVehicle = vehicles.filter(
      (vehicle: any) => vehicle.id?.toString() === id?.toString()
    );
    return filteredVehicle?.[NUMBER.ZERO];
  };

  const handleActionButton = async (type: string, id: string) => {
    let response: any;
    let message: string = "";
    dispatch(toggleProgressBar(true));
    if (type === ACTION_BUTTON_TYPES.PSI) {
      response = await requestPSI({ id });
      message = MESSAGES.VEHICLE.PSI_REQUEST_SUCCESS;
    } else if (type === ACTION_BUTTON_TYPES.OPEN_ARBITRATION) {
      const vehicle = getVehicleById(id);
      if (vehicle) {
        const auctionVehicleId = vehicle.auction_vehicle?.[NUMBER.ZERO]?.id;
        response = await openArbitration({
          auction_vehicle_id: Number(auctionVehicleId),
        });
        message = MESSAGES.VEHICLE.ARBITRATION_REQUEST_SUCCESS;
      }
    }
    dispatch(toggleProgressBar(false));
    if (!response?.error) successDispatcher(true, message);
  };

  async function fetchData(queryParams: DynamicKeyValuesWithNumArray) {
    let apiParams = ``;

    const queryParamObj: DynamicKeyValuesWithNumArray = {
      status: VehicleStatus.Sold,
      page: (queryParams.page as number) + 1,
      limit: queryParams.rowsPerPage as number,
      make: queryParams?.make as string,
      model: queryParams?.model as string,
      startYear: queryParams?.startYear as string,
      endYear: queryParams?.endYear as string,
      startAge: queryParams?.startAge as string,
      endAge: queryParams?.endAge as string,
      startSoldDate: queryParams?.startSoldDate as string,
      endSoldDate: queryParams?.endSoldDate as string,
      vin: queryParams?.vin as string,
    };

    const location = queryParams.location;
    const announcement = queryParams.announcement;
    const dealerName = queryParams.dealerName;

    if (location && Number(location) >= NUMBER.ZERO)
      queryParamObj["location"] = location;
    if (announcement && Number(announcement) >= NUMBER.ZERO)
      queryParamObj["announcementId"] = announcement;
    if (dealerName && Number(dealerName) >= NUMBER.ZERO)
      queryParamObj["dealerId"] = dealerName;

    apiParams += appendQueryParams(queryParamObj, false);

    try {
      const response: any = await getVehicles(apiParams);

      const data = response?.data?.data;
      const vehicles = data?.vehicles;
      const count = data?.count;

      if (!vehicles) {
        setVehicleRows([]);
        return;
      }

      const vehiclesData = mapDataToTableRows(vehicles);

      setVehicleRows(vehiclesData);

      setTotalRowsCount(count);
    } catch (error) {}
  }

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); //eslint-disable-line

  useEffect(() => {
    if (vehicleStateData?.data?.vehicles) {
      let vehiclesData = vehicleStateData?.data?.vehicles;
      vehiclesData = mapDataToTableRows(vehiclesData);
      setVehicleRows(vehiclesData);
      setTotalRowsCount(vehicleStateData?.data?.count);
    }
  }, [vehicleStateData]); //eslint-disable-line

  const applyFilter = (values: DynamicKeyValuesWithNumArray) => {
    setVehicleRows(null);
    const prioritizedQueryParams = prioritizeAndMergeObject(
      values,
      queryParams,
      [
        "page",
        "rowsPerPage",
        "make",
        "model",
        "vin",
        "location",
        "announcement",
        "dealerName",
        "startAge",
        "endAge",
        "startYear",
        "endYear",
        "startSoldDate",
        "endSoldDate",
      ]
    );

    setQueryParams({
      ...queryParams,
      ...prioritizedQueryParams,
    });
  };

  const triggerFilter = (values: DynamicKeyValuesWithNumArray) => {
    applyFilter({
      ...values,
      vin: (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement)?.value,
      page: NUMBER.ZERO,
    });
  };

  const emptyVin = () => {
    if (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement) {
      (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement).value = "";
    }
  };

  return (
    <Box>
      <ButtonContainer
        hideRightSearchField={false}
        inputRefs={inputRefs}
        handlePrint={handlePrint}
        handleExport={handleExport}
        handleVinSearch={() => {
          triggerFilter({
            ...queryParams,
            vin: (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement)?.value,
          });
        }}
        disableButtons={vehicleRows ? vehicleRows?.length === 0 : true}
      />
      <FilterAccordion
        status={"In Sold"}
        triggerFilter={triggerFilter}
        emptyVin={emptyVin}
      />
      <ListingTableComponent
        reference={reference}
        rows={vehicleRows}
        showCheckbox={false}
        rowCount={totalRowsCount}
        applyFilter={applyFilter}
        queryParams={queryParams}
        handleActionButton={handleActionButton}
      />
    </Box>
  );
};

export default VehiclesSold;
