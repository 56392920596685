import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Login";
import Reset from "../pages/Reset";
import Dashboard from "../pages/Dashboard";
import VehiclesInventory from "../pages/VehiclesInventory";
import VehiclesSold from "../pages/VehiclesSold";
import VehiclesTrash from "../pages/VehiclesTrash";
import Auction from "../pages/Auction";
import Arbitration from "../pages/Arbitration";
import Roles from "../pages/Roles";
import Dealers from "../pages/Dealers";
import Users from "../pages/Users";
import Transporter from "../pages/Transporter";
import AuditTrails from "../pages/AuditTrails";
import Locations from "../pages/Locations";
import Announcements from "../pages/Announcements";
import Remarks from "../pages/Remarks";
import SubNavigationParent from "../components/SubNavigationParent";
import Transportation from "../pages/Transportation";
import UnassignedTransportation from "../pages/UnassignedTransportation";
import RequestOVE from "../pages/RequestOVE";
import RerunAuction from "../pages/RerunAuction";
import SecondChanceAuction from "../pages/SecondChanceAuction";
import ArbitrationChat from "../pages/ArbitrationChat";
import TransportRequest from "../pages/TransportRequest";
import TransportScheduledTask from "../pages/TransportScheduledTask";
import AuctionList from "../pages/AuctionList";
import PageNotFound from "../pages/PageNotFound";

import Sidebar from "../components/Sidebar";
import RouteParent from "../components/RouteParent";

import { ProtectedRoute } from "./ProtectedRoute";
import { PublicRoute } from "./PublicRoute";

import { sidebarLinks, bottomLinks } from "../components/Sidebar/links";
import { NavigationTab } from "../ts/component.types";
import ErrorPage from "../pages/ErrorPage";
import ChangePassword from "../pages/ChangePassword";
import DealerUsers from "../pages/DealerUsers";
import TransporterUsers from "../pages/TransporterUsers";
import TransportCompletedTask from "../pages/TransportCompletedTask";
import { Pages, Tabs } from "../misc/access.enum";
import { FRONTEND_ROUTES } from "../misc/enum";
import MainApp from "../MainApp";
import KbbReport from "../pages/KbbReport";

const usersSubNavs: Array<NavigationTab> = [
  {
    value: "users",
    label: "Users",
    access: Tabs.UserTab,
  },
  {
    value: "dealers",
    label: "Dealers",
    access: Tabs.DealerUsersTab,
  },
  {
    value: "transporters",
    label: "Transporters",
    access: Tabs.TransporterUsersTab,
  },
];

const transportationSubNavs: Array<NavigationTab> = [
  {
    value: "transporter",
    label: "Transportation",
    access: Tabs.TransportationTab,
  },
  {
    value: "unassigned-transporter",
    label: "Un-Assigned Transport",
    access: Tabs.UnAssignedTTransporterTab,
  },
  {
    value: "transporter-request",
    label: "Transport Requests",
    access: Tabs.TransportRequestTab,
  },
  {
    value: "transporter-schedule",
    label: "Scheduled Task",
    access: Tabs.ScheduledTransportTab,
  },
  {
    value: "transporter-complete",
    label: "Completed Task",
    access: Tabs.CompletedTransportTab,
  },
];

const vehicleSubNavs: Array<NavigationTab> = [
  {
    value: "vehicle-inventory",
    label: "Inventory",
    access: Tabs.InventoryTab,
  },
  {
    value: "vehicle-sold",
    label: "Sold",
    access: Tabs.SoldTab,
  },
  {
    value: "vehicle-trash",
    label: "Remove From Lot",
    access: Tabs.RemoveFromLotTab,
  },
];

const auctionSubNavs: Array<NavigationTab> = [
  {
    value: "auction",
    label: "Auction",
    access: Tabs.AuctionTab,
  },
  {
    value: "request-ove",
    label: "Request for OVE",
    access: Tabs.OVERequestTab,
  },
  {
    value: "rerun-auction",
    label: "Rerun In Auction",
    access: Tabs.ReRunTab,
  },
  {
    value: "second-chance-auction",
    label: "Second Chance Auction",
    access: Tabs.SecondChanceTab,
  },
  {
    value: "auction-list",
    label: "Auction List",
    access: Tabs.AuctionListTab,
  },
];

const stakeholderSubNavs: Array<NavigationTab> = [
  {
    value: "stakeholders-dealers",
    label: "Dealers",
    access: Tabs.DealersTab,
  },
  {
    value: "stakeholders-transporters",
    label: "Transporters",
    access: Tabs.TransportersTab,
  },
];

const settingSubNavs: Array<NavigationTab> = [
  {
    value: "setting-locations",
    label: "Locations",
    access: Tabs.LocationTab,
  },
  {
    value: "setting-announcements",
    label: "Announcements",
    access: Tabs.AnnouncementTab,
  },
  {
    value: "setting-remarks",
    label: "Sub Announcements",
    access: Tabs.RemarksTab,
  },
];

const unAuthRoutes = [
  {
    path: FRONTEND_ROUTES.AUTH,
    children: [
      {
        path: FRONTEND_ROUTES.LOGIN,
        element: (
          <PublicRoute>
            <Login />
          </PublicRoute>
        ),
      },
      {
        path: FRONTEND_ROUTES.RESET_PASSWORD,
        element: (
          <PublicRoute>
            <Reset />
          </PublicRoute>
        ),
      },
      {
        path: FRONTEND_ROUTES.CHANGE_PASSWORD,
        element: (
          <PublicRoute>
            <ChangePassword />
          </PublicRoute>
        ),
      },
    ],
  },
];

const routes = createBrowserRouter([
  {
    path: FRONTEND_ROUTES.ROOT,
    element: <MainApp />,
    errorElement: <ErrorPage />,
    children: [
      ...unAuthRoutes,
      {
        path: `${FRONTEND_ROUTES.KBB_REPORT}/:vehicleId`,
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute access={Pages.KbbReport}>
            <KbbReport />
          </ProtectedRoute>
        ),
      },
      {
        path: FRONTEND_ROUTES.ROOT,
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute access={Pages.Sidebar}>
            <Sidebar navLinks={sidebarLinks} bottomNavLinks={bottomLinks} />,
          </ProtectedRoute>
        ),
        children: [
          {
            path: FRONTEND_ROUTES.DASHBOARD,
            element: (
              <ProtectedRoute access={Pages.Dashboard}>
                <Dashboard />
              </ProtectedRoute>
            ),
          },
          {
            path: FRONTEND_ROUTES.VEHICLE,
            element: (
              <ProtectedRoute access={Pages.Vehicle}>
                <SubNavigationParent
                  navigationTabs={vehicleSubNavs}
                  hideReportButtons={true}
                />
              </ProtectedRoute>
            ),
            children: [
              {
                path: FRONTEND_ROUTES.VEHICLE_INVENTORY,
                element: <VehiclesInventory />,
              },
              { path: FRONTEND_ROUTES.VEHICLE_SOLD, element: <VehiclesSold /> },
              {
                path: FRONTEND_ROUTES.VEHICLE_TRASH,
                element: <VehiclesTrash />,
              },
            ],
          },
          {
            path: FRONTEND_ROUTES.AUCTION,
            element: (
              <ProtectedRoute access={Pages.Auction}>
                <SubNavigationParent
                  navigationTabs={auctionSubNavs}
                  hideReportButtons={true}
                />
              </ProtectedRoute>
            ),
            children: [
              { path: FRONTEND_ROUTES.AUCTION, element: <Auction /> },
              { path: FRONTEND_ROUTES.REQUEST_OVE, element: <RequestOVE /> },
              { path: FRONTEND_ROUTES.RERUN, element: <RerunAuction /> },
              {
                path: FRONTEND_ROUTES.SECOND_CHANCE,
                element: <SecondChanceAuction />,
              },
              { path: FRONTEND_ROUTES.AUCTION_LIST, element: <AuctionList /> },
            ],
          },
          {
            path: FRONTEND_ROUTES.ARBITRATION,
            element: (
              <ProtectedRoute access={Pages.Arbitration}>
                <RouteParent />
              </ProtectedRoute>
            ),
            children: [
              { path: FRONTEND_ROUTES.ARBITRATION, element: <Arbitration /> },
              {
                path: `${FRONTEND_ROUTES.ARBITRATION}/:caseId`,
                element: <ArbitrationChat />,
              },
            ],
          },
          {
            path: FRONTEND_ROUTES.ROLES,
            element: (
              <ProtectedRoute access={Pages.Roles}>
                <Roles />
              </ProtectedRoute>
            ),
          },
          {
            path: FRONTEND_ROUTES.USER,
            element: (
              <ProtectedRoute access={Pages.User}>
                <SubNavigationParent
                  navigationTabs={usersSubNavs}
                  hideReportButtons={true}
                />
              </ProtectedRoute>
            ),
            children: [
              { path: FRONTEND_ROUTES.USER, element: <Users /> },
              {
                path: FRONTEND_ROUTES.DEALER_USER,
                element: <DealerUsers />,
              },
              {
                path: FRONTEND_ROUTES.TRANSPORTER_USER,
                element: <TransporterUsers />,
              },
            ],
          },
          {
            path: FRONTEND_ROUTES.TRANSPORT,
            element: (
              <ProtectedRoute access={Pages.Transportation}>
                <SubNavigationParent
                  navigationTabs={transportationSubNavs}
                  hideReportButtons={true}
                />
              </ProtectedRoute>
            ),
            children: [
              { path: FRONTEND_ROUTES.TRANSPORT, element: <Transportation /> },
              {
                path: FRONTEND_ROUTES.TRANSPORT_UNASSIGNED,
                element: <UnassignedTransportation />,
              },
              {
                path: FRONTEND_ROUTES.TRANSPORT_REQUEST,
                element: <TransportRequest />,
              },
              {
                path: FRONTEND_ROUTES.TRANSPORT_SCHEDULED,
                element: <TransportScheduledTask />,
              },
              {
                path: FRONTEND_ROUTES.TRANSPORT_COMPLETED,
                element: <TransportCompletedTask />,
              },
            ],
          },
          {
            path: FRONTEND_ROUTES.STAKEHOLDER,
            element: (
              <ProtectedRoute access={Pages.Stakeholder}>
                <SubNavigationParent
                  navigationTabs={stakeholderSubNavs}
                  hideReportButtons={true}
                />
              </ProtectedRoute>
            ),
            children: [
              {
                path: FRONTEND_ROUTES.DEALER_STAKEHOLDER,
                element: <Dealers />,
              },
              {
                path: FRONTEND_ROUTES.TRANSPORTER_STAKEHOLDER,
                element: <Transporter />,
              },
            ],
          },
          {
            path: FRONTEND_ROUTES.AUDIT_TRAIL,
            element: (
              <ProtectedRoute access={Pages.AuditTrail}>
                <AuditTrails />
              </ProtectedRoute>
            ),
          },
          {
            path: FRONTEND_ROUTES.SETTING,
            element: (
              <ProtectedRoute access={Pages.Setting}>
                <SubNavigationParent
                  navigationTabs={settingSubNavs}
                  hideReportButtons={true}
                />
              </ProtectedRoute>
            ),
            children: [
              {
                path: FRONTEND_ROUTES.SETTING_LOCATION,
                element: <Locations />,
              },
              {
                path: FRONTEND_ROUTES.SETTING_ANNOUNCEMENT,
                element: <Announcements />,
              },
              { path: FRONTEND_ROUTES.SETTING_REMARK, element: <Remarks /> },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);

export { routes };
