import { API_ENDPOINT } from "../../misc/enum";
import { CreateAuctionPayload, UpdateAuctionPayload } from "../../ts/api.types";
import { baseAPI } from "./baseSlice";

export const auctionSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAuctions: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.AUCTION}?${params}`,
      }),
      providesTags: ["Auction"],
    }),
    createAuction: builder.mutation({
      query: (payload: CreateAuctionPayload) => ({
        method: "POST",
        url: `${API_ENDPOINT.AUCTION}`,
        body: payload,
      }),
      invalidatesTags: ["Auction"],
    }),
    updateAuction: builder.mutation({
      query: ({ id, payload }: UpdateAuctionPayload) => ({
        url: `${API_ENDPOINT.AUCTION}/${id}`,
        method: "PUT",
        body: { ...payload },
      }),
      invalidatesTags: ["Auction"],
    }),
    finishAuction: builder.mutation({
      query: (id: string) => ({
        url: `${API_ENDPOINT.AUCTION}/finish/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Auction"],
    }),
  }),
});

export const {
  useLazyGetAuctionsQuery,
  useCreateAuctionMutation,
  useUpdateAuctionMutation,
  useFinishAuctionMutation,
} = auctionSlice;
