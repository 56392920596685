import { setError } from "../store/reducer/auth";
import { useAppDispatch } from "./redux-toolkit";

export const useErrorDispatcher = () => {
  const dispatch = useAppDispatch();

  return (error: boolean, payload: string, isInvalidMessage?: boolean) => {
    dispatch(
      setError({
        error,
        errorMessage: payload,
        isInvalidMessage,
      })
    );
  };
};
