import { baseAPI } from "../reducer/baseSlice";
import { deActivateSocket, getSocket } from ".";
import { SocketEventName, UserTypes } from "../../misc/enum";
import { RootState } from "..";
import {
  handleArbitrationCount,
  handleArbitrationChat,
  handleEvents,
} from "./event";
import { logger } from "../../misc/utils";
import { logout } from "../services/auth";
import { setError } from "../reducer/auth";
import { MESSAGES } from "../../misc/messages";

export const socketSlice = baseAPI.injectEndpoints({
  endpoints: (build) => ({
    subscribeToEvents: build.query({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(
        _arg,
        { dispatch, cacheEntryRemoved, cacheDataLoaded, getState }
      ) {
        const authorization = (getState() as RootState).authorization;
        const token = authorization.token;
        const role = authorization.role;
        const userId = authorization.userId;
        const socket = getSocket(token);
        try {
          await cacheDataLoaded;

          socket.on("disconnect", (reason) => {
            logger("Socket Disconnected ==>", reason);
            deActivateSocket(false);
          });

          socket.on("connect", () => {
            logger("Socket Connected ***");

            socket.on(SocketEventName.AuthorizeSuccess, (event) => {
              logger("Auth Success ***");
              socket.on(SocketEventName.NewNotification, (event) => {
                logger("New Notification ***", event);
                if (Number(event.user_id) !== Number(userId)) {
                  handleEvents(event, getState().api.queries, dispatch);
                }
              });

              socket.on(SocketEventName.ArbitrationMessage, (event) => {
                handleArbitrationChat(
                  event,
                  getState().api.queries,
                  dispatch,
                  role as UserTypes
                );
                handleArbitrationCount(
                  event,
                  getState().api.queries,
                  dispatch,
                  role as UserTypes
                );
              });

              socket.on(SocketEventName.ArbitrationMessageError, (event) => {
                logger("ArbitrationMessageError ==>", event);
              });
            });

            socket.on(SocketEventName.AuthorizeError, (event) => {
              logger("Authorization Error ==>", event);
              dispatch(
                setError({
                  error: true,
                  errorMessage: MESSAGES.AUTH.TOKEN_INVALID,
                })
              );
              dispatch(logout());
            });
          });
        } catch {}
        await cacheEntryRemoved;
      },
    }),
  }),
});

export const { useLazySubscribeToEventsQuery } = socketSlice;
