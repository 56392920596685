import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import TableComponent from "./../components/TableComponent";
import Box from "@mui/material/Box";
import ButtonContainer from "../components/ButtonContainer";
import { dealerColumns, dealerCreateData } from "../misc/createData";
import {
  DATE_FORMAT,
  DATE_TYPE,
  MODAL_TYPE,
  NUMBER,
  PDF_PAGE_STYLE,
  PDF_TITLE,
  SIZES,
} from "../misc/constants";

import { toggleModal } from "../store/reducer/modal";
import { useLazyGetDealersQuery } from "../store/reducer/dealer";
import { useLazyGetLocationsQuery } from "../store/reducer/location";
import { Column, ModalSelectOption } from "../ts/component.types";
import { appendQueryParams } from "../misc/api.utils";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { useAppSelector } from "../hooks/redux-toolkit";
import {
  checkBoolean,
  formatDate,
  getRowData,
  prioritizeAndMergeObject,
  getEditModalValues,
} from "../misc/utils";
import { useReactToPrint } from "react-to-print";
import { Actions, Tabs } from "../misc/access.enum";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { MESSAGES } from "../misc/messages";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";

const Dealers = () => {
  const reference = useRef<HTMLTableElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.DEALERS,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const dispatch = useDispatch();
  const errorDispatcher = useErrorDispatcher();
  const { permissions } = useAppSelector((state) => state.authorization);
  const [fieldsData, setFieldsData] = useState<Column[]>([]);
  const [getDealers, { data: dealerStateData }] = useLazyGetDealersQuery();
  const [getLocations, { data: locationsData }] = useLazyGetLocationsQuery();
  const [dealerRows, setDealerRows] = useState<[] | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    activeStatus: "active",
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    millionNumber: "",
    dealerName: "",
    location: "",
    contactNo: "",
    zipCode: "",
    email: "",
  });

  const handleExport = () => {
    const usersData = dealerStateData?.data?.dealers;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.DealersTab,
      usersData
    );

    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Dealers (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const handleDealer = () => {
    dispatch(
      toggleModal({
        open: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.DEALER,
      })
    );
  };

  const buttons = [
    {
      label: "Add New Dealer",
      color: "primary",
      onClick: handleDealer,
      size: SIZES.SMALL,
      access: checkBoolean(permissions?.[Actions.AddDealer]),
    },
  ];

  const parseData = (
    data: readonly Column[],
    locationsData: Array<ModalSelectOption>
  ) =>
    data?.map((field) => {
      if (field.id === "location" && locationsData) {
        const locations = locationsData?.map((location) => {
          return {
            label: location.name,
            value: location.id,
          };
        });
        locations.push({
          label: "All",
          value: NUMBER.MINUS_ONE,
        });
        return {
          ...field,
          options: locations,
        };
      }

      return field;
    });

  const mapDataToTableRows = (users: any) => {
    return users.map((user: any) => {
      return dealerCreateData(
        user?.id || "N/A",
        user?.million_number || "N/A",
        user?.name || "N/A",
        user?.dealer_user?.[NUMBER.ZERO]?.location?.name || "N/A",
        user?.dealer_user?.[NUMBER.ZERO]?.user?.country_code +
          user?.dealer_user?.[NUMBER.ZERO]?.user?.phone_number || "N/A",
        user?.dealer_user?.[NUMBER.ZERO]?.zip || "N/A",
        user?.dealer_user?.[NUMBER.ZERO]?.user?.email || "N/A",
        user?.is_active,
        !checkBoolean(permissions?.[Actions.UpdateDealer])
      );
    });
  };

  async function fetchData(queryParams: DynamicKeyValuesWithNumArray) {
    let apiParams = ``;

    const queryParamObj: DynamicKeyValuesWithNumArray = {
      page: (queryParams.page as number) + 1,
      limit: queryParams.rowsPerPage as number,
      isActive: queryParams.activeStatus as string,
      contactNo: queryParams.contactNo as string,
      email: queryParams.email as string,
      dealerName: queryParams.dealerName as string,
      millionNumber: queryParams.millionNumber as string,
      zipCode: queryParams.zipCode as string,
    };

    const location = queryParams.location;

    if (location && Number(location) >= NUMBER.ZERO)
      queryParamObj["location"] = location;

    apiParams += appendQueryParams(queryParamObj, false);

    try {
      const response = await getDealers(apiParams);
      const data = response?.data?.data;
      const users = data?.dealers;
      const count = data?.count;

      if (!users) {
        setDealerRows([]);
        return;
      }

      const mappedUsers = mapDataToTableRows(users);

      setDealerRows(mappedUsers);

      setTotalRowsCount(count);
    } catch (error) {}
  }

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); // eslint-disable-line

  useEffect(() => {
    let users, count;
    users = dealerStateData?.data?.dealers;
    if (users) {
      count = dealerStateData.data.count;
      const usersData = mapDataToTableRows(users);
      setDealerRows(usersData);
      setTotalRowsCount(count);
    }
  }, [dealerStateData]); //eslint-disable-line

  useEffect(() => {
    const queryParamObj = {
      isActive: "active",
    };
    const apiParams = appendQueryParams(queryParamObj, false);
    if (!locationsData) {
      getLocations(apiParams);
      return;
    }
    const modifiedFields = parseData(
      dealerColumns,
      locationsData?.data?.locations
    );
    setFieldsData(modifiedFields);
  }, [locationsData]); //eslint-disable-line

  const applyFilter = (values: {
    [key: string]: number | boolean | string;
  }) => {
    setDealerRows(null);
    const prioritizedQueryParams = prioritizeAndMergeObject(
      values,
      queryParams,
      [
        "page",
        "rowsPerPage",
        "activeStatus",
        "millionNumber",
        "dealerName",
        "location",
        "email",
        "contactNo",
        "zipCode",
      ]
    );

    setQueryParams({
      ...queryParams,
      ...prioritizedQueryParams,
    });
  };

  const onButtonClick = async (row: any) => {
    let selected_dealer = getRowData(row.id, dealerStateData?.data?.dealers);
    dispatch(
      toggleModal({
        open: true,
        edit: true,
        submitText: "Save and Close",
        modalType: MODAL_TYPE.DEALER,
        data: {
          modalValues: getEditModalValues(MODAL_TYPE.DEALER, selected_dealer),
        },
      })
    );
  };

  return (
    <Box>
      <ButtonContainer
        buttons={buttons}
        hideRightSearchField={true}
        handlePrint={handlePrint}
        handleExport={handleExport}
        disableButtons={dealerRows ? dealerRows?.length === 0 : true}
      />
      <TableComponent
        reference={reference}
        rows={dealerRows}
        columns={fieldsData}
        rowCount={totalRowsCount}
        applyFilter={applyFilter}
        queryParams={queryParams}
        onButtonClick={onButtonClick}
      />
    </Box>
  );
};

export default Dealers;
