import { API_ENDPOINT } from "../../misc/enum";
import {
  CreateVehiclePayload,
  UpdateVehiclePayload,
  DeleteVehiclePayload,
  AssignAuctionPayload,
  ConditionReportPayload,
  UpdateConditionReportPayload,
  RequestPSIPayload,
  UpdatePSIPayload,
  UpdateTitlePayload,
  SendVehicleToOVEPayload,
  UpdateOvePayload,
  UpdateVehicleRequestPayload,
  CreateVehiclesCSVPayload,
} from "../../ts/api.types";
import { baseAPI } from "./baseSlice";
import { VehicleStatus } from "../../misc/enum";
import { current } from "@reduxjs/toolkit";

export const vehicleSlice = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getVehicles: builder.query({
      query: (params: string) => ({
        url: `${API_ENDPOINT.VEHICLE}?${
          !params ? `status=${VehicleStatus.Inventory}` : params
        }`,
      }),
      providesTags: ["Vehicle"],
    }),
    getVehicleById: builder.query({
      query: (id: string) => ({
        url: `${API_ENDPOINT.VEHICLE}/${id}`,
      }),
    }),
    getVehicleDetailByVIN: builder.query({
      query: (id: string) => ({
        url: `${API_ENDPOINT.VEHICLE}/vin/${id}`,
      }),
    }),
    createVehicle: builder.mutation({
      query: (payload: CreateVehiclePayload) => ({
        method: "POST",
        url: `${API_ENDPOINT.VEHICLE}`,
        body: payload,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    createVehiclesCSV: builder.mutation({
      query: ({
        dealer_id,
        location_id,
        formData,
      }: CreateVehiclesCSVPayload) => ({
        method: "POST",
        url: `${API_ENDPOINT.VEHICLE}/csv?dealer_id=${dealer_id}&location_id=${location_id}`,
        body: formData,
        formData: true,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    updateVehicle: builder.mutation({
      query: ({ id, payload }: UpdateVehiclePayload) => ({
        url: `${API_ENDPOINT.VEHICLE}/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Vehicle", "AuctionVehicle"],
    }),
    updateVehicleRequest: builder.mutation({
      query: ({ id, payload }: UpdateVehicleRequestPayload) => ({
        url: `${API_ENDPOINT.VEHICLE}/update-request/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["AuctionVehicle"],
    }),
    deleteVehicle: builder.mutation({
      query: ({ id }: DeleteVehiclePayload) => ({
        url: `${API_ENDPOINT.VEHICLE}/${id}`,
        method: "DELETE",
      }),
    }),
    assignAuction: builder.mutation({
      query: (payload: AssignAuctionPayload) => ({
        url: `${API_ENDPOINT.VEHICLE}/add-to-auction`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    requestCR: builder.mutation({
      query: (payload: ConditionReportPayload) => ({
        url: `${API_ENDPOINT.VEHICLE}/condition-report/request`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    updateCR: builder.mutation({
      query: ({ id, payload }: UpdateConditionReportPayload) => ({
        url: `${API_ENDPOINT.VEHICLE}/condition-report/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    requestPSI: builder.mutation({
      query: ({ id }: RequestPSIPayload) => ({
        url: `${API_ENDPOINT.VEHICLE}/psi-request/${id}`,
        method: "POST",
      }),
    }),
    updatePSI: builder.mutation({
      query: ({ id, payload }: UpdatePSIPayload) => ({
        url: `${API_ENDPOINT.VEHICLE}/psi-request/${id}`,
        method: "PUT",
        body: payload,
      }),
      async onQueryStarted(
        { vehicleId, payload: { status }, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          vehicleSlice.util.updateQueryData(
            "getVehicleById",
            vehicleId,
            (draft) => {
              const prevState = current(draft);
              const updated = {
                ...prevState,
                data: {
                  ...prevState?.data,
                  psi_status: {
                    ...prevState?.data?.psi_status,
                    status: status,
                  },
                },
              };

              Object.assign(draft, updated);
            }
          )
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    updateTitle: builder.mutation({
      query: ({ id, payload }: UpdateTitlePayload) => ({
        url: `${API_ENDPOINT.VEHICLE}/title/${id}`,
        method: "PUT",
        body: payload,
      }),
      async onQueryStarted(
        { vehicleId, payload: { status }, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          vehicleSlice.util.updateQueryData(
            "getVehicleById",
            vehicleId,
            (draft) => {
              const prevState = current(draft);
              const updated = {
                ...prevState,
                data: {
                  ...prevState?.data,
                  title: {
                    ...prevState?.data?.title,
                    status: status,
                  },
                },
              };

              Object.assign(draft, updated);
            }
          )
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    sendVehicleToRerun: builder.mutation({
      query: (id: string) => ({
        url: `${API_ENDPOINT.VEHICLE}/status/rerun/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Vehicle"],
    }),
    sendVehicleToSecondChance: builder.mutation({
      query: (id: string) => ({
        url: `${API_ENDPOINT.VEHICLE}/status/second-chance/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Vehicle"],
    }),
    sendVehicleToOVE: builder.mutation({
      query: ({ id, payload }: SendVehicleToOVEPayload) => ({
        url: `${API_ENDPOINT.VEHICLE}/ove-event-sale/${id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    updateOveStatus: builder.mutation({
      query: ({ id, payload }: UpdateOvePayload) => ({
        url: `${API_ENDPOINT.VEHICLE}/ove-event-sale/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    getKBBReport: builder.query({
      query: ({ id }) => ({
        url: `${API_ENDPOINT.VEHICLE}/kbb/${id}`,
      }),
    }),
    updateKBBReport: builder.mutation({
      query: ({ id }) => ({
        method: "PUT",
        url: `${API_ENDPOINT.VEHICLE}/update-kbb/${id}`,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const { data: updatedResponse } = await queryFulfilled;
        const patchResult = dispatch(
          vehicleSlice.util.updateQueryData("getKBBReport", { id }, (draft) => {
            Object.assign(draft, updatedResponse);
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {
  useLazyGetVehiclesQuery,
  useLazyGetVehicleByIdQuery,
  useLazyGetVehicleDetailByVINQuery,
  useCreateVehicleMutation,
  useCreateVehiclesCSVMutation,
  useUpdateVehicleMutation,
  useUpdateVehicleRequestMutation,
  useDeleteVehicleMutation,
  useAssignAuctionMutation,
  useRequestCRMutation,
  useUpdateCRMutation,
  useRequestPSIMutation,
  useUpdatePSIMutation,
  useUpdateTitleMutation,
  useSendVehicleToOVEMutation,
  useSendVehicleToRerunMutation,
  useSendVehicleToSecondChanceMutation,
  useUpdateOveStatusMutation,
  useGetKBBReportQuery,
  useUpdateKBBReportMutation,
} = vehicleSlice;
