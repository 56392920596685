import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import ButtonContainer from "../components/ButtonContainer";
import ListingTableComponent from "../components/ListingTableComponent";
import {
  CONSTANTS,
  OFFER_BUTTON_TYPES,
  ADMIN_ROLES,
  ACTION_BUTTON_TYPES,
  NUMBER,
  PDF_TITLE,
  PDF_PAGE_STYLE,
  MODAL_TYPE,
  DATE_TYPE,
  DATE_FORMAT,
  VehicleSortFilter,
  AuctionSortFilter,
  SIZES,
} from "../misc/constants";
import { auctionCreateData } from "../misc/createData";
import {
  useLazyGetAuctionVehiclesQuery,
  useOfferRequestMutation,
  useBumpOfferRequestMutation,
  useAcceptOfferRequestMutation,
  useCounterOfferRequestMutation,
  useDeleteAuctionVehicleMutation,
  useUpdateAuctionVehicleMutation,
} from "../store/reducer/auctionVehicle";
import {
  AuctionStatus,
  AuctionVehicleOrderColumn,
  AuctionVehicleStatus,
  OrderByDirection,
  UserTypes,
  VehicleOrderColumn,
  VehicleUpdateRequestStatus,
} from "../misc/enum";
import { CellRow, RowSelectionType } from "../ts/component.types";
import { useAppSelector, useAppDispatch } from "../hooks/redux-toolkit";
import { toggleProgressBar } from "../store/reducer/progressBar";
import { appendQueryParams } from "../misc/api.utils";
import {
  calculateDays,
  checkBoolean,
  formatDate,
  getEditModalValues,
  getRowData,
  hasAccess,
  numericValueOrNull,
  prioritizeAndMergeObject,
  renderRequestTransportResponseHTML,
} from "../misc/utils";
import { MESSAGES } from "../misc/messages";
import { DynamicKeyValuesWithNumArray } from "../ts/api.types";
import { useReactToPrint } from "react-to-print";
import { Actions, Tabs } from "../misc/access.enum";
import { toggleModal } from "../store/reducer/modal";
import { createExcelReportData, exportToExcel } from "../misc/excel";
import { useSuccessDispatcher } from "../hooks/useSuccessDispatcher";
import { useErrorDispatcher } from "../hooks/useErrorDispatcher";
import FilterAccordion from "../components/FilterAccordion";
import { isInteger } from "formik";
import Swal from "sweetalert2";

const Auction = () => {
  const dispatch = useAppDispatch();
  const successDispatcher = useSuccessDispatcher();
  const errorDispatcher = useErrorDispatcher();
  const { role, permissions } = useAppSelector((state) => state.authorization);
  const reference = useRef<HTMLTableSectionElement>(null);

  const handlePrint = useReactToPrint({
    content: () => reference.current,
    documentTitle: PDF_TITLE.AUCTION,
    pageStyle: PDF_PAGE_STYLE.LEGAL,
  });

  const [auctionVehicleRows, setAuctionVehicleRows] = useState<Array<{
    id: string;
    offer: string;
    bumpOffer: string;
    counterOffer: string;
    acceptOffer: string;
    data: CellRow[][];
  }> | null>(null);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(NUMBER.ZERO);
  const [queryParams, setQueryParams] = useState({
    page: NUMBER.ZERO,
    rowsPerPage: 10,
    vin: "",
    auctionId: NUMBER.MINUS_ONE?.toString(),
    auctionStatus: NUMBER.MINUS_ONE?.toString(),
    startAge: "",
    endAge: "",
    startYear: "",
    endYear: "",
    sort: VehicleSortFilter.updateAtDescending,
  });

  const inputRefs = useRef<
    Array<HTMLInputElement | HTMLSelectElement | HTMLButtonElement>
  >([]);

  const [getAuctionVehicles, { data: auctionVehicleStateData, isFetching }] =
    useLazyGetAuctionVehiclesQuery();
  const [pullbackFromAuction] = useDeleteAuctionVehicleMutation();
  const [createOffer] = useOfferRequestMutation();
  const [createAcceptOffer] = useAcceptOfferRequestMutation();
  const [createBumpOffer] = useBumpOfferRequestMutation();
  const [createCounterOffer] = useCounterOfferRequestMutation();
  const [updateAuctionVehicle] = useUpdateAuctionVehicleMutation();

  const [selectedRows, setSelectedRows] = useState<
    Array<RowSelectionType> | []
  >([]);

  const handleExport = () => {
    const vehiclesData = auctionVehicleStateData?.data?.auction_vehicles;
    const { excelData, columnsLength, error } = createExcelReportData(
      Tabs.AuctionTab,
      vehiclesData
    );

    if (!error) {
      exportToExcel(
        excelData,
        columnsLength,
        `Auction (${formatDate(
          new Date()?.toString(),
          DATE_TYPE.DATE_STRING,
          DATE_FORMAT.DD_MM_YYYY_TIME
        )})`
      );
    } else {
      errorDispatcher(error, MESSAGES.MISC.EXPORT_EXCEL_ERROR);
    }
  };

  const mapDataToTableRows = (
    vehiclesData: any,
    edit: boolean = false,
    id?: number,
    offer?: string,
    bumpOffer?: string,
    counterOffer?: string,
    acceptOffer?: string,
    onChange?: boolean
  ) => {
    return vehiclesData.map((vehicleData: any) => {
      const isAuctionInProgressOrCompleted =
        vehicleData?.auction?.status?.toString() ===
          AuctionStatus.InProgress?.toString() ||
        vehicleData?.auction?.status?.toString() ===
          AuctionStatus.Completed.toString();

      const isAuctionInProgress =
        vehicleData?.auction?.status?.toString() ===
        AuctionStatus.InProgress?.toString();

      const isAuctionScheduled =
        vehicleData?.auction?.status?.toString() ===
        AuctionStatus.Scheduled?.toString();

      let vehicleOffer = vehicleData?.offer
        ? vehicleData?.offer
        : !checkBoolean(permissions?.[Actions.Offer]) || !isAuctionInProgress
        ? "-"
        : "";
      let vehicleBumpOffer = vehicleData?.bump_offer || "";
      let vehicleCounterOffer = vehicleData?.counter_offer || "";
      let vehicleAcceptOffer = vehicleData?.accept_offer || "";

      if (onChange) {
        if (id === vehicleData?.id) {
          vehicleOffer = offer === "-" ? "" : offer;
          vehicleBumpOffer = bumpOffer;
          vehicleAcceptOffer = acceptOffer;
          vehicleCounterOffer = counterOffer;
        } else {
          const vehicleWithMatchedId = auctionVehicleRows?.filter(
            (row) => Number(row?.id) === Number(vehicleData?.id)
          );
          vehicleOffer = vehicleWithMatchedId?.[NUMBER.ZERO]?.offer;
          vehicleBumpOffer = vehicleWithMatchedId?.[NUMBER.ZERO]?.bumpOffer;
          vehicleAcceptOffer = vehicleWithMatchedId?.[NUMBER.ZERO]?.acceptOffer;
          vehicleCounterOffer =
            vehicleWithMatchedId?.[NUMBER.ZERO]?.counterOffer;
        }
      }

      const age = calculateDays(vehicleData?.created_at);

      return auctionCreateData(
        vehicleData?.id,
        vehicleData?.vehicle?.vin,
        vehicleData?.vehicle?.make,
        vehicleData?.vehicle?.model,
        vehicleData?.vehicle?.year,
        vehicleData?.vehicle?.dealer?.name || "N/A",
        vehicleData?.vehicle?.location?.name || "N/A",
        `${age} ${age === 1 ? "Day" : "Days"}`,
        vehicleData?.lane || "N/A",
        vehicleData?.run || "N/A",
        vehicleData?.vehicle?.announcement?.label || "N/A",
        "0",
        numericValueOrNull(vehicleData?.vehicle?.acv),
        numericValueOrNull(vehicleData?.vehicle?.dealer_stock_number),
        numericValueOrNull(vehicleData?.vehicle?.floor_price),
        AuctionVehicleStatus[vehicleData?.status],
        vehicleData?.vehicle?.comment || "N/A",
        checkBoolean(permissions?.[Actions.Offer]) &&
          vehicleData?.status !== AuctionVehicleStatus.Sold &&
          isAuctionInProgress
          ? CONSTANTS.INPUT
          : CONSTANTS.TEXT,
        vehicleOffer,
        vehicleData?.status === AuctionVehicleStatus.Sold && true,
        vehicleData?.status === AuctionVehicleStatus.Sold
          ? vehicleData?.vehicle?.sale_price || "N/A"
          : "",
        VehicleUpdateRequestStatus[vehicleData?.update_request?.status],
        vehicleData?.update_request?.status ===
          VehicleUpdateRequestStatus.Pending && !isAuctionInProgress,
        vehicleBumpOffer,
        vehicleAcceptOffer,
        vehicleCounterOffer,
        checkBoolean(permissions?.[Actions.RemoveFromAuction]) &&
          isAuctionScheduled,
        checkBoolean(permissions?.[Actions.UpdateVehicle]) &&
          !isAuctionInProgress,

        false,
        {
          [OFFER_BUTTON_TYPES.ACCEPT_OFFER]: {
            enableText:
              !checkBoolean(permissions?.[Actions.AcceptOffer]) ||
              !isAuctionInProgress,
            enableFreeze: false,
          },
          [OFFER_BUTTON_TYPES.BUMP_OFFER]: {
            enableText:
              !checkBoolean(permissions?.[Actions.BumpOffer]) ||
              !isAuctionInProgress,
            enableFreeze: false,
          },
          [OFFER_BUTTON_TYPES.COUNTER_OFFER]: {
            enableText:
              !checkBoolean(permissions?.[Actions.CounterOffer]) ||
              vehicleData?.bump_offer > NUMBER.ZERO ||
              !isAuctionInProgress,
            enableFreeze: false,
          },
        },
        edit,
        isAuctionInProgressOrCompleted
      );
    });
  };

  const handleSearch = () => {
    let payloadObject = {};
    const vinValue = (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement)
      ?.value;
    payloadObject = {
      ...queryParams,
      page: NUMBER.ZERO,
      vin: vinValue,
    };

    applyFilter(payloadObject);
  };

  const fetchData = async (queryParams: DynamicKeyValuesWithNumArray) => {
    const auctionId = queryParams.auctionId;
    const status = queryParams.auctionStatus;

    let apiParams = ``;

    const queryParamObj: DynamicKeyValuesWithNumArray = {
      page: (queryParams.page as number) + 1,
      limit: queryParams.rowsPerPage as number,
      startYear: queryParams?.startYear as string,
      endYear: queryParams?.endYear as string,
      startAge: queryParams?.startAge as string,
      endAge: queryParams?.endAge as string,
      vin: queryParams?.vin as string,
      lane: queryParams?.lane as number,
      run: queryParams?.run as number,
    };

    switch (queryParams?.sort) {
      case VehicleSortFilter.ageAscending:
        queryParamObj["direction"] = OrderByDirection.ASC;
        queryParamObj["column"] = VehicleOrderColumn.CreatedAt;
        break;
      case VehicleSortFilter.ageDescending:
        queryParamObj["direction"] = OrderByDirection.DESC;
        queryParamObj["column"] = VehicleOrderColumn.CreatedAt;
        break;
      case VehicleSortFilter.updatedAtAscending:
        queryParamObj["direction"] = OrderByDirection.ASC;
        queryParamObj["column"] = VehicleOrderColumn.UpdatedAt;
        break;
      case VehicleSortFilter.updateAtDescending:
        queryParamObj["direction"] = OrderByDirection.DESC;
        queryParamObj["column"] = VehicleOrderColumn.UpdatedAt;
        break;
      case AuctionSortFilter.laneAscending:
        queryParamObj["direction"] = OrderByDirection.ASC;
        queryParamObj["column"] = AuctionVehicleOrderColumn.Lane;
        break;
      case AuctionSortFilter.laneDescending:
        queryParamObj["direction"] = OrderByDirection.DESC;
        queryParamObj["column"] = AuctionVehicleOrderColumn.Lane;
        break;
      case AuctionSortFilter.runAscending:
        queryParamObj["direction"] = OrderByDirection.ASC;
        queryParamObj["column"] = AuctionVehicleOrderColumn.Run;
        break;
      case AuctionSortFilter.runDescending:
        queryParamObj["direction"] = OrderByDirection.DESC;
        queryParamObj["column"] = AuctionVehicleOrderColumn.Run;
        break;
      default:
        break;
    }

    if (auctionId && Number(auctionId) >= NUMBER.ZERO)
      queryParamObj["auctionId"] = auctionId;
    if (status && Number(status) >= NUMBER.ZERO)
      queryParamObj["status"] = status;

    apiParams += appendQueryParams(queryParamObj, false);

    try {
      const vehicles: any = await getAuctionVehicles(apiParams);

      let vehiclesData = vehicles?.data?.data?.auction_vehicles;
      if (vehiclesData) {
        vehiclesData = mapDataToTableRows(vehiclesData);
        setAuctionVehicleRows(vehiclesData);
        setTotalRowsCount(vehicles?.data?.data?.count);
      } else {
        setAuctionVehicleRows([]);
      }
    } catch (err) {
      setAuctionVehicleRows([]);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData(queryParams);
    })();
  }, [queryParams]); //eslint-disable-line

  useEffect(() => {
    if (!isFetching) {
      let vehiclesData = auctionVehicleStateData?.data?.auction_vehicles;
      if (vehiclesData) {
        vehiclesData = mapDataToTableRows(vehiclesData);
        setAuctionVehicleRows(vehiclesData);
        setTotalRowsCount(auctionVehicleStateData?.data?.count);
      }
    }
  }, [auctionVehicleStateData, isFetching]); //eslint-disable-line

  const applyFilter = (values: DynamicKeyValuesWithNumArray) => {
    allSelectionToggle(false);
    setAuctionVehicleRows(null);

    const prioritizedQueryParams = prioritizeAndMergeObject(
      values,
      queryParams,
      [
        "page",
        "rowsPerPage",
        "vin",
        "auctionId",
        "auctionStatus",
        "startAge",
        "endAge",
        "startYear",
        "endYear",
        "lane",
        "run",
        "sort",
      ]
    );

    setQueryParams({
      ...queryParams,
      ...prioritizedQueryParams,
    });
  };

  const handleOfferChange = (type: string, id: number, offer: string) => {
    if (!isNaN(Number(offer))) {
      const vehicleWithMatchedId = auctionVehicleRows?.filter(
        (row) => Number(row?.id) === id
      );

      let offerValue = vehicleWithMatchedId?.[NUMBER.ZERO]?.offer;
      let counterOfferValue = vehicleWithMatchedId?.[NUMBER.ZERO]?.counterOffer;
      let bumpOfferValue = vehicleWithMatchedId?.[NUMBER.ZERO]?.bumpOffer;
      let acceptOfferValue = vehicleWithMatchedId?.[NUMBER.ZERO]?.acceptOffer;

      switch (type) {
        case OFFER_BUTTON_TYPES.OFFER:
          offerValue = offer;
          break;
        case OFFER_BUTTON_TYPES.COUNTER_OFFER:
          counterOfferValue = offer;
          break;
        case OFFER_BUTTON_TYPES.BUMP_OFFER:
          bumpOfferValue = offer;
          break;
        case OFFER_BUTTON_TYPES.ACCEPT_OFFER:
          acceptOfferValue = offer;
          break;
        default:
          break;
      }

      let vehiclesData = auctionVehicleStateData?.data?.auction_vehicles;
      if (vehiclesData) {
        vehiclesData = mapDataToTableRows(
          vehiclesData,
          false,
          id,
          offerValue,
          bumpOfferValue,
          counterOfferValue,
          acceptOfferValue,
          true
        );
        setAuctionVehicleRows(vehiclesData);
      }
    }
  };

  const handleOfferAction = async (type: string, id: number, offer: string) => {
    if (!isNaN(Number(offer))) {
      if (!offer) {
        errorDispatcher(true, MESSAGES.AUCTION.NO_VALUE_PROVIDED);
        return;
      }

      const vehiclesData = auctionVehicleStateData?.data?.auction_vehicles;
      const matchedVehicle = vehiclesData?.find(
        (vehicle: any) => Number(vehicle?.id) === id
      );

      if (!matchedVehicle) {
        // Handle the case where there's no matching vehicle
        return;
      }

      if (!matchedVehicle?.offer) {
        errorDispatcher(true, MESSAGES.AUCTION.CANNOT_PERFORM_ACTION);
        return;
      }

      const offerInNumber: number = Number(offer);
      const matchedOffer: number = Number(matchedVehicle?.offer);

      const isAdmin = hasAccess(ADMIN_ROLES, role as UserTypes);
      const hasBumpOffer = isAdmin && matchedVehicle?.bump_offer > NUMBER.ZERO;

      if (type === OFFER_BUTTON_TYPES.ACCEPT_OFFER) {
        let errorMessage = "";
        if (offerInNumber < matchedOffer) {
          errorMessage = MESSAGES.AUCTION.LESS_OFFER_ERROR;
        } else if (offerInNumber > matchedOffer) {
          if (isAdmin) {
            if (!hasBumpOffer) {
              errorMessage = MESSAGES.AUCTION.GREATER_OFFER_ERROR;
            }
          } else {
            errorMessage = MESSAGES.AUCTION.GREATER_OFFER_ERROR;
          }
        }

        if (errorMessage) {
          errorDispatcher(true, errorMessage);
          return;
        }
        dispatch(toggleProgressBar(true));
        const response: any = await createAcceptOffer({
          id: id?.toString(),
          payload: {
            offer: offerInNumber,
          },
        });

        dispatch(toggleProgressBar(false));
        if (!response?.error)
          successDispatcher(true, MESSAGES.AUCTION.OFFER_ACCEPT_SUCCESS);

        return;
      } else if (type === OFFER_BUTTON_TYPES.BUMP_OFFER) {
        if (offerInNumber !== matchedOffer) {
          errorDispatcher(true, MESSAGES.AUCTION.BUMP_EQUAL_OFFER_ERROR);
          return;
        } else {
          dispatch(toggleProgressBar(true));
          const response: any = await createBumpOffer({
            id: id?.toString(),
            payload: {
              bump_offer: offerInNumber,
            },
          });
          dispatch(toggleProgressBar(false));
          if (!response?.error)
            successDispatcher(true, MESSAGES.AUCTION.BUMP_OFFER_SUBMIT_SUCCESS);

          return;
        }
      } else {
        if (offerInNumber <= matchedOffer) {
          errorDispatcher(true, MESSAGES.AUCTION.COUNTER_EQUAL_OFFER_ERROR);
        } else {
          dispatch(toggleProgressBar(true));
          const response: any = await createCounterOffer({
            id: id?.toString(),
            payload: {
              counter_offer: offerInNumber,
            },
          });
          dispatch(toggleProgressBar(false));
          if (!response?.error)
            successDispatcher(
              true,
              MESSAGES.AUCTION.COUNTER_OFFER_SUBMIT_SUCCESS
            );

          return;
        }
      }
    }
  };

  const handleOfferSubmit = async (type: string, id: number, offer: string) => {
    if (!offer) {
      errorDispatcher(true, MESSAGES.AUCTION.NO_VALUE_PROVIDED);
      return;
    }
    if (Number(offer) < NUMBER.ONE) {
      errorDispatcher(true, MESSAGES.AUCTION.LESS_THAN_ONE);
      return;
    }

    const vehicleWithMatchedId = auctionVehicleRows?.filter(
      (row) => Number(row?.id) === id
    );

    let bumpOfferValue = vehicleWithMatchedId?.[NUMBER.ZERO]?.bumpOffer;

    if (
      Number(bumpOfferValue) > NUMBER.ZERO &&
      Number(offer) < Number(bumpOfferValue)
    ) {
      errorDispatcher(
        true,
        MESSAGES.AUCTION.OFFER_MUST_BE_GREATER_THAN_EQUAL_BUMP
      );
      return;
    }

    dispatch(toggleProgressBar(true));
    const response: any = await createOffer({
      id: id?.toString(),
      payload: {
        offer: offer ? Number(offer) : NUMBER.ZERO,
      },
    });
    dispatch(toggleProgressBar(false));
    if (!response?.error)
      successDispatcher(true, MESSAGES.AUCTION.OFFER_SUBMIT_SUCCESS);
  };

  const getVehicleById = (id: string) => {
    const auctionVehicles = auctionVehicleStateData?.data?.auction_vehicles;
    const auctionVehicle = getRowData(Number(id), auctionVehicles);
    return {
      vehicle: auctionVehicle?.vehicle,
      updateRequest: auctionVehicle?.update_request,
    };
  };

  const handleActionButton = async (type: string, id: string) => {
    let response: any;
    let message: string = "";
    dispatch(toggleProgressBar(true));
    switch (type) {
      case ACTION_BUTTON_TYPES.PULLBACK_FROM_AUCTION:
        response = await pullbackFromAuction({ id });
        message = MESSAGES.AUCTION.PULLBACK_VEHICLE_SUCCESS;
        break;
      case ACTION_BUTTON_TYPES.EDIT_VEHICLE:
        const { vehicle } = getVehicleById(id);
        dispatch(
          toggleModal({
            open: true,
            edit: true,
            submitText: "Save and Close",
            modalType: MODAL_TYPE.ADD_VEHICLE,
            data: {
              modalValues: getEditModalValues(MODAL_TYPE.ADD_VEHICLE, vehicle),
            },
          })
        );
        break;
      case ACTION_BUTTON_TYPES.VEHICLE_UPDATE_REQUEST:
        const auctionVehicleData = getVehicleById(id);
        dispatch(
          toggleModal({
            open: true,
            modalType: MODAL_TYPE.VEHICLE_UPDATE_REQUEST,
            data: {
              modalValues: {
                ...auctionVehicleData,
              },
            },
          })
        );
        break;
      default:
        break;
    }
    dispatch(toggleProgressBar(false));
    if (!response?.error) successDispatcher(true, message);
  };

  const triggerFilter = (values: DynamicKeyValuesWithNumArray) => {
    applyFilter({
      ...values,
      vin: (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement)?.value,
      page: NUMBER.ZERO,
    });
  };

  const emptyVin = () => {
    if (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement) {
      (inputRefs?.current?.[NUMBER.ZERO] as HTMLInputElement).value = "";
    }
  };

  const allSelectionToggle = (selectAll: boolean) => {
    if (!selectAll) {
      setSelectedRows && setSelectedRows([]);
      return;
    }
    const updatedRows =
      auctionVehicleRows?.map((row) => ({
        id: row?.id,
        checked: true,
      })) || [];

    setSelectedRows && setSelectedRows(updatedRows);
  };

  const validSelections = () => {
    const auctionStateData = auctionVehicleStateData?.data?.auction_vehicles;

    let hasValidTypes = true;
    let hasSelections = true;

    const selectedAuctionVehicles: {
      auction_vehicle_ids: Array<number>;
      lane: Array<number>;
      run: Array<number>;
    } = {
      auction_vehicle_ids: [],
      run: [],
      lane: [],
    };

    selectedRows?.forEach((row) => {
      if (row?.checked && (row?.lane || row?.run)) {
        if (isInteger(row?.run) && isInteger(row?.lane)) {
          selectedAuctionVehicles.auction_vehicle_ids.push(Number(row?.id));
          selectedAuctionVehicles.run.push(Number(row?.run));
          selectedAuctionVehicles.lane.push(Number(row?.lane));
        } else if (row?.lane || row?.run) {
          if (
            (row?.lane && !isInteger(row?.lane)) ||
            (row?.run && !isInteger(row?.run))
          )
            hasValidTypes = false;

          if (hasValidTypes) {
            const auctionData = auctionStateData?.find(
              (auctionData: any) => auctionData?.id === row?.id
            );
            selectedAuctionVehicles.auction_vehicle_ids.push(Number(row?.id));
            selectedAuctionVehicles.lane.push(
              Number(row?.lane || auctionData?.lane)
            );
            selectedAuctionVehicles.run.push(
              Number(row?.run || auctionData?.run)
            );
          }
        }
      }
    });

    if (selectedAuctionVehicles?.auction_vehicle_ids.length === 0)
      hasSelections = false;

    return {
      hasSelections,
      hasValidTypes,
      selectedAuctionVehicles,
    };
  };

  const toggleEditMode = (mode = false) => {
    const vehicleStateData = auctionVehicleStateData?.data?.auction_vehicles;

    const filtered = vehicleStateData?.filter((vehicle: any) => {
      return (
        vehicle?.auction?.status?.toString() ===
          AuctionStatus.Scheduled?.toString() ||
        vehicle?.auction?.status?.toString() === AuctionStatus.Lock.toString()
      );
    });

    if (!filtered.length) {
      errorDispatcher(true, MESSAGES.AUCTION.LANE_AND_RUN_ERROR);
      return;
    }

    const vehicleData = mapDataToTableRows(vehicleStateData, mode);
    setAuctionVehicleRows(vehicleData);
    allSelectionToggle(mode);
  };

  const onSaveLaneAndRun = async () => {
    dispatch(toggleProgressBar(true));
    const { hasSelections, hasValidTypes, selectedAuctionVehicles } =
      validSelections();

    if (!hasValidTypes)
      errorDispatcher(true, MESSAGES.VEHICLE.INVALID_LANE_RUN_TYPES);
    else if (!hasSelections) toggleEditMode(false);
    else {
      const response: any = await updateAuctionVehicle(selectedAuctionVehicles);
      let responseHTML = "";
      response?.data?.data?.forEach((data: any) => {
        responseHTML += renderRequestTransportResponseHTML(
          data?.status,
          data?.message,
          data?.vehicle?.vehicle?.vin,
          true
        );
      });

      if (!response?.error) {
        Swal.fire({
          html: responseHTML,
          width: 600,
          position: "top-end",
          title: "Update Lane and Run Results",
          confirmButtonText: "Continue",
          buttonsStyling: false,
          customClass: {
            confirmButton:
              "bg-secondary-200 text-tertiary-200 py-2 px-4 rounded-md",
            title: "text-tertiary-200",
          },
        });
      }

      allSelectionToggle(false);
    }

    dispatch(toggleProgressBar(false));
  };

  const buttons = [
    selectedRows?.length
      ? {
          label: "Save",
          color: CONSTANTS.TERTIARY,
          onClick: () => onSaveLaneAndRun(),
          size: SIZES.SMALL,
          access: checkBoolean(permissions?.[Actions.Offer]),
        }
      : {
          label: "Update Lane And Run",
          color: CONSTANTS.PRIMARY,
          onClick: () => toggleEditMode(true),
          size: SIZES.SMALL,
          access: checkBoolean(permissions?.[Actions.Offer]),
        },
  ];

  return (
    <Box>
      <ButtonContainer
        buttons={buttons}
        inputRefs={inputRefs}
        hideRightSearchField={false}
        handlePrint={handlePrint}
        handleExport={handleExport}
        handleVinSearch={() => handleSearch()}
        disableButtons={
          auctionVehicleRows ? auctionVehicleRows?.length === 0 : true
        }
      />
      <FilterAccordion
        status={"In Auction"}
        triggerFilter={triggerFilter}
        emptyVin={emptyVin}
        sort={queryParams.sort}
      />
      <ListingTableComponent
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        reference={reference}
        rows={auctionVehicleRows}
        rowCount={totalRowsCount}
        queryParams={queryParams}
        applyFilter={applyFilter}
        handleOfferChange={handleOfferChange}
        handleOfferAction={handleOfferAction}
        handleOfferSubmit={handleOfferSubmit}
        handleActionButton={handleActionButton}
      />
    </Box>
  );
};

export default Auction;
