import { useState, useEffect } from "react";
import { Formik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box/Box";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import ButtonComponent from "../ButtonComponent";
import SelectComponent from "../SelectComponent";
import {
  ADMIN_ROLES,
  VehicleSortFilter,
  CONSTANTS,
  DATE_FORMAT,
  DATE_TYPE,
  NUMBER,
  SIZES,
  AuctionSortFilter,
} from "../../misc/constants";
import "./../../styles/AccordionComponent.css";
import { useLazyGetLocationsQuery } from "../../store/reducer/location";
import { useLazyGetAnnouncementsQuery } from "../../store/reducer/setting";
import { useLazyGetDealersQuery } from "../../store/reducer/dealer";
import { ModalSelectOption, SelectOption } from "../../ts/component.types";

import { filterSchema } from "../ModalComponent/schema";
import FieldComponent from "../FieldComponent";
import { DynamicKeyValuesWithNumArray } from "../../ts/api.types";
import { formatDate } from "../../misc/utils";
import { useAppSelector } from "../../hooks/redux-toolkit";
import { AuctionStatus, FRONTEND_ROUTES, UserTypes } from "../../misc/enum";
import { appendQueryParams } from "../../misc/api.utils";
import { SelectChangeEvent } from "@mui/material/Select";
import { useLazyGetAuctionsQuery } from "../../store/reducer/auction";
import CustomDatePickerInput from "../CustomDatePickerInput";

const sortOptions = [
  {
    value: VehicleSortFilter.ageDescending,
    label: "Age-Ascending",
  },
  {
    value: VehicleSortFilter.ageAscending,
    label: "Age-Descending",
  },
  {
    value: VehicleSortFilter.updateAtDescending,
    label: "Last Updated-Ascending",
  },
  {
    value: VehicleSortFilter.updatedAtAscending,
    label: "Last Updated-Descending",
  },
];

const auctionSortOptions = [
  {
    value: AuctionSortFilter.laneAscending,
    label: "Lane-Ascending",
  },
  {
    value: AuctionSortFilter.laneDescending,
    label: "Lane-Descending",
  },
  {
    value: AuctionSortFilter.runAscending,
    label: "Run-Ascending",
  },
  {
    value: AuctionSortFilter.runDescending,
    label: "Run-Descending",
  },
];

const options: Array<SelectOption> = [];

const initialValues = {
  dealerName: NUMBER.MINUS_ONE,
  location: NUMBER.MINUS_ONE,
  announcement: NUMBER.MINUS_ONE,
  auction: NUMBER.MINUS_ONE,
  auctionStatus: NUMBER.MINUS_ONE,
  make: "",
  model: "",
  lane: "",
  run: "",
};

type FilterAccordionProps = {
  status: string;
  triggerFilter: (values: DynamicKeyValuesWithNumArray) => void;
  emptyVin?: () => void;
  sort?: string;
};

const FilterAccordion = ({
  triggerFilter,
  status,
  emptyVin,
  sort,
}: FilterAccordionProps) => {
  const location = useLocation();
  const isAuctionRoute = location.pathname === FRONTEND_ROUTES.AUCTION;
  const isVehicleSoldRoute = location.pathname === FRONTEND_ROUTES.VEHICLE_SOLD;
  const isVehicleInventoryRoute =
    location.pathname === FRONTEND_ROUTES.VEHICLE_INVENTORY;
  const {
    authorization: { role },
    sidebar: { sidebarOpen },
  } = useAppSelector((state) => state);
  const [expanded, setExpanded] = useState<boolean>(false);

  const [auctionFieldsData, setAuctionFieldsData] =
    useState<Array<SelectOption>>(options);
  const [auctionStatusFieldsData, setAuctionStatusFieldsData] =
    useState<Array<SelectOption>>(options);
  const [dealerFieldsData, setDealerFieldsData] =
    useState<Array<SelectOption>>(options);
  const [locationFieldsData, setLocationFieldsData] =
    useState<Array<SelectOption>>(options);
  const [announcementFieldsData, setAnnouncementFieldsData] =
    useState<Array<SelectOption>>(options);

  const [getLocations, { data: locationsData }] = useLazyGetLocationsQuery();
  const [getAnnouncements, { data: announcementsData }] =
    useLazyGetAnnouncementsQuery();
  const [getDealers, { data: dealersData }] = useLazyGetDealersQuery();
  const [getAuctions, { data: auctionStateData }] = useLazyGetAuctionsQuery();

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleFormSubmit = (values: {
    [key: string]: number | boolean | string | Date;
  }) => {
    let startAge = "";
    let endAge = "";
    let startYear = "";
    let endYear = "";
    let startSoldDate = "";
    let endSoldDate = "";

    const queryParams: DynamicKeyValuesWithNumArray = {};
    if ("location" in values) {
      queryParams["location"] = values?.location as number;
    }
    if ("announcement" in values) {
      queryParams["announcement"] = values?.announcement as number;
    }
    if ("dealerName" in values) {
      queryParams["dealerName"] = values?.dealerName as number;
    }
    if ("lane" in values) {
      queryParams["lane"] = values?.lane as number;
    }
    if ("run" in values) {
      queryParams["run"] = values?.run as number;
    }
    if ("auction" in values) {
      queryParams["auctionId"] = values?.auction as number;
    }
    if ("auctionStatus" in values) {
      queryParams["auctionStatus"] = values?.auctionStatus as number;
    }

    if (values?.startYear as Date) {
      startYear = (values?.startYear as Date).getFullYear()?.toString();
    }
    if (values?.endYear as Date) {
      endYear = (values?.endYear as Date).getFullYear()?.toString();
    }
    if (values?.startAge as Date) {
      startAge = formatDate(
        values?.startAge?.toString(),
        DATE_TYPE.START_DATE_RANGE
      );
    }

    if (values?.endAge as Date) {
      endAge = formatDate(values?.endAge?.toString(), DATE_TYPE.END_DATE_RANGE);
    }

    if (values?.startSoldDate as Date) {
      startSoldDate = formatDate(
        values?.startSoldDate?.toString(),
        DATE_TYPE.START_DATE_RANGE
      );
    }

    if (values?.endSoldDate as Date) {
      endSoldDate = formatDate(
        values?.endSoldDate?.toString(),
        DATE_TYPE.END_DATE_RANGE
      );
    }

    queryParams["startYear"] = startYear;
    queryParams["endYear"] = endYear;
    queryParams["startAge"] = startAge;
    queryParams["endAge"] = endAge;
    queryParams["startSoldDate"] = startSoldDate;
    queryParams["endSoldDate"] = endSoldDate;
    queryParams["make"] = (values?.make as string) || "";
    queryParams["model"] = (values?.model as string) || "";
    triggerFilter(queryParams);
  };

  const parseData = (data: Array<ModalSelectOption>) => {
    const mappedOptions = data?.map((row) => {
      return {
        label: row?.name || row?.label,
        value: row.id,
      };
    });

    mappedOptions.push({
      label: "All",
      value: NUMBER.MINUS_ONE,
    });

    return mappedOptions;
  };

  const handleResetForm = (resetForm: () => void) => {
    resetForm();
    emptyVin && emptyVin();
    triggerFilter({
      make: "",
      model: "",
      location: NUMBER.MINUS_ONE,
      announcement: NUMBER.MINUS_ONE,
      dealerName: NUMBER.MINUS_ONE,
      startAge: "",
      endAge: "",
      startSoldDate: "",
      endSoldDate: "",
      startYear: "",
      endYear: "",
      vin: "",
      auctionId: "",
      auctionStatus: "",
      lane: "",
      run: "",
    });
  };

  const handleSort = (sort: string) => {
    triggerFilter({ sort });
  };

  const getAuctionStatus = (id: string) => {
    let auctionStatus: string = "";

    const auctions = auctionStateData?.data?.auction;
    if (auctions?.length) {
      const filtered = auctions?.filter(
        (auction: any) => auction?.id?.toString() === id?.toString()
      );
      if (filtered?.length) {
        auctionStatus = filtered?.[NUMBER.ZERO]?.status;
      }
    }

    return auctionStatus;
  };

  useEffect(() => {
    if (!isAuctionRoute) {
      const queryParamObj = {
        isActive: "active",
      };
      const apiParams = appendQueryParams(queryParamObj, false);
      getAnnouncements("is_deleted=false");
      if (ADMIN_ROLES?.includes(role as UserTypes)) {
        getDealers(apiParams);
        getLocations(apiParams);
      }
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (isAuctionRoute) {
      const apiParams = appendQueryParams(
        {
          status: [
            AuctionStatus.Scheduled,
            AuctionStatus.InProgress,
            AuctionStatus.Lock,
          ],
          isActive: "active",
        },
        false
      );

      getAuctions(apiParams);
    }
  }, [getAuctions]); //eslint-disable-line

  useEffect(() => {
    const locations = locationsData?.data?.locations;
    if (locations) {
      const locationFields = parseData(locations);
      setLocationFieldsData(locationFields);
    }
  }, [locationsData]); //eslint-disable-line

  useEffect(() => {
    const announcements = announcementsData?.data?.announcements;
    if (announcements) {
      const announcementFields = parseData(announcements);
      setAnnouncementFieldsData(announcementFields);
    }
  }, [announcementsData]); //eslint-disable-line

  useEffect(() => {
    const dealers = dealersData?.data?.dealers;
    if (dealers) {
      const dealerFields = parseData(dealers);
      setDealerFieldsData(dealerFields);
    }
  }, [dealersData]); //eslint-disable-line

  useEffect(() => {
    const data = auctionStateData?.data?.auction;
    const auctionsStatus = [
      { label: "All", value: NUMBER.MINUS_ONE?.toString() },
      { label: "Scheduled", value: AuctionStatus.Scheduled },
      { label: "Lock", value: AuctionStatus.Lock },
      { label: "In Progress", value: AuctionStatus.InProgress },
    ];
    if (data) {
      const auctions = [
        {
          label: "All",
          value: NUMBER.MINUS_ONE?.toString(),
        },
      ];
      auctionStateData?.data?.auction?.forEach((auction: any) => {
        if (
          auction?.location?.name &&
          (auction?.auction_date || auction?.start_time)
        ) {
          const auctionDate = auction?.start_time
            ? formatDate(
                auction?.start_time,
                DATE_TYPE.TIMESTAMP,
                DATE_FORMAT.YYYY_MM_DD
              )
            : formatDate(
                auction.auction_date,
                DATE_TYPE.DATE_STRING,
                DATE_FORMAT.YYYY_MM_DD
              );
          auctions.push({
            label: auctionDate + " : " + auction?.location?.name,
            value: auction.id,
          });
        }
      });

      setAuctionFieldsData(auctions);
      setAuctionStatusFieldsData(auctionsStatus);
    }
  }, [auctionStateData]);

  const handleDateChange = (
    date: Date,
    key: string,
    setFieldValue: (key: string, date?: Date) => void
  ) =>
    date === null ? setFieldValue(key, undefined) : setFieldValue(key, date);

  const boxClassName = "flex items-start justify-start lg:items-center my-2";

  return (
    <Box className="relative bg-primary-100 rounded-md border mb-4">
      <Formik
        initialValues={initialValues}
        onSubmit={async (
          values: {
            [key: string]: number | boolean | string | Date;
          },
          { setSubmitting }
        ) => {
          handleFormSubmit(values);
          setSubmitting(false);
        }}
        validationSchema={filterSchema}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
          /* and other goodies */
        }) => (
          <>
            {expanded ? (
              <Box>
                <Box className="flex items-center w-full mr-10">
                  <Box
                    className={`w-fit mx-auto 2xl:w-full 2xl:mx-0 grid py-2 ${
                      isAuctionRoute
                        ? sidebarOpen
                          ? "2xl:grid-cols-3 4xl:grid-cols-4 px-4"
                          : "2xl:grid-cols-4 px-4"
                        : ADMIN_ROLES?.includes(role as UserTypes)
                        ? `2xl:grid-cols-3 5xl:grid-cols-5 px-8
                        ${!sidebarOpen ? "4xl:grid-cols-4" : ""}`
                        : sidebarOpen
                        ? "2xl:grid-cols-2 4xl:grid-cols-4 px-4"
                        : "2xl:grid-cols-4 5xl:grid-cols-5 px-4"
                    }`}
                  >
                    <Box className={`${boxClassName}`}>
                      <Box className="flex flex-col">
                        <Box className="flex justify-start items-center min-w-[150px] 2xl:min-w-0 py-1 px-4 mb-2">
                          Age:
                        </Box>
                        <Box className="flex justify-start items-center min-w-[150px] 2xl:min-w-0 py-1 px-4">
                          Year:
                        </Box>
                      </Box>

                      <Box className="flex flex-col items-start">
                        <Box className="flex items-center">
                          <Box>
                            <CustomDateRangePicker
                              name="startAge"
                              selectsStart={true}
                              selectedDate={values["startAge"] as Date}
                              handleChange={(date: Date) =>
                                handleDateChange(
                                  date,
                                  "startAge",
                                  setFieldValue
                                )
                              }
                              startDate={values["startAge"] as Date}
                              endDate={values["endAge"] as Date}
                              maxDate={values["endAge"] as Date}
                              placeholder="From"
                              validationError={errors["startAge"]}
                            />
                          </Box>
                          <Box className="h-9 mx-[6px] mb-2 flex items-center justify-center">
                            -
                          </Box>
                          <Box>
                            <CustomDateRangePicker
                              name="startAge"
                              selectsEnd={true}
                              selectedDate={values["endAge"] as Date}
                              handleChange={(date: Date) =>
                                handleDateChange(date, "endAge", setFieldValue)
                              }
                              startDate={values["startAge"] as Date}
                              endDate={values["endAge"] as Date}
                              minDate={values["startAge"] as Date}
                              placeholder="To"
                              validationError={errors["endAge"]}
                            />
                          </Box>
                        </Box>

                        <Box className="flex items-center">
                          <Box>
                            <CustomDateRangePicker
                              name="startYear"
                              showYearPicker={true}
                              selectsStart={true}
                              selectedDate={values["startYear"] as Date}
                              handleChange={(date: Date) =>
                                handleDateChange(
                                  date,
                                  "startYear",
                                  setFieldValue
                                )
                              }
                              startDate={values["startYear"] as Date}
                              endDate={values["endYear"] as Date}
                              maxDate={values["endYear"] as Date}
                              placeholder="From"
                              validationError={errors["startYear"]}
                            />
                          </Box>
                          <Box className="h-9 mx-[6px] mb-2 flex items-center justify-center">
                            -
                          </Box>
                          <Box>
                            <CustomDateRangePicker
                              name="endYear"
                              showYearPicker={true}
                              selectsEnd={true}
                              selectedDate={values["endYear"] as Date}
                              handleChange={(date: Date) =>
                                handleDateChange(date, "endYear", setFieldValue)
                              }
                              startDate={values["startYear"] as Date}
                              endDate={values["endYear"] as Date}
                              minDate={values["startYear"] as Date}
                              placeholder="To"
                              validationError={errors["endYear"]}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    {ADMIN_ROLES?.includes(role as UserTypes) &&
                      !isAuctionRoute && (
                        <Box className={`${boxClassName}`}>
                          <Box className="flex flex-col">
                            <Box className="flex justify-start items-center min-w-[150px] 2xl:min-w-0 py-1 px-4 mb-2 whitespace-nowrap">
                              Dealer Name:
                            </Box>

                            <Box className="flex justify-start items-center min-w-[150px] 2xl:min-w-0 py-1 px-4">
                              Location:
                            </Box>
                          </Box>
                          <Box className="flex flex-col">
                            <SelectComponent
                              hideErrorMessage={true}
                              className=" mb-2 w-60"
                              size={SIZES.SMALL}
                              name="dealerName"
                              value={values["dealerName"] as string}
                              options={dealerFieldsData}
                              handleChange={handleChange}
                            />

                            <SelectComponent
                              hideErrorMessage={true}
                              size={SIZES.SMALL}
                              className="w-60"
                              name="location"
                              value={values["location"] as string}
                              options={locationFieldsData}
                              handleChange={handleChange}
                            />
                          </Box>
                        </Box>
                      )}

                    {!isAuctionRoute ? (
                      <Box className={`${boxClassName} 2xl:justify-center`}>
                        <Box className="flex flex-col">
                          <Box className="flex justify-start items-center min-w-[150px] 2xl:min-w-0 py-1 px-4 mb-2">
                            Make:
                          </Box>
                          <Box className="flex justify-start items-center min-w-[150px] 2xl:min-w-0 py-1 px-4">
                            Model:
                          </Box>
                        </Box>
                        <Box className="flex flex-col">
                          <FieldComponent
                            type={CONSTANTS.TEXT}
                            inputClassName="mb-2 w-60 h-[36px]"
                            name="make"
                            placeholder="Make..."
                            value={values["make"] as string}
                            size={SIZES.SMALL}
                            hideErrorMessage={true}
                            handleChange={handleChange}
                          />
                          <FieldComponent
                            type={CONSTANTS.TEXT}
                            inputClassName="w-60 h-[36px]"
                            hideErrorMessage={true}
                            size={SIZES.SMALL}
                            name="model"
                            placeholder="Model..."
                            value={values["model"] as string}
                            handleChange={handleChange}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box className={`${boxClassName}`}>
                        <Box className="flex flex-col">
                          <Box className="flex justify-start items-center min-w-[150px] 2xl:min-w-0 py-1 px-4 mb-2">
                            Lane:
                          </Box>
                          <Box className="flex justify-start items-center min-w-[150px] 2xl:min-w-0 py-1 px-4">
                            Run:
                          </Box>
                        </Box>
                        <Box className="flex flex-col">
                          <FieldComponent
                            type={CONSTANTS.TEXT}
                            inputClassName="mb-2 w-60 h-[36px]"
                            name="lane"
                            placeholder="Lane..."
                            value={values["lane"] as string}
                            size={SIZES.SMALL}
                            hideErrorMessage={true}
                            handleChange={handleChange}
                          />
                          <FieldComponent
                            type={CONSTANTS.TEXT}
                            inputClassName="w-60 h-[36px]"
                            hideErrorMessage={true}
                            size={SIZES.SMALL}
                            name="run"
                            placeholder="Run..."
                            value={values["run"] as string}
                            handleChange={handleChange}
                          />
                        </Box>
                      </Box>
                    )}

                    {isAuctionRoute &&
                      auctionFieldsData?.length &&
                      auctionStatusFieldsData?.length && (
                        <Box className={`${boxClassName}`}>
                          <Box className="flex flex-col">
                            <Box className="flex justify-start items-center min-w-[150px] 2xl:min-w-0 py-1 px-4 mb-2">
                              Auction:
                            </Box>

                            <Box className="flex justify-start items-center min-w-[150px] 2xl:min-w-0 py-1 px-4 invisible">
                              Auction:
                            </Box>
                          </Box>
                          <Box className="flex flex-col">
                            <SelectComponent
                              hideErrorMessage={true}
                              className="mb-2 w-60"
                              size={SIZES.SMALL}
                              name="auction"
                              value={values["auction"] as string}
                              options={auctionFieldsData}
                              handleChange={(
                                event: SelectChangeEvent<string>
                              ) => {
                                const value = event?.target?.value;
                                const auctionStatus = getAuctionStatus(value);
                                setFieldValue("auction", value);
                                setFieldValue(
                                  "auctionStatus",
                                  auctionStatus || -1
                                );
                              }}
                            />

                            <SelectComponent
                              disabled={
                                values["auction"]?.toString() !==
                                NUMBER.MINUS_ONE?.toString()
                              }
                              hideErrorMessage={true}
                              className="w-60"
                              size={SIZES.SMALL}
                              name="auctionStatus"
                              value={values["auctionStatus"] as string}
                              options={auctionStatusFieldsData}
                              handleChange={handleChange}
                            />
                          </Box>
                        </Box>
                      )}

                    {!isAuctionRoute && (
                      <Box className={`${boxClassName}`}>
                        <Box className="flex flex-col">
                          <Box className="flex justify-start items-center min-w-[150px] 2xl:min-w-0 py-1 px-4">
                            Announcement:
                          </Box>
                          {isVehicleSoldRoute && (
                            <Box className="flex justify-start items-center min-w-[150px] 2xl:min-w-0 py-1 px-4">
                              Sold Date:
                            </Box>
                          )}
                        </Box>
                        <Box className="flex flex-col">
                          <SelectComponent
                            hideErrorMessage={true}
                            className={"w-60"}
                            name="announcement"
                            value={values["announcement"] as string}
                            size={SIZES.SMALL}
                            options={announcementFieldsData}
                            handleChange={handleChange}
                          />
                          {isVehicleSoldRoute && (
                            <>
                              <Box className="flex items-center">
                                <Box>
                                  <CustomDateRangePicker
                                    name="startSoldDate"
                                    selectsStart={true}
                                    selectedDate={
                                      values["startSoldDate"] as Date
                                    }
                                    handleChange={(date: Date) =>
                                      handleDateChange(
                                        date,
                                        "startSoldDate",
                                        setFieldValue
                                      )
                                    }
                                    startDate={values["startSoldDate"] as Date}
                                    endDate={values["endSoldDate"] as Date}
                                    maxDate={values["endSoldDate"] as Date}
                                    placeholder="From"
                                    validationError={errors["startSoldDate"]}
                                  />
                                </Box>
                                <Box className="h-9 mx-[6px] mb-2 flex items-center justify-center">
                                  -
                                </Box>
                                <Box>
                                  <CustomDateRangePicker
                                    name="endSoldDate"
                                    selectsEnd={true}
                                    selectedDate={values["endSoldDate"] as Date}
                                    handleChange={(date: Date) =>
                                      handleDateChange(
                                        date,
                                        "endSoldDate",
                                        setFieldValue
                                      )
                                    }
                                    startDate={values["startSoldDate"] as Date}
                                    endDate={values["endSoldDate"] as Date}
                                    minDate={values["startSoldDate"] as Date}
                                    placeholder="To"
                                    validationError={errors["endSoldDate"]}
                                  />
                                </Box>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Box>
                    )}

                    <Box className="flex 2xl:flex-col justify-center ml-4 4xl:ml-0 items-start 2xl:items-center mr-6 w-full">
                      <ButtonComponent
                        className="w-[150px] m-0 margin-y-2"
                        text="Apply"
                        color="primary"
                        size={SIZES.SMALL}
                        onClick={() => handleSubmit()}
                      />
                      <ButtonComponent
                        className="w-[150px]  m-0 margin-y-2"
                        text="Reset"
                        color="primary"
                        size={SIZES.SMALL}
                        onClick={() => handleResetForm(resetForm)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                className="accordion-root text-sm sm:text-base py-3 rounded-md"
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Grid container rowGap={1}>
                  <Grid
                    sm={12}
                    md={sidebarOpen ? 6 : 4}
                    xl={1}
                    className="flex items-center justify-center border-r cursor-pointer"
                    onClick={handleExpanded}
                  >
                    <div className="bg-filterIcon bg-contain bg-center bg-no-repeat w-6 h-6 mr-2"></div>{" "}
                    Filter
                  </Grid>
                  <Grid
                    sm={12}
                    md={
                      isVehicleInventoryRoute || isAuctionRoute
                        ? sidebarOpen
                          ? 6
                          : 3
                        : sidebarOpen
                        ? 6
                        : 4
                    }
                    xl={2}
                    columnGap={1}
                    className="flex items-center justify-center border-r"
                  >
                    <Grid>Status:</Grid>
                    &nbsp;
                    <Grid className="text-secondary-300">{status || ""}</Grid>
                  </Grid>

                  {(isVehicleInventoryRoute || isAuctionRoute) && (
                    <Grid
                      sm={12}
                      md={sidebarOpen ? 6 : 4}
                      xl={3}
                      columnGap={1}
                      className="flex items-center justify-center border-r"
                    >
                      <Grid>Sort:</Grid>
                      &nbsp;
                      <SelectComponent
                        hideErrorMessage={true}
                        variant="standard"
                        value={sort}
                        options={
                          isAuctionRoute
                            ? auctionSortOptions?.concat(sortOptions)
                            : sortOptions
                        }
                        handleChange={(event: SelectChangeEvent) =>
                          handleSort(event?.target?.value)
                        }
                      />
                    </Grid>
                  )}

                  <Grid
                    sm={12}
                    md={sidebarOpen ? 6 : 4}
                    xl={1}
                    className="flex items-center justify-center 2xl:justify-start 2xl:ml-5"
                  >
                    <ButtonComponent
                      type={CONSTANTS.RESTART}
                      text="Reset"
                      color="secondary"
                      className="px-3"
                      size={SIZES.SMALL}
                      onClick={() => handleResetForm(resetForm)}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        )}
      </Formik>

      <Box className="absolute top-5 right-4 flex justify-end items-center">
        <ArrowForwardIosSharpIcon
          sx={{
            fontSize: "1rem",
            cursor: "pointer",
            transform: expanded ? "rotate(-90deg)" : "rotate(90deg)",
          }}
          onClick={handleExpanded}
        />
      </Box>
    </Box>
  );
};

export default FilterAccordion;

const ValidationError = ({ text }: { text?: string }) => {
  return <Box className="text-xs font-semibold text-primary-500">{text}</Box>;
};

const CustomDateRangePicker = ({
  selectedDate,
  handleChange,
  startDate,
  endDate,
  minDate,
  maxDate,
  validationError,
  placeholder,
  name,
  selectsEnd,
  selectsStart,
  showYearPicker,
}: {
  selectedDate?: Date;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  selectsEnd?: boolean;
  selectsStart?: boolean;
  showYearPicker?: boolean;
  name: string;
  placeholder?: string;
  validationError?: string;
  handleChange: (date: Date) => void;
}) => {
  return (
    <div>
      <DatePicker
        placeholderText={placeholder}
        name={name}
        selected={selectedDate}
        isClearable={selectedDate ? true : false}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        selectsEnd={selectsEnd}
        selectsStart={selectsStart}
        showYearPicker={showYearPicker}
        onChange={handleChange}
        className="border rounded-md py-1 px-2 w-28 text-sm h-[36px] text-left"
        customInput={
          <CustomDatePickerInput value="" placeholder={placeholder} />
        }
      />
      <ValidationError text={validationError} />
    </div>
  );
};
