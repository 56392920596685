import {
  Column,
  Data,
  CellRow,
  MultipleValues,
  SelectOption,
  VINModalDataObjectType,
  CellElement,
  Action,
} from "../ts/component.types";
import { StringKeyValues } from "../ts/api.types";

import {
  VehicleAcquiredFrom,
  DealerType,
  UserTypes,
  PSIStatus,
  TitleStatus,
  TransportRequestStatus,
  ArbitrationStatus,
  OVEEventSaleStatus,
  AuctionStatusName,
  AuctionStatus,
} from "./enum";
import { VehicleAnnouncements, VehicleStatus } from "./enum";
import {
  CONSTANTS,
  ACTION_BUTTON_TYPES,
  MODAL_BUTTON_NAMES,
  MODAL_FIELD_NAMES,
  MODAL_FIELD_TYPES,
  OFFER_BUTTON_TYPES,
  TABLE_COLUMNS,
  SELECT_TYPES,
  NUMBER,
  USER_TYPE_NAME,
} from "./constants";

export const dummyOptions = [];

export const statusOptions = [
  { label: "Active", value: "active" },
  { label: "In Active", value: "inactive" },
];

export const OveStatusOptions = [
  { label: "Pending", value: OVEEventSaleStatus.Pending },
  { label: "Posted", value: OVEEventSaleStatus.Posted },
];

export const transportRequestOptions = [
  {
    label: TransportRequestStatus[TransportRequestStatus.Requested],
    value: TransportRequestStatus.Requested?.toString(),
  },
];

export const transportScheduledOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: TransportRequestStatus[TransportRequestStatus.Accepted],
    value: TransportRequestStatus.Accepted?.toString(),
  },
  {
    label: TransportRequestStatus[TransportRequestStatus.Picked],
    value: TransportRequestStatus.Picked?.toString(),
  },
];

export const transportCompletedOptions = [
  {
    label: TransportRequestStatus[TransportRequestStatus.Dropped],
    value: TransportRequestStatus.Dropped?.toString(),
  },
];

export const membershipOptions = [
  { label: "Premium", value: DealerType.Premium },
  { label: "Standard", value: DealerType.Normal },
];

export const acquiredFromOptions = [
  { label: "Trade-in", value: VehicleAcquiredFrom.Trade },
  { label: "Frontline", value: VehicleAcquiredFrom.Frontline },
  { label: "Wholesale", value: VehicleAcquiredFrom.Wholesale },
];

export const announcementOptions = [
  { label: "Guaranteed", value: VehicleAnnouncements.Guaranteed },
  {
    label: "Limited guaranteed",
    value: VehicleAnnouncements.Limited_Guaranteed,
  },
  { label: "As-is (No Arbitration)", value: VehicleAnnouncements.As_Is },
];

export const announcements: StringKeyValues = {
  [VehicleAnnouncements.Guaranteed]: "Guaranteed",
  [VehicleAnnouncements.As_Is]: "As-is (No Arbitration)",
  [VehicleAnnouncements.Limited_Guaranteed]: "Limited guaranteed",
};

export const vehicleStatus: StringKeyValues = {
  [VehicleStatus.Inventory]: "Inventory",
  [VehicleStatus.Sold]: "Sold",
  [VehicleStatus.Removed]: "Removed From Lot",
  [VehicleStatus.RerunInAuction]: "Rerun",
  [VehicleStatus.SecondChanceAuction]: "Second Chance",
};

export const auctionStatusOptions = [
  {
    label: "All",
    value: "-1",
  },
  {
    label: AuctionStatusName[AuctionStatus.Scheduled],
    value: AuctionStatus.Scheduled,
  },
  { label: AuctionStatusName[AuctionStatus.Lock], value: AuctionStatus.Lock },
  {
    label: AuctionStatusName[AuctionStatus.InProgress],
    value: AuctionStatus.InProgress,
  },
  {
    label: AuctionStatusName[AuctionStatus.Completed],
    value: AuctionStatus.Completed,
  },
];

export const adminTypes = [
  { label: "All", value: NUMBER.MINUS_ONE?.toString() },
  { label: USER_TYPE_NAME[UserTypes.SuperAdmin], value: UserTypes.SuperAdmin },
  {
    label: USER_TYPE_NAME[UserTypes.AuctionAdmin],
    value: UserTypes.AuctionAdmin,
  },
  {
    label: USER_TYPE_NAME[UserTypes.InventoryAdmin],
    value: UserTypes.InventoryAdmin,
  },
];

export const dealerUserRoleOptions = [
  { label: "All", value: NUMBER.MINUS_ONE?.toString() },
  {
    label: USER_TYPE_NAME[UserTypes.DealerAuctionUser],
    value: UserTypes.DealerAuctionUser,
  },
  {
    label: USER_TYPE_NAME[UserTypes.DealerInventoryUser],
    value: UserTypes.DealerInventoryUser,
  },
];

export const transporterUserRoleOptions = [
  { label: "All", value: NUMBER.MINUS_ONE?.toString() },
  {
    label: USER_TYPE_NAME[UserTypes.TransporterUser],
    value: UserTypes.TransporterUser,
  },
];

export const arbitrationStatusOptions = [
  { label: "All", value: NUMBER.MINUS_ONE?.toString() },
  { label: "Open", value: ArbitrationStatus.Open },
  { label: "Withdraw", value: ArbitrationStatus.Withdraw },
  { label: "Bought", value: ArbitrationStatus.Bought },
];

export const dealerUserColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.EMAIL,
    label: "Email",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.FIRST_NAME,
    label: "First Name",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.LAST_NAME,
    label: "Last Name",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.CONTACT_NO,
    label: "Contact No.",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.DEALER_NAME,
    label: "Dealer Name",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    options: dummyOptions,
    currentValue: "all",
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ID,
    label: "User ID",
    minWidth: 130,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.USER_ROLE,
    label: "User Role",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    options: dealerUserRoleOptions,
    currentValue: UserTypes.DealerAuctionUser.toString(),
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ACTIVE_STATUS,
    label: "Active Status",
    minWidth: 130,
    filter: CONSTANTS.SELECT,
    options: statusOptions,
    currentValue: "all",
    dataType: CONSTANTS.TAG,
  },
  {
    id: TABLE_COLUMNS.ACTION,
    label: "Search",
    minWidth: 200,
    filter: CONSTANTS.BUTTON,
    align: "center",
    dataType: CONSTANTS.BUTTON,
    hideLabel: true,
  },
];

export function dealerUserCreateData(
  email: string,
  firstName: string,
  lastName: string,
  contactNo: string,
  dealerName: string,
  id: string,
  userRole: string,
  activeStatus: boolean,
  disableEdit: boolean
): Data {
  return {
    email,
    firstName,
    lastName,
    contactNo,
    dealerName,
    id,
    userRole,
    activeStatus: {
      label: activeStatus ? "Active" : "In Active",
      color: activeStatus ? "primary" : "secondary",
    },
    disabled: disableEdit,
  };
}

export const dealerColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.MILLION_NUMBER,
    label: "5 Million Number",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
    type: "millionNumber",
  },
  {
    id: TABLE_COLUMNS.DEALER_NAME,
    label: "Dealer Name",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.LOCATION,
    label: "Location",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    dataType: CONSTANTS.TEXT,
    currentValue: "all",
    options: dummyOptions,
  },
  {
    id: TABLE_COLUMNS.CONTACT_NO,
    label: "Contact No.",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ZIP_CODE,
    label: "Zip Code",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.EMAIL,
    label: "Email",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ACTIVE_STATUS,
    label: "Active Status",
    minWidth: 130,
    filter: CONSTANTS.SELECT,
    options: statusOptions,
    currentValue: "all",
    dataType: CONSTANTS.TAG,
  },
  {
    id: TABLE_COLUMNS.ACTION,
    label: "Search",
    minWidth: 200,
    filter: CONSTANTS.BUTTON,
    align: "center",
    dataType: CONSTANTS.BUTTON,
    hideLabel: true,
  },
];

export function dealerCreateData(
  id: string,
  millionNumber: string,
  dealerName: string,
  location: string,
  contactNo: string,
  zipCode: string,
  email: string,
  activeStatus: boolean,
  disableEdit: boolean
): Data {
  return {
    id,
    millionNumber,
    dealerName,
    location,
    contactNo,
    zipCode,
    email,
    activeStatus: {
      label: activeStatus ? "Active" : "In Active",
      color: activeStatus ? "primary" : "secondary",
    },
    disabled: disableEdit,
  };
}

export const transportRequestColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.ID,
    label: "ID",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
    type: "id",
  },
  {
    id: TABLE_COLUMNS.PICKUP_DATE,
    label: "Pickup Date",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
    filter: CONSTANTS.DATE_RANGE_PICKER,
  },
  {
    id: TABLE_COLUMNS.PICKUP_LOCATION,
    label: "Pick Up Location",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    dataType: CONSTANTS.TEXT,
    currentValue: "all",
    options: dummyOptions,
  },
  {
    id: TABLE_COLUMNS.DROPOFF_LOCATION,
    label: "Drop Off Location",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    dataType: CONSTANTS.TEXT,
    currentValue: "all",
    options: dummyOptions,
  },
  {
    id: TABLE_COLUMNS.STATUS,
    label: "Status",
    minWidth: 130,
    filter: CONSTANTS.SELECT,
    options: transportRequestOptions,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.CONTACT_INFO,
    label: "Contact Info",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: "multiple-values",
    type: "contactInfo",
  },
  {
    id: TABLE_COLUMNS.ALTERNATE_DATE,
    label: "Alternate Date",
    minWidth: 200,
    dataType: "datepicker",
    filter: CONSTANTS.DATE_RANGE_PICKER,
  },
  {
    id: TABLE_COLUMNS.ACTION,
    label: "Search",
    minWidth: 200,
    filter: CONSTANTS.BUTTON,
    align: "center",
    dataType: CONSTANTS.BUTTON,
    hideLabel: true,
  },
];

export function transportRequestCreateData(
  id: string,
  vin: string,
  year: string,
  make: string,
  model: string,
  color: string,
  createdAt: string,
  pickupDate: string,
  pickupLocation: string,
  dropoffLocation: string,
  status: string,
  contactInfo: MultipleValues,
  alternateDate: string,
  actionType: number,
  alternateDateAccess: boolean,
  acceptAccess: boolean,
  transportActionAccess: boolean
): Data {
  return {
    id,
    pickupDate,
    vin,
    year,
    make,
    model,
    color,
    createdAt,
    pickupLocation,
    dropoffLocation,
    status,
    contactInfo,
    alternateDate,
    actionType,
    alternateDateAccess,
    acceptAccess,
    transportActionAccess,
  };
}

export const transportRequestScheduledColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.ID,
    label: "ID",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
    type: "id",
  },
  {
    id: TABLE_COLUMNS.PICKUP_DATE,
    label: "Pickup Date",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
    filter: CONSTANTS.DATE_RANGE_PICKER,
  },
  {
    id: TABLE_COLUMNS.PICKUP_LOCATION,
    label: "Pick Up Location",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    dataType: CONSTANTS.TEXT,
    currentValue: "all",
    options: dummyOptions,
  },
  {
    id: TABLE_COLUMNS.DROPOFF_LOCATION,
    label: "Drop Off Location",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    dataType: CONSTANTS.TEXT,
    currentValue: "all",
    options: dummyOptions,
  },
  {
    id: TABLE_COLUMNS.STATUS,
    label: "Status",
    minWidth: 130,
    filter: CONSTANTS.SELECT,
    options: transportScheduledOptions,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.CONTACT_INFO,
    label: "Contact Info",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: "multiple-values",
    type: "contactInfo",
  },
  {
    id: TABLE_COLUMNS.ALTERNATE_DATE,
    label: "Alternate Date",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
    filter: CONSTANTS.DATE_RANGE_PICKER,
  },
  {
    id: TABLE_COLUMNS.ACTION,
    label: "Search",
    minWidth: 200,
    filter: CONSTANTS.BUTTON,
    align: "center",
    dataType: CONSTANTS.BUTTON,
    hideLabel: true,
  },
];

export const transportRequestCompletedColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.ID,
    label: "ID",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
    type: "id",
  },
  {
    id: TABLE_COLUMNS.PICKUP_DATE,
    label: "Pickup Date",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
    filter: CONSTANTS.DATE_RANGE_PICKER,
  },
  {
    id: TABLE_COLUMNS.PICKUP_LOCATION,
    label: "Pick Up Location",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    dataType: CONSTANTS.TEXT,
    currentValue: "all",
    options: dummyOptions,
  },
  {
    id: TABLE_COLUMNS.DROPOFF_LOCATION,
    label: "Drop Off Location",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    dataType: CONSTANTS.TEXT,
    currentValue: "all",
    options: dummyOptions,
  },
  {
    id: TABLE_COLUMNS.STATUS,
    label: "Status",
    minWidth: 130,
    filter: CONSTANTS.SELECT,
    options: transportCompletedOptions,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.CONTACT_INFO,
    label: "Contact Info",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: "multiple-values",
    type: "contactInfo",
  },
  {
    id: TABLE_COLUMNS.ALTERNATE_DATE,
    label: "Alternate Date",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
    filter: CONSTANTS.DATE_RANGE_PICKER,
  },
  {
    id: TABLE_COLUMNS.ACTION,
    label: "Search",
    minWidth: 200,
    filter: CONSTANTS.BUTTON,
    align: "center",
    dataType: CONSTANTS.BUTTON,
    hideLabel: true,
  },
];

export const userColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.EMAIL,
    label: "Email",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.FIRST_NAME,
    label: "First Name",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.LAST_NAME,
    label: "Last Name",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.CONTACT_NO,
    label: "Contact No.",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ID,
    label: "User ID",
    minWidth: 130,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.USER_ROLE,
    label: "User Role",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    dataType: CONSTANTS.TEXT,
    currentValue: UserTypes.SuperAdmin.toString(),
    options: adminTypes,
  },
  {
    id: TABLE_COLUMNS.ACTIVE_STATUS,
    label: "Active Status",
    minWidth: 130,
    filter: CONSTANTS.SELECT,
    options: statusOptions,
    currentValue: "all",
    dataType: CONSTANTS.TAG,
  },
  {
    id: TABLE_COLUMNS.ACTION,
    label: "Search",
    minWidth: 200,
    filter: CONSTANTS.BUTTON,
    align: "center",
    dataType: CONSTANTS.BUTTON,
    hideLabel: true,
  },
];

export function userCreateData(
  email: string,
  firstName: string,
  lastName: string,
  contactNo: string,
  id: string,
  userRole: string,
  activeStatus: boolean,
  disableEdit: boolean
): Data {
  return {
    email,
    firstName,
    lastName,
    contactNo,
    id,
    userRole,
    activeStatus: {
      label: activeStatus ? "Active" : "In Active",
      color: activeStatus ? "primary" : "secondary",
    },
    disabled: disableEdit,
  };
}

export const transporterColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.ID,
    label: "ID",
    minWidth: 130,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.TRANSPORTER_NAME,
    label: "Transporter Name",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.EMAIL,
    label: "Email",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.FIRST_NAME,
    label: "First Name",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.LAST_NAME,
    label: "Last Name",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.CONTACT_NO,
    label: "Contact No.",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ACTIVE_STATUS,
    label: "Active Status",
    minWidth: 130,
    filter: CONSTANTS.SELECT,
    options: statusOptions,
    currentValue: "all",
    dataType: CONSTANTS.TAG,
  },
  {
    id: TABLE_COLUMNS.ACTION,
    label: "Search",
    minWidth: 200,
    filter: CONSTANTS.BUTTON,
    align: "center",
    dataType: CONSTANTS.BUTTON,
    hideLabel: true,
  },
];

export function transporterCreateData(
  id: string,
  name: string,
  email: string,
  firstName: string,
  lastName: string,
  contactNo: string,
  activeStatus: boolean,
  disableEdit: boolean
): Data {
  return {
    email,
    firstName,
    lastName,
    contactNo,
    transporterName: name,
    id,
    activeStatus: {
      label: activeStatus ? "Active" : "In Active",
      color: activeStatus ? "primary" : "secondary",
    },
    disabled: disableEdit,
  };
}

export const transporterUserColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.EMAIL,
    label: "Email",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.FIRST_NAME,
    label: "First Name",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.LAST_NAME,
    label: "Last Name",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.CONTACT_NO,
    label: "Contact No.",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.TRANSPORTER_NAME,
    label: "Transporter Name",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    options: dummyOptions,
    currentValue: "all",
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ID,
    label: "User ID",
    minWidth: 130,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.USER_ROLE,
    label: "User Role",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    options: transporterUserRoleOptions,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ACTIVE_STATUS,
    label: "Active Status",
    minWidth: 130,
    filter: CONSTANTS.SELECT,
    options: statusOptions,
    currentValue: "all",
    dataType: CONSTANTS.TAG,
  },
  {
    id: TABLE_COLUMNS.ACTION,
    label: "Search",
    minWidth: 200,
    filter: CONSTANTS.BUTTON,
    align: "center",
    dataType: CONSTANTS.BUTTON,
    hideLabel: true,
  },
];

export function transporterUserCreateData(
  email: string,
  firstName: string,
  lastName: string,
  contactNo: string,
  transporterName: string,
  id: string,
  userRole: string,
  activeStatus: boolean,
  disableEdit: boolean
): Data {
  return {
    email,
    firstName,
    lastName,
    contactNo,
    transporterName,
    id,
    userRole,
    activeStatus: {
      label: activeStatus ? "Active" : "In Active",
      color: activeStatus ? "primary" : "secondary",
    },
    disabled: disableEdit,
  };
}

export const transporterCompanyNameOptions = [
  { label: "Company Name 1", value: "company-name-1" },
  { label: "Company Name 2", value: "company-name-2" },
  { label: "Company Name 3", value: "company-name-3" },
];

export const auditTrailColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.ID,
    label: "ID",
    minWidth: 100,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.DATE,
    label: "DATE",
    minWidth: 120,
    dataType: CONSTANTS.TEXT,
    placeholder: "Date",
  },
  {
    id: TABLE_COLUMNS.USER,
    label: "User",
    minWidth: 150,
    dataType: CONSTANTS.TEXT,
    placeholder: "User",
  },
  {
    id: TABLE_COLUMNS.IP,
    label: "IP Address",
    minWidth: 150,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ROLE,
    label: "Role",
    minWidth: 150,
    dataType: CONSTANTS.TEXT,
    placeholder: "Role",
  },
  {
    id: TABLE_COLUMNS.EVENT_TYPE,
    label: "Event Type",
    minWidth: 120,
    dataType: CONSTANTS.TEXT,
    placeholder: "Event Type",
  },
  {
    id: TABLE_COLUMNS.MESSAGE,
    label: "Message",
    minWidth: 250,
    currentValue: "all",
    dataType: CONSTANTS.TEXT,
  },
  // Hided this column for some time, will include it in future
  // {
  //   id: TABLE_COLUMNS.BODY,
  //   label: "Request Body",
  //   minWidth: 400,
  //   currentValue: "all",
  //   dataType: CONSTANTS.TEXT,
  // },
];

export function auditTrailCreateData(
  id: string,
  date: string,
  user: string,
  ip: string,
  role: string,
  eventType: string,
  message: string,
  body: string
): Data {
  return {
    id,
    date,
    user,
    ip,
    role,
    eventType,
    message,
    body,
  };
}

export const transportationColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.VEHICLE_INFO,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.PICKUP_DETAILS,
    label: "",
    minWidth: 350,
    dataType: CONSTANTS.TEXT,
    align: "center",
  },
  {
    id: TABLE_COLUMNS.DROPOFF_DETAILS,
    label: "",
    minWidth: 350,
    dataType: CONSTANTS.TEXT,
    align: "center",
  },
  {
    id: TABLE_COLUMNS.STATUS_DETAILS,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ACTION,
    label: "",
    minWidth: 100,
    dataType: CONSTANTS.TEXT,
    align: "center",
  },
];

export function transportationCreateData(
  id: string,
  make: string,
  model: string,
  year: string,
  dealerName: string,
  vehicleLocation: string,
  pickupLocation: string,
  pickupDate: string,
  dropoffLocation: string,
  status: string,
  transporterName: string,
  alternateDate: string,
  showVINModal: boolean
): {
  id: string;
  data: CellRow[][];
} {
  return {
    id,
    data: [
      [
        {
          title: "Vehicle",
          value: `${year}, ${make}, ${model}`,
          titleWidth: "w-16",
          valueWidth: "w-40",
        },
        {
          title: "VIN",
          value: id,
          titleWidth: "w-16",
          valueWidth: "w-40",
          valueColor: "text-secondary-300",
          showVINModal,
        },
        {
          title: "Location",
          value: vehicleLocation,
          titleWidth: "w-16",
          valueWidth: "w-40",
        },
      ],
      [{ type: "divider" }],
      [
        {
          title: "Pick Up Location",
          value: pickupLocation,
          titleWidth: "w-40",
          valueWidth: "w-40",
        },
        {
          title: "Pickup Date",
          value: pickupDate,
          titleWidth: "w-40",
          valueWidth: "w-40",
        },
      ],
      [
        {
          title: "Drop Off Location",
          value: dropoffLocation,
          titleWidth: "w-40",
          valueWidth: "w-40",
        },
        {
          title: "Dealer",
          value: dealerName,
          titleWidth: "w-40",
          valueWidth: "w-40",
        },
      ],
      [{ type: "divider" }],
      [
        {
          title: "Status",
          value: status,
          titleWidth: "w-32",
          valueWidth: "w-40",
        },
        {
          title: "Transporter",
          value: transporterName,
          titleWidth: "w-32",
          valueWidth: "w-40",
        },
        {
          title: "Alternate Date",
          value: alternateDate,
          titleWidth: "w-32",
          valueWidth: "w-40",
        },
      ],
      [
        {
          type: "action",
          actions: [
            {
              type: "buttons",
              elements:
                status === "pending"
                  ? [
                      { value: "", title: "Accept", color: "primary" },
                      { value: "", title: "Decline", color: "" },
                    ]
                  : [],
            },
          ],
        },
      ],
    ],
  };
}

export function unssignedTransportationCreateData(
  id: string,
  vin: string,
  showVINModal: boolean,
  make: string,
  model: string,
  year: string,
  dealerName: string,
  vehicleLocation: string,
  pickupLocation: string,
  pickupDate: string,
  dropoffLocation: string,
  status: string,
  alternateDate: Array<SelectOption>,
  showAction: boolean,
  transporters: Array<SelectOption>,
  transporterValue?: string,
  alternateDateValue?: string,
  disableAssignTransporter?: boolean,
  disableAcceptAlternateDate?: boolean
): {
  id: string;
  data: CellRow[][];
} {
  const data: CellRow[][] = [
    [
      {
        title: "Vehicle",
        value: `${year}, ${make}, ${model}`,
        titleWidth: "w-16",
        valueWidth: "w-40",
      },
      {
        title: "VIN",
        value: vin,
        titleWidth: "w-16",
        valueWidth: "w-40",
        valueColor: "text-secondary-300",
        showVINModal,
      },
      {
        title: "Location",
        value: vehicleLocation,
        titleWidth: "w-16",
        valueWidth: "w-40",
      },
    ],
    [{ type: "divider" }],
    [
      {
        title: "Pick Up Location",
        value: pickupLocation,
        titleWidth: "w-32",
        valueWidth: "w-48",
      },
      {
        title: "Pickup Date",
        value: pickupDate,
        titleWidth: "w-32",
        valueWidth: "w-48",
      },
    ],
    [
      {
        title: "Drop Off Location",
        value: dropoffLocation,
        titleWidth: "w-32",
        valueWidth: "w-48",
      },
      {
        title: "Dealer",
        value: dealerName,
        titleWidth: "w-32",
        valueWidth: "w-48",
      },
    ],
    [{ type: "divider" }],
  ];

  const auctionData: CellRow[] = [
    {
      title: "Status",
      value: status,
      titleWidth: "w-32",
      valueWidth: "w-40",
    },
  ];

  if (alternateDate.length) {
    auctionData.push({
      titleWidth: "w-32",
      valueWidth: "w-40",
      type: CONSTANTS.SELECT,
      title: "Alternate Date",
      placeholder: "Alternate Dates",
      value: alternateDateValue || "",
      options: alternateDate,
      selectType: SELECT_TYPES.ALTERNATE_DATE,
    });
  } else if (transporters?.length) {
    auctionData.push({
      title: "Transporters",
      value: transporterValue || "",
      titleWidth: "w-32",
      valueWidth: "w-40",
      type: CONSTANTS.SELECT,
      placeholder: "Transporters",
      options: transporters,
      selectType: SELECT_TYPES.TRANSPORTER,
    });
  }

  data.push(auctionData);
  let actions: Action[] = [];

  if (showAction) {
    actions.push({
      type: "buttons",
      elements: [
        {
          value: "",
          type: alternateDate?.length
            ? ACTION_BUTTON_TYPES.ACCEPT_ALTERNATE_DATE
            : ACTION_BUTTON_TYPES.ASSIGN_TRANPORT,
          title: alternateDate?.length ? "Accept" : "Assign",
          color: "primary",
          disabled: alternateDate?.length
            ? disableAcceptAlternateDate
            : disableAssignTransporter,
        },
      ],
    });
  }

  data.push([
    {
      type: "action",
      actions: actions,
    },
  ]);

  return {
    id,
    data: data,
  };
}

export function vehicleInventoryCreateData(
  id: string,
  conditionReportId: string,
  vin: string,
  make: string,
  model: string,
  year: string,
  dealerName: string,
  vehicleLocation: string,
  age: string,
  lane: string,
  run: string,
  announcement: string,
  remarks: string,
  auctionCount: string,
  acv: string,
  dealerStockNumber: string,
  floorPrice: string,
  status: string,
  comment: string,
  crGrade: string,
  cr: boolean,
  picked: boolean,
  dropped: boolean,
  fade: boolean,
  laneAndRunAccess: boolean,
  showEditVehicle: boolean,
  showRemoveVehicle: boolean,
  showVINModal: boolean
): {
  id: string;
  conditionReportId: string;
  fade: boolean;
  data: CellRow[][];
} {
  const actionElements = [];
  if (showEditVehicle) {
    actionElements.push({
      title: "Edit",
      type: ACTION_BUTTON_TYPES.EDIT_VEHICLE,
    });
  }
  if (showRemoveVehicle) {
    actionElements.push({
      title: "Remove",
      type: ACTION_BUTTON_TYPES.REMOVE_VEHICLE,
    });
  }
  const actions = [
    {
      type: "action",
      actions: [
        {
          type: "action-menu",
          elements: actionElements,
        },
      ],
    },
  ];

  const data = [
    [
      { title: "Age", value: age, titleWidth: "w-12", valueWidth: "w-24" },
      {
        type: laneAndRunAccess ? CONSTANTS.INPUT_WITH_CHECKBOX : CONSTANTS.TEXT,
        title: "Lane",
        value: lane,
        titleWidth: "w-12",
        valueWidth: "w-24",
      },
      {
        type: laneAndRunAccess ? CONSTANTS.INPUT_WITH_CHECKBOX : CONSTANTS.TEXT,
        title: "Run",
        value: run,
        titleWidth: "w-12",
        valueWidth: "w-24",
      },
    ],
    [{ type: "divider" }],
    [
      {
        title: "Vehicle",
        value: `${year}, ${make}, ${model}`,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
      {
        title: "VIN",
        value: vin,
        titleWidth: "w-20",
        valueWidth: "w-40",
        valueColor: "text-secondary-300",
        showVINModal,
      },
      {
        title: "Location",
        value: vehicleLocation,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
      {
        title: "Comments",
        value: comment,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
    ],
    [
      {
        title: "Announcement",
        value: announcement,
        titleWidth: "w-40",
        valueWidth: "w-60",
      },
      {
        title: "Sub Announcements",
        value: remarks,
        titleWidth: "w-40",
        valueWidth: "w-60",
      },
      {
        title: "Auction Count",
        value: auctionCount,
        titleWidth: "w-40",
        valueWidth: "w-60",
      },
      {
        title: "Dealer",
        value: dealerName,
        titleWidth: "w-40",
        valueWidth: "w-60",
      },
    ],
    [
      { title: "ACV", value: acv, titleWidth: "w-24", valueWidth: "w-20" },
      {
        title: "Floor Price",
        value: floorPrice,
        titleWidth: "w-24",
        valueWidth: "w-20",
      },
    ],
    [{ type: "divider" }],
    [
      {
        title: "Status",
        value: status,
        titleWidth: "w-20",
        valueWidth: "w-48",
      },
      //Hided CR Report and Car Faxx Report Work for now, this will be included in future
      // {
      //   title: "CR Grade",
      //   value: crGrade,
      //   titleWidth: "w-20",
      //   valueWidth: "w-48",
      // },
      {
        type: CONSTANTS.TAG,
        title: "",
        value: "",
        titleWidth: "w-20",
        valueWidth: "w-48",
        tagOptions: [
          // { title: "CR", active: cr },  //Hided CR Report and Car Faxx Report Work for now, this will be included in future
          { title: "Picked", active: picked },
          { title: "Dropped", active: dropped },
        ],
      },
    ],
  ];

  if (showEditVehicle || showRemoveVehicle) {
    data.push(actions);
  }

  return {
    id,
    fade,
    conditionReportId,
    data: data,
  };
}

export function vehicleTrashCreateData(
  id: string,
  conditionReportId: string,
  vin: string,
  make: string,
  model: string,
  year: string,
  dealerName: string,
  vehicleLocation: string,
  age: string,
  lane: string,
  run: string,
  announcement: string,
  remarks: string,
  acv: string,
  dealerStockNumber: string,
  floorPrice: string,
  status: string,
  comment: string,
  crGrade: string,
  cr: boolean,
  picked: boolean,
  dropped: boolean,
  showVINModal: boolean,
  showMoveToInventory: boolean
): {
  id: string;
  conditionReportId: string;
  data: CellRow[][];
} {
  const actionElements = [];
  if (showMoveToInventory) {
    actionElements.push({
      title: "Move to Inventory",
      type: ACTION_BUTTON_TYPES.MOVE_TO_INVENTORY,
    });
  }

  const actions = [
    {
      type: "action",
      actions: [
        {
          type: "action-menu",
          elements: actionElements,
        },
      ],
    },
  ];

  const data = [
    [
      { title: "Age", value: age, titleWidth: "w-12", valueWidth: "w-24" },
      { title: "Lane", value: lane, titleWidth: "w-12", valueWidth: "w-24" },
      { title: "Run", value: run, titleWidth: "w-12", valueWidth: "w-24" },
    ],
    [{ type: "divider" }],
    [
      {
        title: "Vehicle",
        value: `${year}, ${make}, ${model}`,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
      {
        title: "VIN",
        value: vin,
        titleWidth: "w-20",
        valueWidth: "w-40",
        valueColor: "text-secondary-300",
        showVINModal,
      },
      {
        title: "Location",
        value: vehicleLocation,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
      {
        title: "Comments",
        value: comment,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
    ],
    [
      {
        title: "Announcement",
        value: announcement,
        titleWidth: "w-40",
        valueWidth: "w-60",
      },
      {
        title: "Sub Announcements",
        value: remarks,
        titleWidth: "w-40",
        valueWidth: "w-60",
      },
      {
        title: "Dealer",
        value: dealerName,
        titleWidth: "w-40",
        valueWidth: "w-60",
      },
    ],
    [
      { title: "ACV", value: acv, titleWidth: "w-24", valueWidth: "w-20" },
      {
        title: "Floor Price",
        value: floorPrice,
        titleWidth: "w-24",
        valueWidth: "w-20",
      },
    ],
    [{ type: "divider" }],
    [
      {
        title: "Status",
        value: status,
        titleWidth: "w-20",
        valueWidth: "w-48",
      },
      //Hided CR Report and Car Faxx Report Work for now, this will be included in future
      // {
      //   title: "CR Grade",
      //   value: crGrade,
      //   titleWidth: "w-20",
      //   valueWidth: "w-48",
      // },
      {
        type: CONSTANTS.TAG,
        title: "",
        value: "",
        titleWidth: "w-20",
        valueWidth: "w-48",
        tagOptions: [
          // { title: "CR", active: cr }, //Hided CR Report and Car Faxx Report Work for now, this will be included in future
          { title: "Picked", active: picked },
          { title: "Dropped", active: dropped },
        ],
      },
    ],
  ];

  if (showMoveToInventory) data.push(actions);

  return {
    id,
    conditionReportId,
    data,
  };
}

export const vehicleInventoryColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.AUCTION_INFO,
    label: "",
    minWidth: 150,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.VEHICLE_INFO,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.MISC_INFO,
    label: "",
    minWidth: 250,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.VEHICLE_NUMBERS,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.STATUS_AND_GRADE_INFO,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
    align: "left",
  },
  {
    id: TABLE_COLUMNS.MORE_ACTION,
    label: "",
    minWidth: 50,
    dataType: CONSTANTS.TEXT,
    align: "center",
  },
];

export function vehicleSoldCreateData(
  id: string,
  vin: string,
  make: string,
  model: string,
  year: string,
  dealerName: string,
  vehicleLocation: string,
  age: string,
  announcement: string,
  remarks: string,
  acv: string,
  dealerStockNumber: string,
  floorPrice: string,
  status: string,
  comment: string,
  soldDate: string,
  soldFor: string,
  showRequestPSI: boolean,
  showCreateArbitration: boolean,
  showVINModal: boolean
): {
  id: string;
  data: CellRow[][];
} {
  const actionElements: Array<CellElement> = [];

  if (showRequestPSI) {
    actionElements.push({
      title: "Request PSI",
      type: ACTION_BUTTON_TYPES.PSI,
    });
  }
  if (showCreateArbitration) {
    actionElements.push({
      title: "Open Arbitration",
      type: ACTION_BUTTON_TYPES.OPEN_ARBITRATION,
    });
  }
  const actions = [
    {
      type: "action",
      actions: [
        {
          type: "action-menu",
          elements: actionElements,
        },
      ],
    },
  ];

  const data = [
    [{ title: "Age", value: age, titleWidth: "w-20", valueWidth: "w-24" }],
    [{ type: "divider" }],
    [
      {
        title: "Vehicle",
        value: `${year}, ${make}, ${model}`,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
      {
        title: "VIN",
        value: vin,
        titleWidth: "w-20",
        valueWidth: "w-40",
        valueColor: "text-secondary-300",
        showVINModal,
      },
      {
        title: "Location",
        value: vehicleLocation,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
      {
        title: "Comments",
        value: comment,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
    ],
    [
      {
        title: "Announcement",
        value: announcement,
        titleWidth: "w-40",
        valueWidth: "w-60",
      },
      {
        title: "Sub Announcements",
        value: remarks,
        titleWidth: "w-40",
        valueWidth: "w-60",
      },
      {
        title: "Dealer",
        value: dealerName,
        titleWidth: "w-40",
        valueWidth: "w-60",
      },
    ],
    [
      { title: "ACV", value: acv, titleWidth: "w-24", valueWidth: "w-20" },
      {
        title: "Floor Price",
        value: floorPrice,
        titleWidth: "w-24",
        valueWidth: "w-20",
      },
    ],
    [{ type: "divider" }],
    [
      {
        title: "Status",
        value: status,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
      {
        title: "Sold Date",
        value: soldDate,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
      {
        title: "Sold For",
        value: soldFor,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
    ],
  ];

  if (showRequestPSI || showCreateArbitration) {
    data.push(actions);
  }

  return {
    id,
    data: data,
  };
}

export const vehicleSoldColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.AUCTION_INFO,
    label: "",
    minWidth: 150,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.VEHICLE_INFO,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.MISC_INFO,
    label: "",
    minWidth: 250,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.VEHICLE_NUMBERS,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.SOLD_INFO,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
    align: "left",
  },
  {
    id: TABLE_COLUMNS.MORE_ACTION,
    label: "",
    minWidth: 50,
    dataType: CONSTANTS.TEXT,
    align: "center",
  },
];

export function auctionCreateData(
  id: string,
  vin: string,
  make: string,
  model: string,
  year: string,
  dealerName: string,
  vehicleLocation: string,
  age: string,
  lane: string,
  run: string,
  announcement: string,
  crGrade: string,
  acv: string,
  dealerStockNumber: string,
  floorPrice: string,
  status: string,
  comment: string,
  offerType: string,
  offer: string,
  sold: boolean,
  soldPrice: string | undefined,
  updateRequestStatus: string | undefined,
  fade: boolean,
  bumpOffer: string,
  acceptOffer: string,
  counterOffer: string,
  pullbackFromAuction: boolean,
  showEditVehicle: boolean,
  showVINModal: boolean,
  access: {
    [key: string]: {
      enableText: boolean;
      enableFreeze: boolean;
    };
  },
  edit: boolean,
  isAuctionStartedOrCompleted: boolean
): {
  id: string;
  offer: string;
  bumpOffer: string;
  counterOffer: string;
  acceptOffer: string;
  fade: boolean;
  data: CellRow[][];
} {
  const actionElements = [];
  if (showEditVehicle) {
    actionElements.push({
      title: "Edit Vehicle",
      type: ACTION_BUTTON_TYPES.EDIT_VEHICLE,
    });
  }
  if (pullbackFromAuction) {
    actionElements.push({
      title: "Pullback",
      type: ACTION_BUTTON_TYPES.PULLBACK_FROM_AUCTION,
    });
  }
  if (fade) {
    actionElements.push({
      title: "Change Request",
      type: ACTION_BUTTON_TYPES.VEHICLE_UPDATE_REQUEST,
    });
  }

  const actions = [
    {
      type: "action",
      actions: [
        {
          type: "action-menu",
          elements: actionElements,
        },
      ],
    },
  ];

  const data = [
    [
      { title: "Age", value: age, titleWidth: "w-12", valueWidth: "w-24" },
      {
        title: "Lane",
        value: lane,
        titleWidth: "w-12",
        valueWidth: "w-24",
        type:
          edit && !isAuctionStartedOrCompleted
            ? CONSTANTS.INPUT_WITH_CHECKBOX
            : CONSTANTS.TEXT,
      },
      {
        title: "Run",
        value: run,
        titleWidth: "w-12",
        valueWidth: "w-24",
        type:
          edit && !isAuctionStartedOrCompleted
            ? CONSTANTS.INPUT_WITH_CHECKBOX
            : CONSTANTS.TEXT,
      },
    ],
    [{ type: "divider" }],
    [
      {
        title: "Vehicle",
        value: `${year}, ${make}, ${model}`,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
      {
        title: "VIN",
        value: vin,
        titleWidth: "w-20",
        valueWidth: "w-40",
        valueColor: "text-secondary-300",
        showVINModal,
      },
      {
        title: "Location",
        value: vehicleLocation,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
      {
        title: "Comments",
        value: comment,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
    ],
    [
      {
        title: "Announcement",
        value: announcement,
        titleWidth: "w-32",
        valueWidth: "w-36",
      },
      {
        title: "Update Request",
        value: updateRequestStatus,
        titleWidth: "w-32",
        valueWidth: "w-36",
        hide: !updateRequestStatus,
      },
      {
        title: "Dealer",
        value: dealerName,
        titleWidth: "w-32",
        valueWidth: "w-36",
      },
      //Hided CR Report and Car Faxx Report Work for now, this will be included in future
      // {
      //   title: "CR Grade",
      //   value: crGrade,
      //   titleWidth: "w-32",
      //   valueWidth: "w-36",
      // },
    ],
    [
      { title: "ACV", value: acv, titleWidth: "w-24", valueWidth: "w-20" },
      {
        title: "Floor Price",
        value: floorPrice,
        titleWidth: "w-24",
        valueWidth: "w-20",
      },
    ],
    [{ type: "divider" }],
    [
      {
        type: offerType,
        title: "Offer",
        value: offer,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
      {
        title: "Status",
        value: status,
        titleWidth: "w-20",
        valueWidth: "w-40",
      },
      {
        title: "Sold Price",
        value: soldPrice,
        titleWidth: "w-20",
        valueWidth: "w-40",
        hide: !sold,
      },
    ],
    [
      {
        type: "action",
        actions: [
          {
            type: "input-with-buttons",
            elements: !sold
              ? [
                  {
                    value: acceptOffer,
                    title: "Accept",
                    type: OFFER_BUTTON_TYPES.ACCEPT_OFFER,
                    enableText:
                      access?.[OFFER_BUTTON_TYPES.ACCEPT_OFFER]?.enableText,
                    enableFreeze:
                      access?.[OFFER_BUTTON_TYPES.ACCEPT_OFFER]?.enableFreeze,
                  },
                  {
                    value: bumpOffer,
                    title: "BUMP",
                    type: OFFER_BUTTON_TYPES.BUMP_OFFER,
                    enableText:
                      access?.[OFFER_BUTTON_TYPES.BUMP_OFFER]?.enableText,
                    enableFreeze:
                      access?.[OFFER_BUTTON_TYPES.BUMP_OFFER]?.enableFreeze,
                  },
                  {
                    value: counterOffer,
                    title: "Counter-Offer",
                    type: OFFER_BUTTON_TYPES.COUNTER_OFFER,
                    enableText:
                      access?.[OFFER_BUTTON_TYPES.COUNTER_OFFER]?.enableText,
                    enableFreeze:
                      access?.[OFFER_BUTTON_TYPES.COUNTER_OFFER]?.enableFreeze,
                  },
                ]
              : [],
          },
        ],
      },
    ],
  ];

  data.push(actions);

  return {
    id,
    offer,
    bumpOffer,
    acceptOffer,
    counterOffer,
    fade,
    data: data,
  };
}

export const auctionColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.AUCTION_INFO,
    label: "",
    minWidth: 150,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.VEHICLE_INFO,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.MISC_INFO,
    label: "",
    minWidth: 250,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.VEHICLE_NUMBERS,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.STATUS_DETAILS,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
    align: "left",
  },
  {
    id: TABLE_COLUMNS.AUCTION_BID_INFO,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
    align: "center",
  },
];

export const auctionRerunColumns = vehicleInventoryColumns;
export const auctionSecondChanceColumns = vehicleInventoryColumns;

export function oveRequestCreateData(
  id: string,
  vin: string,
  make: string,
  model: string,
  year: string,
  dealerName: string,
  vehicleLocation: string,
  age: string,
  announcement: string,
  crGrade: string,
  acv: string,
  dealerStockNumber: string,
  status: string,
  statusType: string,
  eventPrice: string,
  showVINModal: boolean
): {
  id: string;
  data: CellRow[][];
} {
  return {
    id,
    data: [
      [
        { title: "Age", value: age, titleWidth: "w-8", valueWidth: "w-40" },
        {
          title: "VIN",
          value: vin,
          titleWidth: "w-8",
          valueWidth: "w-40",
          valueColor: "text-secondary-300",
          showVINModal,
        },
      ],
      [{ type: "divider" }],
      [
        {
          title: "Vehicle",
          value: `${year}, ${make}, ${model}`,
          titleWidth: "w-14",
          valueWidth: "w-40",
        },

        {
          title: "Location",
          value: vehicleLocation,
          titleWidth: "w-14",
          valueWidth: "w-40",
        },
      ],
      [
        {
          title: "Announcement",
          value: announcement,
          titleWidth: "w-32",
          valueWidth: "w-40",
        },
        {
          title: "Dealer",
          value: dealerName,
          titleWidth: "w-32",
          valueWidth: "w-40",
        },
        //Hided CR Report and Car Faxx Report Work for now, this will be included in future
        // {
        //   title: "CR Grade",
        //   value: crGrade,
        //   titleWidth: "w-32",
        //   valueWidth: "w-40",
        // },
      ],
      [{ title: "ACV", value: acv, titleWidth: "w-8", valueWidth: "w-20" }],
      [{ type: "divider" }],
      [
        {
          title: "Ove Event Price",
          value: eventPrice,
          titleWidth: "w-32",
          valueWidth: "w-40",
        },
        {
          title: "Status",
          value: status,
          titleWidth: "w-32",
          valueWidth: "w-40",
          type: statusType,
          options: OveStatusOptions,
          selectType: SELECT_TYPES.OVE_STATUS,
        },
      ],
    ],
  };
}

export const oveRequestColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.AUCTION_INFO,
    label: "",
    minWidth: 150,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.VEHICLE_INFO,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.MISC_INFO,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.VEHICLE_NUMBERS,
    label: "",
    minWidth: 150,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.OVE_DETAILS,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
    align: "left",
  },
  {
    id: TABLE_COLUMNS.MORE_ACTION,
    label: "",
    minWidth: 50,
    dataType: CONSTANTS.TEXT,
    align: "center",
  },
];

export const arbitrationColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.CASE_INFO,
    label: "",
    minWidth: 150,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.VEHICLE_INFO,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ARBITRATION_AUCTION_INFO,
    label: "",
    minWidth: 250,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ARBITRATION_INFO,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ACTION,
    label: "",
    minWidth: 200,
    dataType: CONSTANTS.TEXT,
    align: "center",
  },
];

export function arbitrationCreateData(
  caseId: string,
  caseOpenDate: string,
  caseCloseDate: string,
  vin: string,
  make: string,
  model: string,
  year: string,
  dealerName: string,
  vehicleLocation: string,
  auctionDate: string,
  auctionLocation: string,
  soldPrice: string,
  arbitrationStatus: string,
  unreadCount: number,
  showVINModal: boolean
): {
  id: string;
  unreadCount: number;
  data: CellRow[][];
} {
  return {
    id: caseId,
    unreadCount,
    data: [
      [
        {
          title: "Case ID",
          value: caseId,
          titleWidth: "w-32",
          valueWidth: "w-32",
        },
        {
          title: "Case Open Date",
          value: caseOpenDate,
          titleWidth: "w-32",
          valueWidth: "w-32",
        },
        {
          title: "Case Close Date",
          value: caseCloseDate,
          titleWidth: "w-32",
          valueWidth: "w-32",
        },
      ],
      [{ type: "divider" }],
      [
        {
          title: "Vehicle",
          value: `${year}, ${make}, ${model}`,
          titleWidth: "w-32",
          valueWidth: "w-40",
        },
        {
          title: "VIN",
          value: vin,
          titleWidth: "w-32",
          valueWidth: "w-40",
          valueColor: "text-secondary-300",
          showVINModal,
        },
        {
          title: "Location",
          value: vehicleLocation,
          titleWidth: "w-32",
          valueWidth: "w-40",
        },
      ],
      [
        {
          title: "Auction Date",
          value: auctionDate,
          titleWidth: "w-32",
          valueWidth: "w-32",
        },
        {
          title: "Auction Location",
          value: auctionLocation,
          titleWidth: "w-32",
          valueWidth: "w-32",
        },
        {
          title: "Sold Price",
          value: soldPrice,
          titleWidth: "w-32",
          valueWidth: "w-32",
        },
        {
          title: "Dealer",
          value: dealerName,
          titleWidth: "w-32",
          valueWidth: "w-32",
        },
      ],
      [{ type: "divider" }],
      [
        {
          title: "Arbitration Status",
          value: arbitrationStatus,
          titleWidth: "w-32",
          valueWidth: "w-32",
        },
      ],
      [
        {
          type: "action",
          actions: [
            {
              type: "buttons",
              elements: [
                {
                  title: "Arbitration",
                  color: "primary",
                  type: ACTION_BUTTON_TYPES.OPEN_ARBITRATION,
                },
              ],
            },
          ],
        },
      ],
    ],
  };
}

export function vehicleListCreateData(
  id: string,
  year: string,
  make: string,
  model: string,
  color: string
): Data {
  return {
    id,
    year,
    make,
    model,
    color,
  };
}

export const auctionListColumns: readonly Column[] = [
  {
    id: TABLE_COLUMNS.ID,
    label: "Auction ID",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
    type: "id",
  },
  {
    id: TABLE_COLUMNS.AUCTION_LOCATION,
    label: "Auction Location",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.AUCTION_STATUS,
    label: "Auction Status",
    minWidth: 200,
    filter: CONSTANTS.SELECT,
    dataType: CONSTANTS.TEXT,
    options: auctionStatusOptions,
  },
  {
    id: TABLE_COLUMNS.AUCTION_DATE,
    label: "Auction Date",
    minWidth: 200,
    filter: CONSTANTS.DATE_RANGE_PICKER,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.CREATED_BY,
    label: "Created By",
    minWidth: 200,
    filter: CONSTANTS.INPUT,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.CREATION_DATE,
    label: "Creation Date",
    minWidth: 200,
    filter: CONSTANTS.DATE_RANGE_PICKER,
    dataType: CONSTANTS.TEXT,
  },
  {
    id: TABLE_COLUMNS.ACTIVE_STATUS,
    label: "Active Status",
    minWidth: 130,
    filter: CONSTANTS.SELECT,
    options: statusOptions,
    currentValue: "all",
    dataType: CONSTANTS.TAG,
  },
  {
    id: TABLE_COLUMNS.ACTION,
    label: "Search",
    minWidth: 200,
    filter: CONSTANTS.BUTTON,
    align: "center",
    dataType: CONSTANTS.BUTTON,
    hideLabel: true,
  },
];

export function auctionListCreateData(
  id: string,
  auctionLocation: string,
  auctionDate: string,
  createdBy: string,
  creationDate: string,
  activeStatus: boolean,
  status: number,
  disableEdit: boolean,
  finishAuctionButton: boolean
): Data {
  return {
    id,
    auctionLocation,
    auctionStatus: AuctionStatusName[status],
    auctionDate,
    createdBy,
    creationDate,
    activeStatus: {
      label: activeStatus ? "Active" : "In Active",
      color: activeStatus ? "primary" : "secondary",
    },
    auction_status: status,
    disabled: disableEdit,
    finishAuctionButton,
  };
}

export const createVINModalVehicleData = (
  age: string,
  dealer: string,
  location: string,
  color: string,
  trim: string,
  mileage: string,
  crGrade: string,
  crUrl: string,
  acv: string,
  floorPrice: string,
  dealerStockNumber: string
): Array<VINModalDataObjectType> => {
  return [
    { title: "Age", value: age },
    { title: "Dealer", value: dealer },
    { title: "Location", value: location },
    { title: "Color", value: color },
    // { title: "Trim", value: trim },
    { title: "Mileage", value: mileage },
    // { title: "CR Grade", value: crGrade, url: crUrl, icon: true }, //Hided CR Report and Car Faxx Report Work for now, this will be included in future
    { title: "ACV", value: acv },
    { title: "Floor Price", value: floorPrice },
    { title: "Dealer Stock Number", value: dealerStockNumber },
  ];
};

export const createVINModalAuctionData = (
  crType: string,
  crStatus: string,
  crGrade: string,
  crUrl: string,
  transportStatus: string,
  laneAndRun: string,
  carStatus: string,
  psiType: string,
  psiStatus: string,
  titleType: string,
  title: string,
  showTitle: boolean,
  arbitration: string
): Array<VINModalDataObjectType> => {
  const rows = [
    //Hided CR Report and Car Faxx Report Work for now, this will be included in future
    // {
    //   title: "Condition Report",
    //   value: crStatus,
    //   type: crType,
    //   data: {
    //     crGrade,
    //     crUrl,
    //   },
    // },

    // {
    //   title: "Transportation",
    //   value: transportStatus,
    //   type: MODAL_FIELD_TYPES.TEXT,
    // },
    // {
    //   title: "Auction",
    //   value: laneAndRun,
    //   type: MODAL_FIELD_TYPES.TEXT,
    // },
    { title: "Car", value: carStatus, type: MODAL_FIELD_TYPES.TEXT },
    {
      name: MODAL_FIELD_NAMES.PSI,
      title: "PSI",
      value: psiStatus,
      type: psiType,
      options: psiOptions,
    },
  ];

  if (showTitle) {
    rows.push({
      name: MODAL_FIELD_NAMES.TITLE,
      title: "Title",
      value: title,
      type: titleType,
      options: titleOptions,
    });
  }

  rows.push({
    title: "Arbitration",
    value: arbitration,
    type: MODAL_FIELD_TYPES.TEXT,
  });

  return rows;
};

export const createVINModalButtons = (
  rerun: boolean,
  secondChance: boolean,
  oveEvent: boolean
): Array<VINModalDataObjectType> => {
  return [
    {
      title: "Rerun In Auction",
      className: "modal-button padding-7",
      type: MODAL_BUTTON_NAMES.RERUN,
      show: rerun,
    },
    {
      title: "Second Chance Auction",
      className: "modal-button padding-7 margin-x-4",
      type: MODAL_BUTTON_NAMES.SECOND_CHANCE,
      show: secondChance,
    },
    {
      title: "OVE Event Sale",
      className: "padding-7 outline-yellow",
      type: MODAL_BUTTON_NAMES.OVE_EVENT,
      show: oveEvent,
    },
  ];
};

export const timezone: Array<SelectOption> = [
  { label: "(GMT -12:00) Eniwetok, Kwajalein", value: "-12:00" },
  { label: "(GMT -11:00) Midway Island, Samoa", value: "-11:00" },
  { label: "(GMT -10:00) Hawaii", value: "-10:00" },
  { label: "(GMT -9:30) Taiohae", value: "-09:50" },
  { label: "(GMT -9:00) Alaska", value: "-09:00" },
  { label: "(GMT -8:00) Pacific Time (US & Canada)", value: "-08:00" },
  { label: "(GMT -7:00) Mountain Time (US & Canada)", value: "-07:00" },
  {
    label: "(GMT -6:00) Central Time (US & Canada), Mexico City",
    value: "-06:00",
  },
  {
    label: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
    value: "-05:00",
  },
  { label: "(GMT -4:30) Caracas", value: "-04:50" },
  {
    label: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
    value: "-04:00",
  },
  { label: "(GMT -3:30) Newfoundland", value: "-03:50" },
  { label: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "-03:00" },
  { label: "(GMT -2:00) Mid-Atlantic", value: "-02:00" },
  {
    label: "(GMT -1:00) Azores, Cape Verde Islands",
    value: "-01:00",
  },
  {
    label: "(GMT) Western Europe Time, London, Lisbon, Casablanca",
    value: "+00:00",
  },
  { label: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "+01:00" },
  { label: "(GMT +2:00) Kaliningrad, South Africa", value: "+02:00" },
  {
    label: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
    value: "+03:00",
  },
  { label: "(GMT +3:30) Tehran", value: "+03:50" },
  { label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "+04:00" },
  { label: "(GMT +4:30) Kabul", value: "+04:50" },
  {
    label: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
    value: "+05:00",
  },
  { label: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "+05:50" },
  { label: "(GMT +5:45) Kathmandu, Pokhara", value: "+05:75" },
  { label: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "+06:00" },
  { label: "(GMT +6:30) Yangon, Mandalay", value: "+06:50" },
  { label: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "+07:00" },
  {
    label: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
    value: "+08:00",
  },
  { label: "(GMT +8:45) Eucla", value: "+08:75" },
  {
    label: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
    value: "+09:00",
  },
  { label: "(GMT +9:30) Adelaide, Darwin", value: "+09:50" },
  {
    label: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
    value: "+10:00",
  },
  { label: "(GMT +10:30) Lord Howe Island", value: "+10:50" },
  {
    label: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
    value: "+11:00",
  },
  { label: "(GMT +11:30) Norfolk Island", value: "+11:50" },
  {
    label: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
    value: "+12:00",
  },
  { label: "(GMT +12:45) Chatham Islands", value: "+12:75" },
  { label: "(GMT +13:00) Apia, Nukualofa", value: "+13:00" },
  { label: "(GMT +14:00) Line Islands, Tokelau", value: "+14:00" },
];

export const psiOptions: Array<SelectOption> = [
  { label: "Requested", value: PSIStatus.Requested },
  { label: "Passed", value: PSIStatus.Passed },
  { label: "Failed", value: PSIStatus.Failed },
  { label: "Not Applicable", value: PSIStatus.NotApplicable },
];

export const titleOptions: Array<SelectOption> = [
  { label: "Pending", value: TitleStatus.Pending },
  { label: "Absent", value: TitleStatus.Absent },
  { label: "Validated", value: TitleStatus.Validated },
  { label: "Exception", value: TitleStatus.Exception },
];
