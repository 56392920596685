import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToggleModalPayload } from "../../ts/api.types";
import { ModalState } from "../../ts/api.interface";

const initialState: ModalState = {
  modalType: "",
  submitText: "",
  open: false,
  edit: false,
  data: {
    sold: false,
    vehicleData: null,
    modalValues: {},
    vehicleId: "",
    conditionReportId: "",
  },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    toggleModal: (state, action: PayloadAction<ToggleModalPayload>) => {
      const { payload } = action;
      state.open = payload.open;
      state.edit = payload.edit || false;
      state.data = payload.data || {};
      state.submitText = payload.submitText || "";
      state.modalType = payload.modalType || "";
    },
    setModalData: (
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) => {
      const { payload } = action;
      state.data = {
        modalValues: {
          ...state?.data?.modalValues,
          [payload.key]: payload.value,
        },
      };
    },
    resetModalData: () => {
      return initialState;
    },
  },
});

export const { toggleModal, setModalData } = modalSlice.actions;

export default modalSlice.reducer;
