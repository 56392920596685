const curveType: "straight" = "straight";
const gridPosition: "back" = "back";
const dateTime: "datetime" = "datetime";

export const createAreaChart = (title: string, data: Array<Array<number>>) => {
  return {
    options: {
      chart: {
        id: "area-chart",
        background: "#FFFFFF",
        foreColor: "#A9B5DF",
        fontFamily: "Open Sans",

        toolbar: {
          show: true,
          offsetY: -10,
          tools: {
            selection: true,
            zoom: false,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: false,
            download: false,
          },
        },
      },

      xaxis: {
        tickAmount: 5,
        type: dateTime,
        labels: {
          trim: true,
          style: {
            colors: "#A9B5DF",
            fontSize: "12px",
          },
        },
        title: {
          style: {
            fontWeight: 700,
          },
        },
        axisBorder: {
          show: true,
          color: "#A9B5DF",
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        tickAmount: 4,
        labels: {
          style: {
            colors: "#A9B5DF",
            fontSize: "12px",
          },
        },
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      markers: {
        size: 5,
        colors: ["#FDEE30"],
        strokeWidth: 0,
        hover: {
          size: 0,
          sizeOffset: 6,
        },
      },
      stroke: {
        show: false,
        curve: curveType,
      },
      colors: ["#FDEE30"],
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 700,
        },
      },
      grid: {
        show: true,
        borderColor: "#A9B5DF",
        strokeDashArray: 0,
        position: gridPosition,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          opacity: 0.5,
        },
        column: {
          opacity: 0.5,
        },
      },
    },
    series: [
      {
        name: title,
        data: data,
      },
    ],
  };
};

export const createDateTimeChart = (
  title: string,
  data: Array<Array<number>>
) => {
  return {
    series: [
      {
        name: title,
        data: data,
      },
    ],
    options: {
      chart: {
        id: "area-datetime",
        background: "#FFFFFF",
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: true,
          offsetY: -10,
          tools: {
            selection: true,
            zoom: false,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: false,
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      stroke: {
        show: false,
      },
      xaxis: {
        type: dateTime,
        tickAmount: 6,
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        title: {
          text: "Date",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000000",
            fontSize: "14px",
            fontFamily: "Open Sans",
            fontWeight: 600,
          },
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        title: {
          text: "Sold",
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000000",
            fontSize: "14px",
            fontFamily: "Open Sans",
            fontWeight: 600,
          },
        },
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "fill",
        colors: ["#4F84FF"],
      },
      grid: {
        show: false,
      },
    },
  };
};
