export enum Pages {
  Dashboard = "dashboard",
  Vehicle = "vehicle",
  Auction = "auction",
  Arbitration = "arbitration",
  Roles = "group",
  User = "user",
  Stakeholder = "stakeholder",
  Transportation = "transportation",
  AuditTrail = "audit_trail",
  Setting = "setting",
  Logout = "logout",
  Sidebar = "sidebar",
  KbbReport = "kbb_report",
}

export enum Tabs {
  // Vehicle
  InventoryTab = "inventory_tab",
  SoldTab = "sold_tab",
  RemoveFromLotTab = "remove_from_lot_tab",
  // Auction
  AuctionTab = "auction_tab",
  OVERequestTab = "ove_request_tab",
  SecondChanceTab = "second_chance_tab",
  ReRunTab = "rerun_tab",
  AuctionListTab = "auction_list_tab",
  // Stakeholders
  DealersTab = "dealer_tab",
  TransportersTab = "transporter_tab",
  // Users
  UserTab = "users_tab",
  DealerUsersTab = "dealer_users_tab",
  TransporterUsersTab = "transporter_user_tab",
  // Transportation
  TransportationTab = "transportation_tab",
  TransportRequestTab = "transport_request_tab",
  UnAssignedTTransporterTab = "unassigned_transport_tab",
  ScheduledTransportTab = "scheduled_transport_tab",
  CompletedTransportTab = "completed_transport_tab",
  // Settings
  LocationTab = "location_tab",
  RemarksTab = "remarks_tab",
  AnnouncementTab = "announcement_tab",
  // VIN Modal
  VINModal = "vin_modal",
}
export enum Actions {
  // Vehicle
  AddVehicle = "add_vehicle",
  UpdateVehicle = "update_vehicle",
  ConditionReportRequest = "condition_report_request",
  AddTransportRequest = "add_transport_request",
  AssignAuction = "assign_auction",
  CarFaxReport = "car_fax_report",
  RemoveFromAuction = "remove_from_auction",
  // From Modal
  OVEEventSale = "ove_event_sale",
  PSIStatusUpdate = "psi_status_update",
  TitleStatusUpdate = "title_status_update",
  RerunInAuctionButton = "rerun_in_auction_button",
  SecondChanceAuctionButton = "second_chance_auction_button",
  ConditionReportUpdate = "condition_report_update",
  // Post Sale
  NewArbitrationCase = "new_arbitration_case",
  PSIStatus = "psi_status",
  TitleStatus = "title_status",
  // Auction
  AddAuction = "add_auction",
  UpdateAuction = "update_auction",
  RerunInAuction = "rerun_in_auction",
  SecondChanceAuction = "second_chance_auction",
  UpdateOVEStatus = "update_ove_status",
  // Auction Actions
  Offer = "offer",
  AcceptOffer = "accept_offer",
  BumpOffer = "bump_offer",
  CounterOffer = "counter_offer",
  // Arbitration Actions
  ArbitrationReason = "arbitration_reason",
  AdjustmentAmount = "adjustment_amount",
  ArbitrationAction = "arbitration_action",
  // Groups
  AddGroup = "add_group",
  UpdateGroup = "update_group",
  // Stakeholders
  AddDealer = "add_dealer",
  UpdateDealer = "update_dealer",
  AddTransporter = "add_transporter",
  UpdateTransporter = "update_transporter",
  // Users
  AddAdminUser = "add_admin_user",
  UpdateAdminUser = "update_admin_user",
  AddDealerUser = "add_dealer_user",
  UpdateDealerUser = "update_dealer_user",
  AddTransporterUser = "add_transporter_user",
  UpdateTransporterUser = "update_transporter_user",
  // Transportation
  AcceptTransportRequest = "accept_transport_request",
  AlternateDateRequest = "alternate_date_request",
  AcceptAlternateDateRequest = "accept_alternate_date_request",
  AssignedTransportActions = "assigned_transport_actions",
  // Setting
  AddLocation = "add_location",
  UpdateLocation = "update_location",
  AddAnnouncement = "add_announcement",
  UpdateAnnouncement = "update_announcement",
  AddRemark = "add_remark",
  UpdateRemark = "update_remark",
}
