import Button from "@mui/material/Button";
import { ButtonComponentProps } from "../../ts/component.types";
import "./../../styles/ButtonComponent.css";
import {
  BUTTON_SIZES,
  COLOR_CLASSES,
  CONSTANTS,
  SIZES,
} from "../../misc/constants";
import resetIcon from "./../../assets/images/ResetIcon.svg";
import DoneIcon from "@mui/icons-material/Done";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const ButtonComponent = ({
  onClick,
  color,
  size,
  text,
  type,
  className,
  disabled,
}: ButtonComponentProps) => {
  return (
    <Button
      onClick={onClick}
      className={`${
        disabled
          ? COLOR_CLASSES.CHARCOAL
          : color === CONSTANTS.PRIMARY
          ? COLOR_CLASSES.YELLOW
          : color === CONSTANTS.SECONDARY
          ? COLOR_CLASSES.CHARCOAL
          : color === CONSTANTS.TERTIARY
          ? COLOR_CLASSES.GREEN
          : color === CONSTANTS.QUATERNARY
          ? COLOR_CLASSES.PINK
          : COLOR_CLASSES.RED
      } ${
        size === SIZES.SMALL ? BUTTON_SIZES.SMALL : BUTTON_SIZES.LARGE
      } custom-button ${className}`}
      variant="contained"
      disabled={disabled}
    >
      {type === CONSTANTS.RESTART ? (
        <img className="mr-2" src={resetIcon} alt="Reset" />
      ) : type === CONSTANTS.DONE ? (
        <DoneIcon
          className="text-tertiary-100"
          sx={{ width: "18px", height: "18px" }}
        />
      ) : type === CONSTANTS.PRINT ? (
        <PrintRoundedIcon className="text-primary-100 mr-2" />
      ) : type === CONSTANTS.NEW_WINDOW ? (
        <OpenInNewIcon
          className="text-tertiary-100 mr-2"
          sx={{ width: "18px", height: "18px" }}
        />
      ) : null}
      {text}
    </Button>
  );
};

export default ButtonComponent;
