import { styled } from "@mui/material/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  PaginationComponentProps,
  SelectedPaginationProps,
} from "../../ts/component.types";
import { SIZES } from "../../misc/constants";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export default function PaginationComponent({
  items,
  handlePageChange,
}: PaginationComponentProps) {
  
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    selected: SelectedPaginationProps,
    onClick: React.ReactEventHandler
  ) => {
    handlePageChange(selected);
    onClick(event);
  };

  return (
    <nav>
      <List>
        <li>
          <button
            type="button"
            onClick={(e) => {
              const item = items[0];
              handleChange(
                e,
                { page: item?.page, selected: item?.selected },
                item?.onClick
              );
            }}
            disabled={items[0]?.disabled}
          >
            <ArrowBackIosNewIcon fontSize={SIZES.SMALL} />
          </button>
        </li>
        <div className="flex items-center justify-center bg-primary-100 rounded-md mx-1 px-1 border border-primary-200 ">
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = "…";
            } else if (type === "page") {
              children = (
                <button
                  type="button"
                  className={`text-md px-2 my-1 rounded-md ${
                    selected ? "bg-secondary-100" : "bg-primary-100"
                  }`}
                  onClick={(e) =>
                    handleChange(e, { page, selected }, item?.onClick)
                  }
                  disabled={item.disabled}
                >
                  {page}
                </button>
              );
            }

            return (
              <li key={index} className="">
                {children}
              </li>
            );
          })}
        </div>

        <li>
          <button
            type="button"
            onClick={(e) => {
              const elementIndex = items?.length - 1;
              const item = items[elementIndex];
              handleChange(
                e,
                { page: item?.page, selected: item?.selected },
                item?.onClick
              );
            }}
            disabled={items[items?.length - 1]?.disabled}
          >
            <ArrowForwardIosIcon fontSize={SIZES.SMALL} />
          </button>
        </li>
      </List>
    </nav>
  );
}
